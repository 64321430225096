import React, { useState, useEffect } from 'react';
//import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { getKakao, getKakaoInfo, loginUserByKakao, signupUserByKakao } from '../redux/actions/userActions';
import { CircularProgress, Box, Typography, Button } from '@material-ui/core';

import Footer from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar'

export const KakaoLink = (props) => {
    const [type, ] = useState();
    //const [kakaoResponse, setKakaoResponse] = useState();
    const { kakaoInfo } = useSelector(state => state.user);
    const { loading, errors } = useSelector(state => state.UI);

    const dispatch = useDispatch();
    const kcode = props.location.search.split('=')[1].split('&')[0];
    const mToken = props.location.search.split('&')[1];
    // 토큰 연결
    useEffect(() => {
        if (kcode && kcode !== 'withApp') {
            dispatch(getKakao(kcode));
        } else if (kcode && kcode === 'withApp') { // 카카오 톡로그인인 경우
            dispatch(getKakaoInfo(mToken))
        }
    }, [kcode, mToken, dispatch]);

    //로그인 처리
    useEffect(() => {
        if (kakaoInfo.id) {
            const kakaoLoginData = {
                email: kakaoInfo.kakao_account ? kakaoInfo.kakao_account.email : '',
                password: String(kakaoInfo.id)
            };
            dispatch(loginUserByKakao(kakaoLoginData, props.history));
        }
    }, [kakaoInfo, props.history, type, dispatch])

    // 로그인 실패시
    const handleSingup = () => {
        if (kakaoInfo.id) {
            let phoneNumber = kakaoInfo.kakao_account ? kakaoInfo.kakao_account.phone_number : '';
            let kakaoUserData = {
                email: kakaoInfo.kakao_account ? kakaoInfo.kakao_account.email : '',
                password: String(kakaoInfo.id),
                name: kakaoInfo.properties ? kakaoInfo.properties.nickname : '',
                accountId: String(kakaoInfo.id),
                phone: String(phoneNumber).replace('+82 ', '0'),
                profileImage: kakaoInfo.properties ? kakaoInfo.properties.thumbnail_image : '',
                joinPath: 'kakao'
            };
            dispatch(signupUserByKakao(kakaoUserData, props.history));
        }

    }


    return (
        <>
            <Navbar />
            <div className='container'>
                <Box textAlign='center' height={600}>
                    {
                        loading && <CircularProgress size={100} thickness={6} style={{ marginTop: 150 }} />
                    }
                    {
                        errors && <div>
                            <Typography color='error'>카카오톡으로 회원가입하시겠습니까?</Typography>
                            <Button variant='contained' color='primary' onClick={handleSingup}>5초만에 카카오로 회원가입</Button>
                        </div>
                    }
                </Box>
                <Footer />
            </div>
        </>
    )
}


export default KakaoLink;


