import React, { useState } from 'react';

// components
import Navbar from '../../components/layout/Navbar';
import EnrollStepTitle from '../../components/enroll/EnrollStepTitle';
import EnrollStepper from '../../components/enroll/EnrollStepper';
import MainGrid from '../../components/layout/MainGrid';

import SelectLesson from '../../components/enroll/SelectLesson';

import Footer from '../../components/layout/Footer';
// redux stuff
import { useSelector } from 'react-redux';

// mui stuff
import withStyles from '@material-ui/core/styles/withStyles';



const styles = theme => ({
    ...theme.userPages,
    button: {
        margin: theme.spacing(3),
    },
    buttonRight: {
        margin: theme.spacing(3),
        paddingLeft: 'auto'
    },
    leftMargin: {
        marginLeft: theme.spacing(2),
    }
});


const EnrollStep2 = props => {
    const { history } = props;

    // redux

    const { credentials } = useSelector(state => state.user);
    const { lesson } = useSelector(state => state.enroll);
    //const dispatch = useDispatch();


    // const pName = localStorage.getItem('productName');
    // const pid = localStorage.getItem('productId');
    const productGroupName = localStorage.getItem('productGroupName');
    const categoryName = localStorage.getItem('categoryName');

    const hName = credentials.myHakwon ? credentials.myHakwon.hName : localStorage.getItem('hName');
    const [lessonName, setLessonName] = useState(lesson ? lesson.lessonName : localStorage.getItem('lessonName'));


    return (
        <>
            <Navbar menu={1} history={history} />
            <MainGrid>
                <EnrollStepTitle currentStep={3} />
                <EnrollStepper
                    step1={productGroupName + '(' + categoryName + ')'}
                    step2={hName}
                    step3={lessonName ? lessonName : '수업 선택'} />

                {/* 레슨 select */}
                <SelectLesson
                    setLessonName={setLessonName}
                    history={history}
                />

                <Footer />

            </MainGrid>
        </>
    )

}

export default withStyles(styles)(EnrollStep2);
