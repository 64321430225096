import React, { useState, useEffect } from 'react';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonInfo, getTeachersByHakwon, postTeacher, searchTeachersByName, getTeacher, updateTeacher, deleteTeacher } from '../../redux/actions/managerActions';
import { getUserData } from '../../redux/actions/userActions';
// components

import DummyDivider from '../../components/layout/DummyDivider';
import CenterLoading from '../../components/layout/CenterLoading';
import noImage from '../../images/no-img.png';
import TeacherInfo from '../../components/manager/TeacherInfo';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Skeleton } from '@material-ui/lab';

import { IconButton, Typography, Divider, RadioGroup, Radio, FormControl, FormControlLabel, Box, List, ListItemSecondaryAction, ListItemAvatar, ListItem, ListItemText, Avatar, Button, TextField, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { EditRounded, SaveRounded, SearchRounded } from '@material-ui/icons';
import LevelCheck from './LevelCheck';
import { NotAllowed } from './NotAllowed';



const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    btn: {
        margin: theme.spacing(1)
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        backgroundColor: '#ffffff',
    },
    text: {
        marginLeft: theme.spacing(4)
    },
    divider: {
        backgroundColor: '#555'
    },
    margin: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3)
    },
    box: {
        margin: theme.spacing(6),
        height: 300,
        maxWidth: 500
    },
    paper: {
        padding: theme.spacing(4)
    },
    left: {
        marginLeft: theme.spacing(5)
    }

});
const WhiteRadio = withStyles({
    root: { color: "#666" }
})(Radio);

export const Teacher = (props) => {
    const { classes } = props;
    const { credentials, rnr } = useSelector(state => state.user);
    const { hakwon, teacher, teachers, checkteachers, managerLoading, managerErrors } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    const [allowed, setAllowed] = useState(false); // 권한이 있는지 확인

    const [nickname, setNickname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [checkId, setCheckId] = useState('');
    const [checkedTeacher, setCheckedTeacher] = useState({});


    // dialog
    const [open, setOpen] = useState(false);
    const [dUid, setDUid] = useState('');
    const [m1, setM1] = useState('');
    const [m2, setM2] = useState('');
    const [tname, setTname] = useState('');
    const [lev, setLev] = useState(null);
    const [isWorking, setIsWorking] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };
    if (!localStorage.getItem('hId')) {
        localStorage.setItem('hId', credentials.myHakwon?.hakwonId);
    }
    const hId = credentials ? credentials.myHakwon?.hakwonId : localStorage.getItem('hId');
    // hakwon 세팅
    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
    }, [dispatch]);

    // 교사정보불러오기
    useEffect(() => {
        dispatch(getTeachersByHakwon(hId));

    }, [dispatch, hId]);
    

    const searchTeachers = () => {
        if ((nickname === '' && phone === '') || (nickname === '' && email === '') || (phone === '' && email === '')) {
            alert('2개 이상은 입력하셔야 검색이 가능합니다.');
        } else {
            const data = {
                name: nickname,
                phone: phone,
                email: email
            }
            dispatch(searchTeachersByName(data));
        }
    }
    const onChangeCheckId = (e) => {
        setCheckId(e.target.value);
        setCheckedTeacher(checkteachers[Number(e.target.name)]);

    }
    const saveToTeacher = () => {
        let data = {
            hakwonId: credentials.myHakwon.hakwonId,
            isWorking: true,
            phone: checkedTeacher.phone ? phoneFormat(checkedTeacher.phone) : '',
            teacherLevel: 3,
            teacherName: checkedTeacher.name,
            teacherUid: checkedTeacher.uid,
            profileImage: checkedTeacher.profileImage,
            memo1: ' ',
            memo2: ' '
        }
        
        dispatch(postTeacher(data));
        setNickname('');
        setPhone('');
        setEmail('');

    }

    const editTeacherInfo = (uid) => {
        setOpen(true);
        setDUid(uid);
        dispatch(getTeacher(uid));

    }
    const deleteTeacherInfo = () => {
        const tUid = dUid;
        dispatch(deleteTeacher(tUid, hId));
        setOpen(false);
    }
    const saveNewInfo = () => {
        let data = {
            isWorking: isWorking,
            teacherLevel: Number(lev),
            teacherUid: dUid,
            memo1: m1,
            memo2: m2,
            teacherName: tname,
            hakwonId: hId,
        }
        dispatch(updateTeacher(data));
        setOpen(false);
    }

    return (

        <div>
            <LevelCheck
                checkingTeacherLevel={1}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />

            <Box height={20} />
            <Typography variant='subtitle1' gutterBottom className={classes.margin}>교사 권한 관리</Typography>
            <Divider light variant='middle' className={classes.divider} />
            <Box align='right'>
                <Typography variant='caption' className={classes.margin}>{credentials.myHakwon?.hName}</Typography>
            </Box>

            <DummyDivider top={30} />
            {
                managerLoading && <Box m={3}><Skeleton variant='rect' height={100} style={{ backgroundColor: '#666' }} /></Box>
            }
            {
                !allowed && <NotAllowed allowed={allowed} rnr={rnr} />
            }
            {
                allowed &&
                <div>
                    <Box m={3}>
                        <List dense className={classes.root}>
                            {
                                managerLoading ? <Skeleton variant='rect' height={50} /> :

                                    teachers.teachers?.sort((a, b) => a['teacherLevel'] - b['teacherLevel'])
                                        .map((t, index) => {

                                            return (
                                                <ListItem key={index} button>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={`Avata`}
                                                            src={t.profileImage ? t.profileImage : noImage}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<Typography variant='subtitle2' color='textPrimary'>{t.teacherName}</Typography>}
                                                        secondary={
                                                            <Typography variant='subtitle2' color='error'>레벨: {t.teacherLevel} </Typography>
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={<Typography variant='subtitle2' color='textPrimary'>{t.memo1}</Typography>}
                                                        secondary={<Typography variant='caption' color='textPrimary'>{t.memo2}</Typography>}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="edit" onClick={() => editTeacherInfo(t.teacherUid)}>
                                                            <EditRounded />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                            );
                                        })

                            }
                            {

                                (teachers.teachers?.length === 0) && <Box m={5} align='center'><Typography color='error' variant='subtitle2'>교사 등록이 필요합니다.</Typography></Box>
                            }


                        </List>
                    </Box>
                    <Box height={20} />

                    <Typography variant='subtitle1' gutterBottom className={classes.margin}>교사 검색 후 등록</Typography>
                    <Divider light variant='middle' style={{ marginTop: 10, marginBottom: 10 }} className={classes.divider} />
                    <Box m={3}>
                        <Typography color='secondary' variant='body2' gutterBottom>2개 이상의 정보를 입력하셔야 검색 가능합니다.</Typography>
                        <TextField
                            name='nickname'
                            variant='filled'
                            type='name'
                            label='카카오닉네임(이름)'
                            className={classes.textField}
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            color='secondary'
                            size='small'

                        />
                        <Typography variant='body2' className={classes.text} gutterBottom>카카오에 등록된 닉네임(실명이 아닐수 있습니다)</Typography>
                        <TextField
                            name='phone'
                            type='text'
                            variant='filled'
                            label='휴대전화번호'
                            className={classes.textField}
                            value={phone}
                            size='small'
                            onChange={e => setPhone(e.target.value)}
                            color='secondary'

                        />
                        <Typography variant='body2' className={classes.text} gutterBottom>카카오계정 휴대전화번호</Typography>
                        <TextField
                            name='email'
                            type='email'
                            variant='filled'
                            label='카카오계정이메일'
                            className={classes.textField}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            color='secondary'
                            size='small'

                        />
                        <Typography variant='body2' className={classes.text} gutterBottom>카카오에 가입된 계정 이메일</Typography>
                    </Box>
                    <Box>
                        <Button
                            className={classes.left}
                            color='primary'
                            size='small'
                            variant='contained'
                            endIcon={<SearchRounded />}
                            onClick={searchTeachers}
                            disabled={managerLoading}>
                            검색
                </Button>
                    </Box>
                    <Box align='center' m={4}>
                        {managerLoading && <CenterLoading />}
                        {managerErrors && <Typography color='error'>{managerErrors.error}</Typography>}
                        <FormControl component="fieldset">
                            <RadioGroup value={checkId} onChange={onChangeCheckId}>
                                {
                                    checkteachers.map((te, index) =>
                                        <FormControlLabel
                                            key={index}
                                            value={te.uid}
                                            control={<WhiteRadio name={String(index)} />}
                                            label={
                                                <Box>
                                                    <img style={{ float: 'left' }} src={(typeof te.profileImage === 'undefined' || te.profileImage === '' || te.profileImage === null) ? te.profileImage : noImage} width='33px' height='33px' alt='noImage' />
                                                    <Typography variant='subtitle2'>{te.name + ' ' + phoneFormat(te.phone) + ' ' + te.email}</Typography>
                                                </Box>
                                            }
                                        />
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                        {
                            checkteachers && <Box m={2} align='center'>
                                <Button
                                    color='primary'
                                    size='small'
                                    variant='contained'
                                    className={classes.btn}
                                    endIcon={<SaveRounded />}
                                    onClick={saveToTeacher}
                                    disabled={managerLoading}>
                                    교사로 등록
                                </Button>
                            </Box>
                        }

                    </Box>
                    <Box m={2}>
                        <Box height={40} />
                        <Typography color='primary' variant='subtitle1' gutterBottom>*등록 안내</Typography>
                        <Typography variant='subtitle2' gutterBottom>필요한정보</Typography>
                        <Typography variant='body2' gutterBottom> - 교사의 회원가입이 필수임</Typography>
                        <Typography variant='body2' gutterBottom> - 이름, 휴대전화번호/이메일 중 2개이상 선택입력 후,</Typography>
                        <Typography variant='body2' gutterBottom> - 검색하여 선택하고 권한을 부여함</Typography>

                        <Box height={20} />
                        <Typography color='primary' variant='subtitle1' gutterBottom>*권한 설정 안내</Typography>
                        <Typography variant='subtitle2' gutterBottom>권한의 종류</Typography>
                        <Typography variant='body2' gutterBottom> - 레벨 3: 원생관리 기능부여</Typography>
                        <Typography variant='body2' gutterBottom> - 레벨 2: 원생관리+ 수업관리 기능까지</Typography>
                        <Typography variant='body2' gutterBottom> - 레벨 1: 원생/수업 + 교사정보관리, 학원정보 수정까지</Typography>
                        <Typography variant='body2' gutterBottom> - 레벨 0: 원생/수업 + 교사/학원정보 + 매출정산 정보까지 '학원장'과 동등한 권한 부여</Typography>
                    </Box>
                    <Box>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">교사 정보</DialogTitle>
                            <DialogContent>
                                <Box align='right'>
                                    <Button size='small' color="secondary" variant='outlined' disabled={managerLoading} onClick={deleteTeacherInfo}>
                                        교사정보삭제
                                    </Button>
                                </Box>
                                <DialogContentText>
                                    교사의 권한을 지정하여 주시기 바랍니다.
                                </DialogContentText>

                                <TeacherInfo
                                    teacherInfo={teacher}
                                    m1={m1}
                                    m2={m2}
                                    tname={tname}
                                    lev={lev}
                                    isWorking={isWorking}
                                    setM1={setM1}
                                    setM2={setM2}
                                    setTname={setTname}
                                    setIsWorking={setIsWorking}
                                    setLev={setLev}
                                />



                            </DialogContent>
                            <DialogActions>

                                <Button onClick={handleClose} color="primary" variant='outlined'>
                                    닫기
                        </Button>

                                <Button color="primary" variant='contained' disabled={managerLoading} onClick={saveNewInfo}>
                                    저장하고 닫기
                         </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </div>
            }


        </div >


    );
};


function phoneFormat(string) {
    if (string === '' || typeof string === 'undefined') {
        return;
    } else {
        if (string.startsWith("+")) {
            string = "0" + string.substring(3);
        } else if (string.startsWith("82")) {
            string = "0" + string.substring(2);
        }
        const str = string.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
        return String(str);
    }

}

export default withStyles(styles)(Teacher);

