import React, { useState } from 'react';
// components
import Navbar from '../../components/layout/Navbar';
import EnrollStepTitle from '../../components/enroll/EnrollStepTitle';
import EnrollStepper from '../../components/enroll/EnrollStepper';
import MainGrid from '../../components/layout/MainGrid';
import Pay from '../../components/enroll/Pay';

import Footer from '../../components/layout/Footer';
// redux stuff
import { useSelector } from 'react-redux';
// import { getLesson } from '../../redux/actions/enrollActions';
// mui stuff
import withStyles from '@material-ui/core/styles/withStyles';




const styles = theme => ({
    ...theme.userPages,
    button: {
        margin: theme.spacing(3),
    },
    buttonRight: {
        margin: theme.spacing(3),
        paddingLeft: 'auto'
    },
    leftMargin: {
        marginLeft: theme.spacing(2),
    }
});


const EnrollStep4 = props => {

    const { history } = props;
    // redux
    const { credentials } = useSelector(state => state.user);
    //const dispatch = useDispatch();


    // const pName = localStorage.getItem('productName');
    // const pid = localStorage.getItem('productId');
    const productGroupName = localStorage.getItem('productGroupName');
    const categoryName = localStorage.getItem('categoryName');
    const lessonName = localStorage.getItem('lessonName');
    //const lessonId = localStorage.getItem('lessonId');
    const hName = credentials.myHakwon ? credentials.myHakwon.hName : localStorage.getItem('hName');
    const [payMethod, setPayMethod] = useState(null);

    
    return (
        <>
            <Navbar menu={1} />
            <MainGrid>
                <EnrollStepTitle currentStep={4} />
                <EnrollStepper
                    step1={productGroupName + '(' + categoryName + ')'}
                    step2={hName}
                    step3={lessonName}
                    step4={payMethod ? payMethod : '결제선택'} />

                {/* 레슨 select */}
                <Pay
                    setPayMethod={setPayMethod}
                    history={history}
                />

                <Footer />
            </MainGrid>
        </>
    )

}

export default withStyles(styles)(EnrollStep4);
