import React from 'react';
//import {useDispatch} from 'react-redux';
//import {updateStudent} from '../../redux/actions/enrollActions';
// components
import DialogInputChild from '../../components/layout/DialogInputChild';
// MUI stuff
import { withStyles, useTheme } from '@material-ui/core/styles';
// redux




import { Box, Hidden, TextField, InputAdornment, IconButton, Typography, useMediaQuery} from '@material-ui/core';
import { FaceRounded, Delete as DeleteIcon } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
    textfield : {
        width: '100%',
        marginRight: theme.spacing(2)
    },
});
export const Children = (props) => {
    const { classes, child, setChild } = props;
    //const dispatch = useDispatch();
    const theme= useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const handleChildrenInfo = (e) => {

    }
    // const onDeleteInfo = (id) => {
    //     console.log(id);
    //     let newData = {
    //         studentId: id,
    //         isDeleted: true,
    //     }
    //     dispatch(updateStudent(newData));
    // }


    return (
        <div>
            {
                (typeof child === 'undefined' || child.length === 0) ?
                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                        <Hidden only='xs'>
                            <Box style={{ width: 70, padding: 5 }}>
                                <Typography variant={matches ? 'subtitle2':'h5'}>
                                    {child ? '자녀' : ''}
                                </Typography>
                            </Box>
                        </Hidden>
                        <Box display='flex' justifyContent='flex-start' className={classes.textfield}  >
                            <TextField
                                color='secondary'
                                variant='outlined'
                                name='child'
                                value={''}
                                onChange={e => setChild(child[0])}
                                placeholder='아이콘을 눌러 자녀를 등록하세요'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DialogInputChild
                                                title='자녀 정보'
                                                actionButton='저장'

                                            >
                                                <IconButton onClick={handleChildrenInfo}>
                                                    <FaceRounded color='secondary' />
                                                </IconButton>
                                            </DialogInputChild>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <DeleteIcon color='secondary' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{
                                    backgroundColor: '#ffffff',
                                    height: 56,

                                }}
                                fullWidth
                                disabled
                            />
                        </Box>
                    </Box>
                    :
                    child.map((ch, index) =>
                        <Box key={index} display='flex' flexDirection='row' justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
                            {/* <Hidden only='xs'> */}
                                <Box style={{ width: 70, padding: 5}}>
                                    <Typography variant={matches ? 'subtitle2':'h5'}>
                                        {index === 0 ? '자녀' : ''}
                                    </Typography>
                                </Box>
                           {/*  </Hidden> */}
                            <Box key={index} display='flex' justifyContent='flex-start' className={classes.textfield}  >
                                <TextField
                                    color='secondary'
                                    variant='outlined'
                                    name={ch.studentName}
                                    value={ch ? ch.studentName : ''}
                                    onChange={e => setChild(e.target.value)}
                                    placeholder='아이콘을 눌러 자녀를 등록하세요'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DialogInputChild
                                                    title='자녀 정보'
                                                    textContent='자녀 정보를 입력해주세요.'
                                                    actionButton='저장'
                                                    ch={ch}
                                                >
                                                    <IconButton onClick={handleChildrenInfo}>
                                                        <FaceRounded color='secondary' />
                                                    </IconButton>
                                                </DialogInputChild>
                                            </InputAdornment>
                                        ),
                                      
                                    }}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        height: 56,

                                    }}
                                    fullWidth
                                    readOnly
                                />
                            </Box>
                        </Box>
                    )

            }
        </div>
    );


};



export default withStyles(styles)(Children);

