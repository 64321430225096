import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { checkPaymentResultMobile } from '../../redux/actions/enrollActions';
// components
import Navbar from '../../components/layout/Navbar';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Grid, Typography, Box, Button, Link as MuiLink, Paper } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    paper: {
        padding: theme.spacing(4),
    },
});
export const PaymentResultMobile = (props) => {
    const { classes } = props;
    const { mobilePayCheckResult } = useSelector((state) => state.enroll);
    const dispatch = useDispatch();
    // // qurey string to <json>

    // imp_uid = 결제건을_특정하는_아임포트_번호 & merchant_uid=가맹점_고유_주문번호 & imp_success=true
    // imp_uid = 결제건을_특정하는_아임포트_번호 & merchant_uid=가맹점_고유_주문번호 & imp_success=false & error_code=에러_코드(현재_정리된_체계는_없음) & error_msg=에러_메시지

    const query = QueryStringToJSON(window.location.search.slice(1).split('&'));
    const { imp_uid, merchant_uid, imp_success, error_msg } = query;
    const [check, setCheck] = useState(false);

    // 발송여부를 localStorag에 imp_uid를 temp_imp에 저장함으로써 확인한다.
    const temp_imp_uid = localStorage.getItem('temp_imp');
    const isSentATA = temp_imp_uid === imp_uid;

    // 결제 검증 호출
    useEffect(() => {
        if (!!imp_uid & !!merchant_uid) {
            dispatch(checkPaymentResultMobile({ imp_uid, merchant_uid, checked: isSentATA }));
            localStorage.setItem('temp_imp', imp_uid);
        }
    }, [dispatch, imp_uid, isSentATA, merchant_uid]);

    // 검증 후, 상태 변경
    useEffect(() => {
        if (mobilePayCheckResult.status === 'success') {
            setCheck(true);
        } else {
            setCheck(false);
        }
    }, [mobilePayCheckResult.status]);

    const isSuccessed = getIsSuccessed();

    function getIsSuccessed() {
        if (typeof imp_success === 'string') return imp_success === 'true';
        if (typeof imp_success === 'boolean') return imp_success === true;
    }
    const resultType = isSuccessed ? '완료' : '실패';

    console.log('mobilePayCheckResult', mobilePayCheckResult);

    return (
        <>
            <Navbar />
            <div className="container">
                <Box p={1} m={1} align="center">
                    <Grid container>
                        <Grid item sm />
                        <Grid item sm style={{ textAlign: 'center' }}>
                            <Box height={40} />
                            <Typography variant="h4">결제 {resultType}</Typography>
                            <Box height={40} />
                            <Typography variant="subtitle2" color="primary">
                                결제 {resultType}하였습니다!
                            </Typography>
                            <Box height={40} />
                            <Paper className={classes.paper}>
                                <Typography display="inline">주문번호 : </Typography>
                                <Typography display="inline">{merchant_uid} </Typography>
                            </Paper>
                            <Box height={20} />
                            {!isSuccessed && <Typography color="error">{error_msg}</Typography>}
                            <Box height={20} />
                            {check ? (
                                <Typography color="textSecondary">{mobilePayCheckResult.message}</Typography>
                            ) : mobilePayCheckResult.status === 'failed' ? (
                                <Typography color="error"> [오류] : {mobilePayCheckResult.message}</Typography>
                            ) : (
                                <Typography color="textSecondary">결제 데이터 추가 검증 중입니다... ...</Typography>
                            )}
                            <Box height={40} />
                            <Typography variant="subtitle2" color="primary">
                                상세 주문 내역 및 배송 현황은
                            </Typography>
                            <Typography variant="subtitle2" color="primary">
                                <MuiLink href="/myclass">마이클래스에서</MuiLink> 확인하실수 있습니다.
                            </Typography>
                            <Box height={20} />
                            <Button variant="contained" color="primary" component={Link} to="/">
                                홈으로 돌아가기
                            </Button>
                        </Grid>
                        <Grid item sm />
                    </Grid>
                </Box>
            </div>
        </>
    );
};

function QueryStringToJSON(pairs) {
    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return JSON.parse(JSON.stringify(result));
}

export default withStyles(styles)(PaymentResultMobile);
