import {
    SET_MANAGER_HAKWON,
    SET_MANAGER_ERRORS,
    CLEAR_MANAGER_ERRORS,
    LOADING_MANAGER,
    STOP_LOADING_MANAGER,
    SET_LESSONS_MANAGER,
    SET_LESSON_MANAGER,
    CLEAR_LESSONS_MANAGER,
    SET_PRODUCTS_MANAGER,
    SET_PRODUCTGROUP,
    SET_PRODUCTGROUPALL,
    SET_TEACHER,
    SET_TEACHERS,
    SET_CHECKTEACHERS,
    CLEAR_CHECKTEACHERS,
    SET_MEMBERS,
    SET_PARENT,
    CLEAR_PARENT,
    SET_REQUESTS,
    SET_SALES,
    CLEAR_SALES,
    SET_SALES_SCHEDULE,
    SET_SALES_OTHERSCHEDULE,
    SET_BOARDS,
    CLEAR_BOARDS,
    SET_BOARD,
    CLEAR_BOARD,
    SET_URL,
    SET_SETTLEMENTS,
    SET_SALES_SETTLEMENTS,
    CLEAR_SALES_SETTLEMENTS,
    SET_TEACHING_PLANS,
    SET_SALE_DETAIL,
    CLEAR_SALE_DETAIL,
    SET_NEW_CATEGORY_LESSON,
    CLEAR_NEW_CATEGORY_LESSON,
    EDIT_SALES_SETTLEMENTS,
    SET_SETTLEMENTS_IDS,
    UPDATE_SALES_SETTLEMENTS,
} from '../types';

// Lesson, Hakwon, Teacher, 학원생, 매출(Sales), 정기결제(scheduce), Board(게시판) 등
const initialState = {
    managerLoading: false,
    managerErrors: null,
    hakwon: {},
    lessons: [],
    lesson: {},
    products: [],
    productGroup: {},
    productGroupAll: [],
    teachers: [],
    teacher: {},
    checkteachers: [],
    members: [],
    parent: {},
    requests: [],
    sales: [],
    salesDetail: {},
    settlements: [],
    salesForSettlements: [], //added
    schedules: [],
    otherSchedules: [],
    boards: [],
    board: {},
    url: {},
    teachingPlans: [],
    newCategoryLesson: {},
    salesByIds: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_MANAGER_ERRORS:
            return {
                ...state,
                managerLoading: false,
                managerErrors: action.payload,
            };
        case CLEAR_MANAGER_ERRORS:
            return {
                ...state,
                managerLoading: false,
                managerErrors: null,
            };
        case LOADING_MANAGER:
            return {
                ...state,
                managerLoading: true,
            };
        case STOP_LOADING_MANAGER:
            return {
                ...state,
                managerLoading: false,
            };
        case SET_MANAGER_HAKWON:
            return {
                ...state,
                hakwon: action.payload,
            };
        case SET_LESSON_MANAGER:
            return {
                ...state,
                lesson: action.payload,
            };
        case SET_LESSONS_MANAGER:
            return {
                ...state,
                lessons: action.payload,
            };
        case CLEAR_LESSONS_MANAGER: {
            return {
                ...state,
                lessons: [],
            };
        }
        case SET_NEW_CATEGORY_LESSON: {
            return {
                ...state,
                newCategoryLesson: action.payload,
            };
        }
        case CLEAR_NEW_CATEGORY_LESSON: {
            return {
                ...state,
                newCategoryLesson: {},
            };
        }
        case SET_PRODUCTS_MANAGER: {
            return {
                ...state,
                products: action.payload,
            };
        }
        case SET_PRODUCTGROUP: {
            return {
                ...state,
                productGroup: action.payload,
            };
        }
        case SET_PRODUCTGROUPALL: {
            return {
                ...state,
                productGroupAll: action.payload,
            };
        }
        case SET_TEACHER: {
            return {
                ...state,
                teacher: action.payload,
            };
        }
        case SET_TEACHERS: {
            return {
                ...state,
                teachers: action.payload,
            };
        }
        case SET_CHECKTEACHERS: {
            return {
                ...state,
                checkteachers: action.payload,
            };
        }
        case CLEAR_CHECKTEACHERS: {
            return {
                ...state,
                checkteachers: [],
            };
        }
        case SET_MEMBERS: {
            return {
                ...state,
                members: action.payload,
            };
        }
        case SET_PARENT: {
            return {
                ...state,
                parent: action.payload,
            };
        }
        case CLEAR_PARENT: {
            return {
                ...state,
                parent: {},
            };
        }
        case SET_REQUESTS: {
            return {
                ...state,
                requests: action.payload,
            };
        }
        case SET_SALES: {
            return {
                ...state,
                sales: action.payload,
            };
        }
        case CLEAR_SALES: {
            return {
                ...state,
                sales: [],
            };
        }
        case SET_SALES_SCHEDULE: {
            return {
                ...state,
                schedules: action.payload,
            };
        }
        case SET_SALES_OTHERSCHEDULE: {
            return {
                ...state,
                otherSchedules: action.payload,
            };
        }
        case SET_SETTLEMENTS: {
            return {
                ...state,
                settlements: action.payload,
            };
        }
        case SET_SALES_SETTLEMENTS: {
            return {
                ...state,
                salesForSettlements: action.payload,
            };
        }
        case EDIT_SALES_SETTLEMENTS: {
            return {
                ...state,
                salesForSettlements: state.salesForSettlements.filter((sa) => sa[0] !== action.payload),
            };
        }
        case UPDATE_SALES_SETTLEMENTS: {
            let newData = JSON.parse(JSON.stringify(state.salesForSettlements));
            const withouts = newData.filter((sa) => sa[0] !== action.payload[0]);
            const hSales = action.payload[1].map((sa) => ({
                amount: sa.amount,
                cancel_amount: sa.cancel_amount,
                category: sa.category,
                hName: sa.hName,
                isRevoked: sa.isRevoked,
                lessonDate: sa.lessonDate,
                lessonType: sa.lessonType,
                paid_at: sa.paid_at,
                paymentId: sa.paymentId,
                settlement: sa.settlement,
                status: sa.status,
            }));
            const res = [...withouts, [action.payload[0], hSales]];

            return {
                ...state,
                salesForSettlements: res,
            };
        }

        case CLEAR_SALES_SETTLEMENTS: {
            return {
                ...state,
                salesForSettlements: [],
            };
        }
        case SET_BOARDS: {
            return {
                ...state,
                boards: action.payload,
            };
        }
        case CLEAR_BOARDS: {
            return {
                ...state,
                boards: [],
            };
        }
        case SET_URL: {
            return {
                ...state,
                url: action.payload,
            };
        }
        case SET_BOARD: {
            return {
                ...state,
                board: action.payload,
            };
        }
        case CLEAR_BOARD: {
            return {
                ...state,
                board: {},
            };
        }
        case SET_TEACHING_PLANS: {
            return {
                ...state,
                teachingPlans: action.payload,
            };
        }
        case SET_SALE_DETAIL: {
            return {
                ...state,
                salesDetail: action.payload,
            };
        }
        case CLEAR_SALE_DETAIL: {
            return {
                ...state,
                salesDetail: {},
            };
        }
        case SET_SETTLEMENTS_IDS: {
            return {
                ...state,
                salesByIds: action.payload,
            };
        }
        default:
            return state;
    }
}
