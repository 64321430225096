// IE11의 경우, IE대응
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
// date picker
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// css
import './App.css';
// MUI theme
import ThemeObject from './util/theme';
//import ThemeDarkObject from './util/themeDark';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { koKR } from '@material-ui/core/locale';

// redux
import { Provider } from 'react-redux';
import store from './redux/store';
//import { persistStore } from 'redux-persist';	//persist는 쓰지 않음
//import { PersistGate } from 'redux-persist/integration/react';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';

//pages
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Kakao from './pages/Kakao';
import Joined from './pages/Joined';
import Help from './pages/Help';
import Gogo from './pages/Gogo';
import Lesson from './pages/Lesson';
import EnrollStep2 from './pages/enroll/EnrollStep2';
import EnrollStep3 from './pages/enroll/EnrollStep3';
import EnrollStep4 from './pages/enroll/EnrollStep4';
import TempPayment from './pages/enroll/TempPayment';
import PaymentResult from './pages/enroll/PaymentResult';
import PaymentResultMobile from './pages/enroll/PaymentResultMobile';
import Enroll from './pages/Enroll';
import Myclass from './pages/Myclass';
import Video from './pages/Video';
import AloneVideo from './pages/AloneVideo';
import DialogView from './pages/manager/DialogView';
import Lessonmanager from './pages/Lessonmanager';
import Manager from './pages/Manager';
import Admin from './pages/Admin';
import Mypage from './pages/Mypage';
import Demos from './pages/admin/Demos';
import KakaoLink from './pages/KakaoLink';
import HomeSchool from './pages/HomeSchool';
import SuccessPayment from './pages/enroll/SuccessPayment';
import Terms from './pages/terms/Terms';
import Privacy from './pages/terms/Privacy';
import Marketing from './pages/terms/Marketing';

// components
import AuthRoute from './util/AuthRoute';
import LoginRoute from './util/LoginRoute';

// theme
const theme = createTheme(ThemeObject, koKR);
//const themeDark = createMuiTheme(ThemeDarkObject, koKR);

// axios global setting : baseUrl
axios.defaults.baseURL = process.env.REACT_APP_FIRESTORE_URI;
// axios.defaults.baseURL = 'https://asia-northeast3-gogo-server.cloudfunctions.net/api'; // product
// axios.defaults.baseURL = 'http://localhost:5001/gogo-server/asia-northeast3/api';	// localhost

// Router ScrollTop (페이지마다 스크롤탑 설정)
const _ScrollToTop = (props) => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children;
};
const ScrollToTop = withRouter(_ScrollToTop);
// redux-persist
//const persistor = persistStore(store);

// dayjs date locale 선언
dayjs.locale('ko');

function App(props) {
    // token
    const token = localStorage.FBIdToken;
    //const refreshToken = localStorage.FBrefreshToken || '';

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            store.dispatch(logoutUser());
            // if (refreshToken) {
            // loginWithRefreshToken(refreshToken)
            // 	.then((newAccessToken) => {
            // 		//authenticated === true;
            // 		const FBIdToken = `Bearer ${newAccessToken}`
            // 		store.dispatch({ type: SET_AUTHENTICATED });
            // 		axios.defaults.headers.common['Authorization'] = FBIdToken;
            // 		localStorage.setItem('FBIdToken', FBIdToken);
            // 		store.dispatch(getUserData());
            // 	})
            // 	.catch(console.error);
            // } else {
            //     store.dispatch(logoutUser());
            // }
        } else {
            //authenticated === true;
            store.dispatch({ type: SET_AUTHENTICATED });
            axios.defaults.headers.common['Authorization'] = token;
            store.dispatch(getUserData());
        }
    }

    // axios IE 에러 대응
    const agent = navigator.userAgent.toLowerCase();
    if (
        (navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') > -1) ||
        agent.indexOf('msie') > -1
    ) {
        //axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        //axios.defaults.headers.common['Expires'] = 0;
        axios.defaults.headers.common['Pragma'] = 'no-cache';
    }

    // 서비스 오픈 전
    // const paths = window.location.origin;
    // console.log(paths, process.env.NODE_ENV);
    // (paths === 'https://musicgogo.co.kr' || paths === 'https://www.musicgogo.co.kr') ? <h1>Under construction!</h1> :

    return (
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            <Router>
                <ScrollToTop>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                            <CssBaseline />
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/kakao" component={Kakao} />
                                <Route exact path="/gogo" component={Gogo} />
                                <Route path="/gogo/:topic" component={Gogo} />
                                <Route path="/lesson" component={Lesson} />
                                <Route path="/enroll/:topic" component={Enroll} />

                                <Route path="/enrollstep2" component={EnrollStep2} />
                                <LoginRoute path="/enrollstep3" component={EnrollStep3} />
                                <LoginRoute path="/enrollstep4" component={EnrollStep4} />
                                <Route path="/payment/resultpc" component={PaymentResult} />
                                <Route path="/payment/result" component={PaymentResultMobile} />
                                <Route path="/temppayment/:round/:paymentId/:lessonId" component={TempPayment} />
                                <LoginRoute path="/myclass" component={Myclass} />
                                <Route path="/homeschool/:salesId" component={HomeSchool} />

                                <Route exact path="/help" component={Help} />
                                <Route path="/help/:topic" component={Help} />
                                <AuthRoute path="/login" component={Login} />
                                <AuthRoute path="/signup" component={Signup} />
                                <Route path="/joined" component={Joined} />
                                <LoginRoute path="/mypage" component={Mypage} />

                                <Route path="/terms" component={Terms} />
                                <Route path="/privacy" component={Privacy} />
                                <Route path="/marketing" component={Marketing} />

                                <Route path="/videoclip/:vimeoId" component={AloneVideo} />
                                <Route path="/view/:boardId" component={DialogView} />

                                <LoginRoute path="/video" component={Video} />
                                <LoginRoute path="/lessonmanager" component={Lessonmanager} />
                                <LoginRoute path="/manager/:topic" component={Manager} />
                                <LoginRoute exact path="/admin" component={Admin} />
                                <LoginRoute path="/admin/:topic" component={Admin} />

                                <Route path="/demos" component={Demos} />
                                <Route path="/successpayment/:payid" component={SuccessPayment} />
                                <Route path="/kakaolink" component={KakaoLink} />
                            </Switch>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </ScrollToTop>
            </Router>
            {/*  </PersistGate> */}
        </Provider>
    );
}

export default App;
