import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyClasses, getLesson, getAllEnrollProductGroupAll } from '../redux/actions/enrollActions';

// components
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import DummyDivider from '../components/layout/DummyDivider';
import HomeSchoolLesson from '../components/myclass/HomeSchoolLesson';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Container, Typography, Box, Chip } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
});
export const HomeSchool = (props) => {
    //const { classes } = props;
    const { salesId } = useParams();
    const { myclasses, lesson, productGroupAll} = useSelector(state => state.enroll);
    const dispatch = useDispatch();

    const [value, setValue] = useState(null);
    const [cate, setCate] = useState('');
    const [cate2, setCate2] = useState('');

    useEffect(() => {
        dispatch(getMyClasses());
        dispatch(getAllEnrollProductGroupAll())
    }, [dispatch]);
    
    const myclass = myclasses.filter(cl => cl.merchant_uid === salesId)[0];
    
    useEffect(() => {
        const str = myclass.lessonId.replace(/"/g, ''); 
        
        dispatch(getLesson(str));
    }, [dispatch, myclass]);

    const prod = ['뮤직고고 원데이클래스', '피아노고고 원데이클래스', '뮤직고고', '뮤직고고 프리미엄'];

    const today = new Date() / 1000;
    const expire = myclass?.paidAt + 60 * 60 * 24 * 31;
    const diff = dayjs(today * 1000).format('YYYYMMDD') > dayjs(expire * 1000).format('YYYYMMDD')
    //console.log(dayjs(today * 1000).format('YYYYMMDD'))
    //console.log(dayjs(expire * 1000).format('YYYYMMDD'))

    //console.log(diff);
    const product = productGroupAll.filter(gru=> gru.productIds.indexOf(lesson.productId)> - 1)[0];
    // console.log(product, prod.indexOf(product?.productGroupName));
    useEffect(()=>{
        if(lesson){
            setCate(lesson.category);
            setCate2(lesson.category);
            setValue(prod.indexOf(product?.productGroupName))  
        }
    }, [product, lesson, prod])

    // console.log(cate, cate2, product);
    return (
        <>
            <Navbar menu={2} />
            <div className='container'>
                <Container>
                    <DummyDivider bottom={30} />
                    <Typography variant='h3' gutterBottom>뮤직고고 홈스쿨</Typography>
                    <Chip size='small' color='secondary' label='온라인수업'></Chip>
                    <DummyDivider top={30} />
                    <Typography variant='subtitle2'>- 영상재생 기간 : 구매 후 30일간</Typography>
                    <Typography color='secondary' variant='subtitle2'>- {dayjs(myclass?.paidAt * 1000).format('YYYY-MM-DD(dd)')} ~ {dayjs(expire * 1000).format('YYYY-MM-DD(dd)')}</Typography>
                </Container>
                {
                    diff ? <Box p={1} m={1}>
                        <Typography color='error'>영상 재생 기간이 만료되었습니다.</Typography>
                        <DummyDivider bottom={230} />
                    </Box>
                        :
                        <HomeSchoolLesson
                            value={value}
                            category={cate}
                            category2={cate2}
                        />
                }

                <DummyDivider bottom={30} />

                <Footer />
            </div >
        </>
    );
};



export default withStyles(styles)(HomeSchool);

