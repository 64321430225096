import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gogo from '../logo.svg';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, withAppLogin } from '../redux/actions/userActions';

//import PropTypes from 'prop-types';
import Navbar from '../components/layout/Navbar'
import Footer from '../components/layout/Footer';
//MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
    ...theme.userPages,
    btn: {
        margin: theme.spacing(3)
    }
});

function Login(props) {
    const { classes, history } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    //const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const UI = useSelector(state => state.UI);
    //const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        //setLoading(true);
        const userData = {
            email: email,
            password: password
        };
        dispatch(loginUser(userData, props.history));

    };
    const handleChangeEmail = e => {
        setEmail(e.target.value);
    };
    const handleChangePassword = e => {
        setPassword(e.target.value);
    };

    // compmonentWillReceiveProps
    useEffect(() => {
        if (UI.errors) {
            setErrors(UI.errors);
        }
    }, [UI.errors]);

    const ORIGIN_PATH = window.location.origin;
    const REDIRECT_URI = `${ORIGIN_PATH}/kakao`;
    
    const ref = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=1f452428169c375c44607a1a307b8091&redirect_uri=${REDIRECT_URI}`
    const tobe = props.history.location.search.substr(1);
    const tobeRedir = (tobe ==='/enrollstep4' || tobe==='/entollstep3' || tobe==='enrollstep4' || tobe==='enrollstep3') ? '/lesson' : tobe;
    localStorage.setItem('redir', tobeRedir);

    const YellowButton = withStyles({
        root: {
            background: '#f2da00',
            width: 250,
            height: 38,
            '&:hover': {
                background: '#ffeb33',
            },
        }
    })(Button);


    const withApp = () => {
        dispatch(withAppLogin(history));
    }

   
    

    return (
        <>
            <Navbar />
            <div className="container">
                <Grid container className={classes.form}>
                    <Grid item xs={1} sm />
                    <Grid item xs={10} sm>
                        <Box height={50} />
                        <img src={gogo} width='150px' alt='logo' />
                        <Typography variant='h4' className={classes.pageTitle}>로그인</Typography>
                        <Box height={20} />
                        <div style={{ margin: 15, textAlign: 'center' }}>
                            <a href={ref}><YellowButton> 카카오 로그인 </YellowButton></a>
                        </div>
                        <Hidden smUp>
                            <Box height={50} />
                            <div style={{ margin: 15, textAlign: 'center' }}>
                                <YellowButton onClick={withApp}> 카카오 톡으로 로그인 </YellowButton>
                            </div>
                            <Box height={50} />
                        </Hidden>
                        

                        <Box height={50} />
                        <div>
                            <small>아직 가입하지 않으셨나요?</small><br />
                            <YellowButton component={Link} to='signup'>5초만에 회원 가입</YellowButton>
                        </div>
                        <Box height={50} />
                        <Typography variant='subtitle2' align='left'>다른 방법으로 로그인</Typography>
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                id='email'
                                name='email'
                                type='email'
                                label='Email'
                                variant='outlined'
                                className={classes.textField}
                                value={email}
                                onChange={handleChangeEmail}
                                helperText={errors.email}
                                error={errors.email ? true : false}
                                fullWidth
                                autoComplete='email'
                            />
                            <TextField
                                id='password'
                                name='password'
                                type='password'
                                label='Password'
                                variant='outlined'
                                className={classes.textField}
                                value={password}
                                onChange={handleChangePassword}
                                helperText={errors.password}
                                error={errors.password ? true : false}
                                autoComplete='current-password'
                                fullWidth
                            />
                            {errors.general && <Typography variant="body2" className={classes.customError}>{errors.general}</Typography>}
                            <Button
                                type='submit'
                                variant='outlined'
                                color='primary'
                                disabled={UI.loading}
                                className={classes.btn}
                            >로그인
                        {UI.loading && <CircularProgress size={25} className={classes.progress} />}
                            </Button>


                            <Button variant='outlined' color="primary" className={classes.btn} component={Link} to='signup'>가입</Button>

                        </form>

                    </Grid>
                    <Grid item xs={1} sm />
                </Grid>
                <Footer />
            </div>
        </>
    );

}
// Login.prototype = {
//     classes: PropTypes.object.isRequired,
//     loginUser: PropTypes.func.isRequired,
//      user : PropTypes.object.isRequired,
//      UI: PropTypes.object.isRequired
// }


export default withStyles(styles)(Login);
