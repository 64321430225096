import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveMyStudent, updateStudent } from '../../redux/actions/enrollActions';

import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import CenterLoading from './CenterLoading';

import { EditRounded, FaceRounded, SaveRounded, DeleteRounded } from '@material-ui/icons';


const styles = (theme) => ({
    ...theme.userPages,
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    TextField: {
        margin: theme.spacing(1),

    }
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function DialogInput(props) {
    const { classes, ch } = props;

    const [updateMode, setUpdateMode] = React.useState(false);
    const [cvalue, setCvalue] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [age, setAge] = React.useState('');
    const [relation, setRelation] = React.useState('');
    const [studentId, setStudentId] = React.useState('');

    const [open, setOpen] = React.useState(false);

    const { credentials } = useSelector(state => state.user);
    const { enrollLoading, enrollErrors } = useSelector(state => state.enroll);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ch && ch.studentName !== '') {
            setCvalue(ch.studentName);
            setPhone(ch.phone);
            setAge(ch.age);
            setRelation(ch.relation);
            setStudentId(ch.studentId);
            setUpdateMode(true);
        } else {
            setUpdateMode(false);
        }
    }, [ch])
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const changeToAddMode = () => {
        setUpdateMode(false);
        setCvalue('');
        setPhone('');
        setAge('');
        setRelation('');
    }
    const reUpdateMode = () => {
        setUpdateMode(true);
        if (ch && ch.studentName !== '') {
            setCvalue(ch.studentName);
            setPhone(ch.phone);
            setAge(ch.age);
            setRelation(ch.relation);
            setUpdateMode(true);
            setStudentId(ch.studentId);
        }
    }

    const handleUpdateInfo = () => {
        let newData = {
            hakwonId: credentials.myHakwon?.hakwonId,       // 학원선택이 안되고 진행하능한지 check FIXME:
            studentName: cvalue,
            phone: phoneFormat(phone),
            age: age,
            relation: relation,
            //isRegistered: false,
            //isDeleted: false,
            //isInvisibleForHakwon: false,
            //isPaidGogo: false,
            //sex: '',
            //memo:'',
            //studentNameForHakwon: cvalue,
            parentId: credentials.uid
        }
        if (updateMode) {
            // update
            let data = {
                ...newData,
                studentId: studentId
            }
            console.log(data)

            dispatch(updateStudent(data));


        } else {
            // add
            dispatch(saveMyStudent(newData)); // by Parent only, students2user 적용(Users DB반영)  
        }
        setOpen(false);
    }
    const handleDelete = () => {
        let newData = {
            studentId: studentId,
            isDeleted: true
        }
        console.log(newData);

        dispatch(updateStudent(newData));
        setOpen(false);
    }


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // [props] ==> title / textContent / name / value / actionButton / actionHandler /[open]
    // 
    

    return (
        <div>
            {/* 호출하는 부분  */}
            <Box onClick={handleClickOpen}>
                {props.children}
            </Box>

            {/* 팝업되는 부분 */}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen={fullScreen}
                className={classes.dialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant='body1' gutterBottom>
                        {updateMode ? '자녀 정보를 수정할 수 있습니다.' : '자녀의 정보를 미리 등록해두시면 편리하게 이용하실 수 있습니다.'}
                    </Typography>

                    {
                        updateMode ?

                            <Box>
                                <Box align='right'>
                                    <Button onClick={changeToAddMode} color="primary" variant='outlined' startIcon={<FaceRounded />}>
                                        + 자녀 추가
                                    </Button>
                                </Box>
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='name'
                                    label='자녀이름'
                                    value={cvalue}
                                    //onChange={(e) => setCvalue(e.target.value)}
                                    fullWidth
                                    helperText='자녀이름은 변경할 수 없습니다'
                                    readOnly
                                    className={classes.TextField}

                                />
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='phone'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    label='자녀 전화번호'
                                    helperText='휴대전화가 있으면 기입해주세요.'
                                    fullWidth
                                    className={classes.TextField}
                                />
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='phone'
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    label='자녀생년월'
                                    helperText='201912와 같이 6자리로 생년/월 기입해주세요.'
                                    fullWidth
                                    className={classes.TextField}
                                />
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='relation'
                                    value={relation}
                                    onChange={(e) => setRelation(e.target.value)}
                                    label='관계'
                                    helperText='엄마,아빠, 삼촌 등 관계를 적어주세요'
                                    fullWidth
                                    className={classes.TextField}
                                />
                            </Box>
                            :
                            <Box>
                                {
                                    ch.studentName !== '' &&
                                    <Box align='right'>
                                        <Button onClick={reUpdateMode} color="secondary" variant='outlined' startIcon={<EditRounded />}>
                                            정보 수정으로
                                        </Button>
                                    </Box>
                                }

                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='name'
                                    label='자녀이름'
                                    value={cvalue}
                                    onChange={(e) => setCvalue(e.target.value)}
                                    fullWidth
                                    helperText='자녀이름은 변경할 수 없습니다'
                                    readOnly
                                    className={classes.TextField}

                                />
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='phone'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    label='자녀 전화번호'
                                    helperText='휴대전화가 있으면 기입해주세요.'
                                    fullWidth
                                    className={classes.TextField}
                                />
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='phone'
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    label='자녀생년월'
                                    helperText='201912와 같이 6자리로 생년/월 기입해주세요.'
                                    fullWidth
                                    className={classes.TextField}
                                />
                                <TextField
                                    color='primary'
                                    variant='outlined'
                                    name='relation'
                                    value={relation}
                                    onChange={(e) => setRelation(e.target.value)}
                                    label='관계'
                                    helperText='엄마,아빠, 삼촌 등 관계를 적어주세요'
                                    fullWidth
                                    className={classes.TextField}
                                />
                            </Box>
                    }
                    {
                        enrollLoading && <CenterLoading />
                    }
                    {
                        enrollErrors && <Typography variant='body2' color='error'>{enrollErrors ? enrollErrors.error : ''}</Typography>
                    }




                </DialogContent>
                <DialogActions>

                    <Button autoFocus={false} onClick={handleClose} color="primary" variant='outlined'>
                        닫기
                    </Button>
                    {
                        updateMode &&
                        <Button onClick={handleDelete} color="secondary" variant='contained' disabled={enrollLoading} startIcon={<DeleteRounded />}>
                            정보 삭제 하기
                        </Button>
                    }
                    <Button onClick={handleUpdateInfo} color="primary" variant='contained' disabled={enrollLoading} startIcon={<SaveRounded />}>
                        {props.actionButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function phoneFormat(string) {
    if (string.startsWith("+")) {
        string = "0" + string.substring(3);
    } else if (string.startsWith("82")) {
        string = "0" + string.substring(2);
    }
    const str = string.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
    return String(str);
}
export default withStyles(styles)(DialogInput);