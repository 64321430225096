import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getMembers, getHakwonInfo, getRequestsByHakwon, makeRequestRead } from '../../redux/actions/managerActions';
import { getUserData } from '../../redux/actions/userActions';

// components
import MemberProfile from '../../components/manager/MemberProfile';
import DummyDivider from '../../components/layout/DummyDivider';
import CenterLoading from '../../components/layout/CenterLoading';
import DialogStudentNew from '../../components/manager/DialogStudentNew';
import DialogStudentRegister from '../../components/manager/DialogStudentRegister';

import LevelCheck from './LevelCheck';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Typography, Divider, Badge, Box, Grid, Button, Paper, InputBase, IconButton,
    Select, MenuItem, FormControl, FormControlLabel, Radio, RadioGroup
} from '@material-ui/core';
import { ArrowBackIosRounded, ArrowForwardIosRounded, ClearRounded, EditRounded, MailRounded, SearchRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { NotAllowed } from './NotAllowed';
import MemberListView from '../../components/manager/MemberListView';



export const Student = (props) => {
    const { classes, history } = props;
    const { credentials, rnr } = useSelector(state => state.user);
    const { members, hakwon, managerLoading, managerErrors, requests } = useSelector(state => state.manager);
    const dispatch = useDispatch();

    const [searchName, setSearchName] = useState('');
    const [allowed, setAllowed] = useState(false);

    // 신규원생 dialog
    const [open, setOpen] = useState(false);
    const [ataData, setAtaData] = useState({});
    const handleClose = () => { setOpen(false) };
    const handleOpen = () => { setOpen(true) };
    // view style
    const [viewType, setViewType] = useState('card');

    // 등록원생 dialog
    const [registerOpen, setRegisterOpen] = useState(false);
    const handleRegisterClose = () => { setRegisterOpen(false) };
    //const handleRegisterOpen = () => { setRegisterOpen(true) };
    const [selectedRequestId, setSelectedRequestId] = useState('');
    const [selectedStudentName, setSelectedStudentName] = useState('');

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 30);

    const indexOfLastStu = currentPage * perPage;
    const indexOfFirstStu = indexOfLastStu - perPage;

    const handlePerPage = (e) => {
        setPerPage(e.target.value);
        localStorage.setItem('perPage', e.target.value);
    };

    const hId = credentials.myHakwon ? credentials.myHakwon?.hakwonId : localStorage.getItem('hId');

    useEffect(() => {
        dispatch(getUserData());
        dispatch(getHakwonInfo());
        dispatch(getRequestsByHakwon(hId));
        dispatch(getMembers(hId));
    }, [dispatch, hId]);

    const memCount = members.filter(mem => mem.isInvisibleForHakwon !== true).map(mem => mem.studentNameForHakwon).reduce((acc, value) => ({
        ...acc,
        [value]: (acc[value] || 0) + 1
    }), {});
    const hmembers = members.map(mem => ({
        ...mem, cnt: memCount[mem.studentName]

    }));

    const handlePrev = () => {
        setCurrentPage(pre => pre === 1 ? 1 : pre - 1);
    }
    const handleNext = () => {
        setCurrentPage(pre => pre === lastPage ? lastPage : pre + 1);
    }
    const handleCancelRequest = (requestId) => {
        if (window.confirm('등록을 취소하시겠습니까? 아래 학부모 등록목록에는 사라지지 않습니다.')) {
            dispatch(makeRequestRead(requestId, hId));
        }
    }
    const filteredRequests = requests.length > 0 ? requests?.filter(re => re.isRead === false) : []

    const handleRegister = (requestId, studentName) => {
        setSelectedRequestId(requestId);
        setSelectedStudentName(studentName);
        setRegisterOpen(true);
        const reque = filteredRequests.filter(r => r.requestId === requestId)[0];

        const ata = {
            hName: credentials.myHakwon?.hName,
            parentNum: reque.parent?.phone.replace(/-/gi, ''),
            managerNum: credentials.phone

        }

        setAtaData(ata);

    }

    const filteredMembers = hmembers
        .filter(mem => mem.studentName.startsWith(searchName) && mem.isRegistered === true && mem.isInvisibleForHakwon !== true)
        .sort((a, b) => a.age?.substring(0, 7) > b.age?.substring(0, 7) ? -1 : a.age?.substring(0, 7) > b.age?.substring(0, 7) ? 1 : 0);
    const unRegisterd = hmembers.filter(mem => mem.isRegistered !== true && mem.isInvisibleForHakwon !== true);
    const paginationMembers = filteredMembers.slice(indexOfFirstStu, indexOfLastStu);
    const lastPage = Math.ceil(filteredMembers.length / perPage);

    //console.log(members, hmembers, filteredMembers);

    // console.log(requests.filter(r=>r.requestId ==="LHCc4bUVSSMPdypKTvQf")[0]?.parent);

    return (
        <div style={{ minHeight: 900 }}>
            <LevelCheck
                checkingTeacherLevel={3}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />
            {
                allowed ?
                    <Box>
                        <Box height={20} />
                        <Typography className={classes.marginRL} variant='subtitle1' gutterBottom>원생 등록 요청 <Badge badgeContent={filteredRequests.length} color="primary">
                            <MailRounded />
                        </Badge></Typography>
                        <Divider light className={classes.divider} variant='middle' />
                        <Box align='right'>
                            <Typography variant='caption' className={classes.marginRL}>{credentials.myHakwon?.hName}</Typography>
                        </Box>
                        {
                            managerLoading ?
                                <Box p={2} m={2}>
                                    <Skeleton variant='rect' height={100} style={{ backgroundColor: '#666' }} />
                                </Box> :
                                filteredRequests.length === 0 ?
                                    <Typography color='secondary' className={classes.marginRL}>요청이 없습니다.</Typography>
                                    : filteredRequests
                                        .sort((a, b) => b.createdAt - a.createdAt)
                                        .map((req, ind) =>
                                            <Box p={2} m={2} key={ind}>
                                                <Grid container direction='row' justifyContent='space-between'>
                                                    <Grid item>
                                                        <Typography color='secondary' variant='subtitle2' gutterBottom>[{req.studentName}] [{req.phone}] 원생으로 등록하시겠습니까? </Typography>
                                                        <Typography variant='caption' gutterBottom>({dayjs(req.createdAt * 1000).format('YYYY-MM-DD A hh:mm:ss')})</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button color='primary' size='small' variant='outlined' className={classes.btn} onClick={() => handleRegister(req.requestId, req.studentName)}>등록</Button>
                                                        <Button size='small' color='secondary' variant='outlined' className={classes.btn} onClick={() => handleCancelRequest(req.requestId)}>취소</Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                        }

                        <DummyDivider top={30} />
                        <Grid container>
                            <Grid item>
                                <Typography className={classes.marginRL} variant='subtitle1' gutterBottom>원생 정보 관리</Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: 20 }}>
                                <FormControl component="fieldset" size='small'>
                                    <RadioGroup aria-label="viewType" name="viewType" value={viewType} onChange={(e) => setViewType(e.target.value)} row>
                                        <FormControlLabel value={'card'} control={<Radio />} label='카드 형' />
                                        <FormControlLabel value={'list'} control={<Radio />} label='목록 형' />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider light className={classes.divider} variant='middle' />

                        <Grid container direction='row' justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Box m={2}>
                                    <Paper component="form" className={classes.search}>
                                        <IconButton size='small' className={classes.iconButton} aria-label="search" >
                                            <SearchRounded />
                                        </IconButton>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="이름 검색"
                                            value={searchName}
                                            inputProps={{ 'aria-label': '이름 검색' }}
                                            onChange={e => setSearchName(e.target.value)}
                                        />
                                        <IconButton size='small' onClick={() => setSearchName('')}>
                                            <ClearRounded />
                                        </IconButton>

                                    </Paper>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box m={2} align='right'>
                                    <Button onClick={handleOpen} color='primary' variant='contained' className={classes.btn} startIcon={<EditRounded />}>신규 원생 등록</Button>
                                </Box>

                            </Grid>
                        </Grid>

                        <Box>
                            {
                                managerLoading ? <Skeleton variant='rect' height={200} style={{ backgroundColor: '#666' }} />
                                    : managerErrors ? <Typography color='error'>{managerErrors.error}</Typography>
                                        :
                                        viewType === 'card' ?
                                            <div>
                                                <Grid container className={classes.vacant}>
                                                    {/* pagination을 위해, filteredMembers => paginationMembers */}
                                                    {paginationMembers.map((stu, index) =>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.grid}>
                                                            <MemberProfile student={stu} index={index} />
                                                        </Grid>
                                                    )}

                                                </Grid>
                                                <Box m={1} p={1}>
                                                    <Button onClick={handlePrev} color='primary' variant='contained' startIcon={<ArrowBackIosRounded />} className={classes.button}>이전</Button>
                                                    <Button onClick={handleNext} color='primary' variant='contained' endIcon={<ArrowForwardIosRounded />} className={classes.button}>다음</Button>
                                                    <Typography display='inline' color='primary' variant='subtitle2'>{currentPage} / {lastPage}</Typography>
                                                    <FormControl className={classes.select}>
                                                        <Select value={perPage} onChange={handlePerPage}>
                                                            <MenuItem value={5}>
                                                                <em>페이지당</em>
                                                            </MenuItem>
                                                            <MenuItem value={5}>5명씩</MenuItem>
                                                            <MenuItem value={10}>10명씩</MenuItem>
                                                            <MenuItem value={20}>20명씩</MenuItem>
                                                            <MenuItem value={30}>30명씩</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                            : <MemberListView filteredMembers={filteredMembers} />

                            }
                        </Box>

                        <Box height={50} />
                        <Typography className={classes.marginRL} variant='subtitle1' gutterBottom>미등록된 자녀 정보</Typography>
                        <Divider light className={classes.divider} variant='middle' />
                        <Typography variant='body2'>ㆍ수강 신청 단계에서 원생등록 확인요청 중이거나, 학부모가 마이페이지에서 원생 이름을 별도 등록해 두었을 때 나타납니다.</Typography>
                        <Typography variant='body2'>ㆍ원생이 아니라면 '원생 미등록' 상태로 두시고, 만일 원생이라면 '원생등록확인'으로 상태 변경해주십시오.</Typography>
                        <Typography variant='body2'>ㆍ학원에서 미리 입력한 정보와 중복된 정보가 나타날 경우(학부모가 따로 등록) '원생 삭제'하셔도 됩니다. (학부모에게는 삭제되지 않음)</Typography>
                        <Typography variant='body2'>ㆍ원생삭제 후 동일한 원생의 등록이 불가능할 수 있으며, 그 때는 고객센터로 연락주시면 처리해드리겠습니다.</Typography>
                        {managerLoading ? <CenterLoading />
                            : managerErrors ? <Typography color='error'>{managerErrors.error}</Typography>
                                :
                                <div>
                                    <Grid container className={classes.vacant}>
                                        {unRegisterd.map((stu, index) =>
                                            <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.grid}>
                                                <MemberProfile student={stu} memCount={memCount} />
                                            </Grid>
                                        )}

                                    </Grid>
                                    {/* <Box m={1} p={1}>
                            <Button color='primary' variant='contained' startIcon={<ArrowBackIosRounded />} className={classes.button}>이전</Button>
                            <Button color='primary' variant='contained' endIcon={<ArrowForwardIosRounded />} className={classes.button}>다음</Button>
                        </Box> */}
                                </div>
                        }
                        <Box height={100} />
                        <Typography variant='body2'>* 해당 페이지는 인터넷익스플로러(ie) 환경에서는 일부 작동이 원활하지 않을 수 있습니다. 구글 '크롬(Chrome)' 또는 마이크로소프트 '에지(Edge)'와 같은 최신 브라우저를 사용하여 주시기 바랍니다.</Typography>


                        {/*  */}
                        <DialogStudentNew
                            open={open}
                            handleClose={handleClose}
                            hakwonId={hId}
                            history={history}
                            rnr={rnr}
                        />
                        <DialogStudentRegister
                            registerOpen={registerOpen}
                            handleRegisterClose={handleRegisterClose}
                            hakwonId={hId}
                            selectedStudentName={selectedStudentName}
                            selectedRequestId={selectedRequestId}
                            unRegisterd={unRegisterd}
                            ataData={ataData}
                            history={history}
                        />

                    </Box>
                    :
                    <NotAllowed allowed={allowed} rnr={rnr} />
            }


        </div>


    );
};

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        color: '#fff'
    },
    inline: {
        display: 'inline',
    },
    btn: {
        margin: theme.spacing(1)
    },
    marginRL: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    divider: {
        backgroundColor: '#555',
        marginBottom: theme.spacing(1)
    },
    grid: {
        padding: theme.spacing(1)
    },
    search: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        [theme.breakpoints.down('xs')]: {
            width: 150,
            marginLef: theme.spacing(1)
        }
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        paddingTop: 10,
        paddingBottom: 10
    },
    searchField: {
        backgroundColor: '#fff',
        width: 300,
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            width: 200
        }
    },
    button: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(1)
        }
    },
    vacant: {
        minHeight: 200
    },
    select: {
        backgroundColor: '#fff',
        marginLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
        }
    }

});


export default withStyles(styles)(Student);

