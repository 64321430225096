import React from 'react';
// components
import Navbar from '../components/layout/Navbar';
import Banner1 from '../components/home/Banner1';
import Banner2 from '../components/home/Banner2';
import Footer from '../components/layout/Footer';
import TrialVideos from '../components/home/TrialVideos';

import Event from '../pages/Event';

function Home(props) {
    document.body.style.backgroundColor = '#eeeeee';
    // 메인그리드가 없어, 직접 스타일링
    return (
        <>
            <Navbar />
            {/* menu={0} */}
            <div className="container">
                <Banner1 />
                <Event />
                <Banner2 />
                <TrialVideos />
                <Footer />
            </div>
        </>
    );
}

export default Home;
