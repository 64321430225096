import React from 'react';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';


import { Box, } from '@material-ui/core';
import { AccessTimeRounded, TodayRounded } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%',
        backgroundColor: '#eee'
    },



});
export const DateTimeSelector = (props) => {
    const { classes, startDate, endDate, setStartDate, setEndDate, addMode } = props;


    const handleLessonDate = e => {
        setStartDate(e);
        setEndDate(e + 1000 * 60 * 40);
    }
    const handleStartTime = e => {
        setStartDate(e);
        setEndDate(e + 1000 * 60 * 40);
    }
    const handleEndDate = e => {
        if (e <= startDate) {
            alert('시간선택이 잘못되었습니다')
        } else {
            setEndDate(e);
        }

    }
    //console.log(dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'), dayjs(endDate).format('YYYY-MM-DD HH:mm:ss'))

    return (
        <Box>
            <KeyboardDatePicker
                color='primary'
                label='수업일'
                value={startDate}
                onChange={handleLessonDate}
                inputVariant='filled'
                invalidDateMessage='날짜 선택이 잘못되었습니다'
                format='YYYY-MM-DD'
                margin='dense'
                className={classes.textField}
                keyboardIcon={<TodayRounded color='primary' style={{ margin: 0 }} />}
                disablePast={addMode}
            />
            <KeyboardTimePicker
                color='primary'
                label='시작시간'
                value={startDate}
                onChange={handleStartTime}
                inputVariant='filled'
                format='A hh:mm'
                margin='dense'
                className={classes.textField}
                keyboardIcon={<AccessTimeRounded color='primary' size='small' />}
            />
            <KeyboardTimePicker
                color='primary'
                label='종료시간'
                value={endDate}
                onChange={handleEndDate}
                inputVariant='filled'
                format='A hh:mm'
                margin='dense'
                className={classes.textField}
                keyboardIcon={<AccessTimeRounded color='primary' size='small' />}
            />
        </Box>


    );
};
export default withStyles(styles)(DateTimeSelector);

