import {
    SET_MANAGER_HAKWON,
    SET_MANAGER_ERRORS,
    CLEAR_MANAGER_ERRORS,
    LOADING_MANAGER,
    LOADING_UI,
    STOP_LOADING_MANAGER,
    STOP_LOADING_UI,
    SET_LESSONS_MANAGER,
    SET_PRODUCTS_MANAGER,
    SET_PRODUCTGROUPALL,
    SET_PRODUCTGROUP,
    SET_TEACHER,
    SET_TEACHERS,
    SET_CHECKTEACHERS,
    CLEAR_CHECKTEACHERS,
    SET_MEMBERS,
    SET_PARENT,
    CLEAR_PARENT,
    SET_REQUESTS,
    SET_SALES,
    SET_SALES_SCHEDULE,
    SET_BOARDS,
    CLEAR_BOARDS,
    SET_URL,
    SET_BOARD,
    CLEAR_BOARD,
    SET_SALES_OTHERSCHEDULE,
    LOADING_ADMIN,
    STOP_LOADING_ADMIN,
    SET_SETTLEMENTS,
    SET_TEACHING_PLANS,
    SET_SALE_DETAIL,
    CLEAR_SALE_DETAIL,
    SET_NEW_CATEGORY_LESSON,
    CLEAR_NEW_CATEGORY_LESSON,
    SET_ERRORS,
    CLEAR_ERRORS,
    SET_LESSON_MANAGER,
    SET_SALES_SETTLEMENTS,
    EDIT_SALES_SETTLEMENTS,
    SET_SETTLEMENTS_IDS,
    UPDATE_SALES_SETTLEMENTS,
} from '../types';

import axios from 'axios';
import dayjs from 'dayjs';

// GET all lessons by hakwon
export const getHakwonInfo = () => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get(`/user`)
        .then((res) => {
            return res.data.credentials.myHakwon.hakwonId;
        })
        .then((hakwonId) => {
            return axios.get(`/hakwon/${hakwonId}`);
        })
        .then((res) => {
            dispatch({
                type: SET_MANAGER_HAKWON,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_HAKWON,
                payload: [],
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
export const updateHakwonInfo = (value) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    let hId;
    axios
        .get('/user')
        .then((res) => {
            hId = res.data.credentials.myHakwon.hakwonId;
            return hId;
        })
        .then((hakwonId) => {
            //console.log(value);
            return axios.post(`/hakwon/${hakwonId}`, value);
        })
        .then((res) => {
            //console.log(res.data);
            if (res.data.message === '저장되었습니다') {
                return hId;
            }
        })
        .then((hakwonId) => {
            return axios.get(`/hakwon/${hakwonId}`);
        })
        .then((res) => {
            dispatch({
                type: SET_MANAGER_HAKWON,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_MANAGER_HAKWON,
                payload: [],
            });
            dispatch({ type: STOP_LOADING_UI });
        });
};
// 수업일정관리
export const getLessonsByHakwon = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    let hId;

    axios
        .get('/user')
        .then((res) => {
            hId = res.data.credentials.myHakwon.hakwonId;
            return hId;
        })
        .then((hakwonId) => {
            return axios.get(`/hakwon/${hakwonId}`);
        })
        .then((res) => {
            dispatch({
                type: SET_MANAGER_HAKWON,
                payload: res.data,
            });
        })
        .then(() => {
            return axios.get(`/getlessons/${hId}`);
        })
        .then((res) => {
            dispatch({
                type: SET_LESSONS_MANAGER,
                payload: res.data,
            });
            return axios.get('/getproductswithcurri'); // 상품과 커리큘럼을 같이 가져오기
        })
        .then((res) => {
            dispatch({
                type: SET_PRODUCTS_MANAGER,
                payload: res.data,
            });
            dispatch({
                type: STOP_LOADING_UI,
            });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_UI });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
// 상품 커리와 같이 가져오기
export const getProductsWithCurri = () => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get('/getproductswithcurri')
        .then((res) => {
            dispatch({
                type: SET_PRODUCTS_MANAGER,
                payload: res.data,
            });
            dispatch({
                type: STOP_LOADING_MANAGER,
            });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};

// 수업(레슨) 저장하기
export const saveNewLesson = (newLessonData) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios
        .post('/lesson', newLessonData)
        .then((res) => {
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getLessonsByHakwon());
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        });
};
export const getLessonInHakwon = (lessonId) => (dispatch) => {
    axios
        .get(`/lesson/${lessonId}`)
        .then((res) => {
            dispatch({
                type: SET_LESSON_MANAGER,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};
// 카테고리를 변경하여 새로운 레슨 생성
export const saveNewCategoryLesson = (newLessonData) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post('/lesson', newLessonData)
        .then((res) => {
            dispatch({ type: CLEAR_ERRORS });
            dispatch(getLessonsByHakwon());
            dispatch({
                type: SET_NEW_CATEGORY_LESSON,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
            dispatch({ type: CLEAR_NEW_CATEGORY_LESSON });
            dispatch({ type: STOP_LOADING_UI });
        });
};
// 새로운 레슨 아이디로 기존 결제정보(payments) 수정 업데이트
export const editPaymentInfoByNewLesson = (lessonInfo) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post('/editpaymentlesson', lessonInfo)
        .then((res) => {
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getLessonsByHakwon());
            dispatch({
                type: SET_NEW_CATEGORY_LESSON,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
            dispatch({
                type: CLEAR_NEW_CATEGORY_LESSON,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
// 수업(레슨) 업데이트
export const updateLesson = (lessonId, lessonData) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post(`/lesson/${lessonId}`, lessonData)
        .then((res) => {
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getLessonsByHakwon());
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        });
};

// 수업(레슨) 삭제하기
export const deleteLesson = (lessonId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .delete(`/lesson/${lessonId}`)
        .then((res) => {
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getLessonsByHakwon());
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        });
};

export const deleteLessons = (lessons) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    dispatch({ type: CLEAR_MANAGER_ERRORS });
    axios
        .post(`/deletelessons`, lessons)
        .then((res) => {
            dispatch({ type: STOP_LOADING_UI });

            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: res.data,
            });
            dispatch(getLessonsByHakwon());
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_UI });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};

// ProductGroup
export const getProductGroup = (productGroupId) => (dispatch) => {
    axios
        .get(`/productgroup/${productGroupId}`)
        .then((res) => {
            dispatch({
                type: SET_PRODUCTGROUP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.error(err);
        });
};
export const getAllProductGroup = () => (dispatch) => {
    axios
        .get(`/productgroup`)
        .then((res) => {
            dispatch({ type: SET_PRODUCTGROUPALL, payload: res.data });
        })
        .catch((err) => {
            console.error(err);
        });
};

// post batch lessons
export const postBactchLessons = (newLessons) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post('/batchlessons', newLessons)
        .then((res) => {
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch({ type: STOP_LOADING_UI });
            dispatch(getLessonsByHakwon());
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};

// get teachers
export const getTeachersByHakwon = (hakwonId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get(`/teachers/${hakwonId}`)
        .then((res) => {
            dispatch({
                type: SET_TEACHERS,
                payload: res.data,
            });
            dispatch({
                type: STOP_LOADING_MANAGER,
            });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
        });
};
export const searchTeachersByName = (data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post('/checkteacher', data)
        .then((res) => {
            dispatch({
                type: SET_CHECKTEACHERS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .catch((err) => {
            console.error(err);
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const postTeacher = (data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/teacher`, data)
        .then((res) => {
            dispatch({
                type: SET_TEACHER,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            // 검색창도 비우기
            dispatch({ type: CLEAR_CHECKTEACHERS });
            alert('등록되었습니다.');
            dispatch(getTeachersByHakwon(data.hakwonId));
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
            dispatch({ type: CLEAR_CHECKTEACHERS });
        });
};
export const updateTeacher = (data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/teacher/${data.teacherUid}`, data)
        .then((res) => {
            dispatch({
                type: SET_TEACHER,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getTeachersByHakwon(data.hakwonId));
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
        });
};
export const getTeacher = (teacherUid) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get(`/teacher/${teacherUid}`)
        .then((res) => {
            dispatch({
                type: SET_TEACHER,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
        });
};
export const deleteTeacher = (teacherUid, hakwonId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .delete(`/teacher/${teacherUid}`)
        .then((res) => {
            dispatch(getTeachersByHakwon(hakwonId));
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
        });
};
export const getMembers = (hakwonId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get(`/students/${hakwonId}`)
        .then((res) => {
            dispatch({
                type: SET_MEMBERS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const postMember = (hakwonId, data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/studentbymanager`, data)
        .then((res) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getMembers(hakwonId));
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
            console.error(err.response.data);
        });
};
export const updateMember = (studentId, data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/updatestudentbymanager/${studentId}`, data)
        .then((res) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .then(() => {
            dispatch(getMembers(data.hakwonId));
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const getParentUserInfo = (parentId) => (dispatch) => {
    axios
        .get(`/user/${parentId}`)
        .then((res) => {
            dispatch({
                type: SET_PARENT,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
        });
};
export const clearParentUserInfo = (parentId) => (dispatch) => {
    dispatch({
        type: CLEAR_PARENT,
    });
};
// request
export const getRequestsByHakwon = (hakwonId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get(`/requeststudent/${hakwonId}`)
        .then((res) => {
            dispatch({
                type: SET_REQUESTS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            console.error(err);
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const makeRequestRead = (requestId, hakwonId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/updaterequest/${requestId}`)
        .then((res) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_MANAGER_ERRORS });
            dispatch(getRequestsByHakwon(hakwonId));
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });

            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response.data,
            });
        });
};
export const getSalesByHakwon = (hakwonId, fromTo) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .post(`/saleshakwon/${hakwonId}`, fromTo ? fromTo : { from: 1577836800, to: 4102444800 })
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            //console.log(err);

            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.error,
            });
        });
};

export const getAllSales = () => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .get(`/allsalesdirect`)
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.error(err);
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const getAllSalesFromTo = (dates) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .post(`/allsalesfromto`, dates) // POST
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.log(err, 'transaction data error - djkim');
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const getAllSalesFromToInDelivery = (dates) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .post(`/allsales/delivery`, dates) // POST
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.log(err, 'transaction data error - djkim');
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const getAllSalesFromToGet = (dates) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .get(`/allsalesfromtoget/${dates.from}/${dates.to}`) // get
        .then((res) => {
            console.log(dates);
            console.log(res);
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};

export const getAllSalesDirectly = () => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .get(`/allsalesdirect`)
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
// 정산용 매출 자료 (백엔드에서 작업해서 받음)
export const getSalesForSettlements = (variables) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/getsalesforsettlements`, variables) // POST
        .then((res) => {
            dispatch({
                type: SET_SALES_SETTLEMENTS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.log(err, 'transaction data error - djkim');
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const getFailedSales = (dates) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    dispatch({
        type: SET_SALES,
        payload: [],
    });
    axios
        .post(`/failedsales`, dates) // POST
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            // console.log(err);
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
// 빌링스케쥴
export const getBillingSchedule = (merchant_uid) => (dispatch) => {
    dispatch({
        type: LOADING_UI,
    });
    dispatch({
        type: SET_SALES_SCHEDULE,
        payload: [],
    });

    axios
        .post('/billingschedule', merchant_uid)
        .then((res) => {
            //console.log(merchant_uid);
            //console.log(res.data)
            dispatch({
                type: SET_SALES_SCHEDULE,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_UI });
        });
};
// other 빌링스케쥴(취소, 삭제 기타 등등)
export const getBillingOtherSchedule = (merchant_uid) => (dispatch) => {
    dispatch({
        type: LOADING_ADMIN,
    });
    dispatch({
        type: SET_SALES_OTHERSCHEDULE,
        payload: [],
    });
    axios
        .post('/billingscheduleothers', merchant_uid)
        .then((res) => {
            dispatch({
                type: SET_SALES_OTHERSCHEDULE,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ADMIN });
        })
        .catch((err) => {
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_ADMIN });
        });
};
// 빌링 스케쥴 추가(변경, 업데이트)
export const addOneSchedule = (info) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    // const { imp_uid, new_merchant_uid, new_datetime, nindex, original_merchant_uid } = info;
    axios
        .post(`/addschedule`, info)
        .then((res) => {
            dispatch(getBillingSchedule({ merchant_uid: 'b_' + info.original_merchant_uid.split('_')[1] }));
            dispatch(getBillingOtherSchedule({ merchant_uid: 'b_' + info.original_merchant_uid.split('_')[1] }));
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_UI });
        });
};

// 빌링 스케쥴 취소(변경 대신 취소만)
export const cancelOneSchedule = (info) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    // const { customer_uid, merchant_uid } = info;
    axios
        .post(`/revokeschedule`, info)
        .then((res) => {
            //console.log(info.merchant_uid)
            dispatch(getBillingSchedule({ merchant_uid: 'b_' + info.merchant_uid.split('_')[1] }));
            dispatch(getBillingOtherSchedule({ merchant_uid: 'b_' + info.merchant_uid.split('_')[1] }));
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_UI });
        });
};

export const getSales = (salesId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    //console.log(salesId);
    axios
        .get(`/onesales/${salesId}`)
        .then((res) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const getOneSaleDetail = (paymentId) => (dispatch) => {
    axios
        .get(`/getonesales/${paymentId}`)
        .then((res) => {
            dispatch({
                type: SET_SALE_DETAIL,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CLEAR_SALE_DETAIL,
            });
        });
};
export const getOneSales = (paymentId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .get(`/getonesales/${paymentId}`)
        .then((res) => {
            dispatch({
                type: SET_SALES,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};

export const getUserSales = (uid) => (dispatch) => {
    axios
        .get(`/getsales/${uid}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.error(err);
        });
};

// 결제취소
export const requestCancel = (info, dates) => (dispatch) => {
    axios
        .post('/requestcancel', info)
        .then((res) => {
            alert(res.data.message);
            dispatch(getAllSalesFromTo(dates));
        })
        .catch((err) => {
            alert(err.message);
            console.error(err);
        });
};

// 배송정보
export const updateDeliveryInfo = (salesId, num) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    let data = { deliNumber: num };
    axios
        .post(`/sales/${salesId}`, data)
        .then((res) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch(getAllSalesDirectly());
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};
export const batchUpdateDeliveryInfos = (lists, val, dates) => (dispatch) => {
    // dispatch({ type: LOADING_MANAGER });
    axios
        .post('/batchupdatedeliveryinfo', { ids: lists, value: val })
        .then((res) => {
            console.log(res, val);
            // dispatch({ type: STOP_LOADING_MANAGER });
            // dispatch(getAllSalesFromTo(dates));
        })
        .catch((err) => {
            // dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};

export const batchUpdateDeliveryInfosAllData = (lists, vals, dates) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post('/batchupdatedeliveryinfoalldata', {
            ids: lists,
            values: vals,
        })
        .then((res) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch(getAllSalesFromTo(dates));
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};

// 게시판
export const getAllBoards = () => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post('/allboards')
        .then((res) => {
            dispatch({
                type: SET_BOARDS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.error(err);
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_BOARDS });
        });
};
export const getBoardList = (type) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post('/boards', type)
        .then((res) => {
            dispatch({
                type: SET_BOARDS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.error(err);
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({ type: CLEAR_BOARDS });
        });
};
export const postBoard = (data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .post('/board', data)
        .then((res) => {
            dispatch(getAllBoards());
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
export const deleteBoard = (bId, typeofBo) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .delete(`/board/${bId}`)
        .then((res) => {
            dispatch(getAllBoards());
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
export const uploadBoardImage = (formData) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    dispatch({
        type: SET_URL,
        payload: '',
    });
    axios
        .post('/imageboardupload', formData)
        .then((res) => {
            dispatch({
                type: SET_URL,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
export const uploadBoardFile = (formData) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    dispatch({
        type: SET_URL,
        payload: '',
    });
    axios
        .post('/uploadpdf', formData)
        .then((res) => {
            dispatch({
                type: SET_URL,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
export const editBoard = (data) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .post(`/board/${data.boardId}`, data)
        .then((res) => {
            dispatch(getAllBoards());
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
export const getBoard = (boardId) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });

    axios
        .get(`/board/${boardId}`)
        .then((res) => {
            dispatch({
                type: SET_BOARD,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            console.error(err);
            dispatch({ type: CLEAR_BOARD });
            dispatch({ type: STOP_LOADING_MANAGER });
        });
};
//교안
export const getTeachingPlans = () => (dispatch) => {
    axios
        .get('/getteachingplans')
        .then((res) => {
            dispatch({
                type: SET_TEACHING_PLANS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.err(err);
        });
};
export const postTeachingPlan = (info) => (dispatch) => {
    axios
        .post('/postteachingplan', info)
        .then((res) => {
            dispatch(getTeachingPlans());
        })
        .catch((err) => {
            dispatch(getTeachingPlans());
        });
};
export const updateTeachingPlan = (tId, data) => (dispatch) => {
    axios
        .post('/updateteachingplan', { tId: tId, data })
        .then((res) => {
            dispatch(getTeachingPlans());
        })
        .catch((err) => {
            console.err(err);
        });
};
export const deleteTeachingPlan = (tId) => (dispatch) => {
    axios
        .delete(`/deleteteachingplan/${tId}`)
        .then((res) => {
            alert(res.data.data);
            dispatch(getTeachingPlans());
        })
        .catch((err) => {
            alert(err.response.error);
        });
};

// 정산 (settlement)
export const postSettlement = (info, hName) => (dispatch) => {
    dispatch({ type: LOADING_MANAGER });
    axios
        .post(`/settlement`, info)
        .then((res) => {
            dispatch({
                type: EDIT_SALES_SETTLEMENTS,
                payload: hName,
            });
            dispatch({ type: STOP_LOADING_MANAGER });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_MANAGER });
            dispatch({
                type: SET_MANAGER_ERRORS,
                payload: err.response,
            });
        });
};



export const getSettlements = () => (dispatch) => {
    axios
        .get('/settlements')
        .then((res) => {
            dispatch({
                type: SET_SETTLEMENTS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

// 학원별 개별 sales 정산정보 (by ids);
export const getSalesByIds = (ids) => (dispatch) => {
    dispatch({ type: LOADING_ADMIN });

    axios
        .post('/getsalesbyids', ids)
        .then((res) => {
            // console.log(res.data);
            dispatch({
                type: SET_SETTLEMENTS_IDS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ADMIN });
        })
        
        .catch((err) => {
            dispatch({ type: STOP_LOADING_ADMIN });
            return err.error;
        });
};
export const updateSettlementState = (hName, salesData) => (dispatch) => {
    dispatch({
        type: UPDATE_SALES_SETTLEMENTS,
        payload: [hName, salesData],
    });
};

export const updateSalesBySettledIds = (ids) => (dispatch) => {
    axios
        .post('/batchupdatesales', ids)
        .then((res) => {
            return res.message;
        })
        .catch((err) => {
            return err.error;
        });
};
export const updateSettlement = (sid, info, withPrevious, month, searchHakwon, searchType) => (dispatch) => {
    axios
        .post(`/settlement/${sid}`, info)
        .then((res) => {
            const variables = {
                from: 0,
                to: dayjs() / 1000,
                withPrevious: withPrevious,
                month: month,
                searchHakwon: searchHakwon,
                searchType: searchType,
            };
            //console.log(res);
            dispatch(getSalesForSettlements(variables));
            dispatch(getSettlements());
            return res.message;
        })
        .catch((err) => {
            return err.error;
        });
};

// enrolled(수강생) 레슨 타임 변경
export const postUpdateLessonTime = (lessonTime, salesId, hId) => (dispatch) => {
    axios
        .post(`/sales/${salesId}`, { lessonDate: lessonTime })
        .then((res) => {
            dispatch(getSalesByHakwon(hId, { from: 1577836800, to: 4102444800 }));
        })
        .catch((err) => {
            console.log(err);
        });
};

// sales memo 추가
export const updateSalesWithMemo = (salesId, str, from, to) => (dispatch) => {
    const dates = { from: from, to: to };

    axios
        .post(`/sales/${salesId}`, { memo: str })
        .then((res) => {
            dispatch(getAllSalesFromTo(dates));
        })
        .catch((err) => console.log(err));
};
export const updateSalesWithNote = (salesId, str, from, to) => (dispatch) => {
    const dates = { from: from, to: to };

    axios
        .post(`/sales/${salesId}`, { note: str })
        .then((res) => {
            dispatch(getAllSalesFromTo(dates));
        })
        .catch((err) => console.log(err));
};
