import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
// Redux stuff
//import { useSelector, useDispatch } from 'react-redux';
// components
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Box, Divider, Typography, FormGroup, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
});
export const MakeLessonList = (props) => {
    const {
        classes,
        selectedProductGroupId,
        productGroupAll,
        setLessonLists,
        wday,
        selectedCategory,
        setIsCreated,
        startDate,
        endDate,
        isCountLimit,
        exDates,
        rDates,
        repeatRadio,
    } = props;
    //const user = useSelector(state => state.user);
    //const dispatch = useDispatch()

    // checkbox by payment method

    const [check, setCheck] = useState({
        oneMonth: true,
        threeMonths: true,
        halfYear: true,
        oneYear: true,
        all: true,
    });
    const handlePayCheckbox = (e) => {
        setCheck({ ...check, [e.target.name]: e.target.checked });
    };

    const handleCheckAll = (e) => {
        setCheck({
            oneMonth: true,
            threeMonths: true,
            halfYear: true,
            oneYear: true,
            all: true,
        });
    };

    const makeLessonList = (groupId) => {
        let arr = [];
        if (selectedCategory === '') {
            alert('연령/카테고리를 선택해주십시오.');
        } else if (wday.length === 0) {
            alert('요일을 선택해주십시오.');
        
        } else if (isEmpty(startDate) || (isEmpty(endDate) && isCountLimit === false)) {
            alert('날짜를 바르게 선택하셔야 합니다.');
        } else if (repeatRadio === 'repeat' && wday === []) {
            
            alert('요일을 선택하셔야 합니다.');
        } else if (repeatRadio === 'one' && isEmpty(endDate)) {
            
            alert('종료일을 선택하셔야 합니다.');
        } else if (exDates !== '' && !checkDate(exDates)) {
            
            alert('제외일자의 날짜 형식을 바르게 입력해주세요.');
        } else if (rDates !== '' && !checkDate(rDates)) {
            
            alert('추가일자의 날짜 형식을 바르게 입력해주세요.');
        } else {
            const pList = productGroupAll.filter((group) => group.productGroupId === groupId)[0].products;
            // eslint-disable-next-line array-callback-return
            pList.map((p) => {
                if (check.oneMonth && p.range === '1개월') {
                    arr.push(p.productId);
                }
                if (check.threeMonths && p.range === '3개월') {
                    arr.push(p.productId);
                }
                if (check.halfYear && p.range === '6개월') {
                    arr.push(p.productId);
                }
                if (check.oneYear && p.range === '12개월') {
                    arr.push(p.productId);
                }
            });
            setLessonLists(arr);

            // 생성결과처리
            setIsCreated(true);
        }
    };

    return (
        (selectedProductGroupId === 'musicgogo' || selectedProductGroupId === 'musicgogoPremium') && (
            <Box>
                <Divider light className={classes.divider} />
                <Typography variant="subtitle2" gutterBottom>
                    등록 형태별 수업 자동 생성
                </Typography>
                <FormGroup row>
                    <FormControlLabel
                        label="모두 생성"
                        control={
                            <Checkbox
                                checked={check.all}
                                onChange={handleCheckAll}
                                name="all"
                                color="primary"
                                size="small"
                                indeterminate={
                                    !(check.oneMonth && check.threeMonths && check.halfYear && check.oneYear)
                                }
                            />
                        }
                    />
                    <FormControlLabel
                        label="1개월"
                        control={
                            <Checkbox
                                checked={check.oneMonth}
                                onChange={handlePayCheckbox}
                                name="oneMonth"
                                size="small"
                                color="primary"
                            />
                        }
                    />
                    <FormControlLabel
                        label="3개월 선결제"
                        control={
                            <Checkbox
                                checked={check.threeMonths}
                                onChange={handlePayCheckbox}
                                name="threeMonths"
                                size="small"
                                color="primary"
                            />
                        }
                    />
                    <FormControlLabel
                        label="매달 정기결제(6개월)"
                        control={
                            <Checkbox
                                checked={check.halfYear}
                                onChange={handlePayCheckbox}
                                name="halfYear"
                                size="small"
                                color="primary"
                            />
                        }
                    />
                    <FormControlLabel
                        label="매달 정기결제(12개월)"
                        control={
                            <Checkbox
                                checked={check.oneYear}
                                onChange={handlePayCheckbox}
                                name="oneYear"
                                size="small"
                                color="primary"
                            />
                        }
                    />
                </FormGroup>
                <Box height={15} />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => makeLessonList(selectedProductGroupId)}
                    startIcon={<AddRounded />}
                >
                    생성
                </Button>
            </Box>
        )
    );
};

const isEmpty = (string) => {
    if (!string) {
        return true;
    } else if (string.toString().trim() === '') {
        return true;
    } else {
        return false;
    }
};
function checkDate(string) {
    const reg = /(|,)(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))+/g;
    return reg.test(string);
}

export default withStyles(styles)(MakeLessonList);
