import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// mui stuff
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const AntTabs = withStyles(theme => ({
    root: {
        borderBottom: '1px solid #eeeeee', //'1px solid #e8e8e8', */
    },
    indicator: {
        backgroundColor: '#e8e8e8',//theme.palette.primary.light,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#e8e8e8'//theme.palette.primary.light,
        },
    },
}))(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        fontSize: 16, // 18
        textTransform: 'none',
        minWidth: 30, //40
        
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1), // 4
        '&:hover': {
            color: theme.palette.primary.light,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props}/>);
/* }))((props) => <Tab disableRipple {...props} />); */

const useStyles = makeStyles(theme => ({   
    root: {
        flexGrow: 1,
    },
    padding: {
        paddingLeft: theme.spacing(2),  // 패딩
    },
    background: {
        /* backgroundColor: '#eeeeee', */ /* theme.palette.background.paper, */
    },

}));

// tabInfo 를 넘겨주어야 함 
// [{id, label, link}...] 로 구성
// initialvalue를 initValue로 넘김
const CustomizedTabs = (props) => {
    const { tabInfo } = props;
    const classes = useStyles();
    let initialValue = props.initValue ? props.initValue : 0;
    
    const [value, setValue] = React.useState(initialValue);
    const { topic } = useParams();

    const handleValue = () => {
        tabInfo.forEach((tab, index) => {
            if (topic === tab.id) {
                setValue(index);
            }
        })
    };

    useEffect(() => {
        handleValue();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    
    return (
        <div className={classes.root}>
            <div className={classes.background}>
                <AntTabs value={value} onChange={handleChange} aria-label="메뉴" variant="scrollable" scrollButtons="auto">
                    {tabInfo.map(tab =>
                        (<AntTab label={tab.label} key={tab.id} component={Link} to={tab.link} />)
                    )}
                </AntTabs>
                <Typography className={classes.padding} />
            </div>
        </div>
    );
}
export default CustomizedTabs;
