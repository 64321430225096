import React, { useState, useEffect } from 'react';


// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonsContactsByArea, setMainHakwon } from '../../redux/actions/enrollActions';

// components
import { proviceCode, addressCode } from './Address';
import NoHakwon from './NoHakwon';
import CenterLoading from '../layout/CenterLoading';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Container, FormControl, InputLabel, Select, Grid, Box, Radio, RadioGroup, FormControlLabel, Typography, Divider } from '@material-ui/core';


const styles = (theme) => ({
    ...theme.userPages,
    formControl: {
        margin: theme.spacing(1),
        width: '90%',
        backgroundColor: '#ffffff'
    },


    pos: {
        marginBottom: 12,
    },

});

export const SearchHakwon = (props) => {
    // 필요한 props => setSelectedHakwon, setIsHakwonLists 
    const { classes, setSelectedHakwon, setIsHakwonLists, setHakwonName } = props;
    const { hakwons, enrollLoading } = useSelector(state => state.enroll);
    const { rnr } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const initialCode = localStorage.getItem('code') !== '' ? localStorage.getItem('code') : 0;
    const initialArea = localStorage.getItem('area') !== '' ? localStorage.getItem('area') : 0;

    const [code, setCode] = useState(initialCode);
    const [area, setArea] = useState(initialArea);
    const [radio, setRadio] = useState(-1);


    const handleCode = (e) => {
        e.preventDefault();
        setCode(Number(e.target.value));
        setIsHakwonLists(false);
        localStorage.setItem('code', Number(e.target.value));
    };

    const handleArea = (e) => {
        e.preventDefault();
        setIsHakwonLists(false);
        setArea(Number(e.target.value));
        localStorage.setItem('area', Number(e.target.value));
        setRadio(-1);
        setSelectedHakwon({ id: '', name: '', phone: '' });
    }



    // 학원선택
    const selectHakwonHandler = (e) => {
        e.preventDefault();
        setRadio(e.target.value);

        setSelectedHakwon({
            id: e.target.dataset.hid,
            name: e.target.dataset.hname,
            phone: e.target.dataset.phone
        });
        // console.log(e.target.dataset.phone, e.target.dataset.hname, e.target.dataset.hid);

        // 스토리지 저장 
        localStorage.setItem('hName', e.target.dataset.hname);
        localStorage.setItem('hId', e.target.dataset.hid);
        if (setHakwonName) {
            setHakwonName(e.target.dataset.hname); //상단 스텝퍼에 표기하기
        }


        setIsHakwonLists(true); // 학원리스트존재시에, 자녀 검색 나타내기

        dispatch(setMainHakwon(e.target.dataset.hid)); // user DB 및 enroll 리듀서에 저장 
    }

    useEffect(() => {
        if (area > 999)
            dispatch(getHakwonsContactsByArea(area));
    }, [area, dispatch]);


    //console.log(hakwons);

    return (
        <div>

            <Container>
                <Divider light style={{ marginTop: 20, marginBottom: 10 }} />
                {/* 셀렉트박스 */}
                <Grid container direction='row' spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' className={classes.formControl}>
                            <InputLabel htmlFor="firstCode" color='secondary' >시/도</InputLabel>
                            <Select
                                native
                                value={code}
                                onChange={handleCode}
                                color='secondary'
                                label="시/도"
                                inputProps={{
                                    name: 'code',
                                    id: 'firstCode',
                                }}
                            >
                                <option aria-label="None" value='' />
                                {proviceCode.map(pro =>
                                    <option key={pro.code} value={pro.code}>{pro.province}</option>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' className={classes.formControl}>
                            <InputLabel htmlFor="secondCode" color='secondary'>시/군/구</InputLabel>
                            <Select
                                native
                                value={area}
                                color='secondary'
                                onChange={handleArea}
                                label='시/군/구'
                                inputProps={{
                                    name: 'area',
                                    id: 'secondCode',
                                }}
                            >
                                <option aria-label="None" value='' />
                                {
                                    addressCode.map(ad => (
                                        ad.provinceCode === Number(code) && <option key={ad.code} value={ad.code}>{ad.area}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />

            {/* 출력부분 */}
            { enrollLoading ? <CenterLoading />
                :
                hakwons.length > 0 ?
                    <div>
                        <Container>
                            <Typography variant='subtitle1'>아래에서 가까운 어린음악대를 선택해 주세요.</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="hakwon" name="학원" value={Number(radio)} onChange={selectHakwonHandler}>
                                    {hakwons.map((hak, index) => {
                                        if (hak.hakwonId === 'SDkUB38VqSpv0QMFXhrR') {     // 주은교육테스트원 : 관리자만 볼 수 있게 함
                                            if (rnr.level < 2) {
                                                return (
                                                    <Box key={index}>
                                                        <FormControlLabel value={index} control={<Radio id={hak.hakwonId} name={hak.hName} inputProps={{ 'data-phone': hak.ownerPhone ? hak.ownerPhone[0] : '', 'data-hid': hak.hakwonId , 'data-hname': hak.hName}} />} label={
                                                            <Typography display='inline' variant='body1' color="secondary" gutterBottom>{hak.hName}</Typography>
                                                        } />
                                                        <Typography variant='body1' color='inherit' style={{ marginLeft: 20 }}> ({`${hak.address?.juso1} ${hak.address?.juso3} (${hak.address?.juso2})`})</Typography>
                                                    </Box>
                                                );
                                            } else {
                                                return null;
                                            }
                                        } else {
                                            return (
                                                <Box key={index}>
                                                    <FormControlLabel value={index} control={<Radio id={hak.hakwonId} name={hak.hName} inputProps={{ 'data-phone': hak.ownerPhone ? hak.ownerPhone[0] : '', 'data-hid': hak.hakwonId, 'data-hname': hak.hName }} />} label={
                                                        <Typography display='inline' variant='body1' color="secondary" gutterBottom>{hak.hName} </Typography>
                                                    } />
                                                    <Typography variant='body1' color='inherit' style={{ marginLeft: 20 }}> ({`${hak.address?.juso1} ${hak.address?.juso3} (${hak.address?.juso2})`})</Typography>
                                                </Box>
                                            );
                                        }

                                    }

                                    )}
                                </RadioGroup>
                            </FormControl>


                        </Container >
                    </div >

                    : area > 100 && props.online ?
                        /* 가맹원이 없을 때 */
                        <Container>
                            <NoHakwon />
                        </Container>

                        : area > 100 && !props.online ?
                            <Container>
                                <Typography variant='subtitle2' color='error'>검색결과가 없습니다</Typography>
                            </Container>

                            : null
            }
        </div >


    );
};



export default withStyles(styles)(SearchHakwon);

