import React, { useEffect } from 'react';
import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonInfo, getTeachingPlans } from '../../redux/actions/managerActions';
import { isLogged } from '../../redux/actions/userActions';
// components
import DummyDivider from '../../components/layout/DummyDivider';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Paper, Typography, Divider, MenuItem, Box, Button } from '@material-ui/core';
import { Folder } from '@material-ui/icons';


const styles = (theme) => ({
    ...theme.userPage,
    margin: {
        marginLeft: 20
    },
    divider: {
        backgroundColor: '#555'
    },



});

export const TeachingPlan = (props) => {
    const { classes, history } = props;
    const { credentials } = useSelector(state => state.user);
    const { teachingPlans } = useSelector(state => state.manager);
    const dispatch = useDispatch();


    const printPDF = (e, file) => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            e.preventDefault();
            window.open(file, "PRINT", "height=800,width=600");
        }

    };

    // hakwon 세팅
    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getTeachingPlans());
    }, [dispatch]);

    const cates = ['스타터', '플레이어', '러너', '러너플러스'];
    const filteredTeachingPlans = teachingPlans
        .filter(p => p.product === '뮤직고고' && p.type === '교안')
        .filter(p => cates.indexOf(p.category) > -1)
        .filter(p => dayjs(p.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') && dayjs(p.endDate).format('YYYYMMDD') >= dayjs().format('YYYYMMDD'));


    return (

        <div>

            <Box height={20} />
            <Typography variant='subtitle1' gutterBottom className={classes.margin}>교안 보기</Typography>

            <Box align='right'>
                <Typography variant='caption' className={classes.margin}>{credentials.myHakwon?.hName}</Typography>
            </Box>
            <Box p={1} m={1}>
                <Typography variant='body2'>어린음악대 가맹원에만 제공되는 뮤직고고 전과정 교안으로 단계별, 주차별로 원하는 교안을 선택하여 보실수 있습니다(매월 4주차씩 제공)</Typography>
                <Typography variant='body2'>모든 교안은 프린터로 인쇄 가능합니다.</Typography>
            </Box>

            <DummyDivider top={30} />
            <Box p={1} m={1}>
                <Grid container spacing={3}>
                    {
                        cates.map((c, ind) => {
                            return (
                                <Grid key={ind} item xs={12} sm={6}>
                                    <Paper>
                                        <Typography variant='subtitle1' display='inline'>ㆍ 이 달의 </Typography>
                                        <Typography variant='subtitle1' display='inline' color='secondary'> {c} </Typography>
                                        <Typography variant='subtitle1' display='inline'> 교안 </Typography>
                                        <Divider className={classes.divider} />
                                        {
                                            filteredTeachingPlans
                                                .filter(p => p.category === c)
                                                .sort((a, b) => a.order > b.order ? 1 : -1)
                                                .map((p, i) =>
                                                    <MenuItem key={i}>
                                                        <Folder color='primary' />
                                                        <Button onClick={e => printPDF(e, p.url)} color='primary'>{p.name}</Button>
                                                    </MenuItem>)
                                        }

                                    </Paper>
                                </Grid>);
                        })

                    }

                </Grid>
            </Box>


        </div >


    );
};



export default withStyles(styles)(TeachingPlan);

