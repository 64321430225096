import React, { useState } from 'react';
// redux
import { useDispatch } from 'react-redux';
import { postMember } from '../../redux/actions/managerActions';
// MUI stuff
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, useMediaQuery, Button, TextField, Dialog, DialogContent, DialogContentText, FormControlLabel, RadioGroup, Radio, FormControl, DialogActions, Switch, DialogTitle } from '@material-ui/core';
import { SaveRounded } from '@material-ui/icons';
import { isLogged } from '../../redux/actions/userActions';
import NewStudent from './NewStudent';
const styles = (theme) => ({
    ...theme.userPage,

});
const DialogStudentNew = props => {
    const { open, handleClose, hakwonId, history, rnr } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const initialValue = {
        age: '',
        sex: '',
        memo: '',
        phone: '',
        siblings: [],
        classInfo: {
            musicClass: '',
            artClass: ''
        },
        isRegistered: true,
        isPaidGogo: false,
        isInvisibleForHakwon: false,
        studentNameForHakwon: ''
    }
    const [value, setValue] = useState(initialValue);
    /* 임시 오픈 */
    const [anotherInfoOpen, setAnotherInfoOpen] = useState(false);

    const onChangeSex = e => {
        setValue({
            ...value,
            sex: e.target.value
        })
    }
    const onChangeValue = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const onChangeSwitch = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.checked
        });
        if (e.target.checked && e.target.name === 'isInvisibleForHakwon') {
            alert('삭제하시겠습니까? 저장 버튼을 누르면 삭제됩니다.');
        }
    }
    const onChangeClass = e => {
        setValue({
            ...value,
            classInfo: {
                ...value.classInfo,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleNewStudent = () => {
        const newData = {
            hakwonId: hakwonId,
            isRegistered: value.isRegistered,
            isDeleted: false,
            isPaidGogo: value.isPaidGogo,
            isInvisibleForHakwon: value.isInvisibleForHakwon,
            studentName: value.studentNameForHakwon,
            studentNameForHakwon: value.studentNameForHakwon,
            age: value.age,
            sex: value.sex,
            classInfo: value.classInfo,
            memo: value.memo,
            phone: value.phone === '' ? value.phone = '없음' : value.phone,
            siblings: value.siblings
        }
        
        //console.log(newData);
        const hId = hakwonId ? hakwonId : window.localStorage.getItem('hId');

        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(postMember(hId, newData));
            handleClose();
            setValue(initialValue);
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen={matches} maxWidth='md' fullWidth>
            <DialogTitle id="form-dialog-title">원생 정보 입력</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='body1' gutterBottom>원생정보를 입력합니다. </Typography>

                </DialogContentText>
                <LeftTitleTextField
                    title="원생 표시이름"
                    type="text"
                    name='studentNameForHakwon'
                    value={value.studentNameForHakwon}
                    onChange={onChangeValue}
                    helperText={!!value.studentNameForHakwon && '중복된 이름이 있을때 본명과 달리 기록해주세요.'}
                />
                <LeftTitleRadio title='성별' value={value.sex} onChange={onChangeSex}>
                    <FormControlLabel value='남' control={<Radio color='primary' />} label='남' />
                    <FormControlLabel value='여' control={<Radio color='primary' />} label='여' />

                </LeftTitleRadio>

                <LeftTitleTextField
                    title="생년월일*(YYYYMMDD)"
                    type="text"
                    name='age'
                    value={value.age}
                    onChange={onChangeValue}
                    helperText={!!value.age && 'ex) 20101231 (YYYYMMDD 8자리숫자로 기록해주세요)'}
                />
                <LeftTitleTextField
                    title="학생전화번호"
                    type="text"
                    name='phone'
                    value={value.phone}
                    onChange={onChangeValue}
                    helperText={!!value.phone && '있을시 기록해주세요'}
                />
                <LeftTitleTextField
                    title="메모"
                    type="text"
                    name='memo'
                    value={value.memo}
                    onChange={onChangeValue}
                    rows={2}
                    multiline
                    helperText={!!value.memo && '메인에 표시됩니다.'}
                />
                <LeftTitle title='뮤직고고'>
                    <FormControlLabel
                        control={<Switch checked={value.isPaidGogo} onChange={onChangeSwitch} name="isPaidGogo" />}
                        label={value.isPaidGogo ? "뮤직고고 수강중" : "뮤직고고 미등록"}
                    />
                </LeftTitle>

                <LeftTitleTextField
                    title="어린음악대 반이름"
                    type="text"
                    name='musicClass'
                    value={value.classInfo?.musicClass}
                    onChange={onChangeClass}
                />
                <LeftTitleTextField
                    title="어린화가들 반이름"
                    type="text"
                    name='artClass'
                    value={value.classInfo?.artClass}
                    onChange={onChangeClass}
                />
                <LeftTitle title='원생등록'>
                    <FormControlLabel
                        control={<Switch checked={value.isRegistered} onChange={onChangeSwitch} name="isRegistered" />}
                        label={value.isRegistered ? "원생 등록 확인" : "원생 미등록"}
                    />
                </LeftTitle>


                {
                    rnr.level < 2 &&
                    <Button onClick={() => setAnotherInfoOpen(!anotherInfoOpen)} variant='contained'> Lv1이하 testView</Button>
                }
                {
                    anotherInfoOpen &&
                    <NewStudent
                        value={value}
                        setValue={setValue}
                    />
                }



            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='contained'>닫기</Button>
                <Button onClick={handleNewStudent} color="primary" variant='contained' startIcon={<SaveRounded />}>저장</Button>
            </DialogActions>
        </Dialog>
    );
}
const LeftTitleTextField = ({ title, ...other }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' alignItems='center' >{title}</Grid>
        <Grid item xs={matches ? 12 : 10}>
            <TextField
                {...other}
                variant='outlined'
                size='small'
                margin='dense'
                fullWidth
                color='secondary'
            />
        </Grid>
    </Grid>);
}
const LeftTitleRadio = ({ title, value, onChange, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container direction='row' alignItems='center'>
            <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
            <Grid item xs={matches ? 12 : 10}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="category" name="category" value={value} onChange={onChange} row>
                        {children}
                    </RadioGroup>
                </FormControl>
            </Grid>

        </Grid>

    )
}
const LeftTitle = ({ title, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
        <Grid item xs={matches ? 12 : 10}>
            {children}
        </Grid>
    </Grid>);
}

export default withStyles(styles)(DialogStudentNew);