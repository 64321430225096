import React from 'react';
import { useParams } from 'react-router-dom';
// Redux stuff

// components
import MainGridDark from '../components/layout/MainGridDark';
import MainTabsDark from '../components/layout/MainTabsDark';
import HakwonMain from './manager/HakwonMain';
import Students from './manager/Students';
import Teachers from './manager/Teachers';
import Sales from './manager/Sales';
import Settle from './manager/Settle';
import Info from './manager/Info';
import Enrolled from './manager/Enrolled';
import TeachingPlan from './manager/TeachingPlan';
import Footer from '../components/layout/Footer';


// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import NavBarDark from '../components/layout/NavBarDark';

const styles = (theme) => ({
    ...theme.userPage,
    dense: {
        width: 90,
    }
});
export const Manager = (props) => {
    const { classes, history } = props;

    const { topic } = useParams();
    const tabInfo = [
        { id: "main", label: "메인", link: '/manager/main' },
        { id: "info", label: "학원 정보", link: '/manager/info' },
        { id: "students", label: "원생 관리", link: '/manager/students' },
        { id: "teachers", label: "교사 관리", link: '/manager/teachers' },
        { id: "sales", label: "매출", link: '/manager/sales' },
        { id: "settle", label: "정산", link: '/manager/settle' },
        { id: "enrolled", label: "수강생", link: '/manager/enrolled' },
        { id: "teachingplan", label: "교안", link: '/manager/teachingplan' },

    ];
    return (
        <>
            <NavBarDark menu={6} history={history} />
            <div className='container'>
                <MainGridDark>
                    <MainTabsDark tabInfo={tabInfo} className={classes.dense} />
                    {(topic === tabInfo[0].id || topic === 0) && <HakwonMain history={history} />}
                    {(topic === tabInfo[1].id || topic === 1) && <Info history={history} />}
                    {(topic === tabInfo[2].id || topic === 2) && <Students history={history} />}
                    {(topic === tabInfo[3].id || topic === 3) && <Teachers history={history} />}
                    {(topic === tabInfo[4].id || topic === 4) && <Sales history={history} />}
                    {(topic === tabInfo[5].id || topic === 5) && <Settle history={history} />}
                    {(topic === tabInfo[6].id || topic === 6) && <Enrolled history={history} />}
                    {(topic === tabInfo[7].id || topic === 7) && <TeachingPlan history={history} />}
                </MainGridDark>
                <Footer />
            </div>
        </>
    );
};



export default withStyles(styles)(Manager);

