import {
    SET_USER, SET_ERRORS, CLEAR_ERRORS,
    LOADING_UI, STOP_LOADING_UI,
    SET_UNAUTHENTICATED, LOADING_USER, SET_KAKAO, SET_KAKAOINFO, STOP_LOADING_USER, SET_MESSAGE, CLEAR_MESSAGE, SET_AUTHENTICATED
} from '../types';
import axios from 'axios';
import qs from 'qs';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';



export const loginUser = (userData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI }); // setLoading
    axios
        .post('/login', userData)
        .then(res => {
            setAuthorizationHeader(res.data.token, res.data?.refreshToken);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            let redir = localStorage.getItem('redir');
            if (redir) {
                let previous = (redir==='/enrollstep4' || redir ==='/enrollstep3') ? '/enrollstep2' : redir
                history.push(previous);
                localStorage.removeItem('redir');

            } else {
                history.push('/');
            }
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });
}


export const signupUser = (newUserData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI }); // setLoading
    axios
        .post('/signup', newUserData)
        .then(res => {
            setAuthorizationHeader(res.data.token, res.data?.refreshToken);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            history.push('/');
        })
        .catch(err => {

            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });
}

export const logoutUser = (history) => (dispatch) => {
    // dispatch({ type: LOADING_USER });
    localStorage.removeItem('FBIdToken');
    localStorage.removeItem('FBrefreshToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED });
    const redir = localStorage.getItem('redir');

    if (redir) {
        window.location.href = (redir ==='/enrollstep4' || redir ==='/enrollstep3') ? '/lesson' : redir;
        localStorage.removeItem('redir');
        
    } else if (history) {
        history.goBack();  
    } else {
        window.location.href = '/';
    }
         
}

export const getUserData = () => (dispatch) => {
    dispatch({ type: LOADING_USER });
    
    axios
        .get('/user')       // getAuthUser 함수 실행
        .then(res => {
            
            dispatch({
                type: SET_USER,
                payload: res.data
            })
            dispatch({
                type: STOP_LOADING_USER
            })
        })
        .catch(err => {
            /* dispatch({
                type: SET_UNAUTHENTICATED
            }) */
            dispatch({
                type: STOP_LOADING_USER
            })

        });
};

// [추가됨] refreshToken으로 접근할때 (not dispatch);
export const loginWithRefreshToken = async (refreshToken) => {
    const api_key = 'AIzaSyAXlSbuqFjKvPpREk3ckNFyZMspNFIru0k';
    const secureUrl = `https://securetoken.googleapis.com/v1/token?key=${api_key}&grant_type=refresh_token&refresh_token=${refreshToken}`;
                
    const res = await axios({
        method: "POST",
        url: secureUrl,
        headers: { "Content-Type": "application/json" }
    });
    const access_token = res.data.access_token;
    
    return access_token;
}

export const isLogged = (history) => (dispatch) => {
    const token = localStorage.FBIdToken;
    
    const rdrurl = window.location.pathname.replace('/', '') + window.location.search;
    const returnUrl = 
        rdrurl ==='enrollstep2' || rdrurl ==='enrollstep3' || rdrurl ==='enrollstep4' ?
        `/login?lesson` : `/login?${rdrurl}`;
    localStorage.setItem('redir', returnUrl);

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            //authenticated = false;
            dispatch(logoutUser(history));
            return returnUrl;

        } else {
            //authenticated = true;
            dispatch({ type: SET_AUTHENTICATED });
            axios.defaults.headers.common['Authorization'] = token;
            dispatch(getUserData());
            return 'logged';
        }
    } else {
        dispatch(logoutUser(history));
        return returnUrl;
    }

}



// 카카오 연결끊기/탈퇴
export const signoutUser = (history) => (dispatch) => {
    dispatch({ type: LOADING_USER });
    dispatch(logoutUser(history));
    const Kakao = window.Kakao;
    if (!Kakao.isInitialized()) {
        Kakao.init('be5ea3edf2d75970788c5adabba77a38');
    }
    Kakao.API.request({
        url: '/v1/user/unlink',
        success: function (response) {

            return response;
        },
        fail: function (error) {
            return error;
        }
    });
    dispatch({ type: STOP_LOADING_USER });


}

export const isUser = (userData) => (dispatch) => {
    axios
        .post('/login', userData)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
        });
}
export const uploadImage = (formData) => (dispatch) => {
    dispatch({ type: LOADING_USER });
    axios
        .post('/user/image', formData)
        .then(res => {
            dispatch(getUserData());
            dispatch({ type: STOP_LOADING_USER });
        })
        .catch(err => {
            console.error(err);
            dispatch({ type: STOP_LOADING_USER });
        })
};
export const editUserDetails = (userDetails) => (dispatch) => {
    dispatch({ type: LOADING_USER });
    axios
        .post(`/user`, userDetails)
        .then(res => {
            dispatch(getUserData());
            dispatch({ type: STOP_LOADING_USER });
        })
        .catch(err => {
            console.error(err);
            dispatch({ type: STOP_LOADING_USER });
        })
};
export const signoutUserForever = (credentials, history) => (dispatch) => {
    // 교사 삭제
    // auth는 삭제
    // user collection은 아이디만 남기고, status : false
    // + 카카오연결끊기

    let userDetails = {
        accountId: 'deleted',
        address: null,
        level: 9,
        status: false,
        changedAt: new Date() / 1000,
        email: 'deleted',
        joinPath: '',
        name: '탈퇴',
        phone: '010-0000-0000',
        profileImage: 'deleted'
    }
    axios
        .post(`/user`, userDetails)
        .then((res) => {
            dispatch({ type: STOP_LOADING_USER });

            return axios.delete('/user');
        })
        .then((res) => {
            if (credentials.level === 3) {
                return axios.delete(`/teacher/${credentials.uid}`)
            } else {
                return res;
            }
        })
        .then((res) => {
            dispatch(signoutUser(history));
        })
        .then(() => {
            dispatch(logoutUser(history));
            history.push('/');
        })
        .catch(err => {
            dispatch({ type: STOP_LOADING_USER });
            alert("오류가 발생했습니다.")
        })
};


const setAuthorizationHeader = (token, refreshToken) => {
    const FBIdToken = `Bearer ${token}`;
    const FBrefreshToken = refreshToken;
    // set FBIdToken in localstorage
    localStorage.setItem('FBIdToken', FBIdToken);
    if (refreshToken) {
        localStorage.setItem('FBrefreshToken', FBrefreshToken);
    }
    // axios header
    axios.defaults.headers.common['Authorization'] = FBIdToken;
};
const ORIGIN_PATH = window.location.origin;
const REDIRECT_URI = `${ORIGIN_PATH}/kakao`;

// get Kakao Token
export const getKakao = (code) => (dispatch) => {
    // JS 이용 시
    // REST_API 이용 시
    axios({
        method: 'post',
        url: 'https://kauth.kakao.com/oauth/token',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
            'client_id': '1f452428169c375c44607a1a307b8091',
            'redirect_uri': REDIRECT_URI,
            'grant_type': 'authorization_code',
            'code': code
        })
    }).then(res => {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
            kakao.init('be5ea3edf2d75970788c5adabba77a38');
        }
        kakao.Auth.setAccessToken(res.data.access_token);
        dispatch({
            type: SET_KAKAO,
            payload: res.data
        });
        return kakao;
    }).then(kakao => {
        kakao.Auth.getAccessToken();
        kakao.API.request({
            url: '/v2/user/me',
            success: function (response) {
                dispatch({
                    type: SET_KAKAOINFO,
                    payload: response
                })
                return response;
            },
            fail: function (error) {
                return error;
            }
        });
    }).catch(err => {
        console.error(err);
        return err;
    })
};


// test : 모바일에서 앱으로 연결되어 로그인하기
export const withAppLogin = () => (dispatch) => {
    // JS 이용 시
    const Kakao = window.Kakao;
    if (!Kakao.isInitialized()) {
        Kakao.init('be5ea3edf2d75970788c5adabba77a38');
    }
    Kakao.Auth.login({
        success: (auth) => {
            dispatch({
                type: SET_KAKAO,
                payload: auth.data
            });
            
            window.location.href = `${REDIRECT_URI}?code=withApp&${auth.access_token}`;

        },
        fail: err => {
            console.error(err);
        }
    })


};
// get Kakao Info
export const getKakaoInfo = (mToken) => (dispatch) => { // 모바일 톡으로 연결할때 하는 것으로 변경

    const Kakao = window.Kakao;
    if (!Kakao.isInitialized()) {
        Kakao.init('be5ea3edf2d75970788c5adabba77a38');
    }

    Kakao.Auth.setAccessToken(mToken);
    Kakao.Auth.getAccessToken();
    Kakao.API.request({
        url: '/v2/user/me',
        success: function (response) {

            dispatch({
                type: SET_KAKAOINFO,
                payload: response
            });
            return response;
        },
        fail: function (error) {

            return error;
        }
    });


};

// signup User by kakao
export const signupUserByKakao = (newUserData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI }); // setLoading
    axios
        .post('/signup', newUserData)
        .then(res => {
            setAuthorizationHeader(res.data.token, res.data?.refreshToken);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            // 방문 로그 남기기
            dispatch(visitLogWithKakao('/joined'));

            history.push('/joined');
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });
}

// login by Kakao
export const loginUserByKakao = (userData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post('/login', userData)
        .then(res => {
            setAuthorizationHeader(res.data.token, res.data?.refreshToken);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            let redir = localStorage.getItem('redir');
            // 방문 로그 남기기
            dispatch(visitLogWithKakao(redir));
            // redir
            if (redir) {
                // 결제최종단계는 전단계로 redirect
                let previous = (redir==='/enrollstep4' || redir ==='/enrollstep3') ? '/enrollstep2' : redir
                history.push(previous);
                localStorage.removeItem('redir');
            } else {
                history.push('/');
            }
        })
        .catch(err => {
            console.log(err.response.data)
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });
}

// update info

export const updateUserInfo = (userData) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post('/user', userData)
        .then(res => {

            dispatch({
                type: SET_MESSAGE,
                payload: res.data
            })
            dispatch({ type: CLEAR_ERRORS });
            dispatch({
                type: STOP_LOADING_UI
            })
            dispatch(getUserData());
            alert(`${res.data.message}`);
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response
            })
            dispatch({ type: CLEAR_MESSAGE })
            dispatch({
                type: STOP_LOADING_UI
            })
        })
}

// write visit log;
export const visitLogWithKakao = (dir) => async (dispatch) => {
    const brows = checkBrowser();

    const resu = await axios.get('/user');
    const us = resu.data.credentials;
    const info = {
        name: us.name,
        phone: us.phone,
        uid: us.uid,
        hName: us.myHakwon.hName,
        level: us.level,
        accountId: us.accountId,
        media: brows ? brows : null,
        dir: dir,
        date: dayjs().format('YYYYMMDD')
    }


    axios
        .post(`/writevisitlog`, info)
        .then(res => {
            //console.log(res.data);
        })
        .catch(err => {
            //console.log(err);
        })
}

export const checkBrowser = () => {
    // Get the user-agent string 
    let userAgentString =
        navigator.userAgent;

    let EdgeAgent =
        userAgentString.indexOf("Edg") > -1;
    // Detect Chrome 
    let chromeAgent =
        userAgentString.indexOf("Chrome") > -1;

    // Detect Internet Explorer 
    let IExplorerAgent =
        userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1;
    // Detect Firefox 
    let firefoxAgent =
        userAgentString.indexOf("Firefox") > -1;

    // Detect Safari 
    let safariAgent =
        userAgentString.indexOf("Safari") > -1;

    // Discard Safari since it also matches Chrome 
    if ((chromeAgent) && (safariAgent))
        safariAgent = false;

    // Detect Opera 
    let operaAgent =
        userAgentString.indexOf("OP") > -1;



    // Discard Chrome since it also matches Opera      
    if ((chromeAgent) && (operaAgent))
        chromeAgent = false;
    const device = /iPhone/i.test(navigator.userAgent) ? 'iPhone' : /iPad/i.test(navigator.userAgent) ? 'iPad' : /Android/i.test(navigator.userAgent) ? 'Android' : 'Web';

    if (IExplorerAgent) return 'IE-' + device;
    if (firefoxAgent) return 'firefox-' + device;
    if (safariAgent) return 'safari-' + device;
    if (operaAgent) return 'opera-' + device;
    if (EdgeAgent) return 'Edge-' + device;
    if (chromeAgent) return 'chrome-' + device;
    return navigator.userAgent;
}