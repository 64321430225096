import React, { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getAllHakwonsContacts } from '../../redux/actions/adminActions';

function HakwonAutocomplete(props) {
    const { searchHakwon, setSearchHakwon, label } = props;
    const { adminhakwons } = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllHakwonsContacts());
    }, [dispatch]);

    const options = adminhakwons ? adminhakwons.map((h) => h.hName) : null;

    return (
        <Autocomplete
            inputValue={searchHakwon}
            onInputChange={(e, newVal) => {
                setSearchHakwon(newVal);
            }}
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ width: 230, backgroundColor: '#FAF3E7' }}
                    label={label}
                    size="small"
                    margin="dense"
                />
            )}
        />
    );
}

export default HakwonAutocomplete;
