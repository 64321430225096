// 가맹원 주소 코드 정의
export const proviceCode = [
    { code: 1, province: "서울특별시" },
    { code: 2, province: "인천광역시" },
    { code: 3, province: "경기도" },
    { code: 4, province: "강원도" },
    { code: 5, province: "세종특별자치시" },
    { code: 6, province: "대전광역시" },
    { code: 7, province: "충청남도" },
    { code: 8, province: "충청북도" },
    { code: 9, province: "부산광역시" },
    { code: 10, province: "대구광역시" },
    { code: 11, province: "울산광역시" },
    { code: 12, province: "경상남도" },
    { code: 13, province: "경상북도" },
    { code: 14, province: "광주광역시" },
    { code: 15, province: "전라남도" },
    { code: 16, province: "전라북도" },
    { code: 17, province: "제주특별자치도" },
    { code: 18, province: "국외" }
];
export const addressCode = [
    { code: 1000, provinceCode: 4, province: "강원도", area: "철원군" },
    { code: 1001, provinceCode: 4, province: "강원도", area: "화천군" },
    { code: 1002, provinceCode: 4, province: "강원도", area: "춘천시" },
    { code: 1003, provinceCode: 4, province: "강원도", area: "양구군" },
    { code: 1004, provinceCode: 4, province: "강원도", area: "인제군" },
    { code: 1005, provinceCode: 4, province: "강원도", area: "고성군" },
    { code: 1006, provinceCode: 4, province: "강원도", area: "속초시" },
    { code: 1007, provinceCode: 4, province: "강원도", area: "양양군" },
    { code: 1008, provinceCode: 4, province: "강원도", area: "홍천군" },
    { code: 1009, provinceCode: 4, province: "강원도", area: "횡성군" },
    { code: 1010, provinceCode: 4, province: "강원도", area: "평창군" },
    { code: 1011, provinceCode: 4, province: "강원도", area: "강릉시" },
    { code: 1012, provinceCode: 4, province: "강원도", area: "동해시" },
    { code: 1013, provinceCode: 4, province: "강원도", area: "삼척시" },
    { code: 1014, provinceCode: 4, province: "강원도", area: "태백시" },
    { code: 1015, provinceCode: 4, province: "강원도", area: "정선군" },
    { code: 1016, provinceCode: 4, province: "강원도", area: "영월군" },
    { code: 1017, provinceCode: 4, province: "강원도", area: "원주시" },
    { code: 1018, provinceCode: 3, province: "경기도", area: "김포시" },
    { code: 1019, provinceCode: 3, province: "경기도", area: "고양시 일산서구" },
    { code: 1020, provinceCode: 3, province: "경기도", area: "고양시 일산동구" },
    { code: 1021, provinceCode: 3, province: "경기도", area: "고양시 덕양구" },
    { code: 1022, provinceCode: 3, province: "경기도", area: "파주시" },
    { code: 1023, provinceCode: 3, province: "경기도", area: "연천군" },
    { code: 1024, provinceCode: 3, province: "경기도", area: "포천시" },
    { code: 1025, provinceCode: 3, province: "경기도", area: "동두천시" },
    { code: 1026, provinceCode: 3, province: "경기도", area: "양주시" },
    { code: 1027, provinceCode: 3, province: "경기도", area: "의정부시" },
    { code: 1028, provinceCode: 3, province: "경기도", area: "구리시" },
    { code: 1029, provinceCode: 3, province: "경기도", area: "남양주시" },
    { code: 1030, provinceCode: 3, province: "경기도", area: "가평군" },
    { code: 1031, provinceCode: 3, province: "경기도", area: "양평군" },
    { code: 1032, provinceCode: 3, province: "경기도", area: "여주시" },
    { code: 1033, provinceCode: 3, province: "경기도", area: "광주시" },
    { code: 1034, provinceCode: 3, province: "경기도", area: "하남시" },
    { code: 1035, provinceCode: 3, province: "경기도", area: "성남시 수정구" },
    { code: 1036, provinceCode: 3, province: "경기도", area: "성남시 중원구" },
    { code: 1037, provinceCode: 3, province: "경기도", area: "성남시 분당구" },
    { code: 1038, provinceCode: 3, province: "경기도", area: "과천시" },
    { code: 1039, provinceCode: 3, province: "경기도", area: "안양시 만안구" },
    { code: 1040, provinceCode: 3, province: "경기도", area: "안양시 동안구" },
    { code: 1041, provinceCode: 3, province: "경기도", area: "광명시" },
    { code: 1042, provinceCode: 3, province: "경기도", area: "부천시" },
    { code: 1043, provinceCode: 3, province: "경기도", area: "시흥시" },
    { code: 1044, provinceCode: 3, province: "경기도", area: "안산시 상록구" },
    { code: 1045, provinceCode: 3, province: "경기도", area: "안산시 단원구" },
    { code: 1046, provinceCode: 3, province: "경기도", area: "군포시" },
    { code: 1047, provinceCode: 3, province: "경기도", area: "의왕시" },
    { code: 1048, provinceCode: 3, province: "경기도", area: "수원시 장안구" },
    { code: 1049, provinceCode: 3, province: "경기도", area: "수원시 영통구" },
    { code: 1050, provinceCode: 3, province: "경기도", area: "수원시 팔달구" },
    { code: 1051, provinceCode: 3, province: "경기도", area: "수원시 권선구" },
    { code: 1052, provinceCode: 3, province: "경기도", area: "용인시 기흥구" },
    { code: 1053, provinceCode: 3, province: "경기도", area: "화성시" },
    { code: 1054, provinceCode: 3, province: "경기도", area: "용인시 수지구" },
    { code: 1055, provinceCode: 3, province: "경기도", area: "용인시 처인구" },
    { code: 1056, provinceCode: 3, province: "경기도", area: "이천시" },
    { code: 1057, provinceCode: 3, province: "경기도", area: "안성시" },
    { code: 1058, provinceCode: 3, province: "경기도", area: "평택시" },
    { code: 1059, provinceCode: 3, province: "경기도", area: "오산시" },
    { code: 1060, provinceCode: 12, province: "경상남도", area: "함양군" },
    { code: 1061, provinceCode: 12, province: "경상남도", area: "거창군" },
    { code: 1062, provinceCode: 12, province: "경상남도", area: "합천군" },
    { code: 1063, provinceCode: 12, province: "경상남도", area: "창녕군" },
    { code: 1064, provinceCode: 12, province: "경상남도", area: "밀양시" },
    { code: 1065, provinceCode: 12, province: "경상남도", area: "양산시" },
    { code: 1066, provinceCode: 12, province: "경상남도", area: "김해시" },
    { code: 1067, provinceCode: 12, province: "경상남도", area: "창원시 의창구" },
    { code: 1068, provinceCode: 12, province: "경상남도", area: "창원시 마산회원구" },
    { code: 1069, provinceCode: 12, province: "경상남도", area: "창원시 마산합포구" },
    { code: 1070, provinceCode: 12, province: "경상남도", area: "창원시 성산구" },
    { code: 1071, provinceCode: 12, province: "경상남도", area: "창원시 진해구" },
    { code: 1072, provinceCode: 12, province: "경상남도", area: "함안군" },
    { code: 1073, provinceCode: 12, province: "경상남도", area: "의령군" },
    { code: 1074, provinceCode: 12, province: "경상남도", area: "산청군" },
    { code: 1075, provinceCode: 12, province: "경상남도", area: "하동군" },
    { code: 1076, provinceCode: 12, province: "경상남도", area: "남해군" },
    { code: 1077, provinceCode: 12, province: "경상남도", area: "사천시" },
    { code: 1078, provinceCode: 12, province: "경상남도", area: "진주시" },
    { code: 1079, provinceCode: 12, province: "경상남도", area: "고성군" },
    { code: 1080, provinceCode: 12, province: "경상남도", area: "통영시" },
    { code: 1081, provinceCode: 12, province: "경상남도", area: "거제시" },
    { code: 1082, provinceCode: 13, province: "경상북도", area: "영주시" },
    { code: 1083, provinceCode: 13, province: "경상북도", area: "봉화군" },
    { code: 1084, provinceCode: 13, province: "경상북도", area: "울진군" },
    { code: 1085, provinceCode: 13, province: "경상북도", area: "영덕군" },
    { code: 1086, provinceCode: 13, province: "경상북도", area: "영양군" },
    { code: 1087, provinceCode: 13, province: "경상북도", area: "안동시" },
    { code: 1088, provinceCode: 13, province: "경상북도", area: "예천군" },
    { code: 1089, provinceCode: 13, province: "경상북도", area: "문경시" },
    { code: 1090, provinceCode: 13, province: "경상북도", area: "상주시" },
    { code: 1091, provinceCode: 13, province: "경상북도", area: "의성군" },
    { code: 1092, provinceCode: 13, province: "경상북도", area: "청송군" },
    { code: 1093, provinceCode: 13, province: "경상북도", area: "포항시 북구" },
    { code: 1094, provinceCode: 13, province: "경상북도", area: "포항시 남구" },
    { code: 1095, provinceCode: 13, province: "경상북도", area: "경주시" },
    { code: 1096, provinceCode: 13, province: "경상북도", area: "청도군" },
    { code: 1097, provinceCode: 13, province: "경상북도", area: "경산시" },
    { code: 1098, provinceCode: 13, province: "경상북도", area: "영천시" },
    { code: 1099, provinceCode: 13, province: "경상북도", area: "군위군" },
    { code: 1100, provinceCode: 13, province: "경상북도", area: "구미시" },
    { code: 1101, provinceCode: 13, province: "경상북도", area: "김천시" },
    { code: 1102, provinceCode: 13, province: "경상북도", area: "칠곡군" },
    { code: 1103, provinceCode: 13, province: "경상북도", area: "성주군" },
    { code: 1104, provinceCode: 13, province: "경상북도", area: "고령군" },
    { code: 1105, provinceCode: 13, province: "경상북도", area: "울릉군" },
    { code: 1106, provinceCode: 14, province: "광주광역시", area: "북구" },
    { code: 1107, provinceCode: 14, province: "광주광역시", area: "동구" },
    { code: 1108, provinceCode: 14, province: "광주광역시", area: "남구" },
    { code: 1109, provinceCode: 14, province: "광주광역시", area: "서구" },
    { code: 1110, provinceCode: 14, province: "광주광역시", area: "광산구" },
    { code: 1111, provinceCode: 10, province: "대구광역시", area: "동구" },
    { code: 1112, provinceCode: 10, province: "대구광역시", area: "북구" },
    { code: 1113, provinceCode: 10, province: "대구광역시", area: "서구" },
    { code: 1114, provinceCode: 10, province: "대구광역시", area: "중구" },
    { code: 1115, provinceCode: 10, province: "대구광역시", area: "수성구" },
    { code: 1116, provinceCode: 10, province: "대구광역시", area: "남구" },
    { code: 1117, provinceCode: 10, province: "대구광역시", area: "달서구" },
    { code: 1118, provinceCode: 10, province: "대구광역시", area: "달성군" },
    { code: 1119, provinceCode: 6, province: "대전광역시", area: "유성구" },
    { code: 1120, provinceCode: 6, province: "대전광역시", area: "대덕구" },
    { code: 1121, provinceCode: 6, province: "대전광역시", area: "동구" },
    { code: 1122, provinceCode: 6, province: "대전광역시", area: "중구" },
    { code: 1123, provinceCode: 6, province: "대전광역시", area: "서구" },
    { code: 1124, provinceCode: 9, province: "부산광역시", area: "기장군" },
    { code: 1125, provinceCode: 9, province: "부산광역시", area: "금정구" },
    { code: 1126, provinceCode: 9, province: "부산광역시", area: "해운대구" },
    { code: 1127, provinceCode: 9, province: "부산광역시", area: "북구" },
    { code: 1128, provinceCode: 9, province: "부산광역시", area: "강서구" },
    { code: 1129, provinceCode: 9, province: "부산광역시", area: "사상구" },
    { code: 1130, provinceCode: 9, province: "부산광역시", area: "부산진구" },
    { code: 1131, provinceCode: 9, province: "부산광역시", area: "연제구" },
    { code: 1132, provinceCode: 9, province: "부산광역시", area: "동래구" },
    { code: 1133, provinceCode: 9, province: "부산광역시", area: "수영구" },
    { code: 1134, provinceCode: 9, province: "부산광역시", area: "남구" },
    { code: 1135, provinceCode: 9, province: "부산광역시", area: "동구" },
    { code: 1136, provinceCode: 9, province: "부산광역시", area: "중구" },
    { code: 1137, provinceCode: 9, province: "부산광역시", area: "영도구" },
    { code: 1138, provinceCode: 9, province: "부산광역시", area: "서구" },
    { code: 1139, provinceCode: 9, province: "부산광역시", area: "사하구" },
    { code: 1140, provinceCode: 1, province: "서울특별시", area: "강북구" },
    { code: 1141, provinceCode: 1, province: "서울특별시", area: "도봉구" },
    { code: 1142, provinceCode: 1, province: "서울특별시", area: "노원구" },
    { code: 1143, provinceCode: 1, province: "서울특별시", area: "중랑구" },
    { code: 1144, provinceCode: 1, province: "서울특별시", area: "동대문구" },
    { code: 1145, provinceCode: 1, province: "서울특별시", area: "성북구" },
    { code: 1146, provinceCode: 1, province: "서울특별시", area: "종로구" },
    { code: 1147, provinceCode: 1, province: "서울특별시", area: "은평구" },
    { code: 1148, provinceCode: 1, province: "서울특별시", area: "서대문구" },
    { code: 1149, provinceCode: 1, province: "서울특별시", area: "마포구" },
    { code: 1150, provinceCode: 1, province: "서울특별시", area: "용산구" },
    { code: 1151, provinceCode: 1, province: "서울특별시", area: "중구" },
    { code: 1152, provinceCode: 1, province: "서울특별시", area: "성동구" },
    { code: 1153, provinceCode: 1, province: "서울특별시", area: "광진구" },
    { code: 1154, provinceCode: 1, province: "서울특별시", area: "강동구" },
    { code: 1155, provinceCode: 1, province: "서울특별시", area: "송파구" },
    { code: 1156, provinceCode: 1, province: "서울특별시", area: "강남구" },
    { code: 1157, provinceCode: 1, province: "서울특별시", area: "서초구" },
    { code: 1158, provinceCode: 1, province: "서울특별시", area: "동작구" },
    { code: 1159, provinceCode: 1, province: "서울특별시", area: "영등포구" },
    { code: 1160, provinceCode: 1, province: "서울특별시", area: "강서구" },
    { code: 1161, provinceCode: 1, province: "서울특별시", area: "양천구" },
    { code: 1162, provinceCode: 1, province: "서울특별시", area: "구로구" },
    { code: 1163, provinceCode: 1, province: "서울특별시", area: "금천구" },
    { code: 1164, provinceCode: 1, province: "서울특별시", area: "관악구" },
    { code: 1165, provinceCode: 5, province: "세종특별자치시", area: "전체" },
    { code: 1166, provinceCode: 11, province: "울산광역시", area: "동구" },
    { code: 1167, provinceCode: 11, province: "울산광역시", area: "북구" },
    { code: 1168, provinceCode: 11, province: "울산광역시", area: "중구" },
    { code: 1169, provinceCode: 11, province: "울산광역시", area: "남구" },
    { code: 1170, provinceCode: 11, province: "울산광역시", area: "울주군" },
    { code: 1171, provinceCode: 2, province: "인천광역시", area: "계양구" },
    { code: 1172, provinceCode: 2, province: "인천광역시", area: "부평구" },
    { code: 1173, provinceCode: 2, province: "인천광역시", area: "남동구" },
    { code: 1174, provinceCode: 2, province: "인천광역시", area: "연수구" },
    { code: 1175, provinceCode: 2, province: "인천광역시", area: "미추홀구" },
    { code: 1176, provinceCode: 2, province: "인천광역시", area: "중구" },
    { code: 1177, provinceCode: 2, province: "인천광역시", area: "동구" },
    { code: 1178, provinceCode: 2, province: "인천광역시", area: "서구" },
    { code: 1179, provinceCode: 2, province: "인천광역시", area: "강화군" },
    { code: 1180, provinceCode: 2, province: "인천광역시", area: "옹진군" },
    { code: 1181, provinceCode: 15, province: "전라남도", area: "영광군" },
    { code: 1182, provinceCode: 15, province: "전라남도", area: "함평군" },
    { code: 1183, provinceCode: 15, province: "전라남도", area: "장성군" },
    { code: 1184, provinceCode: 15, province: "전라남도", area: "담양군" },
    { code: 1185, provinceCode: 15, province: "전라남도", area: "곡성군" },
    { code: 1186, provinceCode: 15, province: "전라남도", area: "구례군" },
    { code: 1187, provinceCode: 15, province: "전라남도", area: "광양시" },
    { code: 1188, provinceCode: 15, province: "전라남도", area: "순천시" },
    { code: 1189, provinceCode: 15, province: "전라남도", area: "화순군" },
    { code: 1190, provinceCode: 15, province: "전라남도", area: "나주시" },
    { code: 1191, provinceCode: 15, province: "전라남도", area: "영암군" },
    { code: 1192, provinceCode: 15, province: "전라남도", area: "무안군" },
    { code: 1193, provinceCode: 15, province: "전라남도", area: "목포시" },
    { code: 1194, provinceCode: 15, province: "전라남도", area: "신안군" },
    { code: 1195, provinceCode: 15, province: "전라남도", area: "진도군" },
    { code: 1196, provinceCode: 15, province: "전라남도", area: "해남군" },
    { code: 1197, provinceCode: 15, province: "전라남도", area: "완도군" },
    { code: 1198, provinceCode: 15, province: "전라남도", area: "강진군" },
    { code: 1199, provinceCode: 15, province: "전라남도", area: "장흥군" },
    { code: 1200, provinceCode: 15, province: "전라남도", area: "보성군" },
    { code: 1201, provinceCode: 15, province: "전라남도", area: "고흥군" },
    { code: 1202, provinceCode: 15, province: "전라남도", area: "여수시" },
    { code: 1203, provinceCode: 16, province: "전라북도", area: "군산시" },
    { code: 1204, provinceCode: 16, province: "전라북도", area: "김제시" },
    { code: 1205, provinceCode: 16, province: "전라북도", area: "익산시" },
    { code: 1206, provinceCode: 16, province: "전라북도", area: "전주시 덕진구" },
    { code: 1207, provinceCode: 16, province: "전라북도", area: "전주시 완산구" },
    { code: 1208, provinceCode: 16, province: "전라북도", area: "완주군" },
    { code: 1209, provinceCode: 16, province: "전라북도", area: "진안군" },
    { code: 1210, provinceCode: 16, province: "전라북도", area: "무주군" },
    { code: 1211, provinceCode: 16, province: "전라북도", area: "장수군" },
    { code: 1212, provinceCode: 16, province: "전라북도", area: "남원시" },
    { code: 1213, provinceCode: 16, province: "전라북도", area: "임실군" },
    { code: 1214, provinceCode: 16, province: "전라북도", area: "순창군" },
    { code: 1215, provinceCode: 16, province: "전라북도", area: "정읍시" },
    { code: 1216, provinceCode: 16, province: "전라북도", area: "부안군" },
    { code: 1217, provinceCode: 16, province: "전라북도", area: "고창군" },
    { code: 1218, provinceCode: 17, province: "제주특별자치도", area: "제주시" },
    { code: 1219, provinceCode: 17, province: "제주특별자치도", area: "서귀포시" },
    { code: 1220, provinceCode: 7, province: "충청남도", area: "천안시 서북구" },
    { code: 1221, provinceCode: 7, province: "충청남도", area: "천안시 동남구" },
    { code: 1222, provinceCode: 7, province: "충청남도", area: "아산시" },
    { code: 1223, provinceCode: 7, province: "충청남도", area: "당진시" },
    { code: 1224, provinceCode: 7, province: "충청남도", area: "서산시" },
    { code: 1225, provinceCode: 7, province: "충청남도", area: "태안군" },
    { code: 1226, provinceCode: 7, province: "충청남도", area: "홍성군" },
    { code: 1227, provinceCode: 7, province: "충청남도", area: "예산군" },
    { code: 1228, provinceCode: 7, province: "충청남도", area: "공주시" },
    { code: 1229, provinceCode: 7, province: "충청남도", area: "금산군" },
    { code: 1230, provinceCode: 7, province: "충청남도", area: "계룡시" },
    { code: 1231, provinceCode: 7, province: "충청남도", area: "논산시" },
    { code: 1232, provinceCode: 7, province: "충청남도", area: "부여군" },
    { code: 1233, provinceCode: 7, province: "충청남도", area: "청양군" },
    { code: 1234, provinceCode: 7, province: "충청남도", area: "보령시" },
    { code: 1235, provinceCode: 7, province: "충청남도", area: "서천군" },
    { code: 1236, provinceCode: 8, province: "충청북도", area: "단양군" },
    { code: 1237, provinceCode: 8, province: "충청북도", area: "제천시" },
    { code: 1238, provinceCode: 8, province: "충청북도", area: "충주시" },
    { code: 1239, provinceCode: 8, province: "충청북도", area: "음성군" },
    { code: 1240, provinceCode: 8, province: "충청북도", area: "진천군" },
    { code: 1241, provinceCode: 8, province: "충청북도", area: "증평군" },
    { code: 1242, provinceCode: 8, province: "충청북도", area: "괴산군" },
    { code: 1243, provinceCode: 8, province: "충청북도", area: "청주시 흥덕구" },
    { code: 1244, provinceCode: 8, province: "충청북도", area: "청주시 청원구" },
    { code: 1245, provinceCode: 8, province: "충청북도", area: "청주시 서원구" },
    { code: 1246, provinceCode: 8, province: "충청북도", area: "청주시 상당구" },
    { code: 1247, provinceCode: 8, province: "충청북도", area: "보은군" },
    { code: 1248, provinceCode: 8, province: "충청북도", area: "옥천군" },
    { code: 1249, provinceCode: 8, province: "충청북도", area: "영동군" },
    { code: 1250, provinceCode: 18, province: "국외", area: "캄보디아" },
    { code: 1251, provinceCode: 18, province: "국외", area: "중국" }

]
