import React, { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getCurriculumsByProduct } from '../../redux/actions/dataActions';
// component
import VideoClip from '../../components/home/VideoClip';
import CenterLoading from '../../components/layout/CenterLoading';
// mui
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
    Box,
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Dialog,
    DialogContent,
    IconButton,
    Button,
    useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import dayjs from 'dayjs';

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        margin: theme.spacing(3),
        backgroundColor: '#ffffff',
    },
    box: {
        margin: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    img: {
        [theme.breakpoints.down('sm')]: {
            width: 138,
            height: 79,
        },
        [theme.breakpoints.up('md')]: {
            width: 224,
            height: 126,
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

function MonthlyPlan(props) {
    const { classes, month } = props;
    const { curriculums, dataLoading } = useSelector((state) => state.data);
    const dispatch = useDispatch();
    //const cName = localStorage.getItem('categoryName');
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(localStorage.categoryName || '플레이어');

    // popup
    const [open, setOpen] = useState(false);
    const [num, setNum] = useState(0);
    const [images, setImages] = useState(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const popupView = (imgs, index) => {
        setImages(imgs);
        setNum(index);
        setOpen(true);
    };

    // withYear
    //const thisYear = month < dayjs().month()+1 ? dayjs().add(1, 'year').year() : dayjs().year();
    //const thisYear = dayjs().year();
    // const thisMonth = String(thisYear) + (month < 9 ? ('0' + String(month + 1)) : String(month + 1));

    // 월별 탭을 눌렀을 때, 나타나는 커리큘럼월을 고정함. 고정된 날짜에 맞춰 값을 수정하여 매년 사용.
    const applied = [
        '202101',
        '202102',
        '202103',
        '202104',
        '202105',
        '202106',
        '202107',
        '202108',
        '202109',
        '202110',
        '202111',
        '202112',
    ];
    const thisMonth = applied[month];

    useEffect(() => {
        dispatch(getCurriculumsByProduct('DzbNwY2Bz5uPXP5r2sX6'));
    }, [dispatch]);

    useEffect(() => {
        let cats = curriculums.map((curri) => curri.category); // 중복제거 (카테고리구하기)
        setCategories(cats.filter((f, i) => cats.indexOf(f) === i).reverse());
    }, [curriculums]);

    const handleCategory = (e) => {
        setCategory(e.target.value);
        localStorage.setItem('categoryName', e.target.value);
    };

    // withoutYear
    const curriOfMonth = curriculums
        .filter((cu) => cu.yyyymm === thisMonth && cu.status === 'open' && cu.category === category)
        .sort((a, b) => (a.week < b.week ? -1 : a.week > b.week ? 1 : 0));

    return (
        <Box>
            <Box align="center">
                <FormControl component="fieldset">
                    <RadioGroup aria-label="category" name="category" value={category} onChange={handleCategory} row>
                        {categories.map((cate, ind) => (
                            <FormControlLabel key={ind} value={cate} control={<Radio />} label={`${cate} 단계`} />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box p={2} align={'center'}>
                <Typography variant="body2" color="textSecondary">
                    ※ 효과적인 주제 활동을 위해 교구의 디자인과 내용은 수정, 보완될 수 있습니다.{' '}
                </Typography>
            </Box>

            {dataLoading && <CenterLoading />}
            {curriOfMonth.map((cu, index) => (
                <Box className={classes.root} key={index}>
                    <Box className={classes.box}>
                        <Typography variant="subtitle1" color="secondary" gutterBottom>
                            {cu.curriculumName}
                        </Typography>
                    </Box>
                    <Box className={classes.box}>
                        <Typography variant="h4" gutterBottom>
                            {cu.desc.활동명}{' '}
                        </Typography>
                        <Typography variant="subtitle1" color="secondary" gutterBottom>
                            <b>
                                [{cu.desc.활동}] {cu.desc.준비}
                            </b>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            - 수업목표 : {cu.desc.목표}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            - 활동자료 : {cu.desc.활동자료}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            - 워크북 : {cu.desc.워크북}
                        </Typography>
                    </Box>
                    <Box height={10} />
                    <Box m={1}>
                        <Grid container direction="row">
                            {cu.images
                                ? cu.images.map((im, ind) => (
                                      <Grid key={ind} item xs={12} sm={6} md={4} lg={3}>
                                          <Box
                                              style={{ cursor: 'pointer' }}
                                              onClick={matches ? null : () => popupView(cu.images, ind)}
                                          >
                                              {im.substring(0, 17) === 'https://vimeo.com' ? (
                                                  <VideoClip url={im} style={{ paddingBottom: 5 }} />
                                              ) : (
                                                  <Box p={1} m={1}>
                                                      <img width="100%" src={im} alt="curriculum" />
                                                  </Box>
                                              )}
                                          </Box>
                                      </Grid>
                                  ))
                                : null}
                        </Grid>
                    </Box>
                </Box>
            ))}

            {/* 외부 dialog */}
            {images ? (
                <Dialog open={open} maxWidth="md" fullWidth>
                    <DialogContent align="center" style={{ width: 960 }}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
                            <CloseIcon color="secondary" />
                        </IconButton>
                        {images[num].substring(0, 17) === 'https://vimeo.com' ? (
                            <VideoClip url={images[num]} style={{ paddingBottom: 5 }} />
                        ) : (
                            <Box p={1} m={1}>
                                <img width="100%" src={images[num]} alt="curriculum" />
                            </Box>
                        )}
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                <Button
                                    color="secondary"
                                    onClick={() => setNum((prev) => prev - 1)}
                                    disabled={num <= 0}
                                >
                                    이전
                                </Button>
                            </Grid>
                            <Typography>
                                {num + 1} / {images.length}
                            </Typography>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    onClick={() => setNum((prev) => prev + 1)}
                                    disabled={num + 1 >= images.length}
                                >
                                    다음
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            ) : null}
        </Box>
    );
}

export default withStyles(styles)(MonthlyPlan);
