import React from 'react';
import { useParams } from 'react-router-dom';
// Redux stuff
// components
import Navbar from '../components/layout/Navbar';
import MainGrid from '../components/layout/MainGrid';
import MainTabs from '../components/layout/MainTabs';
import EnrollPremium from './enroll/EnrollPremium';
import EnrollOneday from './enroll/EnrollOneday';
import EnrollRegular from './enroll/EnrollRegular';
import EnrollPianogogo from './enroll/EnrollPianogogo';
import Footer from '../components/layout/Footer';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


const styles = (theme) => ({
    ...theme.userPage,
});
export const Enroll = (props) => {
    const { topic } = useParams();
    const tabInfo = [
        { id: "regular", label: "뮤직고고", link: '/enroll/regular' },
        { id: "premium", label: "뮤직고고 프리미엄", link: '/enroll/premium' },
        { id: "oneday", label: "뮤직고고 원데이클래스", link: '/enroll/oneday' },
        { id: "pianogogo", label: "피아노고고 원데이클래스", link: '/enroll/pianogogo' },
    ];
    
    return (
        <>
            <Navbar menu={1} />
            <div className='container'>
                <MainGrid>
                    <MainTabs tabInfo={tabInfo} />
                    {topic === tabInfo[0].id && <EnrollRegular />}
                    {topic === tabInfo[1].id && <EnrollPremium />}
                    {topic === tabInfo[2].id && <EnrollOneday />}                    
                    {topic === tabInfo[3].id && <EnrollPianogogo />}
                </MainGrid>
                <Footer />
            </div>
        </>

    );
};




export default withStyles(styles)(Enroll);

