import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getBoard } from '../../redux/actions/managerActions';
// component
import VideoClip from '../../components/home/VideoClip'; // 필요

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid, Button, Dialog, DialogContent, DialogTitle, Box } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    img: {
        textAlign: 'center'
    }
});
const DialogView = props => {

    const { board } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [vime, setVime] = useState('');
    const { boardId } = useParams();
    const [buttonUrl, setButtonUrl] = useState('/');
    const [buttonTitle, setButtonTitle] = useState('뮤직고고 바로가기');

    useEffect(() => {
        dispatch(getBoard(boardId));
    }, [dispatch, boardId]);

    useEffect(() => {
        if (board) {
            setTitle(board.title);
            setDesc(board.description);
            setVime(board.vimeos);
            setButtonUrl(board.buttonUrl);
            setButtonTitle(board.buttonTitle);
        }
    }, [board]);
    let markup = desc?.replace(/<p>/g, '<Typography variant="body1">')
        .replace(/<\/p>/g, '</Typography><br/>')
        .replace(/<pre>/g, '<Typography variant="subtitle1">')
        .replace(/<\/pre>/g, '</Typography><br/>')
        .replace(/<img/gi, '<img style={{display:"block", margin:"auto"}}')


    
    return (
        <Box p={1} m={1}>
            <Dialog open={true} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title" align='center'>
                    <Typography variant='h4'>{title}</Typography></DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={2}>
                        <Grid item sm={3} />
                        <Grid item xs={12} sm={6}>
                            <Typography variant='h5' color='primary' gutterBottom></Typography>

                            <Box dangerouslySetInnerHTML={{ __html: markup }} />
                            <Box>

                                {vime && vime.replace(/ /g, '').split(',').map((vimeo, index) =>
                                    <VideoClip
                                        url={`https://vimeo.com/${vimeo}`}
                                        key={index}
                                    />)
                                }

                            </Box>
                        </Grid>
                        <Grid item sm={3} />
                    </Grid>
                </DialogContent>
                <Box p={1} m={1}>
                    <Button size='small' style={{ width: '100%' }} color="primary" variant='contained' component={Link} to={buttonUrl}>{buttonTitle}</Button>
                </Box>

            </Dialog>


        </Box>
    );
}

export default withStyles(styles)(DialogView);