import React from 'react';
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '40%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    typo: {
        width: '100%',

    }
});

const LessonAccordian = (props) => {
    const { classes, curriculums, recurrentDates, withoutTimeTable } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const datesCount = recurrentDates ? recurrentDates.length : 0;


    return (
        <div className={classes.root}>
            {
                curriculums.map((c, i) => {
                    if (i < datesCount) {
                        return (<Accordion key={i} expanded={expanded === 'panel_' + i} onChange={handleChange('panel_' + i)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="content"
                                id={`header_${i}`}
                            >
                                <Typography className={classes.heading}><strong>[{i + 1}회차]</strong> </Typography>
                                <Typography className={classes.secondaryHeading}>{c.desc.활동명}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Typography variant='subtitle2' color='secondary'>[{c.curriculumName}]</Typography><br />
                                    {/* <Typography variant='subtitle2'>[{c.yyyymm} {c.week} ] </Typography> */}
                                    <Typography variant='subtitle2'>ㆍ수업일 : {dayjs(recurrentDates[i]).format(withoutTimeTable ? 'YYYY-MM-DD ddd' : 'YYYY-MM-DD ddd HH:mm')}</Typography>
                                    <Box m={1} p={1}>
                                        <Typography variant='body2'>-활동: {c.desc.활동}</Typography>
                                        <Typography variant='body2'>-목표: {c.desc.목표}</Typography>
                                        <Typography variant='body2'>-워크북: {c.desc.워크북}</Typography>
                                        <Typography variant='body2'>-활동자료: {c.desc.활동자료}</Typography>
                                        <Typography variant='body2'>-패키지: {c.packages}</Typography>
                                        <Typography variant='body2'>-준비: {c.desc.준비}</Typography>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>);
                    } else {
                        return null;
                    }


                })
            }



        </div>
    );
}

export default withStyles(styles)(LessonAccordian);