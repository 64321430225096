import React, { useState } from 'react';
import dayjs from 'dayjs';
// Redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { isLogged } from '../../redux/actions/userActions';

// components
import UploadImage from '../../components/util/UploadImage';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Box, Paper, Typography, TextField, Button, Divider, DialogTitle, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { deleteEvent, getEvents, postEvent, updateEvent } from '../../redux/actions/dataActions';
import { ChangeHistory, CloseRounded, DeleteRounded, EditOutlined, SearchTwoTone } from '@material-ui/icons';


const styles = (theme) => ({
    ...theme.userPage,
    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    half: {
        width: 400
    },
    padding: {
        padding: theme.spacing(1)
    }
});
export const AdminEvent = (props) => {
    const { classes, history } = props;
    const dispatch = useDispatch();
    const { events } = useSelector(state => state.data);
    const { credentials } = useSelector(state => state.user)

    const initialInfo = {
        title: '',
        mainImageUrl: '',
        bannerImageUrl: '',
        bannerBackgroundColor: '',
        startDatetime: dayjs()/1000,
        endDatetime: dayjs()/1000,
        createdAt: dayjs()/1000,
        createdBy: ''
    }
    const [info, setInfo] = useState(initialInfo);
    const [eventId, setEventId] = useState(null);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const handleGetEvents = () => {
        dispatch(getEvents());
    }
    const handleOpenDialog = () => {
        setOpen(true);
        setEventId(null)
        setInfo(initialInfo);
        setEditMode(false);
    }
    const handleCloseDialog = () => {
        setOpen(false);
        setEventId(null);
        setInfo(initialInfo);
    }
    const handleEditEvent = (ev) => {
        setOpen(true);
        setEventId(ev.eventId);
        setInfo(ev);
        setEditMode(true);
    }
    const handlePostEvent = () => {
        const res = dispatch(isLogged(history));
        const eId = eventId;

        const posted = {
            title: info.title,
            mainImageUrl: info.mainImageUrl,
            bannerImageUrl: info.bannerImageUrl,
            bannerBackgroundColor: info.bannerBackgroundColor,
            startDatetime: dayjs(info.startDatetime) / 1,
            endDatetime: dayjs(info.endDatetime) / 1,
            createdAt: dayjs() / 1000,
            createdBy: credentials.uid
        }
        console.log(posted);


        if (res === 'logged') {
            if (editMode) {
                if (window.confirm('수정하시겠습니까?')) {
                    dispatch(updateEvent(eId, posted));
                }
            } else {
                if (window.confirm('등록하시겠습니까?')) {
                    dispatch(postEvent(posted));
                }
            }

        }

    }

    const handleDelete = () => {
        const eId = eventId;
        if (window.confirm('삭제하시겠습니까?')) {
            dispatch(deleteEvent(eId));
        }
    }
   

    /// test
    return (
        <Box p={1} m={1}>

            <Typography variant='subtitle1' gutterBottom>Event 노출 관리</Typography>
            <Divider light />
            <Box height={20} />
            <Typography variant='subtitle1' gutterBottom>메인Event</Typography>
            <Box m={1} p={1}>
                <Button color='primary' size='small' variant='contained' onClick={handleGetEvents} className={classes.btn} startIcon={<SearchTwoTone />} >불러오기</Button>
                <Button color='secondary' size='small' variant='contained' onClick={handleOpenDialog} className={classes.btn} startIcon={<EditOutlined />} >신규등록</Button>
            </Box>
            <Box m={1} p={1}>
                {
                    events
                        .sort((a, b) => b.createdAt - a.createdAt)
                        .map((ev, i) => <Paper className={classes.padding} key={i}>
                            <Grid container direction='row'>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle1' color='primary'>[{i + 1}] {ev.title}</Typography>
                                    <Typography variant='subtitle2'>-생성일시 : {dayjs(ev.createdAt * 1000).format('YYYY-MM-DD HH:mm')}</Typography>
                                    <Typography variant='subtitle2' color={
                                        dayjs(ev.startDatetime * 1000) > dayjs() ? 'textSecondary'
                                            : ((dayjs(ev.startDatetime * 1000) < dayjs()) && (dayjs() < dayjs(ev.endDatetime * 1000))) ? 'secondary' : 'error'
                                    }>-시작일시 : {dayjs(ev.startDatetime * 1000).format('YYYY-MM-DD HH:mm')}
                                    </Typography>
                                    <Typography variant='subtitle2' color={
                                        dayjs(ev.startDatetime * 1000) > dayjs() ? 'textSecondary'
                                            : ((dayjs(ev.startDatetime * 1000) < dayjs()) && (dayjs() < dayjs(ev.endDatetime * 1000))) ? 'secondary' : 'error'
                                    }>-종료일시 : {dayjs(ev.endDatetime * 1000).format('YYYY-MM-DD HH:mm')}
                                    </Typography>
                                    <Button style={{ marginTop: 30 }} color='primary' variant='contained' size='small' onClick={() => handleEditEvent(ev)} startIcon={<ChangeHistory />}>수정</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <img src={ev.mainImageUrl} width={300} alt='main' />
                                    <img src={ev.bannerImageUrl} width={300} alt='banner' />
                                    <Box style={{ backgroundColor: ev.bannerBackgroundColor }} width={100} height={100} />
                                </Grid>
                            </Grid>

                        </Paper>)
                }
            </Box>
            <Dialog open={open}>
                <DialogTitle>
                    {editMode ? '이벤트 수정' : '이벤트 등록'}
                </DialogTitle>
                <DialogContent>
                    <Typography>URL생성</Typography>
                    <UploadImage />
                    <TextField
                        size='small'
                        margin='dense'
                        label='타이틀'
                        required
                        fullWidth
                        value={info.title}
                        onChange={e => setInfo({ ...info, title: e.target.value })}
                    />
                    <TextField
                        size='small'
                        margin='dense'
                        label='메인 이미지 URL'
                        required
                        fullWidth
                        value={info.mainImageUrl}
                        onChange={e => setInfo({ ...info, mainImageUrl: e.target.value })}
                    />
                    <TextField
                        size='small'
                        margin='dense'
                        label='배너 이미지 URL'
                        required
                        fullWidth
                        value={info.bannerImageUrl}
                        onChange={e => setInfo({ ...info, bannerImageUrl: e.target.value })}
                    />
                    <TextField
                        size='small'
                        margin='dense'
                        label='배너 백그라운드 컬러'
                        required
                        fullWidth
                        value={info.bannerBackgroundColor}
                        onChange={e => setInfo({ ...info, bannerBackgroundColor: e.target.value })}
                    />
                    <TextField
                        size='small'
                        margin='dense'
                        label='노출 시작일시'
                        type='datetime-local'
                        required
                        fullWidth
                        value={dayjs(info.startDatetime * 1000).format('YYYY-MM-DDTHH:mm')}
                        onChange={e=>setInfo({
                            ...info,
                            startDatetime: dayjs(e.target.value)/1000
                        })}
                    />
                    <TextField
                        size='small'
                        margin='dense'
                        label='노출 종료일시'
                        type='datetime-local'
                        required
                        fullWidth
                        value={dayjs(info.endDatetime*1000).format('YYYY-MM-DDTHH:mm')}
                        onChange={e => setInfo({ ...info, endDatetime: dayjs(e.target.value)/1000 })}
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='secondary' onClick={handlePostEvent} startIcon={<EditOutlined />}>{editMode ? '수정' : '등록'}</Button>
                    {editMode && <Button variant='contained' color='primary' onClick={handleDelete} startIcon={<DeleteRounded />}>삭제</Button>}
                    <Button variant='contained' onClick={handleCloseDialog} startIcon={<CloseRounded />}>닫기</Button>
                </DialogActions>
            </Dialog>

        </Box>


    );
};



export default withStyles(styles)(AdminEvent);

