import React, { useState, useEffect } from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonInfo, updateHakwonInfo } from '../../redux/actions/managerActions';
import { getUserData } from '../../redux/actions/userActions';
// component
import LevelCheck from './LevelCheck';
import NotAllowed from './NotAllowed';
import PostCode from '../../components/util/PostCode';
import DummyDivider from '../../components/layout/DummyDivider';
// mui
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { InputAdornment, CircularProgress } from '@material-ui/core';
import { HomeRounded, PhoneRounded, PhoneIphoneRounded, LocationOnRounded, SaveRounded, InfoRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';


const styles = (theme) => ({
    ...theme.userPages,
    buttons: {
        margin: theme.spacing(3),
    },
    button: {
        marginRight: theme.spacing(3)
    },
    textField: {
        marginTop: theme.spacing(2),
        backgroundColor: '#ffffff',
        width: '100%'
    },
    address: {
        margin: theme.spacing(2),
    },
    text: {
        marginLeft: theme.spacing(4)
    },
    leftmargin: {
        marginLeft: theme.spacing(3)
    },
    rightmargin: {
        marginRight: theme.spacing(3)
    },
    box: {
        margin: theme.spacing(1),
        height: 300,
        maxWidth: 500
    },
    paper: {
        padding: theme.spacing(4)
    }
});

const Info = (props) => {
    const { classes } = props;

    //redux
    const { credentials, rnr } = useSelector(state => state.user);
    const { loading } = useSelector(state => state.UI);
    const { hakwon, managerLoading } = useSelector(state => state.manager);
    const dispatch = useDispatch();

    const [allowed, setAllowed] = useState(false); // 권한이 있는지 확인
    const [value, setValue] = useState({
        tel: '',
        mobile: '',
        juso1: '',
        juso3: '',
        instagram: '',
        blog: '',
        facebook: ''
    });

    const onChangeValue = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
    }
    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
    }, [dispatch])

    useEffect(() => {
        if (hakwon) {
            setValue({
                ...value,
                tel: typeof hakwon.contacts !== 'undefined' ? hakwon.contacts?.tel : '',
                mobile: hakwon.contacts?.mobile,
                juso1: hakwon.address?.juso1 + ' (' + hakwon.address?.juso2 + ')',
                juso3: hakwon.address ? hakwon.address.juso3 : '',
                facebook: hakwon?.facebook,
                instagram: hakwon?.instagram,
                blog: hakwon?.blog,

            })

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, hakwon, credentials, setValue]);


    function handleSetJuso1(fullAddress) {
        setValue({
            ...value, juso1: fullAddress
        });
    }
    const handleSaveHakwonInfo = () => {
        const updates = {
            contacts: {
                tel: value.tel,
                mobile: value.mobile
            },
            address: {
                juso1: value.juso1.split('(')[0],
                juso2: value.juso1.split('(')[1].split(')')[0],
                juso3: value.juso3
            },
            blog: value.blog ? value.blog : '',
            instagram: value.instagram ? value.instagram : '',
            facebook: value.facebook ? value.facebook : ''
        }
        
        dispatch(updateHakwonInfo(updates));
    }

    

    return (
        <Box p={1} m={1}>
            <LevelCheck
                checkingTeacherLevel={1}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />
            <Box align='right'>
                <Typography variant='caption' className={classes.rightmargin}>{hakwon?.hName}</Typography>
            </Box>
            <Typography variant='subtitle2' color='primary' className={classes.leftmargin}>학원정보를 수정하실 수 있습니다.</Typography>

            <NotAllowed allowed={allowed} rnr={rnr} />
            {
                managerLoading ? <Box>
                    <Box height={10} />
                    <Skeleton variant='rect' height={50} style={{ backgroundColor: '#666' }} />
                    <Box height={10} />
                    <Skeleton variant='rect' height={50} style={{ backgroundColor: '#666' }} />
                    <Box height={10} />
                    <Skeleton variant='rect' height={50} style={{ backgroundColor: '#666' }} />
                    <Box height={10} />
                    <Skeleton variant='rect' height={50} style={{ backgroundColor: '#666' }} />
                </Box>
                    :
                    allowed ?
                        <Box>

                            <form noValidate onSubmit={null}>
                                <Box>
                                    <TextField
                                        id='hName'
                                        name='hName'
                                        type='text'
                                        label='학원명'
                                        className={classes.textField}
                                        value={hakwon.hName}
                                        color='primary'
                                        variant='filled'
                                        required
                                        readOnly
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <HomeRounded />
                                                </InputAdornment>),
                                        }}


                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>어린음악대 OOO원 * 학원명은 변경하실 수 없습니다.</Typography>
                                </Box>
                                <Box>
                                    <TextField
                                        id='tel'
                                        name='tel'
                                        type='text'
                                        label='전화번호'
                                        className={classes.textField}
                                        value={value.tel}
                                        onChange={onChangeValue}

                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        required

                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneRounded />
                                                </InputAdornment>),
                                            /* endAdornment: (
                                                <InputAdornment position='end'>
                                                    <DialogInput 
                                                        title='학원 전화 번호' 
                                                        textContent='전화번호를 변경하시겠습니까?'
                                                        name='tel'
                                                        value={hakwon.contacts ? hakwon.contacts.tel : ''}
                                                        actionButton='수정' 
                                                        helperText='- 표기없이 입력해주세요 '
                                                    >
                                                    <IconButton>
                                                        <EditRounded />
                                                    </IconButton>
                                                    </DialogInput>
                                                </InputAdornment>
                                            ) */
                                        }}

                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>번호를 수정하면, 학부모에게 동일하게 노출됩니다.</Typography>
                                </Box>
                                <Box>
                                    <TextField
                                        id='mobile'
                                        name='mobile'
                                        type='text'
                                        label='휴대전화'
                                        className={classes.textField}
                                        value={value.mobile}
                                        onChange={onChangeValue}


                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIphoneRounded />
                                                </InputAdornment>),
                                        }}

                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>휴대전화 번호를 수정하면, 학부모에게 동일하게 노출됩니다</Typography>
                                </Box>
                                <Box>
                                    <TextField
                                        id='juso12'
                                        name='juso12'
                                        type='text'
                                        label='주소'
                                        className={classes.textField}

                                        value={value.juso1}
                                        onChange={onChangeValue}
                                        helperText={''}
                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationOnRounded />
                                                </InputAdornment>),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PostCode title='주소검색' textContent="주소검색" onChange={handleSetJuso1}>
                                                        <Button variant='contained' color='secondary'>검색</Button>
                                                    </PostCode>
                                                </InputAdornment>
                                            ),

                                        }}
                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>도로명 주소를 권장합니다.</Typography>
                                    <TextField
                                        id='juso3'
                                        name='juso3'
                                        type='text'
                                        label='상세주소'
                                        className={classes.textField}
                                        value={value.juso3}
                                        onChange={onChangeValue}

                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        


                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>상세주소는 빌딩이나 상가의 층수과 호수를 명확하게 기록해주세요.</Typography>
                                    <Divider light />
                                    <DummyDivider top={10} />
                                    <Typography variant='h4' gutterBottom>SNS</Typography>

                                    <TextField
                                        id='instagram'
                                        name='instagram'
                                        type='text'
                                        label='인스타그램 주소 (전체 url또는 아이디)'
                                        className={classes.textField}
                                        value={value.instagram}
                                        onChange={onChangeValue}


                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        

                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>인스타그램 주소를 입력해주세요.</Typography>
                                    <TextField
                                        id='blog'
                                        name='blog'
                                        type='text'
                                        label='블로그 주소 (전체 url또는 아이디)'
                                        className={classes.textField}
                                        value={value.blog}
                                        onChange={onChangeValue}


                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        

                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>노출될 블로그의 주소를 기록해주세요.</Typography>
                                    <TextField
                                        id='facebook'
                                        name='facebook'
                                        type='text'
                                        label='페이스북 주소 (전체 url또는 아이디)'
                                        className={classes.textField}
                                        value={value.facebook}
                                        onChange={onChangeValue}
                                        error={null}
                                        color='primary'
                                        variant='filled'
                                        

                                    />
                                    <Typography variant='body2' className={classes.text} gutterBottom>페이스북도 사용하신다면, 입력해주세요.</Typography>
                                </Box>



                            </form>
                            <InfoRounded color='primary' />
                            <Typography variant='subtitle2' color='primary' className={classes.leftmargin}>원장님을 추가하거나, 변경이 필요한 경우에 본사 관리자에게 연락을 주시기 바랍니다.</Typography>
                            <Box className={classes.buttons}>
                                <Button variant='contained' color='primary' onClick={handleSaveHakwonInfo} className={classes.button} disabled={managerLoading} endIcon={<SaveRounded />}>변경 사항 저장</Button>
                                {
                                    loading && <CircularProgress size={30} color='primary' />
                                }
                            </Box>
                        </Box>
                        : null
            }



        </Box>

    )
}

export default withStyles(styles)(Info);

