import React, { useState } from 'react';
import dayjs from 'dayjs';
// Redux stuff
// components
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Box, Typography, TextField, Button, Grid } from '@material-ui/core';

import { BackspaceRounded,  DeleteRounded  } from '@material-ui/icons';


const styles = (theme) => ({
    ...theme.userPage,
    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    half: {
        width: 400
    },
    padding: {
        padding: theme.spacing(1)
    },
    dial: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        width: 60,
        height: 60,
        margin: 5,
        fontSize: 20
    },
    button: {
        width: 100,
        height: 40
    }
});
const CssTextField = withStyles({
    root: {
        fontSize: 'larger',
        '& input:valid + fieldset': {
            borderColor: '#f15624',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: '#f15624',
            borderWidth: 2,
            fontSize: 'large'
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            fontSize: 'large',
            padding: '4px !important', // override inline-style
        },

    },
})(TextField);

export const AdminAttendPad = (props) => {
    const { classes } = props;
    //const dispatch = useDispatch();

    const [typed, setTyped] = useState('');
    const [time, setTime] = useState(null);
    const tick = () => {
        setTime(dayjs().format('MM/DD(ddd) A hh:mm:ss'));
    }

    setInterval(tick, 1000);

    const addNumber = (num) => {
        if (typed === '0') {

        }
        setTyped(typed + num);
    }
    const handleBackspace = () => {
        let temp = typed.split('');
        temp.pop();
        setTyped(temp.join(''));
    }
    const handleDelete = () => {
        setTyped('');
    }


    const sendMessage = () => {
        if (typed.length>3) {
            alert('발송했습니다.');
            setTyped('');
        }

    }

    /// test
    return (
        <Box p={1} m={1}>

            <Box m={1} p={1}>
                <Grid container direction='row' alignContent='center'>
                    <Grid item sm />
                    <Grid item sm>
                        <Box align='center'>
                            <Typography variant='subtitle1'>{time}</Typography>
                        </Box>
                        <Box height={10} />
                        <Box>
                            <CssTextField
                                variant='outlined'
                                value={typed}
                                onChange={e => setTyped(e.target.value)}
                                fullWidth
                            />
                        </Box>
                        <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                            <Button onClick={() => addNumber('1')} variant='contained' className={classes.dial}>1</Button>
                            <Button onClick={() => addNumber('2')} variant='contained' className={classes.dial}>2</Button>
                            <Button onClick={() => addNumber('3')} variant='contained' className={classes.dial}>3</Button>
                        </Box>
                        <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                            <Button onClick={() => addNumber('4')} variant='contained' className={classes.dial}>4</Button>
                            <Button onClick={() => addNumber('5')} variant='contained' className={classes.dial}>5</Button>
                            <Button onClick={() => addNumber('6')} variant='contained' className={classes.dial}>6</Button>

                        </Box>
                        <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                            <Button onClick={() => addNumber('7')} variant='contained' className={classes.dial}>7</Button>
                            <Button onClick={() => addNumber('8')} variant='contained' className={classes.dial}>8</Button>
                            <Button onClick={() => addNumber('9')} variant='contained' className={classes.dial}>9</Button>
                        </Box>
                        <Box display='flex' justifyContent='space-evenly' alignItems='center'>
                            <Button onClick={handleDelete} variant='contained' className={classes.dial}><DeleteRounded /></Button>
                            <Button onClick={() => addNumber('0')} variant='contained' className={classes.dial}>0</Button>
                            <Button onClick={handleBackspace} variant='contained' className={classes.dial}><BackspaceRounded /></Button>
                        </Box>
                        <Box height={20} />
                        <Box display='flex' justifyContent='space-evenly'>
                            <Button onClick={sendMessage} className={classes.button} variant='contained' color='primary'>학원왔어요</Button>
                            <Button onClick={sendMessage} className={classes.button} variant='contained' color='secondary'>집에 가요</Button>
                        </Box>


                    </Grid>
                    <Grid item sm />
                </Grid>
            </Box>

        </Box >


    );
};



export default withStyles(styles)(AdminAttendPad);

