import React from 'react';
import Divider from '@material-ui/core/Divider';

export default function DummyDivider (props) {
    return (
        <Divider style={{backgroundColor:'transparent', marginTop: props.top, marginBottom: props.bottom }} />
    );
};


