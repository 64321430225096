import React, { useState, useEffect } from 'react';

// components
import Navbar from '../../components/layout/Navbar';
import EnrollStepTitle from '../../components/enroll/EnrollStepTitle';
import EnrollStepper from '../../components/enroll/EnrollStepper';
import MainGrid from '../../components/layout/MainGrid';


import SelectHakwon from '../../components/enroll/SelectHakwon';
import Footer from '../../components/layout/Footer';
// redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from '../../redux/actions/dataActions';
import { getEnrollProductGroup } from '../../redux/actions/enrollActions';
// mui stuff
import withStyles from '@material-ui/core/styles/withStyles';


const styles = theme => ({
    ...theme.userPages,
    button: {
        margin: theme.spacing(3),
    },
    buttonRight: {
        margin: theme.spacing(3),
        paddingLeft: 'auto'
    },
    leftMargin: {
        marginLeft: theme.spacing(2),
    }
});


const EnrollStep2 = props => {

    //const { classes } = props;
    // redux
    // const { product } = useSelector(state => state.data);
    const { credentials } = useSelector(state => state.user);
    //const { lesson, hakwon, productGroup } = useSelector(state => state.enroll);
    const dispatch = useDispatch();


    //const pName = localStorage.getItem('productName');
    const pid = localStorage.getItem('productId');
    const productGroupName = localStorage.getItem('productGroupName');
    const productGroupId = localStorage.getItem('productGroupId');
    const categoryName = localStorage.getItem('categoryName');
    // 결제 방지를 위해, 삭제함
    localStorage.removeItem('lessonId');
    localStorage.removeItem('lessonName');

    useEffect(() => {
        dispatch(getProduct(pid));
        dispatch(getEnrollProductGroup(productGroupId))
    }, [pid, productGroupId, dispatch]);

    const hName = credentials.myHakwon ? credentials.myHakwon.hName : localStorage.getItem('hName');
    const [hakwonName, setHakwonName] = useState(hName);


    return (
        <>
            <Navbar menu={1} />
            <MainGrid>
                <EnrollStepTitle currentStep={2} />
                <EnrollStepper
                    step1={productGroupName + '(' + categoryName + ')'}
                    step2={hakwonName}
                />

                {/* 학원 선택 select */}
                <SelectHakwon
                    pid={pid}
                    productGroupName={productGroupName}
                    hakwonName={hakwonName}
                    setHakwonName={setHakwonName}
                />

                {/* 자녀 정보 선택 */}
                <Footer />

            </MainGrid>
        </>
    )

}

export default withStyles(styles)(EnrollStep2);
