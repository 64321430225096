import React, { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonInfo, getSalesByHakwon, getAllProductGroup, getSettlements } from '../../redux/actions/managerActions';
import { getUserData, isLogged } from '../../redux/actions/userActions';
// components
import LevelCheck from './LevelCheck';
import CenterLoading from '../../components/layout/CenterLoading';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';



import { Typography, Divider, Button, Box, TextField, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ArchiveRounded } from '@material-ui/icons';
import { NotAllowed } from './NotAllowed';
import dayjs from 'dayjs';
// excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    date: {
        backgroundColor: '#FAF3E7',
        width: 90,
        margin: theme.spacing(1)
    },
    btn: {
        marginLeft: theme.spacing(6),
        marginTop: theme.spacing(1)
    },
    text: {
        verticalAlign: 'center'
    },
    table: {
        padding: 3
    },
    padding: {
        paddingRight: 3,
        paddingLeft: 3,
    },
});


export const Settle = (props) => {
    const { classes, history } = props;
    const { rnr, credentials } = useSelector(state => state.user);
    const { hakwon, sales, managerLoading, settlements } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    const [allowed, setAllowed] = useState(false);


    const hId = credentials.myHakwon ? credentials.myHakwon?.hakwonId : localStorage.getItem('hId');

    // 예정, 결과
    const [searchType, setSearchType] = useState('isSettled');



    // 정산
    const [month, setMonth] = useState(dayjs().format('YYYY/MM'));

    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
        dispatch(getAllProductGroup());
    }, [dispatch]);

    // toggle
    const [sortType, setSortType] = useState('nameAsc');



    const toggleCreatedAt = () => {
        if (sortType === 'createdAtDesc') {
            setSortType('createdAtAsc');
        } else {
            setSortType('createdAtDesc');
        }
    }

    const toggleName = () => {
        if (sortType === 'nameDesc') {
            setSortType('nameAsc');
        } else {
            setSortType('nameDesc');
        }
    }
    const toggleNo = () => {
        if (sortType === 'noDesc') {
            setSortType('noAsc');
        } else {
            setSortType('noDesc');
        }
    }
    const toggleType = () => {
        if (sortType === 'typeDesc') {
            setSortType('typeAsc');
        } else {
            setSortType('typeDesc');
        }
    }
    const toggleMonthType = () => {
        if (sortType === 'monthTypeDesc') {
            setSortType('monthTypeAsc');
        } else {
            setSortType('monthTypeDesc');
        }
    }
    const toggleStatus = () => {
        if (sortType === 'statusD') {
            setSortType('statusA');
        } else {
            setSortType('statusD');
        }
    }
    const toggleAmount = () => {
        if (sortType === 'amountD') {
            setSortType('amountA');
        } else {
            setSortType('amountD');
        }
    }
    const toggleSettle = () => {
        if (sortType === 'settleD') {
            setSortType('settleA');
        } else {
            setSortType('settleD');
        }
    }


    // 정산결과


    const handleRetrieveSettments = () => {
        dispatch(getSettlements());
    }
    const handleSearchSettlement = (type) => {
        setSearchType(type);
        const fromTo = { from: 0, to: dayjs() / 1000 };
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(getSalesByHakwon(hId, fromTo));
        }

    }

    const filteredSettlements = settlements.filter(sa => sa.hakwonId === hId && sa.yyyymm === dayjs(month).format('YYYYMM'));

    const filterSalesForSettlement = sales
        .filter(sa => sa.lessonType === 'trial' ? dayjs(sa.lessonDate * 1000) < dayjs(month) && (!sa.isSettled || sa.isRevoked) : true)    // 원데이는 당월 이전, 이미정산된것은 제외 
        .filter(sa => (sa.lessonType === 'regular' || sa.lessonType === 'premium') ?                    // 정기수업은 수업마감월기준(전25~당25)
            (
                (sa.isRevoked && dayjs(sa.paid_at * 1000) < dayjs(month)) || //이전달에 결제되고, 정산후취소된것
                (
                    // dayjs(sa.lessonDate * 1000) >= dayjs(month).add(-1, 'month').set('date', 25) &&
                    dayjs(sa.lessonDate * 1000) < dayjs(month).set('date', 26) &&
                    !sa.isSettled && dayjs(sa.paid_at * 1000) < dayjs(month).set('date', 16) &&

                    ((sa.status === 'cancelled' && sa.cancel_amount < sa.amount) || (sa.amount > 0 && sa.status === 'paid')) // 부분취소와 정상결제만 가져옴 (취소는 정산하지 않음)

                )
            )
            : true);

    const filterResults = sales
        .filter(sa => sa.isSettled === true && dayjs(sa.settledAt * 1000).format('YYYY/MM') === month)

    const results = searchType === 'notSettled' ? filterSalesForSettlement : filterResults;

    // 학원별 매출리스트
    const saleList = results.filter(sa => sa.amount > 0).sort((a, b) =>
        sortType === 'createdAtAsc' ? (a.createdAt > b.createdAt ? 1 : -1)
            : sortType === 'createdAtDesc' ? (b.createdAt > a.createdAt ? 1 : -1)
                : sortType === 'createdAtDesc' ? (b.createdAt > a.createdAt ? 1 : -1)
                    : sortType === 'nameDesc' ? (b.child_name > a.child_name ? 1 : -1)
                        : sortType === 'nameAsc' ? (a.child_name > b.child_name ? 1 : -1)
                            : sortType === 'noDesc' ? (b.paymentId > a.paymentId ? 1 : -1)
                                : sortType === 'noAsc' ? (a.paymentId > b.paymentId ? 1 : -1)
                                    : sortType === 'settleD' ? ((b.status === 'paid' && b.amount > 0 ? b.settlement : 0) > (a.status === 'paid' && a.amount > 0 ? a.settlement : 0) ? 1 : -1)
                                        : sortType === 'settleA' ? ((b.status === 'paid' && b.amount > 0 ? b.settlement : 0) < (a.status === 'paid' && a.amount > 0 ? a.settlement : 0) ? 1 : -1)
                                            : sortType === 'amountD' ? (b.amount - (b.cancel_amount ? b.cancel_amount : 0) > a.amount - (a.cancel_amount ? a.cancel_amount : 0) ? 1 : -1)
                                                : sortType === 'amountA' ? (b.amount - (b.cancel_amount ? b.cancel_amount : 0) < a.amount - (a.cancel_amount ? a.cancel_amount : 0) ? 1 : -1)
                                                    : sortType === 'statusD' ? (b.status > a.status ? 1 : -1)
                                                        : sortType === 'statusA' ? (b.status < a.status ? 1 : -1)
                                                            : sortType === 'typeDesc' ? (b.lessonType + b.totalRound + b.amount > a.lessonType + a.totalRound + a.amount ? 1 : -1)
                                                                : sortType === 'typeAsc' ? (b.lessonType + b.totalRound + b.amount < a.lessonType + a.totalRound + a.amount ? 1 : -1)
                                                                    : sortType === 'monthTypeDesc' ? ((dayjs(b.lessonDate * 1000).get('date') > 20 && b.lessonType === 'regular') ? dayjs(b.lessonDate * 1000).add(1, 'month').get('month') : dayjs(b.lessonDate * 1000).get('month') > (dayjs(a.lessonDate * 1000).get('date') > 20 && a.lessonType === 'regular') ? dayjs(a.lessonDate * 1000).add(1, 'month').get('month') : dayjs(a.lessonDate * 1000).get('month') ? 1 : -1)
                                                                        : sortType === 'monthTypeAsc' ? ((dayjs(b.lessonDate * 1000).get('date') > 20 && b.lessonType === 'regular') ? dayjs(b.lessonDate * 1000).add(1, 'month').get('month') : dayjs(b.lessonDate * 1000).get('month') < (dayjs(a.lessonDate * 1000).get('date') > 20 && a.lessonType === 'regular') ? dayjs(a.lessonDate * 1000).add(1, 'month').get('month') : dayjs(a.lessonDate * 1000).get('month') ? 1 : -1)
                                                                            : null

    );
    const hCount = saleList.filter(sa => sa.amount > 0 && sa.status === 'paid').length;
    const hAmount = saleList.reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const hSettle = saleList.reduce((p, n) => p + (n.status === 'paid' && n.amount > 0 ? n.settlement :
        n.isRevoked === true ? Math.abs(n.settlement) * -1 : 0
    ), 0);



    return (
        <div>
            <LevelCheck
                checkingTeacherLevel={0}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />
            {
                allowed ?
                    <Box m={1} p={1}>
                        <Box height={20} />
                        <Typography variant='subtitle1' gutterBottom>정산 예정 금액 조회</Typography>
                        <Divider light />
                        <Box align='right'>
                            <Typography variant='caption' className={classes.marginRL}>{credentials.myHakwon?.hName}</Typography>
                        </Box>

                        <Box m={1} p={1}>
                            <TextField
                                size='small'
                                margin='dense'
                                label='정산월 YYYY/MM'
                                style={{ backgroundColor: '#FAF3E7', width: 150 }}
                                value={month}
                                onChange={e => setMonth(e.currentTarget.value)}
                            />
                            <Button style={{ marginTop: 10, marginLeft: 10 }} color='primary' variant='contained' onClick={() => handleSearchSettlement('notSettled')}>예정 조회</Button>
                            <Button style={{ marginTop: 10, marginLeft: 10 }} color='secondary' variant='contained' onClick={() => handleSearchSettlement('isSettled')}>정산 결과</Button>
                            <ExcelFile style={{ marginLeft: 50 }} element={<Button style={{ marginLeft: 10, marginTop: 10 }} color='secondary' variant='contained' startIcon={<ArchiveRounded />}>Excel 다운로드</Button>}>
                                <ExcelSheet data={saleList} name='Sales'>
                                    <ExcelColumn label={'결제번호'} value='paymentId' />
                                    <ExcelColumn label={'자녀이름'} value='child_name' />
                                    <ExcelColumn label={'학원명'} value='hName' />
                                    <ExcelColumn label={'금액'} value={row => (row.amount - (row.cancel_amount ? row.cancel_amount : 0))} />
                                    <ExcelColumn label={'상태'} value={row => row.status === 'paid' ? '결제' : (row.status === 'cancelled' && row.cancel_amount < row.amount) ? '부분취소' : '취소'} />
                                    <ExcelColumn label={'나이'} value='age' />
                                    <ExcelColumn label={'레슨명'} value='name' />
                                    <ExcelColumn label={'수업시작일'} value={c => dayjs(c.lessonDate * 1000).format('YYYY-MM-DD HH:mm')} />
                                    <ExcelColumn label={'유형'} value={row => row.totalRound ? row.totalRound + '개월' : row.lessonType === 'trial' ? '원데이' : row.lessonType === 'premium' ? '프리미엄' : row.imp_uid.substring(0, 5) === 'imps_' ? String(row.merchant_uid?.split('_')[3]) + '개월' : (row.lessonType === 'regular' && row.amount === 45000) ? '3개월' : row.lessonType === 'regular' && row.amount === 15000 ? '1개월' : '-'} />
                                    <ExcelColumn label={'월구분'} value={row => dayjs(row.lessonDate * 1000).get('date') > 20 && row.lessonType === 'regular' ? String(dayjs(row.lessonDate * 1000).add(1, 'month').get('month') + 1) + '월' : String(dayjs(row.lessonDate * 1000).get('month') + 1) + '월'} />
                                    <ExcelColumn label={'상품그룹'} value='productGroupId' />
                                    <ExcelColumn label={'부모명'} value='parentName' />
                                    <ExcelColumn label={'전화'} value='buyer_tel' />
                                    <ExcelColumn label={'카테고리'} value='category' />
                                    <ExcelColumn label={'결제일'} value={c => dayjs(c.createdAt * 1000).format('YYYY-MM-DD HH:mm')} />

                                    <ExcelColumn label={'imp아이디'} value='imp_uid' />
                                    <ExcelColumn label={'수업아이디'} value='lessonId' />
                                    <ExcelColumn label={'수업형태'} value='lessonType' />
                                    <ExcelColumn label={'결제일시'} value={c => dayjs(c.paid_at * 1000).format('YYYY-MM-DD HH:mm')} />
                                    <ExcelColumn label={'주소'} value='buyer_addr' />

                                    <ExcelColumn label={'정가'} value='price' />
                                    <ExcelColumn label={'정산'} value={row => row.status === 'paid' && row.amount > 0 ? row.settlement :
                                        row.isRevoked === true ? Math.abs(row.settlement) * -1 : 0} />
                                    <ExcelColumn label={'상품아이디'} value='productId' />
                                    <ExcelColumn label={'정기결제'} value='totalRound' />


                                </ExcelSheet>
                            </ExcelFile>
                        </Box >
                        <Typography variant='body2'>* 정산 이전의 예상 내역은 '예정 조회'버튼을 눌러 확인하시고,</Typography>
                        <Typography variant='body2'>* 정산 이후에는 '정산 결과'버튼을 눌러 확인하실 수 있습니다.</Typography>
                        <Typography variant='body2'>* 정산 이후 환불/취소된 경우, 정산내역에서 제외되고 다음월 정산시 상계처리되어 공제됩니다.</Typography>
                        <Typography variant='body2' color="primary" gutterBottom>* 30인 이하 3,000원, 50인 이하 4,000원, 51인 이상 5,000원이 정산됩니다.</Typography>

                        {
                            managerLoading ? <CenterLoading /> : <Box>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.padding}>No</TableCell>
                                                <TableCell className={classes.padding}><Button onClick={toggleNo} endIcon={sortType === 'noAsc' ? <ArrowDropDown color='primary' /> : sortType === 'noDesc' && <ArrowDropUp color='secondary' />}>주문번호</Button></TableCell>
                                                <TableCell className={classes.padding}><Button onClick={toggleName} endIcon={sortType === 'nameAsc' ? <ArrowDropDown color='primary' /> : sortType === 'nameDesc' && <ArrowDropUp color='secondary' />}>원생</Button></TableCell>

                                                <TableCell className={classes.padding}><Button onClick={toggleCreatedAt} endIcon={sortType === 'createdAtAsc' ? <ArrowDropDown color='primary' /> : sortType === 'createdAtDesc' && <ArrowDropUp color='secondary' />}>결제일</Button></TableCell>
                                                <TableCell className={classes.padding}>수업일</TableCell>
                                                {/* <TableCell className={classes.padding} align='center'><Button onClick={toggleLName} endIcon={sortType === 'lNameAsc' ? <ArrowDropDown color='primary' /> : sortType === 'lNameDesc' && <ArrowDropUp color='secondary' />}>수업이름</Button></TableCell> */}

                                                <TableCell className={classes.padding}><Button onClick={toggleMonthType} endIcon={sortType === 'monthTypeAsc' ? <ArrowDropDown color='primary' /> : sortType === 'monthTypeDesc' && <ArrowDropUp color='secondary' />}>월구분</Button></TableCell>
                                                <TableCell className={classes.padding}><Button onClick={toggleStatus} endIcon={sortType === 'statusA' ? <ArrowDropDown color='primary' /> : sortType === 'statusD' && <ArrowDropUp color='secondary' />}>상태</Button></TableCell>
                                                <TableCell className={classes.padding}><Button onClick={toggleAmount} endIcon={sortType === 'amountA' ? <ArrowDropDown color='primary' /> : sortType === 'amountD' && <ArrowDropUp color='secondary' />}>매출</Button></TableCell>
                                                <TableCell className={classes.padding}><Button onClick={toggleSettle} endIcon={sortType === 'settleA' ? <ArrowDropDown color='primary' /> : sortType === 'settleD' && <ArrowDropUp color='secondary' />}>
                                                    <Typography variant='button'>정산</Typography>
                                                </Button></TableCell>


                                                <TableCell className={classes.padding}><Button onClick={toggleType} endIcon={sortType === 'typeAsc' ? <ArrowDropDown color='primary' /> : sortType === 'typeDesc' && <ArrowDropUp color='secondary' />}>유형</Button></TableCell>
                                                <TableCell className={classes.padding}>카테고리</TableCell>
                                                <TableCell className={classes.padding}>정산여부</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            <TableBody>
                                                {saleList.map((row, index) => (
                                                    <TableRow key={row.paymentId}>
                                                        <TableCell size='small' className={classes.padding} >{index + 1}</TableCell>
                                                        <TableCell size='small' className={classes.padding} align="left" component="th" scope="row" style={{ cursor: 'pointer' }}><Typography variant='body2'>{row.paymentId}</Typography></TableCell>
                                                        <TableCell size='small' className={classes.padding} align='center'><strong>{row.child_name}</strong></TableCell>

                                                        <TableCell size='small' className={classes.padding} width='100px' >{dayjs(row.createdAt * 1000).format('YYYY-MM-DD')}</TableCell>
                                                        <TableCell size='small' className={classes.padding}>{row.lessonDate ? dayjs(row.lessonDate * 1000).format('MM/DD(dd)') : ''}</TableCell>
                                                        {/* <TableCell size='small' className={classes.padding} width='20%'>{row.name}</TableCell> */}

                                                        <TableCell size='small' className={classes.padding} align='center'>{dayjs(row.lessonDate * 1000).get('date') > 20 && row.lessonType === 'regular' ? String(dayjs(row.lessonDate * 1000).add(1, 'month').get('month') + 1) + '월' : String(dayjs(row.lessonDate * 1000).get('month') + 1) + '월'}</TableCell>
                                                        <TableCell size='small' className={classes.padding} >{row.status === 'paid' ? '결제' : (row.status === 'cancelled' && row.cancel_amount < row.amount) ? <Typography color='error'>부분취소</Typography> : <Typography color='error'>취소</Typography>}</TableCell>
                                                        <TableCell size='small' className={classes.padding} ><Typography variant='body2' color={(row.status === 'cancelled' && row.cancel_amount < row.amount) ? 'error' : 'inherit'}>{numberFormat(row.amount - (row.cancel_amount ? row.cancel_amount : 0))}</Typography></TableCell>
                                                        <TableCell size='small' className={classes.padding} ><Typography variant='body2'>{
                                                            row.status === 'paid' && row.amount > 0 ? numberFormat(row.settlement) :
                                                                row.isRevoked === true ? numberFormat(Math.abs(row.settlement) * -1) : 0

                                                        } </Typography>
                                                        </TableCell>

                                                        <TableCell size='small' className={classes.padding} >{row.totalRound ? row.totalRound + '개월' : row.lessonType === 'trial' ? '원데이' : row.lessonType === 'premium' ? '프리미엄' : row.imp_uid.substring(0, 5) === 'imps_' ? String(row.paymentId.split('_')[3]) + '개월' : (row.lessonType === 'regular' && row.amount === 45000) ? '3개월' : row.lessonType === 'regular' && row.amount === 15000 ? '1개월' : '-'}</TableCell>
                                                        <TableCell size='small' className={classes.padding}>{row.category}</TableCell>
                                                        <TableCell size='small' className={classes.padding}>{row.isSettled ? '완료' : row.status === 'paid' && row.amount > 0 ? '정산대상' : row.isRevoked ? '상계처리' : '-'}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {/* 합계 */}
                                                <TableRow style={{ backgroundColor: '#fee' }}>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding} align='right'>{hCount}건</TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}><strong>{numberFormat(hAmount)}</strong></TableCell>
                                                    <TableCell size='small' className={classes.padding}><strong>{numberFormat(hSettle)}</strong></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                    <TableCell size='small' className={classes.padding}></TableCell>
                                                </TableRow>

                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                            </Box>
                        }

                        <Box height={50} />

                        <Typography variant='subtitle1' gutterBottom>정산 결과</Typography>
                        <Box m={1} p={1}>
                            <TextField
                                size='small'
                                margin='dense'
                                label='정산월 YYYY/MM'
                                style={{ backgroundColor: '#FAF3E7', width: 150 }}
                                value={month}
                                onChange={e => setMonth(e.currentTarget.value)}
                            />
                            <Button style={{ marginTop: 10, marginLeft: 10 }} color='primary' variant='contained' onClick={handleRetrieveSettments}>조회</Button>

                        </Box >

                        <Box m={1} p={1}>
                            {
                                filteredSettlements.length === 0 ? <Typography variant='body1'>정산내역이 없습니다. (매월15일정산됩니다)</Typography>
                                    : filteredSettlements.map((s, i, a) => {
                                        if (i === a.length - 1) {
                                            return (<Box key={i}>
                                                <Typography display='inline'>ㆍ 정산월 : </Typography>
                                                <Typography display='inline' color='primary'><strong>{dayjs(month).format('YYYY년 MM월')}</strong></Typography><br />
                                                <Typography display='inline'>ㆍ 정산일자 : </Typography>
                                                <Typography display='inline'>{dayjs(s.createdAt * 1000).format('YYYY-MM-DD HH:mm:ss')}</Typography><br />
                                                <Typography display='inline'>ㆍ 지급계좌 : </Typography>
                                                <Typography display='inline'>{`${hakwon?.accountBank} ${hakwon?.accountNumber} - ${hakwon?.accountOwner}`}</Typography><br /><br />
                                                <Typography display='inline'>ㆍ 현금정산액 : </Typography>
                                                <Typography display='inline' color='primary'>{numberFormat(s.paidInCash)} 원</Typography><br />
                                                <Typography display='inline'>ㆍ 포인트정산액 : </Typography>
                                                <Typography display='inline' color='primary'>{numberFormat(s.paidInPoint)} 원</Typography><br />
                                                <Typography display='inline'>ㆍ 추가정산액 : </Typography>
                                                <Typography display='inline'>{numberFormat(s.incentive)} 원</Typography><br /><br />
                                                <Typography display='inline'>ㆍ 총 정산 합계 : </Typography>
                                                <Typography display='inline' color='primary'><strong>{numberFormat(s.incentive + s.paidInCash + s.paidInPoint)} 원</strong></Typography><br />
                                                <Typography display='inline'>ㆍ 원천세(3.3%) : </Typography>
                                                <Typography display='inline'>{numberFormat(s.tax)} 원</Typography><br />
                                                <Typography display='inline'>ㆍ 실지급액 : </Typography>
                                                <Typography display='inline' color='primary'><strong>{numberFormat(s.paidInCash + s.paidInPoint + s.incentive - s.tax)} 원</strong></Typography><br />
                                                <Typography display='inline'>ㆍ 비고 : </Typography>
                                                <Typography display='inline'>{s.memo}</Typography><br />
                                            </Box>)
                                        } else {
                                            return false;
                                        }

                                    })
                            }
                        </Box>

                        <Box height={100} />
                    </Box>

                    :
                    <NotAllowed allowed={allowed} rnr={rnr} />
            }

        </div >


    );
};

const numberFormat = (inputNumber) => {
    if (!inputNumber) return 0;
    if (typeof (inputNumber) === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default withStyles(styles)(Settle);

