import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import ReactExport from 'react-export-excel';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
    addOneSchedule,
    getAllSalesFromTo,
    getFailedSales,
    getBillingOtherSchedule,
    getBillingSchedule,
    requestCancel,
    cancelOneSchedule,
    updateSalesWithMemo,
    updateSalesWithNote,
    getOneSaleDetail,
} from '../../redux/actions/managerActions';
import { isLogged } from '../../redux/actions/userActions';
// components

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import {
    Grid,
    Box,
    Paper,
    Typography,
    Divider,
    TextField,
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    Badge,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    IconButton,
    Select,
    MenuItem,
} from '@material-ui/core';
import CenterLoading from '../../components/layout/CenterLoading';
import {
    ArchiveRounded,
    ArrowDropDown,
    ArrowDropUp,
    ArrowBackIosRounded,
    ArrowForwardIosRounded,
    DeleteOutlined,
    DeleteRounded,
    DeleteTwoTone,
    DoneTwoTone,
    EditRounded,
    ExpandLessRounded,
    ExpandMoreRounded,
    FirstPageRounded,
    FlagRounded,
    LastPageRounded,
    MailRounded,
    PauseTwoTone,
    SearchTwoTone,
    SmsFailedTwoTone,
} from '@material-ui/icons';
import { deleteRefund, getRefunds, updateRefund } from '../../redux/actions/dataActions';
import { sendATA8 } from '../../redux/actions/enrollActions';

// excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const styles = (theme) => ({
    ...theme.userPage,
    table: {},
    fullWidth: {
        width: '100vw',
        position: 'relative',
        marginLeft: '-50vw',
        left: '50%',
    },
    expand: {
        paddingRight: 30,
        paddingLeft: 30,
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    padding: {
        paddingRight: 5,
        paddingLeft: 5,
    },

    date: {
        backgroundColor: '#FAF3E7',
        width: 90,
        margin: theme.spacing(1),
    },
    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    text: {
        verticalAlign: 'center',
    },
});

// 월결제 되는 Product Ids
const monthlyProductIds = [
    'LWftI74DJAzr3h9ZGERH',
    'LY2bzzk1kL1jOyssZXDI',
    'KiflCPN7CCrr7pTAeJQz',
    'WkHIN2b2KZ2316kcjIFs',
];

export const AdminSales = (props) => {
    const { classes, history } = props;
    //const user = useSelector(state => state.user);
    const { sales, schedules, otherSchedules, managerLoading, salesDetail } = useSelector((state) => state.manager);
    const { loading } = useSelector((state) => state.UI);
    const { adminLoading } = useSelector((state) => state.admin);
    const { refunds } = useSelector((state) => state.data);

    const dispatch = useDispatch();

    // sales detail popup
    useEffect(() => {
        if (salesDetail) {
            setSd(salesDetail);
        }
    }, [salesDetail, dispatch]);
    // 환불내역 badge
    useEffect(() => {
        dispatch(getRefunds());
    }, [dispatch]);
    const length = refunds?.filter((r) => r.status === '요청').length;

    const [expan, setExpan] = useState(false);
    const handleExpand = () => {
        setExpan(!expan);
    };
    // dialog
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false); // 일정취소
    const [open3, setOpen3] = useState(false); // 결제취소
    const [open4, setOpen4] = useState(false); // 일정추가
    const [sd, setSd] = useState({});
    // const [imps, setImps] = useState({});
    const [refund, setRefund] = useState({});
    const [info, setInfo] = useState({
        imp_uid: '',
        merchant_uid: '',
        amount: 0,
        tax_free: 0,
        checksum: null,
        reason: '관리자취소',
    });
    const [scheduleInfo, setScheduleInfo] = useState({
        imp_uid: '',
        new_merchant_uid: '',
        new_datetime: null,
        nindex: null,
        original_merchant_uid: '',
    });
    // sort
    const [sortType, setSortType] = useState('createdAtDesc');
    const [withCancel, setWithCancel] = useState(0);

    const [withMonthlyPaid, setWithMonthlyPaid] = useState(true);
    const [searchName, setSearchName] = useState('');
    const [searchhName, setSearchhName] = useState('');
    const [searchPaymentId, setSearchPaymentId] = useState('');

    const [payType, setPayType] = useState('all');
    const handleType = (e) => {
        e.preventDefault();
        setPayType(e.target.value);
    };
    const [memo, setMemo] = useState('');
    const [note, setNote] = useState('');
    const [memoOpen, setMemoOpen] = useState(false);
    const [noteOpen, setNoteOpen] = useState(false);
    const [refundOpen, setRefundOpen] = useState(false);

    const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYYMMDD'));
    const [toDate, setToDate] = useState(dayjs().format('YYYYMMDD'));
    let numDateFrom = dayjs(fromDate) / 1000;
    let numDateTo = dayjs(toDate).add(1, 'day') / 1000;
    let filteredSales = sales; //.filter(sa => sa.createdAt >= numDateFrom && sa.createdAt < numDateTo); //

    const handleAdminSales = () => {
        const dates = {
            from: numDateFrom,
            to: numDateTo,
        };
        const res = dispatch(isLogged(history));

        if (res === 'logged') {
            dispatch(getAllSalesFromTo(dates));
            dispatch(getRefunds());
        }
    };
    const handleFailedSales = () => {
        const dates = {
            from: numDateFrom,
            to: numDateTo,
        };
        const res = dispatch(isLogged(history));

        if (res === 'logged') {
            dispatch(getFailedSales(dates));
            dispatch(getRefunds());
        }
    };
    const handleViewRefundsRequest = () => {
        dispatch(getRefunds());
        setRefundOpen(true);
    };

    const reFiltered = filteredSales
        .filter(
            (sa) =>
                (monthlyProductIds.indexOf(sa.productId) > -1 && payType !== 'general') ||
                (monthlyProductIds.indexOf(sa.productId) === -1 && payType === 'general') ||
                payType === 'all'
        )
        .filter((sa) => (withCancel === 0 ? true : withCancel === 1 ? sa.status === 'paid' : sa.status === 'cancelled'))
        .filter((sa) => (searchhName === '' ? true : sa.hName?.startsWith(searchhName)))
        .filter((sa) =>
            searchName === '' ? true : sa.buyer_name === null ? true : sa.buyer_name?.startsWith(searchName)
        )
        .filter((sa) => (searchPaymentId === '' ? true : sa.paymentId?.startsWith(searchPaymentId)))
        .filter((sa) => (withMonthlyPaid ? true : sa.imp_uid.substring(0, 4) !== 'imps'))
        .sort((a, b) =>
            sortType === 'hNameAsc'
                ? a.hName > b.hName
                    ? 1
                    : -1
                : sortType === 'hNameDesc'
                ? b.hName > a.hName
                    ? 1
                    : -1
                : sortType === 'createdAtAsc'
                ? a.createdAt > b.createdAt
                    ? 1
                    : -1
                : sortType === 'createdAtDesc'
                ? b.createdAt > a.createdAt
                    ? 1
                    : -1
                : sortType === 'createdAtDesc'
                ? b.createdAt > a.createdAt
                    ? 1
                    : -1
                : sortType === 'lNameDesc'
                ? b.name > a.name
                    ? 1
                    : -1
                : sortType === 'lNameAsc'
                ? a.name > b.name
                    ? 1
                    : -1
                : sortType === 'nameDesc'
                ? b.buyer_name > a.buyer_name
                    ? 1
                    : -1
                : sortType === 'nameAsc'
                ? a.buyer_name > b.buyer_name
                    ? 1
                    : -1
                : sortType === 'noDesc'
                ? b.paymentId > a.paymentId
                    ? 1
                    : -1
                : sortType === 'noAsc'
                ? a.paymentId > b.paymentId
                    ? 1
                    : -1
                : sortType === 'typeDesc'
                ? b.lessonType + b.totalRound + b.amount > a.lessonType + a.totalRound + a.amount
                    ? 1
                    : -1
                : sortType === 'typeAsc'
                ? b.lessonType + b.totalRound + b.amount < a.lessonType + a.totalRound + a.amount
                    ? 1
                    : -1
                : sortType === 'monthTypeDesc'
                ? dayjs(b.lessonDate * 1000).get('date') > 20 && b.lessonType === 'regular'
                    ? dayjs(b.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(b.lessonDate * 1000).get('month') >
                      (dayjs(a.lessonDate * 1000).get('date') > 20 && a.lessonType === 'regular')
                    ? dayjs(a.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(a.lessonDate * 1000).get('month')
                    ? 1
                    : -1
                : sortType === 'monthTypeAsc'
                ? dayjs(b.lessonDate * 1000).get('date') > 20 && b.lessonType === 'regular'
                    ? dayjs(b.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(b.lessonDate * 1000).get('month') <
                      (dayjs(a.lessonDate * 1000).get('date') > 20 && a.lessonType === 'regular')
                    ? dayjs(a.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(a.lessonDate * 1000).get('month')
                    ? 1
                    : -1
                : sortType === 'categoryDesc'
                ? b.category > a.category
                    ? 1
                    : -1
                : sortType === 'categoryAsc'
                ? a.category > b.category
                    ? 1
                    : -1
                : null
        );
    const sum_sales = reFiltered.reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const count_sales = reFiltered.length;
    const count_hakwons = Array.from(new Set(reFiltered.map((sa) => sa.hName))).length;

    const openDetail = (detail) => {
        dispatch(getOneSaleDetail(detail.paymentId));
        setOpen(true);
        dispatch(getBillingSchedule({ merchant_uid: detail.paymentId.substring(0, 15) }));
        dispatch(getBillingOtherSchedule({ merchant_uid: detail.paymentId.substring(0, 15) }));
    };

    // 일정변경 추가
    const openSmallDetail = (detail, index) => {
        setScheduleInfo({
            ...scheduleInfo,
            new_datetime: dayjs(detail.schedule_at * 1000).format('YYYY-MM-DD HH:mm:ss'),
            nindex: index,
            imp_uid: sd.imp_uid,
            original_merchant_uid: detail.merchant_uid,
            new_merchant_uid: detail.merchant_uid,
        });
        setOpen2(true);
    };
    const toggleHName = () => {
        if (sortType === 'hNameDesc') {
            setSortType('hNameAsc');
        } else {
            setSortType('hNameDesc');
        }
    };
    const toggleCreatedAt = () => {
        if (sortType === 'createdAtDesc') {
            setSortType('createdAtAsc');
        } else {
            setSortType('createdAtDesc');
        }
    };
    const toggleLName = () => {
        if (sortType === 'lNameDesc') {
            setSortType('lNameAsc');
        } else {
            setSortType('lNameDesc');
        }
    };
    const toggleName = () => {
        if (sortType === 'nameDesc') {
            setSortType('nameAsc');
        } else {
            setSortType('nameDesc');
        }
    };
    const toggleNo = () => {
        if (sortType === 'noDesc') {
            setSortType('noAsc');
        } else {
            setSortType('noDesc');
        }
    };
    const toggleType = () => {
        if (sortType === 'typeDesc') {
            setSortType('typeAsc');
        } else {
            setSortType('typeDesc');
        }
    };
    const toggleCategory = () => {
        if (sortType === 'categoryDesc') {
            setSortType('categoryAsc');
        } else {
            setSortType('categoryDesc');
        }
    };
    const toggleMonthType = () => {
        if (sortType === 'monthTypeDesc') {
            setSortType('monthTypeAsc');
        } else {
            setSortType('monthTypeDesc');
        }
    };
    const handleChangeWithCancel = () => {
        if (withCancel === 0) {
            setWithCancel(1);
        } else if (withCancel === 1) {
            setWithCancel(2);
        } else {
            setWithCancel(0);
        }
    };
    const handleChangeWithMonthlyPaid = () => {
        if (withMonthlyPaid) {
            setWithMonthlyPaid(false);
        } else {
            setWithMonthlyPaid(true);
        }
    };
    // 카드 결제 취소
    const handleCancelOpen = (detail) => {
        setOpen3(true);
        setRefund(detail);
        const selectedPrice = detail.price ? detail.price : detail.amount;
        setInfo({ ...info, amount: selectedPrice, tax_free: selectedPrice, checksum: selectedPrice });
    };

    const handleCardCancel = (detail) => {
        const cancelInfo = {
            imp_uid: detail.imp_uid,
            merchant_uid: detail.merchant_uid,
            amount: info.amount,
            tax_free: info.tax_free,
            checksum: info.checksum,
            reason: info.reason,
        };
        const dates = { from: numDateFrom, to: numDateTo };

        dispatch(requestCancel(cancelInfo, dates));
        setOpen3(false);
        setOpen(false);
    };

    // 스케쥴 업데이트
    const handleUpdateSchedule = () => {
        if (scheduleInfo.new_merchant_uid === scheduleInfo.original_merchant_uid) {
            alert('이전 merchant_uid를 사용하실 수 없습니다. 변경하여 주세요.');
        } else {
            dispatch(addOneSchedule(scheduleInfo));
            setOpen2(false);
        }
    };
    // 스케쥴 취소(변경하지 않고 취소만 하는 경우)
    const handleUnSubscriptionOpen = (s) => {
        const info = {
            merchant_uid: s.merchant_uid,
            customer_uid: s.customer_uid,
        };
        const ale = window.confirm(
            '스케쥴(결제예약)을 삭제하시겠습니까? 변경을 원하시면 왼쪽 수정 버튼을 사용하십시오.'
        );
        if (ale) {
            dispatch(cancelOneSchedule(info));
        }
    };

    const handleWriteMemo = (pId) => {
        if (memo.trim()) {
            if (window.confirm('작성하시겠습니까?')) {
                dispatch(updateSalesWithMemo(pId, memo, numDateFrom, numDateTo));
                setOpen(false);
                setMemoOpen(false);
                setMemo('');
            }
        } else {
            alert('내용이 없습니다.');
        }
    };
    const handleWriteNote = (pId) => {
        if (note.trim()) {
            if (window.confirm('작성하시겠습니까?')) {
                dispatch(updateSalesWithNote(pId, note, numDateFrom, numDateTo));
                setOpen(false);
                setNote('');
                setNoteOpen(false);
            }
        } else {
            alert('내용이 없습니다.');
        }
    };

    // 환불다이어로그
    const [withoutRefundDone, setWithoutRefundDone] = useState(true);
    const handleWithoutRefundDone = () => {
        setWithoutRefundDone(!withoutRefundDone);
    };
    const filterRefunds = refunds
        .filter((rf) => (withoutRefundDone ? rf.status !== '완료' : true))
        .sort((a, b) => b.createdAt - a.createdAt);
    const handleDone = (rId) => {
        dispatch(
            updateRefund(rId, {
                status: '완료',
                result: new Date() / 1000,
            })
        );

        // 완료버튼 누를때, ATA8 발송
        const doneRefund = refunds.filter((r) => r.refundId === rId)[0];
        const ataData = {
            name: doneRefund.child_name,
            phone: doneRefund.phone.replace(/-/gi, ''),
            lessonName: doneRefund.lessonName,
        };

        dispatch(sendATA8(ataData));
    };
    const handlePause = (rId) => {
        dispatch(
            updateRefund(rId, {
                status: '보류',
                result: new Date() / 1000,
            })
        );
    };
    const handleDelete = (rId) => {
        dispatch(deleteRefund(rId));
    };

    const handleCloseDetail = () => {
        setOpen(false);
        setMemo('');
        setNote('');
        setMemoOpen(false);
        setNoteOpen(false);
        setSd({});
    };

    // 페이지네이션(pagination)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 50);
    const indexOfLastSales = currentPage * perPage;
    const indexOfFirstSales = indexOfLastSales - perPage;
    const handlePerPage = (e) => {
        setPerPage(e.target.value);
        localStorage.setItem('perPage', e.target.value);
    };
    const handlePrev = () => {
        setCurrentPage((pre) => (pre === 1 ? 1 : pre - 1));
    };
    const handleNext = () => {
        setCurrentPage((pre) => (pre === lastPage ? lastPage : pre + 1));
    };
    const paginationSales = reFiltered.slice(indexOfFirstSales, indexOfLastSales);
    const lastPage = Math.ceil(reFiltered.length / perPage);

    return (
        <Box p={1} m={1}>
            <Box height={20} />
            <Typography variant="subtitle1" display="inline">
                결제 현황 조회
            </Typography>

            <Divider light />
            <Box p={1} m={1}>
                <TextField
                    size="small"
                    margin="dense"
                    className={classes.date}
                    style={{ backgroundColor: '#FAF3E7', width: 90 }}
                    required
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                />
                <Typography display="inline" className={classes.text}>
                    {' '}
                    ~{' '}
                </Typography>
                <TextField
                    size="small"
                    margin="dense"
                    style={{ backgroundColor: '#FAF3E7', width: 90 }}
                    required
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                />
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleAdminSales}
                    className={classes.btn}
                    startIcon={<SearchTwoTone />}
                >
                    조회
                </Button>
                <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={handleFailedSales}
                    className={classes.btn}
                    startIcon={<SmsFailedTwoTone />}
                >
                    실패내역
                </Button>

                <TextField
                    size="small"
                    margin="dense"
                    style={{ backgroundColor: '#FAF3E7', width: 120, marginLeft: 30 }}
                    label="학생이름"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                />
                <TextField
                    size="small"
                    margin="dense"
                    style={{ backgroundColor: '#FAF3E7', width: 150, marginLeft: 20 }}
                    label="가맹학원명"
                    value={searchhName}
                    onChange={(e) => setSearchhName(e.target.value)}
                />
                <TextField
                    size="small"
                    margin="dense"
                    style={{ backgroundColor: '#FAF3E7', width: 150, marginLeft: 20 }}
                    label="주문결제번호"
                    value={searchPaymentId}
                    onChange={(e) => setSearchPaymentId(e.target.value)}
                />
            </Box>
            <FormControl component="fieldset">
                <RadioGroup aria-label="category" name="category" value={payType} onChange={handleType} row>
                    <FormControlLabel
                        value={'all'}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">전체</Typography>}
                    />
                    <FormControlLabel
                        value={'general'}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">일반결제</Typography>}
                    />
                    <FormControlLabel
                        value={'monthly'}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">정기결제(초기인증포함)</Typography>}
                    />
                </RadioGroup>
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={withCancel === 0}
                        indeterminate={withCancel === 2}
                        onChange={handleChangeWithCancel}
                        name="checked"
                    />
                }
                label={
                    withCancel === 0 ? (
                        <Typography variant="body2">취소포함</Typography>
                    ) : withCancel === 2 ? (
                        <Typography variant="body2">취소 만</Typography>
                    ) : (
                        <Typography variant="body2">취소제외</Typography>
                    )
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={withMonthlyPaid}
                        onChange={handleChangeWithMonthlyPaid}
                        name="checked"
                    />
                }
                label={
                    withMonthlyPaid ? (
                        <Typography variant="body2">월자동결제포함</Typography>
                    ) : (
                        <Typography variant="body2">월자동결제제외</Typography>
                    )
                }
            />

            <ExcelFile
                style={{ marginLeft: 50 }}
                element={
                    <Button color="primary" size="small" variant="outlined" startIcon={<ArchiveRounded />}>
                        Excel 다운로드
                    </Button>
                }
            >
                <ExcelSheet data={reFiltered} name="Sales">
                    <ExcelColumn label={'결제번호'} value="paymentId" />
                    <ExcelColumn label={'자녀이름'} value="buyer_name" />
                    <ExcelColumn label={'학원명'} value="hName" />
                    <ExcelColumn
                        label={'금액'}
                        value={(row) => row.amount - (row.cancel_amount ? row.cancel_amount : 0)}
                    />
                    <ExcelColumn
                        label={'상태'}
                        value={(row) =>
                            row.status === 'paid'
                                ? '결제'
                                : row.status === 'cancelled' && row.cancel_amount < row.amount
                                ? '부분취소'
                                : '취소'
                        }
                    />
                    <ExcelColumn label={'나이'} value="age" />
                    <ExcelColumn label={'레슨명'} value="name" />
                    <ExcelColumn
                        label={'수업시작일'}
                        value={(c) => dayjs(c.lessonDate * 1000).format('YYYY-MM-DD HH:mm')}
                    />
                    <ExcelColumn
                        label={'유형'}
                        value={(row) =>
                            row.totalRound
                                ? row.totalRound + '개월'
                                : row.lessonType === 'trial'
                                ? '원데이'
                                : row.lessonType === 'premium'
                                ? '프리미엄'
                                : row.imp_uid.substring(0, 5) === 'imps_'
                                ? String(row.merchant_uid?.split('_')[3]) + '개월'
                                : row.lessonType === 'regular' && row.amount === 45000
                                ? '3개월'
                                : row.lessonType === 'regular' && row.amount === 15000
                                ? '1개월'
                                : '-'
                        }
                    />
                    <ExcelColumn
                        label={'월구분'}
                        value={(row) =>
                            dayjs(row.lessonDate * 1000).get('date') > 20 && row.lessonType === 'regular'
                                ? String(
                                      dayjs(row.lessonDate * 1000)
                                          .add(1, 'month')
                                          .get('month') + 1
                                  ) + '월'
                                : String(dayjs(row.lessonDate * 1000).get('month') + 1) + '월'
                        }
                    />
                    <ExcelColumn label={'상품그룹'} value="productGroupId" />
                    <ExcelColumn label={'부모명'} value="parentName" />
                    <ExcelColumn label={'전화'} value="buyer_tel" />
                    <ExcelColumn label={'카테고리'} value="category" />
                    <ExcelColumn label={'결제일'} value={(c) => dayjs(c.createdAt * 1000).format('YYYY-MM-DD HH:mm')} />
                    <ExcelColumn label={'부모아이디'} value="customer_uid" />
                    <ExcelColumn label={'학원아이디'} value="hakwonId" />
                    <ExcelColumn label={'학원전화'} value="hakwonPhone" />
                    <ExcelColumn label={'imp아이디'} value="imp_uid" />
                    <ExcelColumn label={'수업아이디'} value="lessonId" />
                    <ExcelColumn label={'수업형태'} value="lessonType" />
                    <ExcelColumn label={'결제일시'} value={(c) => dayjs(c.paid_at * 1000).format('YYYY-MM-DD HH:mm')} />
                    <ExcelColumn label={'주소'} value="buyer_addr" />
                    <ExcelColumn label={'pg'} value="pg" />
                    <ExcelColumn label={'정가'} value="price" />
                    <ExcelColumn label={'정산'} value="settlement" />
                    <ExcelColumn label={'상품아이디'} value="productId" />
                    <ExcelColumn label={'정기결제'} value="totalRound" />
                    <ExcelColumn label={'정기결제중단'} value="memo" />
                    {/* <ExcelColumn label={'예약결제일자'} value={c.billingDates.join(',')}/>
                        <ExcelColumn label={'예약결제번호'} value={c=>c.billingUids.join(',')}/> */}
                </ExcelSheet>
            </ExcelFile>
            {/* 환불요청 알림 */}
            <Button
                size="small"
                style={{ marginBottom: 10 }}
                color={length > 0 ? 'secondary' : 'inherit'}
                variant={'contained'}
                onClick={handleViewRefundsRequest}
                className={classes.btn}
            >
                환불요청{' '}
                <Badge badgeContent={length} color="error">
                    <MailRounded />
                </Badge>
            </Button>

            {managerLoading ? (
                <CenterLoading />
            ) : (
                <Box className={expan ? classes.fullWidth : classes.none}>
                    <Box className={expan ? classes.expand : classes.none}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.padding}>
                                            <IconButton color="secondary" size="small" onClick={handleExpand}>
                                                {expan ? <LastPageRounded /> : <FirstPageRounded />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleNo}
                                                endIcon={
                                                    sortType === 'noAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'noDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                주문번호
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleName}
                                                endIcon={
                                                    sortType === 'nameAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'nameDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                원생
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleHName}
                                                endIcon={
                                                    sortType === 'hNameAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'hNameDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                학원
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleCreatedAt}
                                                endIcon={
                                                    sortType === 'createdAtAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'createdAtDesc' && (
                                                            <ArrowDropUp color="secondary" />
                                                        )
                                                    )
                                                }
                                            >
                                                결제일
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding} align="center">
                                            <Button
                                                onClick={toggleLName}
                                                endIcon={
                                                    sortType === 'lNameAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'lNameDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                수업이름
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>수업일</TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleMonthType}
                                                endIcon={
                                                    sortType === 'monthTypeAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'monthTypeDesc' && (
                                                            <ArrowDropUp color="secondary" />
                                                        )
                                                    )
                                                }
                                            >
                                                월구분
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>금액</TableCell>
                                        <TableCell className={classes.padding}>상태</TableCell>
                                        {/* <TableCell className={classes.padding}>학부모명</TableCell> */}
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleType}
                                                endIcon={
                                                    sortType === 'typeAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'typeDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                유형
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.padding}>
                                            <Button
                                                onClick={toggleCategory}
                                                endIcon={
                                                    sortType === 'categoryAsc' ? (
                                                        <ArrowDropDown color="primary" />
                                                    ) : (
                                                        sortType === 'categoryDesc' && <ArrowDropUp color="secondary" />
                                                    )
                                                }
                                            >
                                                카테고리
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    <TableBody>
                                        {paginationSales.map((row, index) => (
                                            <TableRow key={row.paymentId}>
                                                <TableCell size="small" className={classes.padding}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell
                                                    size="small"
                                                    className={classes.padding}
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                    onClick={() => openDetail(row)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <Typography variant="subtitle2">{row.paymentId}</Typography>
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.buyer_name}
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    <Button>{row.hName} </Button>
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.createdAt > 0
                                                        ? dayjs(row.createdAt * 1000).format('YYYY-MM-DD')
                                                        : dayjs(row.failed_at * 1000).format('YYYY-MM-DD')}
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.lessonDate
                                                        ? dayjs(row.lessonDate * 1000).format('MM/DD(dd)')
                                                        : ''}
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding} align="center">
                                                    {dayjs(row.lessonDate * 1000).get('date') > 20 &&
                                                    row.lessonType === 'regular'
                                                        ? String(
                                                              dayjs(row.lessonDate * 1000)
                                                                  .add(1, 'month')
                                                                  .get('month') + 1
                                                          ) + '월'
                                                        : String(dayjs(row.lessonDate * 1000).get('month') + 1) + '월'}
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    <Typography
                                                        variant="body2"
                                                        color={
                                                            row.status === 'cancelled' && row.cancel_amount < row.amount
                                                                ? 'error'
                                                                : 'inherit'
                                                        }
                                                    >
                                                        {numberFormat(
                                                            row.amount - (row.cancel_amount ? row.cancel_amount : 0)
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.status === 'paid' ? (
                                                        '결제'
                                                    ) : row.status === 'cancelled' && row.cancel_amount < row.amount ? (
                                                        <Typography variant="body2" color="error">
                                                            부분취소
                                                        </Typography>
                                                    ) : row.status === 'failed' ? (
                                                        <Typography variant="body2" color="error">
                                                            {row.fail_reason}
                                                        </Typography>
                                                    ) : (
                                                        <Typography color="error" variant="body2">
                                                            취소
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                {/* <TableCell size="small" className={classes.padding}>
                                                    {row.parentName}
                                                </TableCell> */}
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.totalRound
                                                        ? row.totalRound + '개월'
                                                        : row.lessonType === 'trial'
                                                        ? '원데이'
                                                        : row.lessonType === 'premium'
                                                        ? '프리미엄'
                                                        : row.imp_uid.substring(0, 5) === 'imps_'
                                                        ? String(row.paymentId.split('_')[3]) + '개월'
                                                        : row.lessonType === 'regular' && row.amount === 45000
                                                        ? '3개월'
                                                        : row.lessonType === 'regular' && row.amount === 15000
                                                        ? '1개월'
                                                        : '-'}
                                                </TableCell>
                                                <TableCell size="small" className={classes.padding}>
                                                    {row.category}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow style={{ backgroundColor: '#efe' }}>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding} align="center">
                                                <strong>{numberFormat(count_sales)}건</strong>
                                            </TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding} align="center">
                                                <strong>{numberFormat(count_hakwons)}개원</strong>
                                            </TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding}>
                                                <strong>{numberFormat(sum_sales)}</strong>
                                            </TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            {/* <TableCell size="small" className={classes.padding}></TableCell> */}
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                            <TableCell size="small" className={classes.padding}></TableCell>
                                        </TableRow>
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box m={1} p={1}>
                        <FormControl>
                            <Select value={perPage} onChange={handlePerPage}>
                                <MenuItem value={10}>10건</MenuItem>
                                <MenuItem value={20}>20건</MenuItem>
                                <MenuItem value={50}>50건</MenuItem>
                                <MenuItem value={100}>100건</MenuItem>
                                <MenuItem value={200}>200건</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            onClick={handlePrev}
                            color="primary"
                            startIcon={<ArrowBackIosRounded />}
                            className={classes.button}
                        >
                            이전
                        </Button>
                        <Typography display="inline" color="primary" variant="subtitle2">
                            {currentPage} / {lastPage}
                        </Typography>
                        <Button
                            onClick={handleNext}
                            color="primary"
                            //variant="contained"
                            endIcon={<ArrowForwardIosRounded />}
                            className={classes.button}
                        >
                            다음
                        </Button>
                    </Box>
                </Box>
            )}

            {/* 매출 상세 Dialog */}
            <Dialog open={open} maxWidth="md" fullWidth onBackdropClick={() => setOpen(false)}>
                <DialogTitle>{sd.paymentId}</DialogTitle>
                <DialogContent>
                    <Typography>결제아이디 : {sd.paymentId}</Typography>
                    <Typography>주문번호(apply_num) : {sd.apply_num}</Typography>
                    <Typography>imp번호 : {sd.imp_uid}</Typography>
                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        주문
                    </Typography>
                    <Typography>
                        학원 : {sd.hName} ({sd.hakArea?.area1} {sd.hakArea?.area2})
                    </Typography>
                    <Typography variant="subtitle1">학생이름 : {sd.buyer_name}</Typography>
                    <Typography>생년월일 : {sd.age}</Typography>

                    <br />
                    <Typography variant="subtitle1" gutterBottom>
                        상품/수업
                    </Typography>
                    <Typography>수업아이디 : {sd.lessonId}</Typography>
                    <Typography>상품아이디 : {sd.productId} </Typography>
                    <Typography>수업명 : {sd.name}</Typography>
                    <Typography>수업일자 : {dayjs(sd.lessonDate * 1000).format('YYYY-MM-DD HH:mm')}</Typography>
                    <Typography>카테고리 : {sd.category} </Typography>
                    <Typography>수업가격: {sd.price} </Typography>
                    <Typography>정산금액: {sd.settlement} </Typography>
                    <Typography variant="body2">
                        ㆍ정산여부 : {sd.isSettled ? '정산완료' : '미정산'}{' '}
                        {sd.isSettled && dayjs(sd.settledAt * 1000).format('YYYY-MM-DD HH:mm:ss')}{' '}
                    </Typography>
                    <Typography variant="body2">ㆍ정산후 취소 : {sd.isRevoked ? '정산후취소' : '-'}</Typography>
                    <Typography>수업형태: {sd.lessonType} </Typography>
                    <br />

                    {loading ? (
                        <CenterLoading />
                    ) : (
                        schedules.length > 0 && (
                            <Box>
                                <Typography variant="subtitle1" gutterBottom>
                                    정기결제내역
                                </Typography>
                                {/* <Button size='small' startIcon={<EditRounded />}>일괄수정</Button> */}

                                {schedules.map((s, i) => (
                                    <Grid container direction="row" alignItems="center" key={i}>
                                        <Grid item>
                                            <Button
                                                onClick={() => openSmallDetail(s, i)}
                                                size="small"
                                                color={s.payment_status === 'paid' ? 'primary' : 'default'}
                                                disabled={s.payment_status === 'paid'}
                                                startIcon={<EditRounded />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {s.merchant_uid} (
                                                {s.payment_status !== null ? s.payment_status : s.schedule_status}){' '}
                                                {s.executed_at
                                                    ? dayjs(s.executed_at * 1000).format('YYYY-MM-DD HH:mm:ss')
                                                    : dayjs(s.schedule_at * 1000).format('YYYY-MM-DD HH:mm:ss')}{' '}
                                                {s.name} {s.imp_uid}
                                            </Typography>
                                        </Grid>
                                        {s.payment_status === 'paid' && (
                                            <Grid item>
                                                <Button
                                                    size="small"
                                                    startIcon={<DeleteRounded />}
                                                    onClick={() => handleCancelOpen(s)}
                                                />
                                            </Grid>
                                        )}
                                        {s.schedule_status === 'scheduled' && (
                                            <Grid item>
                                                <Button
                                                    size="small"
                                                    startIcon={<DeleteRounded />}
                                                    onClick={() => handleUnSubscriptionOpen(s)}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                            </Box>
                        )
                    )}
                    {adminLoading ? (
                        <CenterLoading />
                    ) : (
                        otherSchedules.length > 0 && (
                            <Box>
                                <Divider light style={{ marginTop: 10, marginBottom: 10 }} />

                                <Typography variant="body2">[취소된 내역]</Typography>
                                {otherSchedules.map((s, i) => (
                                    <Typography variant="body2" key={i}>
                                        {' '}
                                        ㆍ{s.merchant_uid} (
                                        {s.payment_status !== null ? s.payment_status : s.schedule_status}){' '}
                                        {s.executed_at
                                            ? dayjs(s.executed_at * 1000).format('YYYY-MM-DD HH:mm:ss')
                                            : dayjs(s.schedule_at * 1000).format('YYYY-MM-DD HH:mm:ss')}{' '}
                                        {s.name} {s.imp_uid}
                                    </Typography>
                                ))}
                            </Box>
                        )
                    )}
                    <Box height={20} />

                    <Typography variant="subtitle1" gutterBottom>
                        결제
                    </Typography>
                    <Typography>결제금액 : {sd.amount}</Typography>
                    <Typography>
                        결제시각 :{' '}
                        {sd.paid_at > 0
                            ? dayjs(sd.paid_at * 1000).format('YYYY-MM-DD HH:mm:ss')
                            : dayjs(sd.failed_at * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                    <Typography>
                        결제방법 : {sd.pay_method} ({sd.channel})
                    </Typography>
                    <Typography color={sd.status === 'cancelled' ? 'error' : 'initial'}>
                        상태 : {sd.status}{' '}
                        {sd.status === 'paid' && (
                            <Button
                                size="small"
                                color="primary"
                                variant="outlined"
                                startIcon={<DeleteOutlined />}
                                onClick={() => handleCancelOpen(sd)}
                            >
                                카드 취소 하기
                            </Button>
                        )}
                    </Typography>
                    <Box>
                        환불 :{' '}
                        {sd.cancel_history !== null
                            ? sd.cancel_history?.map((s, i) => (
                                  <Box key={i}>
                                      <Typography variant="body2">ㆍ취소금액 : {s.amount}</Typography>
                                      <Typography variant="body2">ㆍpg_tid : {s.pg_tid}</Typography>
                                      <Typography variant="body2">
                                          ㆍ취소일자 : {dayjs(s.cancelled_at * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                      </Typography>
                                      <Typography variant="body2">ㆍ사유 : {s.reason}</Typography>
                                      <Typography variant="body2">ㆍ영수증 : {s.receipt_url}</Typography>
                                      <br />
                                  </Box>
                              ))
                            : '-'}
                    </Box>

                    {/* memo */}
                    {sd.memo ? (
                        <div>
                            <Typography display="inline">정기결제 결제후 중단 : </Typography>{' '}
                            <Typography display="inline" color="primary">
                                <strong>{sd.memo}</strong>
                            </Typography>
                        </div>
                    ) : null}
                    {sd.status === 'paid' && !!sd.billingUids && (
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            size="small"
                            onClick={() => setMemoOpen(!memoOpen)}
                            startIcon={<FlagRounded />}
                            endIcon={memoOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                        >
                            중단일자작성
                        </Button>
                    )}
                    {sd.status === 'paid' && !!sd.billingUids && memoOpen && (
                        <Box>
                            <TextField
                                label="memo(형식: YYYYMM_메모기록)"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                                helperText='정기결제 중 기결제된 내역이 있어 취소하지 못할때, 기록합니다. 수강생 메뉴에 노출될 "수강정지시작월"을 YYYYMM_ 형식으로 앞에 반드시 기록합니다. '
                            />
                            <Button
                                className={classes.btn}
                                variant="contained"
                                color="primary"
                                onClick={() => handleWriteMemo(sd.paymentId)}
                            >
                                기록
                            </Button>
                        </Box>
                    )}

                    {/* Note */}
                    {sd.note ? (
                        <div>
                            <Typography display="inline">Note : </Typography>{' '}
                            <Typography display="inline" color="primary">
                                <strong>{sd.note}</strong>
                            </Typography>
                        </div>
                    ) : null}

                    <Button
                        className={classes.btn}
                        variant="outlined"
                        size="small"
                        onClick={() => setNoteOpen(!noteOpen)}
                        startIcon={<FlagRounded />}
                        endIcon={noteOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                    >
                        Note 작성
                    </Button>

                    {noteOpen && (
                        <Box>
                            <TextField
                                label="Note"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                helperText="매출 처리 상에 기록할 메모를 노트합니다."
                            />
                            <Button
                                className={classes.btn}
                                variant="contained"
                                color="primary"
                                onClick={() => handleWriteNote(sd.paymentId)}
                            >
                                기록
                            </Button>
                        </Box>
                    )}

                    <br />
                    <Box height={20} />
                    <Typography variant="subtitle1" gutterBottom>
                        주문자
                    </Typography>
                    <Typography>주문자 : {sd.parentName}</Typography>
                    <Typography>주문자전화 : {sd.buyer_tel}</Typography>
                    <Typography>주문자아이디 : {sd.customer_uid}</Typography>
                    <Typography>
                        배송주소 : {sd.buyer_addr} ({sd.which}){' '}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDetail}>닫기</Button>
                </DialogActions>
            </Dialog>
            <Box height={50} />
            {/* 개별 결제 변경 */}
            <Dialog open={open2} maxWidth="xs">
                <DialogTitle>
                    <Button color="primary">새로운 결제 스케쥴 예약 업데이트</Button>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        ㆍ 원 결제 아이디(merchant_uid): {scheduleInfo.original_merchant_uid}
                    </Typography>
                    <Typography variant="body2">ㆍ 순서/차례(index+1): {scheduleInfo.nindex + 1}</Typography>
                    <Typography variant="body2">ㆍ imp 번호(imp_uid): {scheduleInfo.imp_uid}</Typography>
                    <br />
                    <TextField
                        label="ㆍ결제 아이디 변경(new_merchant_uid)"
                        value={scheduleInfo.new_merchant_uid}
                        onChange={(e) => setScheduleInfo({ ...scheduleInfo, new_merchant_uid: e.target.value })}
                        fullWidth
                    />
                    <Typography variant="caption">
                        (반드시 새로운 번호여여함. 맨끝에 _1 과 같이 추가하여 생성)
                    </Typography>
                    <br />
                    <TextField
                        label="ㆍ결제예정일(YYYY-MM-DD HH:mm:ss)"
                        value={scheduleInfo.new_datetime}
                        onChange={(e) => setScheduleInfo({ ...scheduleInfo, new_datetime: e.target.value })}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={handleUpdateSchedule}>
                        업데이트
                    </Button>
                    <Button color="primary" variant="outlined" onClick={() => setOpen2(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open3} fullWidth maxWidth="xs">
                {' '}
                {/* 결제 취소 */}
                <DialogTitle>
                    <Button color="primary">결제 취소 처리</Button>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="취소금액"
                        value={info.amount}
                        onChange={(e) => setInfo({ ...info, amount: e.target.value })}
                    />
                    <TextField
                        label="면세금액(취소금액과 동일)"
                        value={info.tax_free}
                        onChange={(e) => setInfo({ ...info, tax_free: e.target.value })}
                    />
                    <TextField
                        label="checksum(원래금액)"
                        value={info.checksum}
                        onChange={(e) => setInfo({ ...info, checksum: e.target.value })}
                    />
                    <TextField
                        label="사유"
                        value={info.reason}
                        onChange={(e) => setInfo({ ...info, reason: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => handleCardCancel(refund)}>
                        취소처리
                    </Button>
                    <Button variant="outlined" onClick={() => setOpen3(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open4} maxWidth="xs">
                {' '}
                {/* 일정추가 */}
                <DialogContent>
                    <TextField
                        label="면세금액(취소금액과 동일)"
                        value={info.reason}
                        onChange={(e) => setInfo({ ...info, reason: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained">
                        취소처리
                    </Button>
                    <Button variant="outlined" onClick={() => setOpen4(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
            <Box height={100} />

            <Dialog open={refundOpen} fullWidth maxWidth="md">
                {' '}
                {/* 환불요청 */}
                <DialogTitle>
                    <Button color="primary">환불/취소 요청 내역</Button>
                </DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={withoutRefundDone}
                                onChange={handleWithoutRefundDone}
                                name="checked"
                            />
                        }
                        label={
                            withoutRefundDone ? (
                                <Typography variant="body2">완료 제외</Typography>
                            ) : (
                                <Typography variant="body2">완료 포함</Typography>
                            )
                        }
                    />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.padding}>No</TableCell>
                                    <TableCell className={classes.padding}>결제아이디</TableCell>
                                    <TableCell className={classes.padding}>자녀이름</TableCell>
                                    <TableCell className={classes.padding}>요청날짜</TableCell>
                                    <TableCell className={classes.padding}>회신할연락처</TableCell>
                                    <TableCell className={classes.padding}>요청메모</TableCell>
                                    <TableCell className={classes.padding}>상태</TableCell>
                                    <TableCell className={classes.padding}>처리</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterRefunds.map((rf, index) => (
                                    <TableRow
                                        key={index}
                                        style={{ backgroundColor: rf.status === '요청' ? '#fce7d9' : '#fff' }}
                                    >
                                        <TableCell className={classes.padding}>{index + 1}</TableCell>
                                        <TableCell className={classes.padding}>
                                            <strong>{rf.paymentId}</strong>
                                        </TableCell>
                                        <TableCell className={classes.padding}>{rf.child_name}</TableCell>
                                        <TableCell className={classes.padding}>
                                            {dayjs().isBefore(dayjs(rf.createdAt * 1000), 'day')
                                                ? dayjs(rf.createdAt * 1000).format('A hh:mm')
                                                : dayjs(rf.createdAt * 1000).format('YYYY-MM-DD HH:mm')}
                                        </TableCell>
                                        <TableCell className={classes.padding}>{rf.phone}</TableCell>
                                        <TableCell className={classes.padding}>{rf.memo}</TableCell>
                                        <TableCell className={classes.padding}>
                                            <Typography color={rf.status === '요청' ? 'error' : 'initial'}>
                                                {rf.status}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.padding}>
                                            {rf.status === '완료' ? (
                                                dayjs(rf.result * 1000).format('YYYY-MM-DD HH:mm:ss')
                                            ) : (
                                                <Box>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<DoneTwoTone />}
                                                        onClick={() => handleDone(rf.refundId)}
                                                    >
                                                        완료
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        style={{ marginLeft: 10 }}
                                                        variant="contained"
                                                        color="secondary"
                                                        startIcon={<PauseTwoTone />}
                                                        onClick={() => handlePause(rf.refundId)}
                                                    >
                                                        보류
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        style={{ marginLeft: 10 }}
                                                        variant="contained"
                                                        color="inherit"
                                                        startIcon={<DeleteTwoTone />}
                                                        onClick={() => handleDelete(rf.refundId)}
                                                    >
                                                        삭제
                                                    </Button>
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setRefundOpen(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>

            <Box height={100} />
        </Box>
    );
};
const numberFormat = (inputNumber) => {
    if (!inputNumber) return;
    if (typeof inputNumber === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default withStyles(styles)(AdminSales);
