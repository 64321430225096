import React, {useEffect} from 'react';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    ...theme.userPages,
    mainGrid: {
        /* backgroundColor: theme.palette.primary.contrastText, */
        marginBottom: 0,
        padding: theme.spacing(1),
    },
});
function MainGrid(props) {
    // react-router-dom link로 인한 페이지 변경에서 스크롤탑을 인위적으로 진행함
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    document.body.style.backgroundColor = '#eeeeee';

    //const { classes } = props;
    return (
        <div className='container'>
            <Grid container>
                <Grid item md={1}  />
                <Grid item xs={12} sm={12} md={10}>
                    {props.children}
                </Grid>
                <Grid item md={1} />
            </Grid>
        </div>
    )
}

export default withStyles(styles)(MainGrid);

