import {
    SET_PRODUCTS,
    SET_PRODUCT,
    LIKE_PRODUCT,
    UNLIKE_PRODUCT,
    LOADING_DATA,
    STOP_LOADING_DATA,
    DELETE_PRODUCT,
    POST_PRODUCT,
    SUBMIT_COMMENT,
    SET_DATA_ERRORS,
    CLEAR_DATA_ERRORS,
    SET_CURRICULUMS,
    SET_CURRICULUM,
    SET_VIDEOS,
    CLEAR_VIDEOS,
    SET_FREE_VIDEOS,
    SET_EVENTS,
    SET_REFUNDS

} from '../types';

// Product, Curriculum, Video 관련
const initialState = {
    products: [],
    product: {},
    dataLoading: false,
    dataErrors: null,
    curriculum: {},
    curriculums: [],
    videos: [],
    freeVideos: [],
    events: [],
    refunds: []
   
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                dataLoading: true
            }
        case STOP_LOADING_DATA:
            return {
                ...state,
                dataLoading: false
            }
        case SET_DATA_ERRORS:
            return {
                ...state,
                dataLoading: false,
                dataErrors: action.payload
            };
        case CLEAR_DATA_ERRORS:
            return {
                ...state,
                dataLoading: false,
                dataErrors: null
            };
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                dataLoading: false
            };
        case SET_PRODUCT:
            return {
                ...state,
                product: action.payload
            };
        

        case LIKE_PRODUCT:
        case UNLIKE_PRODUCT:
            let index = state.products.findIndex(prod => prod.productId === action.payload.productId);
            state.products[index] = action.payload;

            if (state.product.productId === action.payload.productId) {
                state.product = action.payload;
            }
            return {
                ...state
            }
        case DELETE_PRODUCT:
            let d_index = state.products.findIndex(prod => prod.productId === action.payload);
            state.products.splice(d_index, 1);
            return {
                ...state,
            }
        case POST_PRODUCT:
            return {
                ...state,
                products: [             // [] array
                    action.payload,     //앞에 써주면 젤 먼저 위치하게 된다. lol
                    ...state.products
                ]
            }
        case SUBMIT_COMMENT:
            return {
                ...state,
                product: {
                    ...state.product,
                    comments: [
                        action.payload,
                        ...state.product.comments
                    ]
                }
            }
        case SET_CURRICULUMS:
            return {
                ...state,
                curriculums: action.payload
            }
        case SET_CURRICULUM:
            return {
                ...state,
                curriculum: action.payload
            }
        case SET_VIDEOS:{
            return {
                ...state,
                videos: action.payload
            }
        }
        case CLEAR_VIDEOS:{
            return {
                ...state,
                videos: [],
                freeVideos: []
            }
        }
        case SET_FREE_VIDEOS : {
            return {
                ...state,
                freeVideos: action.payload
            }
        }
        case SET_EVENTS : {
            return {
                ...state,
                events: action.payload
            }
        }
        case SET_REFUNDS : {
            return {
                ...state,
                refunds: action.payload
            }
        }
       
        default:
            return state;
    }
}