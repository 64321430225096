import React, { useState } from 'react';
// component
import DialogStudent from './DialogStudent';
// redux
import { useDispatch } from 'react-redux';
import { updateMember } from '../../redux/actions/managerActions';
// mui stuff
import { makeStyles } from '@material-ui/core/styles';


import { Box, Paper, TableContainer, Table, TableBody, TableHead, TableCell, Button, TableRow } from '@material-ui/core';

import { red } from '@material-ui/core/colors';

import { EditRounded } from '@material-ui/icons';


const MemberListView = (props) => {
    const classes = useStyles();
    const { filteredMembers } = props;
    // redux
    //const { parent } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    // modal

    const [open, setOpen] = useState(false);

    // 원생정보
    const initialValue = {
        age: '',
        sex: '',
        memo: '',
        phone: '',
        siblings: [],
        classInfo: {
            musicClass: '',
            artClass: ''
        },
        isRegistered: false,
        isPaidGogo: false,
        isInvisibleForHakwon: false,
        studentNameForHakwon: ''
    }
    const [value, setValue] = useState(initialValue);
    const [student, setStudent] = useState(initialValue);

    const dialogOpen = (student) => {
        setOpen(true);
        setStudent(student);
        setValue({
            age: student.age,
            sex: student.sex,
            memo: student.memo,
            phone: student.phone,
            siblings: [],
            classInfo: student.classInfo,
            isRegistered: student.isRegistered,
            isPaidGogo: student.isPaidGogo,
            isInvisibleForHakwon: student.isInvisibleForHakwon ? student.isInvisibleForHakwon : false,
            studentNameForHakwon: student.studentNameForHakwon ? student.studentNameForHakwon : student.studentName
        });

    }
    const dialogClose = () => {
        setOpen(false);
        setValue(initialValue);
        setStudent(initialValue);
    }
    const onChangeSex = e => {
        setValue({
            ...value,
            sex: e.target.value
        })
    }
    const onChangeValue = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const onChangeSwitch = e => {
        setValue({
            ...value,
            [e.target.name]: e.target.checked
        });
        if (e.target.checked && e.target.name === 'isInvisibleForHakwon') {
            alert('삭제하시겠습니까? 저장 버튼을 누르면 삭제됩니다.');
        }
    }
    const onChangeClass = e => {
        setValue({
            ...value,
            classInfo: {
                ...value.classInfo,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSaveStudentInfo = () => {
        const newData = {
            hakwonId: student.hakwonId,
            isRegistered: value.isRegistered,
            isDeleted: student.isDeleted,
            isPaidGogo: value.isPaidGogo,
            isInvisibleForHakwon: value.isInvisibleForHakwon,
            studentName: student.studentName,
            studentNameForHakwon: value.studentNameForHakwon,
            age: value.age,
            sex: value.sex,
            classInfo: value.classInfo,
            memo: value.memo,
            phone: value.phone,
            siblings: value.siblings
        }

        dispatch(updateMember(student.studentId, newData));
        setOpen(false);
        setValue(initialValue);
    }


    return (
        <Box m={1} p={1}>
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#fce7d9' }}>
                            <TableCell className={classes.padding}>일련번호</TableCell>
                            <TableCell className={classes.padding}>이름</TableCell>
                            <TableCell className={classes.padding}>연령</TableCell>
                            <TableCell className={classes.padding}>생년월일</TableCell>
                            <TableCell className={classes.padding}>등록여부</TableCell>
                            <TableCell className={classes.padding}>관리</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredMembers.map((stu, index) => <TableRow key={index}>
                                <TableCell className={classes.padding}>{`${index + 1}`}</TableCell>
                                <TableCell className={classes.padding}><Button size='small' onClick={() => dialogOpen(stu)}>{stu.studentNameForHakwon}</Button></TableCell>

                                <TableCell className={classes.padding}>{
                                    stu.age?.length === 8 ? `${koreanAge(stu.age)}세` : null
                                }</TableCell>
                                <TableCell className={classes.padding}>{stu.age}</TableCell>
                                <TableCell className={classes.padding}>{stu.isRegistered ? '등록' : '미등록'}</TableCell>
                                <TableCell className={classes.padding}>
                                    <Button size='small' onClick={() => dialogOpen(stu)}><EditRounded /></Button>
                                    
                                </TableCell>
                            </TableRow>)
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <DialogStudent
                open={open}
                dialogOpen={dialogOpen}
                dialogClose={dialogClose}
                student={student}
                value={value}
                onChangeValue={onChangeValue}
                onChangeClass={onChangeClass}
                onChangeSex={onChangeSex}
                onChangeSwitch={onChangeSwitch}
                handleSaveStudentInfo={handleSaveStudentInfo}
            />
        </Box>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        /*  maxWidth: 345, */
    },
    padding: {
        paddingRight: 5,
        paddingLeft: 5,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    avatarNone: {
        backgroundColor: '#666'
    },
    left: {
        marginLeft: theme.spacing(2)
    }
}));
function koreanAge(string) {
    const today = new Date();
    const year = Number(string.substring(0, 4));
    const month = Number(string.substring(4, 6));
    const day = Number(string.substring(6, 8));
    const birthDate = new Date(year, month, day); // 2000년 8월 10일

    let age = today.getFullYear()
        - birthDate.getFullYear()
        + 1;
    return age;
}
export default MemberListView;
