import React, { useState, useEffect } from 'react';

//import { Link } from 'react-router-dom';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { addOwnerPhone, deleteOwnerPhone, getAllHakwons, getOneHakwon, updateHakwonInfo } from '../../redux/actions/adminActions';
import { uploadBoardImage } from '../../redux/actions/managerActions';
// components

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { IconButton, Box, Typography, TextField, Button, Chip, FormControlLabel, Switch, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { CloseRounded, DeleteRounded, SaveRounded, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import CenterLoading from '../../components/layout/CenterLoading';
import { isLogged } from '../../redux/actions/userActions';


const styles = (theme) => ({
    ...theme.userPage,
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    padding: {
        paddingRight: 3,
        paddingLeft: 3
    },
});

export const AdminHakwon = (props) => {
    const { classes, history } = props;
    //const user = useSelector(state => state.user);
    const { adminhakwons, adminLoading } = useSelector(state => state.admin);
    const { url, managerLoading } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    // countdown loading
    const [second, setSecond] = useState(30);
    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(second) > 1) {
                setSecond(parseInt(second) - 1);
            }

        }, 1000);
        return () =>
            clearInterval(countdown);
    }, [second]);

    const [tempUrl, setTempUrl] = useState('');

    useEffect(() => {
        if (url) {
            setTempUrl(url.message);
        }
    }, [url]);

    const [inputHname, setInputHname] = useState('');
    const [open, setOpen] = useState(false);
    const initial = {
        accountBank: '',
        accountNumber: '',
        accountOwner: '',
        accountNIN: '',
        accountImages: [],
        address: {},
        area: {},
        blog: '',
        changedBy: '',
        cahngedAt: null,
        contacts: {},
        createdAt: null,
        createdBy: '',
        facebook: '',
        hName: '',
        inUse: true,
        instagram: '',
        isOpen: true,
        ownerName: '',
        ownerPhone: [],
        partnerType: '',
        joinYN: false,
        lessons: 0,
        teachers: 0,
        users: 0
    }
    const [hakwon, setHakwon] = useState(initial);
    const [addPhone, setAddPhone] = useState('');

    const handleGetAllHakwons = () => {
        setSecond(20);
        dispatch(getAllHakwons());
    }
    const handleGetOneHakwons = () => {
        setSecond(2);
        dispatch(getOneHakwon({ hName: inputHname }));
    }
    const handleViewHakwon = (hak) => {
        setOpen(true);
        setHakwon(hak)
    }
    const handleSave = () => {
        setOpen(false);
        let newData = {
            accountBank: hakwon.accountBank,
            accountNumber: hakwon.accountNumber,
            accountOwner: hakwon.accountOwner,
            accountNIN: hakwon.accountNIN,
            address: hakwon.address,
            area: hakwon.area,
            blog: hakwon.blog,
            contacts: hakwon.contacts,
            facebook: hakwon.facebook,
            hName: hakwon.hName,
            hakwonId: hakwon.hakwonId,
            inUse: hakwon.inUse,
            instagram: hakwon.instagram,
            isOpen: hakwon.isOpen,
            ownerId: hakwon.ownerId,
            partnerType: hakwon.partnerType
        }
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(updateHakwonInfo(hakwon.hakwonId, newData));
            setHakwon(initial);
        }

    }
    const onChangeSwitch = (e) => {
        setHakwon({
            ...hakwon,
            [e.target.name]: e.target.checked
        })
    }
    const onChangeValue = e => {
        setHakwon({
            ...hakwon,
            [e.target.name]: e.target.value
        })
    }
    const onChangeAddress = e => {
        setHakwon({
            ...hakwon,
            address: {
                ...hakwon.address,
                [e.target.name]: e.target.value
            }
        })
    }
    const onChangeArea = e => {
        setHakwon({
            ...hakwon,
            area: {
                ...hakwon.area,
                [e.target.name]: e.target.value
            }
        })
    }
    const onChangeContacts = e => {
        setHakwon({
            ...hakwon,
            contacts: {
                ...hakwon.contacts,
                [e.target.name]: e.target.value
            }
        })
    }
    // toggle
    const [sortType, setSortType] = useState('d7');
    const toggle = (no) => {
        if (sortType === 'a' + no) {
            setSortType('d' + no);
        } else {
            setSortType('a' + no);
        }
    }

    const handleDeletePhone = (phone) => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            if (window.confirm(phone + ' 번호를 삭제하시겠습니까?')) {
                dispatch(deleteOwnerPhone(hakwon.hakwonId, phone, hakwon.hName));
                setOpen(false);
            } else {
                return false;
            }
        }


    }
    const handleAddPhone = (phone) => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            if (window.confirm(phone + ' 번호를 추가하시겠습니까?')) {
                dispatch(addOwnerPhone(hakwon.hakwonId, phone, hakwon.hName));
                setOpen(false)
            } else {
                return false;
            }
        }

    }
    const handleSaveToPC = jsonData => {

        const fileData = JSON.stringify(jsonData);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'hakwons.json';
        link.href = url;
        link.click();
    }
    // image 업로드
    const handleAddPicture = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    }
    const handleImageChange = (e) => {
        const image = e.target.files[0];
        // send to the server
        const formData = new FormData();    // FormData()를 이용하여 전송
        formData.append('image', image, image.name);

        handleUploadImage(formData);
    }
    const handleUploadImage = (formData) => {
        if (url) {

            dispatch(uploadBoardImage(formData));
        }

    }

    const handleUpdateImageData = (url) => {
        if (url) {
            let old = hakwon.accountImages ? hakwon.accountImages : [];
            let newData = [...old, url];
            setTempUrl('');
            dispatch(updateHakwonInfo(hakwon.hakwonId, { accountImages: newData, hName: hakwon.hName }));
        } else {
            alert('url이 잘못되었습니다')
        }
    }
    const handleDeleteImageData = (index) => {
        let old = hakwon.accountImages ? hakwon.accountImages : [];
        let newData = old.splice(index, 1);

        dispatch(updateHakwonInfo(hakwon.hakwonId, { accountImages: newData, hName: hakwon.hName }));
    }

    const filteredInfos = adminhakwons?.filter(h => h.isOpen === true)
        .sort((a, b) => sortType === 'd1' ? (b.area.area1 > a.area.area1 ? 1 : -1) : sortType === 'a1' ? (a.area.area1 > b.area.area1 ? 1 : -1)
                : sortType === 'd2' ? (b.area.area2 > a.area.area2 ? 1 : -1) : sortType === 'a2' ? (a.area.area2 > b.area.area2 ? 1 : -1)
                : sortType === 'd3' ? (b.hName > a.hName ? 1 : -1) : sortType === 'a3' ? (a.hName > b.hName ? 1 : -1)
                : sortType === 'd4' ? (b.ownerName > a.ownerName ? 1 : -1) : sortType === 'a4' ? (a.ownerName > b.ownerName ? 1 : -1)
                : sortType === 'd5' ? (b.joinYN > a.joinYN ? 1 : -1) : sortType === 'a5' ? (a.joinYN > b.joinYN ? 1 : -1)
                : sortType === 'd6' ? (b.teachers - a.teachers) : sortType === 'a6' ? (a.teachers - b.teachers)
                : sortType === 'd7' ? (b.users - a.users) : sortType === 'a7' ? (a.users - b.users)
                : sortType === 'd8' ? (b.students - a.students) : sortType === 'a8' ? (a.students - b.students)
                : sortType === 'd9' ? (b.lessons - a.lessons) : sortType === 'a9' ? (a.lessons - b.lessons)
                :null

        )




    return (
        <Box m={2} p={2} align='center'>
            <Typography variant='subtitle1' gutterBottom>학원 현황</Typography>
            <TextField label='학원명 검색(OO원)' value={inputHname} onChange={(e) => setInputHname(e.target.value)} />
            <Button variant='contained' onClick={handleGetOneHakwons} style={{ marginLeft: 10, marginRight: 10 }}>검색</Button>
            <Button variant='contained' onClick={handleGetAllHakwons}>전체 조회</Button>
            {
                adminLoading && <div><CenterLoading /><Typography color='primary' variant='caption'>로딩 중... {second}초 남음</Typography></div>
            }

            <Box height={10} />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.padding}>no</TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(1)}
                                    endIcon={sortType === 'a1' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd1' && <ArrowDropUp color='secondary' />
                                    }>시/도</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(2)}
                                    endIcon={sortType === 'a2' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd2' && <ArrowDropUp color='secondary' />
                                    }>시/군/구</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(3)}
                                    endIcon={sortType === 'a3' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd3' && <ArrowDropUp color='secondary' />
                                    }>가맹원 상세정보</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(4)}
                                    endIcon={sortType === 'a4' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd4' && <ArrowDropUp color='secondary' />
                                    }>
                                    원장이름</Button>
                            </TableCell>

                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(5)}
                                    endIcon={sortType === 'a5' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd5' && <ArrowDropUp color='secondary' />
                                    }>원장가입여부</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(6)}
                                    endIcon={sortType === 'a6' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd6' && <ArrowDropUp color='secondary' />
                                    }>가입교사수</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(7)}
                                    endIcon={sortType === 'a7' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd7' && <ArrowDropUp color='secondary' />
                                    }>학부모고객</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>    <Button
                                onClick={() => toggle(8)}
                                endIcon={sortType === 'a8' ? <ArrowDropDown color='primary' />
                                    : sortType === 'd8' && <ArrowDropUp color='secondary' />
                                }>학생수</Button>
                            </TableCell>
                            <TableCell className={classes.padding}>
                                <Button
                                    onClick={() => toggle(9)}
                                    endIcon={sortType === 'a9' ? <ArrowDropDown color='primary' />
                                        : sortType === 'd9' && <ArrowDropUp color='secondary' />
                                    }>생성수업수</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredInfos.map((hak, index) =>
                                <TableRow key={index}>
                                    <TableCell size='small' className={classes.padding}>{index + 1}</TableCell>
                                    <TableCell size='small' className={classes.padding}>{hak.area.area1}</TableCell>
                                    <TableCell size='small' className={classes.padding}>{hak.area.area2}</TableCell>
                                    <TableCell size='small' className={classes.padding}><Button onClick={() => handleViewHakwon(hak)} color='secondary'>{hak.hName}</Button></TableCell>
                                    <TableCell size='small' className={classes.padding}>{hak.ownerName}</TableCell>
                                    <TableCell size='small' className={classes.padding}>{hak.joinYN ?
                                        <Typography variant='body2' color='secondary' display='inline'>원장가입</Typography>
                                        : <Typography color='error' variant='body2' display='inline'>미가입</Typography>}</TableCell>
                                    <TableCell size='small' className={classes.padding} align='center'>{hak.teachers > 0 ? <b>{hak.teachers}</b> : 0}</TableCell>
                                    <TableCell size='small' className={classes.padding} align='center'>{hak.users > 0 ? <b>{hak.users}</b> : 0}</TableCell>
                                    <TableCell size='small' className={classes.padding} align='center'>{hak.students > 0 ? <b>{hak.students}</b> : 0}</TableCell>
                                    <TableCell size='small' className={classes.padding} align='center'>{hak.lessons > 0 ? <b>{hak.lessons}</b> : 0}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>



            <Box height={220} />
            {
                adminhakwons?.length > 2 ? <Box>
                    <Typography>총 {adminhakwons?.filter(h => h.isOpen === true).length}개 학원 중, {adminhakwons?.filter(h => h.isOpen === true && h.joinYN === true).length}개원 원장가입,</Typography>
                    <Typography>{adminhakwons?.filter(h => h.isOpen === true).reduce((acc, cur) => (acc + cur.teachers), 0)}명 교사 등록(
                        {adminhakwons?.filter(h => h.isOpen === true && h.teachers > 0).length}개원
                    ),
                    {adminhakwons?.filter(h => h.isOpen === true).reduce((acc, cur) => (acc + cur.users), 0)}명 학부모 등록(
                        {adminhakwons?.filter(h => h.isOpen === true && h.users > 0).length}개원
                    ),
                    {adminhakwons?.filter(h => h.isOpen === true).reduce((acc, cur) => (acc + cur.lessons), 0)}개 수업생성(
                        {adminhakwons?.filter(h => h.isOpen === true && h.lessons > 0).length}개원
                    )
                    </Typography>
                    <Button color='primary' onClick={() => handleSaveToPC(adminhakwons)}>상세정보 json 파일 다운로드</Button><br />
                    <Typography variant='caption'>json to excel로 컨버팅해서 사용하세요. <a href='http://convertcsv.com/json-to-csv.htm' target='_blank' rel="noopener noreferrer">http://convertcsv.com/json-to-csv.htm</a></Typography>
                </Box> : null

            }

            <Dialog fullWidth maxWidth='md' open={open}>
                <DialogTitle style={{ backgroundColor: '#23a9f2' }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
                        <CloseRounded />
                    </IconButton>
                    <Typography variant='h5'>{hakwon.hName}</Typography>
                    <Typography variant='subtitle2' color='textSecondary'>{hakwon.hakwonId}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box p={2} m={2}>

                        <TextField label='구분' value={hakwon.partnerType} name='partnerType' onChange={onChangeValue} fullWidth />
                        <TextField label='시도' value={hakwon.area?.area1} name='area1' onChange={onChangeArea} fullWidth />
                        <TextField label='시군구' value={hakwon.area?.area2} name='area2' onChange={onChangeArea} fullWidth />
                        <TextField label='주소1' value={hakwon.address?.juso1} name='juso1' onChange={onChangeAddress} fullWidth />
                        <TextField label='주소2' value={hakwon.address?.juso2} name='juso2' onChange={onChangeAddress} fullWidth />
                        <TextField label='주소3' value={hakwon.address?.juso3} name='juso3' onChange={onChangeAddress} fullWidth />
                        <TextField label='핸드폰' value={hakwon.contacts?.mobile} name='mobile' onChange={onChangeContacts} fullWidth />
                        <TextField label='전화번호' value={hakwon.contacts?.tel} name='tel' onChange={onChangeContacts} fullWidth />
                        <TextField label='원장이름' value={hakwon.ownerName} name='ownerName' onChange={onChangeValue} fullWidth />
                        <Box height={10} />
                        <Typography variant='subtitle2' gutterBottom>등급부여 전화번호</Typography>
                        <Typography variant='caption' color='textSecondary' gutterBottom>* 이 번호를 통해 학원장 레벨(level2) Batch작업을 수행합니다.</Typography><br />
                        <Typography variant='caption' color='textSecondary' gutterBottom>* 저장 버튼 누르기 전, 직접 여기에서 직접 추가/삭제 관리합니다.</Typography><br />
                        {
                            hakwon.ownerPhone.map(pho => <Chip
                                label={pho} variant="outlined" color="primary" deleteIcon={<DeleteRounded />} onDelete={() => handleDeletePhone(pho)} />
                            )
                        }
                        <TextField variant='outlined' size='small' label='번호 추가(-포함)' value={addPhone} onChange={e => setAddPhone(e.target.value)} />
                        <Button color='primary' variant='contained' onClick={() => handleAddPhone(addPhone)}>추가</Button>

                        <TextField label='인스타' value={hakwon.instagram} name='instagram' onChange={onChangeValue} fullWidth />
                        <TextField label='페이스북' value={hakwon.facebook} name='facebook' onChange={onChangeValue} fullWidth />
                        <TextField label='블로그' value={hakwon.blog} name='blog' onChange={onChangeValue} fullWidth />

                        <FormControlLabel
                            control={<Switch checked={hakwon.isOpen} onChange={onChangeSwitch} name="isOpen" />}
                            label={hakwon.isOpen ? "오픈" : "미 오픈"}
                        /><br />
                        <FormControlLabel
                            control={<Switch checked={hakwon.inUse} onChange={onChangeSwitch} name="inUse" />}
                            label={hakwon.inUse ? "서비스사용" : "미 사용"}
                        /><br />
                        <Box height={20} />
                        <Typography variant='subtitle1'>정산계좌</Typography>
                        <TextField label='은행' value={hakwon.accountBank} name='accountBank' onChange={onChangeValue} fullWidth />
                        <TextField label='계좌번호' value={hakwon.accountNumber} name='accountNumber' onChange={onChangeValue} fullWidth />
                        <TextField label='예금주' value={hakwon.accountOwner} name='accountOwner' onChange={onChangeValue} fullWidth />
                        <TextField label='주민번호' value={hakwon.accountNIN} name='accountNIN' onChange={onChangeValue} fullWidth />
                        <Typography>이미지자료</Typography>
                        {
                            hakwon.accountImages && hakwon.accountImages.length > 0 ? hakwon.accountImages.map((v, i) => <Box key={i}><Button size='small' variant='contained' onClick={() => window.open(v, '자료_' + Number(i + 1), "location=no")}>{'자료_' + Number(i + 1)}</Button><IconButton onClick={() => handleDeleteImageData(i)}><DeleteRounded /></IconButton></Box>) : null
                        }
                        <Box>
                            <Box>
                                <Button size='small' color='primary' variant='contained' onClick={handleAddPicture}>+이미지</Button>
                                <input
                                    type='file'
                                    id='imageInput'
                                    hidden='hidden'
                                    onChange={handleImageChange} />
                            </Box>
                            <Box>
                                <Typography variant='caption' id='url'>{tempUrl}</Typography>
                                {
                                    managerLoading ? <CenterLoading /> : tempUrl && <img src={tempUrl} alt='URL' />
                                }
                                {

                                    tempUrl && <Button size='small' color='primary' variant='contained' onClick={() => { handleUpdateImageData(tempUrl); setOpen(false) }}>등록하고 닫기</Button>
                                }
                            </Box>





                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus={false} onClick={() => setOpen(false)} color="primary" variant='outlined'>
                        닫기
                    </Button>
                    <Button onClick={handleSave} color="primary" variant='contained' startIcon={<SaveRounded />}>
                        변경사항 저장
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );

}


export default withStyles(styles)(AdminHakwon);

