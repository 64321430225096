/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
// Redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { getKakaoLinks } from '../../redux/actions/adminActions';
// components
import KakaoLink from './KakaoLink';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Box, Typography, TextField, Button, Grid } from '@material-ui/core';
import { postKakaoLink } from '../../redux/actions/adminActions';
//import { InboxOutlined } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
});
export const AdminKakaoLink = (props) => {
    // const { classes } = props;
    const { rnr } = useSelector(state => state.user);
    const { adminKakaolinks } = useSelector(state => state.admin);
    const dispatch = useDispatch();
    const [kakaolinks, setKakaolinks] = useState([]);
    const [hakwonName,] = useState('');
    const defaultTitle = '제목은 템플릿에서 기록해주세요'
    const defaultDescription = '템플릿에 대한 설명입니다.';
    const [title, setTitle] = useState(defaultTitle);
    const [description, setDescription] = useState(defaultDescription);
    const [imageurl, setImageurl] = useState('템플릿에서 이미지를 캡쳐하여, 이미지url을 등록해주세요.')
    const [template, setTemplate] = useState(42275);
    const [sendUrl, setSendUrl] = useState('https://musicgogo.co.kr');
    const [sendText, setSendText] = useState('200자이내');
    //
    const [btitle, setBtitle] = useState('제목');
    const [bdesc, setBdesc] = useState('상세');
    const [bimage, setBimage] = useState('url');
    const [blink, setBlink] = useState('게시글번호');
    const [bbtn, setBbtn] = useState('버튼명');
    // feed형
    const [feed, setFeed] = useState({
        title: '제목',
        description: '상세',
        imageUrl: '이미지url',
        button1Title: '버튼명',
        button1Link: '링크 url',
        button2Title: '버튼명(미사용시 삭제필요)',
        button2Link: '링크 url(미사용시 삭제필요)'   // [{title, link:{mobileWebUrl, webUrl}, {...} ]
    });

    let name = 'test원';


    useEffect(() => {
        dispatch(getKakaoLinks());
    }, [dispatch]);

    useEffect(() => {
        if (adminKakaolinks) {
            setKakaolinks(adminKakaolinks);
        }
    }, [adminKakaolinks]);

    function sendLink() {
        const Kakao = window.Kakao;
        if (!Kakao.isInitialized()) {
            Kakao.init('be5ea3edf2d75970788c5adabba77a38');

        };
        Kakao.Link.sendCustom({
            templateId: Number(template),
            templateArgs: {
                hName: hakwonName,
                title: title.replace('${name}', name),
                description: description.replace('${name}', name)
            },
            serverCallbackArgs: {
                hName: hakwonName
            }
        });
    }
    /*    const sendDummy = ()=>{
           const Kakao = window.Kakao;
           if (!Kakao.isInitialized()) {
               Kakao.init('be5ea3edf2d75970788c5adabba77a38');
   
           };
           Kakao.Link.sendCustom({
               templateId: Number(template),
               templateArgs: {
                   title: "",
                   description: ""
               }      
           });
       } */

    const sendCustomLink = () => {
        const Kakao = window.Kakao;
        if (!Kakao.isInitialized()) {
            Kakao.init('be5ea3edf2d75970788c5adabba77a38');

        };
        Kakao.Link.sendDefault({
            objectType: 'text',
            text: sendText,
            link: {
                mobileWebUrl: sendUrl,
                webUrl: sendUrl,
            },
        });
    }
    const sendCustomFeedLink = () => {
        const Kakao = window.Kakao;
        if (!Kakao.isInitialized()) {
            Kakao.init('be5ea3edf2d75970788c5adabba77a38');
        };
        const btns = feed.button2Title === '' ? [{ title: feed.button1Title, link: { mobileWebUrl: feed.button1Link, webUrl: feed.button1Link } }]
            : [{ title: feed.button1Title, link: { mobileWebUrl: feed.button1Link, webUrl: feed.button1Link } }, { title: feed.button2Title, link: { mobileWebUrl: feed.button2Link, webUrl: feed.button2Link } }];

        Kakao.Link.sendDefault({

            objectType: 'feed',
            content: {
                title: feed.title,
                description: feed.description,
                imageUrl: feed.imageUrl,
                link: {
                    mobileWebUrl: feed.button1Link,
                    webUrl: feed.button1Link,
                },
            },
            /* social: {
                likeCount: 286,
                commentCount: 45,
                sharedCount: 845,
            }, */
            buttons: btns
            /* objectType: 'feed',
            content: {
                title: feed.title,
                description: feed.description,
                imageUrl: feed.imageUrl,
            },
            buttons: btns */
        });
    }
    const sendBoard = () => {
        const Kakao = window.Kakao;
        if (!Kakao.isInitialized()) {
            Kakao.init('be5ea3edf2d75970788c5adabba77a38');

        };
        Kakao.Link.sendCustom({
            templateId: 42846,
            templateArgs: {
                titel: btitle,
                description: bdesc,
                THU: bimage,
                link: blink,
                BUT: bbtn
            },
            serverCallbackArgs: {
                description: bdesc
            }
        });
    }
    const saveToDB = (both, type, template) => {
        let data = {
            title: btitle,
            description: bdesc,
            image: bimage,
            link: blink,
            button: bbtn,
            template: template,
            type: type,
            where: both
        }
        dispatch(postKakaoLink(data));
    }
    const saveToDB2 = (both, type, template) => {
        let data = {
            title: title,
            description: description,
            template: template,
            type: type,
            where: both
        }
        dispatch(postKakaoLink(data));
    }
    const saveToDB3 = (both, type, template)=>{
        const data = {
            title: feed.title,
            description: feed.description,
            template: template,
            type: type,
            where: both,
            //
            image: feed.imageUrl,
            link: feed.button1Link,
            button: feed.button1Title,
            link2: feed.button2Link,
            button2: feed.button2Title
        }
        dispatch(postKakaoLink(data));
    }


    return (
        <Box p={1} m={1}>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box m={1} p={1}>
                        <Typography color='primary' variant='h4'>카카오톡 공지</Typography>
                        <img src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png" alt='kakao' />
                        <Box p={1} m={1}>
                            <Typography variant='subtitle2'>1. 템플릿 유형</Typography>
                            <Typography variant='body2'>- 템플릿을 미리작성한 후 선택하여 보냅니다</Typography>
                            <Typography variant='body2'>- 템플릿 제작/보기는 <a href='https://developers.kakao.com/tool/template-builder/app/383879' target='_blank' rel='noopener noreferrer'>[링크]</a> 참조 </Typography>
                            <Typography variant='body2'>- 템플릿 제작 권한은 관리자에게 요청</Typography>
                            <Typography variant='body2'>- 미리보기가 불가능합니다. 나에게 보내기한 후, 확인하고 발송합니다.</Typography>
                            <Box height={20} />
                            <TextField
                                label='템플릿번호'
                                value={template}
                                onChange={e => setTemplate(e.target.value)}
                                fullWidth
                            /><br />
                            <TextField
                                label='관리이름(발송이 아니라 표시용임)'
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                fullWidth
                            /><br />
                            <TextField
                                label='내용 설명 (원장님안내)'
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                fullWidth
                                multiline
                            />
                            <TextField
                                label='이미지 캡쳐 (가로 264)'
                                value={imageurl}
                                onChange={e => setImageurl(e.target.value)}
                                fullWidth
                            /><br />
                            <Box height={5} />
                            <Button id="send-to-btn" onClick={sendLink} variant='contained'>
                                즉시 카카오톡 보내기
                            </Button>
                            <Button onClick={() => saveToDB2('db', '지정템플릿', template)} variant='contained' style={{ marginLeft: 5 }}>DB에만 저장</Button>
                            <Button onClick={() => saveToDB2('both', '지정템플릿', template)} variant='contained' color='secondary' style={{ marginLeft: 5 }}>학원메인으로</Button>
                        </Box>
                        <Box height={20} />
                        <Box p={1} m={1}>
                            <Typography variant='subtitle2'>2. 직접작성</Typography>
                            <Typography variant='body2'>- 직접 텍스트를 작성하여 보낼 수 있습니다.</Typography>
                            <Box height={20} />
                            <TextField
                                label='보낼 텍스트(200자이내로, 줄바꿈가능)'
                                value={sendText}
                                onChange={e => setSendText(e.target.value)}
                                fullWidth
                                multiline
                                rowsMax={5}
                            /><br />
                            <TextField
                                label='버튼 URL'
                                value={sendUrl}
                                onChange={e => setSendUrl(e.target.value)}
                                fullWidth
                            /><br />

                            <Box height={5} />
                            <Button id="send-to-btn" onClick={sendCustomLink} variant='contained'>
                                텍스트로 카카오톡 보내기
                            </Button>
                        </Box>
                        <Box p={1} m={1}>
                            <Typography variant='subtitle2'>3. 게시판 공유</Typography>
                            <Typography variant='body2'>- 학원관리에서 생성된 공지글을 버튼으로 첨부하여 이미지와 함께 내용을 보내실 수 있습니다.</Typography>
                            <Typography variant='body2'>- 게시글 번호는 게시글보기에서 'url복사'버튼을 누르면 나오는 링크의 ~view/ 이후의 마지막 인자입니다.</Typography>
                            <Box height={20} />
                            <TextField
                                label='타이틀'
                                value={btitle}
                                onChange={e => setBtitle(e.target.value)}
                                fullWidth
                            /><br />
                            <TextField
                                label='내용'
                                value={bdesc}
                                onChange={e => setBdesc(e.target.value)}
                                fullWidth
                                multiline
                            />
                            <TextField
                                label='이미지 (가로 264)'
                                value={bimage}
                                onChange={e => setBimage(e.target.value)}
                                fullWidth
                            /><br />
                            <TextField
                                label='버튼이름'
                                value={bbtn}
                                onChange={e => setBbtn(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                label='링크_게시글 번호(url복사의 마지막일련번호)'
                                value={blink}
                                onChange={e => setBlink(e.target.value)}
                                fullWidth

                            />
                            <Box height={5} />
                            <Button id="send-btn" onClick={sendBoard} variant='contained'>
                                저장없이 보내기
                            </Button>
                            <Button onClick={() => saveToDB('db', '게시판템플릿', 42846)} variant='contained' style={{ marginLeft: 5 }}>DB에만 저장</Button>
                            <Button onClick={() => saveToDB('both', '게시판템플릿', 42846)} variant='contained' color='secondary' style={{ marginLeft: 5 }}>학원메인으로</Button>
                        </Box>

                        <Box p={1} m={1}>
                            <Typography variant='subtitle2'>4. 피드형 컨텐츠 발송</Typography>
                            <Typography variant='body2'>- 이미지/제목/설명/버튼 두개(가로형태).</Typography>
                            <Typography variant='body2'>- 이때는 링크를 https://로 시작하는 full url을 써주시고, 외부 링크도 가능합니다.</Typography>
                            <Box height={20} />
                            <TextField
                                label='타이틀'
                                value={feed.title}
                                onChange={e => setFeed({ ...feed, title: e.target.value })}
                                fullWidth
                            /><br />
                            <TextField
                                label='상세내용'
                                value={feed.description}
                                onChange={e => setFeed({ ...feed, description: e.target.value })}
                                fullWidth
                                multiline
                            />
                            <TextField
                                label='이미지 (가로 264)'
                                value={feed.imageUrl}
                                onChange={e => setFeed({ ...feed, imageUrl: e.target.value })}
                                fullWidth
                            /><br />
                            <TextField
                                label='버튼1 제목 (짧게)'
                                value={feed.button1Title}
                                onChange={e => setFeed({ ...feed, button1Title: e.target.value })}
                                fullWidth
                            />
                            <TextField
                                label='버튼1 URL (https://로 시작하는 full url)'
                                value={feed.button1Link}
                                onChange={e => setFeed({ ...feed, button1Link: e.target.value })}
                                fullWidth
                            />
                            <TextField
                                label='버튼2 제목(짧게)'
                                value={feed.button2Title}
                                onChange={e => setFeed({ ...feed, button2Title: e.target.value })}
                                fullWidth
                            />
                            <TextField
                                label='버튼2 URL (https://로 시작하는 full url)'
                                value={feed.button2Link}
                                onChange={e => setFeed({ ...feed, button2Link: e.target.value })}
                                fullWidth
                            />


                            <Box height={5} />
                            <Button id="send-btn" onClick={sendCustomFeedLink} variant='contained'>
                                저장없이 보내기
                            </Button>
                            <Button onClick={() => saveToDB3('db', 'feed', null)} variant='contained' style={{ marginLeft: 5 }}>DB에만 저장</Button>
                            <Button onClick={() => saveToDB3('both', 'feed', null)} variant='contained' color='secondary' style={{ marginLeft: 5 }}>학원메인으로</Button>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} m={1} align='center'>
                        <Typography color='primary' variant='h4'>저장된 메세지</Typography>
                        <Box height={20} />
                        {

                            kakaolinks.length > 0 ? kakaolinks.sort((a,b)=>b.createdAt-a.createdAt).map((kakao, index) => <Box key={index}>
                                <KakaoLink
                                    data={kakao}
                                    hName={'학원명'}
                                    rnr={rnr}
                                />
                            </Box>) : null
                        }
                    </Box>

                </Grid>
            </Grid>
        </Box>





    );
};



export default withStyles(styles)(AdminKakaoLink);

