import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const LoginRoute = ({authenticated, ...props}) => {
    let markup = !authenticated ? <Redirect to={`/login?${window.location.pathname}`} /> : <Route {...props} />; 
    return <div>
        {markup}
    </div>;
}
const mapStateToProps = state => ({
    // get the single info from Redux user state
    authenticated: state.user.authenticated
})
export default connect(mapStateToProps)(LoginRoute);