import React from 'react';
import { Link } from 'react-router-dom';
// Redux stuff
//import { useSelector, useDispatch } from 'react-redux';
// components
import Navbar from '../../components/layout/Navbar';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Grid, Typography, Box, Button, Link as MuiLink, Paper } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    paper: {
        padding: theme.spacing(4)
    }
});
export const PaymentResult = (props) => {
    const { classes } = props;
    // const user = useSelector(state => state.user);
    // const dispatch = useDispatch();
    // // qurey string to <json>
    const query = QueryStringToJSON(window.location.search.slice(1).split('&'));
    
    const { merchant_uid, error_msg, imp_success } = query;
    //console.log(merchant_uid, error_msg, imp_uid, imp_success, error_code);


    const isSuccessed = getIsSuccessed();
    function getIsSuccessed() {

        if (typeof imp_success === 'string') return imp_success === 'true';
        if (typeof imp_success === 'boolean') return imp_success === true;
    }
    const resultType = isSuccessed ? '성공' : '실패';

    return (
        <>
            <Navbar />
            <div className='container'>
                <Box p={1} m={1} align='center'>
                    <Grid container>
                        <Grid item sm />
                        <Grid item sm style={{ textAlign: 'center' }}>
                            <Box height={40} />
                            <Typography variant='h4'>결제 {resultType}</Typography>
                            <Box height={40} />
                            <Typography variant='subtitle2' color='primary'>결제에 {resultType}하였습니다!</Typography>
                            <Box height={40} />
                            <Paper className={classes.paper}>
                                <Typography display='inline'>주문번호 : </Typography>
                                <Typography display='inline'>{merchant_uid} </Typography>
                            </Paper>
                            <Box height={20} />
                            {
                                !isSuccessed && <Typography color='error'>{error_msg}</Typography>
                            }
                            <Box height={40} />
                            <Typography variant='subtitle2' color='primary'>상세 주문 내역 및 배송 현황은</Typography>
                            <Typography variant='subtitle2' color='primary'><MuiLink href='/myclass'>마이클래스에서</MuiLink> 확인하실수 있습니다.</Typography>
                            <Box height={20} />
                            <Button variant='contained' color='primary' component={Link} to='/'>홈으로 돌아가기</Button>

                        </Grid>
                        <Grid item sm />
                    </Grid>
                </Box>

            </div>
        </>
    );
};

function QueryStringToJSON(pairs) {


    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}

export default withStyles(styles)(PaymentResult);

