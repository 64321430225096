import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


const AuthRoute = ({authenticated, ...props}) => {
    const dr = localStorage.getItem('redir');
    //console.log(dr);
    let markup = authenticated ? <Redirect to={dr ? dr : '/'} /> : <Route {...props} />; 
    return <div>
        {markup}
    </div>;
}
const mapStateToProps = state => ({
    // get the single info from Redux user state
    authenticated: state.user.authenticated
})
export default connect(mapStateToProps)(AuthRoute);

