import React, { useEffect } from 'react';
// Redux stuff
import { useDispatch, useSelector } from 'react-redux';
// mui
import { Box, Dialog, DialogContent, IconButton, useMediaQuery } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import useTheme from '@material-ui/styles/useTheme';
import { getEvents } from '../redux/actions/dataActions';
import dayjs from 'dayjs';

const Event = (props) => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const { events } = useSelector(state => state.data);

    // 페이지를 열때, events (data리듀서) 를 가져온다
    useEffect(() => {
        dispatch(getEvents());
    }, [dispatch]);

    // 이벤트 기간인 것만 가져온다.
    const thisEvent = events.filter(ev => ev.startDatetime < dayjs() / 1000 && ev.endDatetime > dayjs() / 1000);

    const event_banner = thisEvent.length>0 ? thisEvent[0].bannerImageUrl : null;
    const event_url = thisEvent.length>0 ? thisEvent[0].mainImageUrl : null;
    const banner_backgroundcolor = thisEvent.length>0 ? thisEvent[0].bannerBackgroundColor : null;

    return (<Box>
        {thisEvent.length>0 &&
            <Box>
                <Box align='center' style={{ backgroundColor: banner_backgroundcolor }} onClick={() => setOpen(true)}>
                    {/* 배너 위치 bannerImageUrl*/}
                    <img src={event_banner} alt='event' width='100%' style={{ maxHeight: 210, maxWidth: 450, cursor:'pointer' }} />
                    <Box height={10} />
                </Box>
                {/* 클릭시 다이어로그 팝업 */}
                <Dialog open={open} fullScreen={matches} style={{ margin: 0, padding: 0 }}>
                    <DialogContent style={{ margin: 0, padding: 0 }}>
                        <IconButton aria-label="close" style={{ position: 'absolute', right: 10, top: 60 }} onClick={() => setOpen(false)}>
                            <CancelRounded color='primary' />
                        </IconButton>
                        {/* maingImagUrl */}
                        <img src={event_url} alt='event' width='100%' />
                        <IconButton aria-label="close" style={{ position: 'absolute', right: 10, bottom: 20 }} onClick={() => setOpen(false)}>
                            <CancelRounded color='primary' />
                        </IconButton>
                    </DialogContent>
                </Dialog>
            </Box>
        }
    </Box>);
}

export default Event;