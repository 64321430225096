import React from 'react';
import { Link } from 'react-router-dom';
// mui
import { withStyles } from '@material-ui/core/styles';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Tabs, Tab, Toolbar, Button } from '@material-ui/core';
import { LibraryAddCheckRounded } from '@material-ui/icons';
import MonthlyPlan from './MonthlyPlan';



const styles = (theme) => ({
    ...theme.userPage,
    root: {
        margin: 0,
        maxWidth: 1300,
    },
    tabs: {
        margin: 0
    }

});

const monthlyTabs = [
    { id: 1, label: "1월", link: '/gogo/curriculum/month/1' },
    { id: 2, label: "2월", link: '/gogo/curriculum/month/2' },
    { id: 3, label: "3월", link: '/gogo/curriculum/month/3' },
    { id: 4, label: "4월", link: '/gogo/curriculum/month/4' },
    { id: 5, label: "5월", link: '/gogo/curriculum/month/5' },
    { id: 6, label: "6월", link: '/gogo/curriculum/month/6' },
    { id: 7, label: "7월", link: '/gogo/curriculum/month/7' },
    { id: 8, label: "8월", link: '/gogo/curriculum/month/8' },
    { id: 9, label: "9월", link: '/gogo/curriculum/month/9' },
    { id: 10, label: "10월", link: '/gogo/curriculum/month/10' },
    { id: 11, label: "11월", link: '/gogo/curriculum/month/11' },
    { id: 12, label: "12월", link: '/gogo/curriculum/month/12' }
];



function GogoCurriculum(props) {
    const { classes } = props;

    // 시작월 설정 - 다음달을 보여 줌 (구매유도 목적)
    const initialMonth = new Date().getMonth() + 1;

    const [value, setValue] = React.useState(initialMonth===12 ? 0 : initialMonth);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box className={classes.root}>
            <Toolbar style={{ marginRight: 0, marginLeft: 0 }}>
                <Tabs
                    orientation='horizontal'
                    variant="scrollable"
                    scrollButtons="auto"
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                    className={classes.tabs}
                    indicatorColor="secondary"
                //fullWidth
                >
                    {
                        monthlyTabs.map((t, index) => <StyledTab key={index} label={t.label} {...a11yProps(index)} />)
                    }


                </Tabs>
            </Toolbar>
            {
                monthlyTabs.map((t, i) =>
                    <TabPanel value={value} index={i} key={i}>
                        <MonthlyPlan month={i} />
                    </TabPanel>
                )
            }
            <Box m={3} align='center'>
                <Button color='primary' variant='contained' size='large' startIcon={<LibraryAddCheckRounded />} component={Link} to='/lesson'>수업 신청 하기</Button>
            </Box>

        </Box>
    )
}

export default withStyles(styles)(GogoCurriculum);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box component='div' style={{ paddingTop: 10, paddingBottom: 10, maginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 80, // 60
        height: 40,
        backgroundColor: '#fff',
        borderBottom: '1px solid',
        borderBottomColor: '#dbdbdb',
        fontWeight: theme.typography.fontWeightRegular,
        margin: 0,
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&$selected': {
            color: '#fff',
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.secondary.main
        },
        '&:focus': {
            color: '#fff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);