import React, { useState, useEffect } from 'react';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonInfo, getSalesByHakwon, getAllProductGroup } from '../../redux/actions/managerActions';
import { getUserData, isLogged } from '../../redux/actions/userActions';
// components
import LevelCheck from './LevelCheck';
import CenterLoading from '../../components/layout/CenterLoading';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import { Typography, Divider, Button, Box, Checkbox, TextField, Hidden, FormControl, RadioGroup, Radio, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { NotAllowed } from './NotAllowed';
import dayjs from 'dayjs';
import { HelpRounded } from '@material-ui/icons';


const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    date: {
        backgroundColor: '#FAF3E7',
        width: 90,
        margin: theme.spacing(1)
    },
    btn: {
        marginLeft: theme.spacing(6),
        marginTop: theme.spacing(1)
    },
    text: {
        verticalAlign: 'center'
    },
    table: {
        padding: 3
    },
    padding: {
        paddingRight: 3,
        paddingLeft: 3,
    },
});
// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('뮤직고고체험수업', 159, 6.0, 24, 4.0),
//     createData('피아노고고 체험수업', 237, 9.0, 37, 4.3),
//     createData('뮤직고고', 262, 16.0, 24, 6.0),
//     createData('뮤직고고 정기결제', 305, 3.7, 67, 4.3),
//     createData('뮤직고고 프리미엄', 356, 16.0, 49, 3.9),
// ];

const monthlyProductIds = ['LWftI74DJAzr3h9ZGERH', 'LY2bzzk1kL1jOyssZXDI', 'KiflCPN7CCrr7pTAeJQz', 'WkHIN2b2KZ2316kcjIFs'];

const WhiteRadio = withStyles({
    root: { color: "#666" }
})(Radio);


export const Sales = (props) => {
    const { classes, history } = props;
    const { rnr, credentials } = useSelector(state => state.user);
    const { hakwon, sales, managerLoading } = useSelector(state => state.manager);
    const dispatch = useDispatch();
    const [allowed, setAllowed] = useState(false);
    const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYYMMDD'));
    const [toDate, setToDate] = useState(dayjs().format('YYYYMMDD'));
    const [payType, setPayType] = useState('all');
    const hId = credentials.myHakwon ? credentials.myHakwon?.hakwonId : localStorage.getItem('hId');

    let numDateFrom = dayjs(fromDate) / 1000;
    let numDateTo = dayjs(toDate).add(1, 'day') / 1000;
    const [open, setOpen] = useState(false);
    const [withCancel, setWithCancel] = useState(true);
    const [searchName, setSearchName] = useState('');


    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
        dispatch(getAllProductGroup());
    }, [dispatch]);


    // const searchSales = () => {
    //     let salesId = 'm_1607331202295';
    //     dispatch(getSales(salesId));
    // }
    const handleHakwonSales = () => {
        const fromTo = { from: numDateFrom, to: numDateTo };
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(getSalesByHakwon(hId, fromTo));
        }

    }
    const handleType = (e) => {
        e.preventDefault();
        setPayType(e.target.value);
    }
    const handleChangeWithCancel = () => {
        if (withCancel) {
            setWithCancel(false);
        } else {
            setWithCancel(true);
        }
    }



    const reFiltered = sales
        .filter(sa => sa.createdAt >= numDateFrom && sa.createdAt < numDateTo)
        .filter(sa => withCancel ? true : sa.status === 'paid')
        .filter(sa => searchName === '' ? true : sa.child_name.startsWith(searchName))
        .filter(sa => (monthlyProductIds.indexOf(sa.productId) > -1 && payType !== 'general')
            || (monthlyProductIds.indexOf(sa.productId) === -1 && payType === 'general')
            || payType === 'all'
        );





    return (
        <div>
            <LevelCheck
                checkingTeacherLevel={0}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />
            {
                allowed ?
                    <Box m={1} p={1}>
                        <Box height={20} />
                        <Typography variant='subtitle1' gutterBottom>원생별 결제 현황 조회</Typography>
                        <Divider light />
                        <Box align='right'>
                            <Typography variant='caption' className={classes.marginRL}>{credentials.myHakwon?.hName}</Typography>
                        </Box>
                        <Box m={1} p={1}>
                            <Typography className={classes.text} display='inline'>기간선택:</Typography>

                            <TextField
                                size='small'
                                margin='dense'
                                className={classes.date}
                                style={{ backgroundColor: '#FAF3E7', width: 90 }}
                                required
                                value={fromDate}
                                onChange={e => setFromDate(e.target.value)}
                            />
                            <Typography display='inline' className={classes.text}> ~ </Typography>
                            <TextField
                                size='small'
                                margin='dense'
                                style={{ backgroundColor: '#FAF3E7', width: 90 }}
                                required
                                value={toDate}
                                onChange={e => setToDate(e.target.value)}
                            />
                            <br />
                            <Typography className={classes.text} display='inline'>학생이름:</Typography>
                            <TextField
                                size='small'
                                margin='dense'
                                style={{ backgroundColor: '#FAF3E7', width: 90, marginLeft: 7 }}
                                value={searchName}
                                onChange={e => setSearchName(e.target.value)}
                            />
                            <Button color='primary' size='small' variant='contained' onClick={handleHakwonSales} className={classes.btn}>조회</Button>


                        </Box>

                        <FormControl component="fieldset">
                            <RadioGroup aria-label="category" name="category" value={payType} onChange={handleType} row>
                                <FormControlLabel
                                    value={'all'}
                                    control={<WhiteRadio />}
                                    label={<Typography>전체</Typography>}
                                />
                                <FormControlLabel
                                    value={'general'}
                                    control={<WhiteRadio />}
                                    label={<Typography>일반결제</Typography>}
                                />
                                <FormControlLabel
                                    value={'monthly'}
                                    control={<WhiteRadio />}
                                    label={<Typography>정기결제(매월자동결제)</Typography>}
                                />


                            </RadioGroup>
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox checked={withCancel} onChange={handleChangeWithCancel} name="checked" />} label={withCancel ? "취소포함" : "취소제외"}
                        />
                        <Box m={1} align='right'>
                            <Button size='small' variant='contained' startIcon={<HelpRounded />} className={classes.marginRL} onClick={() => setOpen(true)}>정기결제 도움말</Button>
                        </Box>

                        {
                            managerLoading ? <CenterLoading /> :
                                <Box>
                                    <Hidden only='xs'>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.padding}>No</TableCell>
                                                        <TableCell className={classes.padding}>주문번호</TableCell>
                                                        <TableCell className={classes.padding}>원생이름</TableCell>
                                                        <TableCell className={classes.padding}>결제일</TableCell>
                                                        <TableCell className={classes.padding}>수업이름</TableCell>
                                                        <TableCell className={classes.padding}>카테고리</TableCell>
                                                        <TableCell className={classes.padding}>수업일</TableCell>
                                                        <TableCell className={classes.padding}>금액</TableCell>
                                                        <TableCell className={classes.padding}>상태</TableCell>
                                                        <TableCell className={classes.padding}>학부모명</TableCell>
                                                        <TableCell className={classes.padding}>연락처</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reFiltered.map((row, index) => (
                                                        <TableRow key={row.paymentId}>
                                                            <TableCell className={classes.padding}>{index + 1}</TableCell>
                                                            <TableCell className={classes.padding}>{row.paymentId}</TableCell>
                                                            <TableCell className={classes.padding}>{row.child_name}</TableCell>
                                                            <TableCell className={classes.padding}>{dayjs(row.createdAt * 1000).format('YYYY-MM-DD')}</TableCell>
                                                            <TableCell className={classes.padding}>{row.status === 'cancelled' ? <strike>{row.lessonName}</strike> : row.lessonName}</TableCell>
                                                            <TableCell className={classes.padding}>{row.category}</TableCell>
                                                            <TableCell className={classes.padding}>{row.lessonDate ? dayjs(row.lessonDate * 1000).format('MM/DD(dd)') : ''}</TableCell>
                                                            <TableCell className={classes.padding}>{(row.paymentId.substring(0, 2) === 'b_' && row.paymentId.split('_').length < 3) ? '정기 결제' : numberFormat(row.amount)}</TableCell>
                                                            <TableCell className={classes.padding}>{row.status === 'paid' ? '결제' : row.status === 'cancelled' ? <Typography color='error'>취소</Typography> : '미정'}</TableCell>
                                                            <TableCell className={classes.padding}>{row.parentName}</TableCell>
                                                            <TableCell className={classes.padding}>{row.buyer_tel}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Hidden>
                                    <Hidden smUp>
                                        {reFiltered.map((row, index) => (
                                            <Paper key={row.paymentId}>
                                                <Box m={1} p={1}>

                                                    <Typography variant='body2' color='secondary'>ㆍ주문번호 : {row.paymentId}</Typography>
                                                    <Typography variant='body2' color='secondary'>ㆍ원생이름 : <strong>{row.child_name}</strong></Typography>
                                                    <Typography variant='body2'>ㆍ결제일 : {dayjs(row.createdAt * 1000).format('YYYY-MM-DD HH:mm')}</Typography>
                                                    <Typography variant='body2'>ㆍ수업명 : {row.lessonName}</Typography>
                                                    <Typography variant='body2'>ㆍ수업시작일 : {row.lessonDate ? dayjs(row.lessonDate * 1000).format('MM/DD(dd)') : ''}</Typography>
                                                    <Typography variant='body2'>ㆍ결제금액 : <strong>{(row.paymentId.substring(0, 2) === 'b_' && row.paymentId.split('_').length < 3) ? '정기 결제 등록' : numberFormat(row.amount) + '원'}</strong></Typography>
                                                    <Typography variant='body2'>ㆍ상태 : <strong>{row.status === 'paid' && (row.paymentId.substring(0, 2) === 'b_' && row.paymentId.split('_').length < 3) ? '결제등록' : row.status === 'paid' ? '결제' : row.status === 'cancelled' ? '취소' : '미정'}</strong></Typography>
                                                    <Typography variant='body2'>ㆍ주문자명 : {row.parentName}</Typography>
                                                    <Typography variant='body2'>ㆍ주문자연락처 : {row.buyer_tel}</Typography>
                                                    <Box align='right'>
                                                        <Typography color='textSecondary' variant='body2'>{index + 1}</Typography>
                                                    </Box>



                                                </Box>

                                            </Paper>
                                        ))}
                                    </Hidden>
                                </Box>
                        }





                        <Box height={100} />
                    </Box>

                    :
                    <NotAllowed allowed={allowed} rnr={rnr} />
            }
            <Dialog open={open}>
                <DialogTitle>
                    정기결제 안내
                </DialogTitle>
                <DialogContent>
                    <Typography>정기 결제란 수업 신청시에 결제 대행사에 카드번호를 등록해두어, <strong>매월 자동으로 결제</strong> 되는 구조입니다. 현재 6개월, 12개월 정기결제를 운영하고 있습니다.</Typography>
                    <Typography>ㆍ결제 금액이 표기되지 않은 경우는, 정기 결제를 등록한 상태이며, 아직 첫 결제가 이루어지지 않았음을 의미합니다.</Typography>
                    <Typography>ㆍ자동 결제일은 <strong>매월 26일입니다.(다음월 수업의 선결제)</strong></Typography>
                    <Typography>ㆍ매월 정기 결제가 진행되면 <strong>각 회차별 결제 내역이 하나씩 추가됩니다.</strong></Typography>
                    <Typography>ㆍ정기결제 취소를 원하시는 학부모가 있으시면, 뮤직고고 고객센터를 통해 취소하실 수 있으며, 주문과 더불어 배송되거나, 지급된 경품, 수업보조용품은 별도 환불하거나, 반품하셔야 합니다.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>닫기</Button>
                </DialogActions>
            </Dialog>
        </div >


    );
};

const numberFormat = (inputNumber) => {
    if (!inputNumber) return 0;
    if (typeof (inputNumber) === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default withStyles(styles)(Sales);

