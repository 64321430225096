import React, { useEffect } from 'react';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getFreeVideos } from '../../redux/actions/dataActions';
// components
import VideoClip from './VideoClip';
import CenterLoading from '../layout/CenterLoading';

// MUI stuff
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        maxWidth: 1300,
    },
    title: {
        padding: theme.spacing(1)
    }
});

export const TrialVideos = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const { freeVideos, dataLoading } = useSelector(state => state.data);

    useEffect(() => {
        dispatch(getFreeVideos());
    }, [dispatch]);
    
    return (
        <Box>
            <Grid container direction='column' className={classes.root}>
                <Box height={30} />
                <Grid container item xs={12} >
                    <Box height={20} />
                    <Typography variant='h5' className={classes.title}>1분 맛보기 영상</Typography>
                </Grid>
                <Grid container>
                    {
                        dataLoading && <CenterLoading />
                    }
                    {freeVideos
                        .filter(v => v.status === 'open' && v.type==='메인맛보기')
                        .sort((a, b)=>a.order - b.order)
                        .map((v, index) =>
                            <Grid item xs={12} sm={6} key={index}>
                                <VideoClip
                                    url={v.videoUrl}
                                    category={`[${v.videoName}]`}
                                    videoName={v.desc}
                                />
                            </Grid>)
                    }
                </Grid>
                

            </Grid>
        </Box>

    );
};

export default withStyles(styles)(TrialVideos);

