
import React from 'react';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        margin: theme.spacing(2)
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },

});

// open= true, 
// severity (error, warning, info, success)
// message

const Snackbar1 = props => {

    const { classes } = props;
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.close(false);    
    };   

    return (
        <div className={classes.root}>
            <Snackbar open={props.open} autoHideDuration={props.second*1000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.severity}>
                    {props.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default withStyles(styles)(Snackbar1);