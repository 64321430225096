import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
    //getAllSalesFromTo,
    getSalesForSettlements,
    getSettlements,
    postSettlement,
    updateSalesBySettledIds,
    updateSettlement,
} from '../../redux/actions/managerActions';
import { getOneHakwon, updatePaymentData } from '../../redux/actions/adminActions';
import { isLogged } from '../../redux/actions/userActions';
// components
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import {
    Box,
    Typography,
    Divider,
    TextField,
    Button,
    TableContainer,
    Table,
    Paper,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Link as MuiLink,
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import CenterLoading from '../../components/layout/CenterLoading';
import { ArrowDropDown, ArrowDropUp, EditRounded, FunctionsRounded, PaymentRounded } from '@material-ui/icons';
import HakwonAutocomplete from '../../components/util/HakwonAutocomplete';
import AdminSettleDetail from './AdminSettleDetail';

//import { InboxOutlined } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
    padding: {
        paddingRight: 3,
        paddingLeft: 3,
    },
    date: {
        backgroundColor: '#FAF3E7',
        width: 90,
        margin: theme.spacing(1),
    },
    text: {
        verticalAlign: 'center',
    },
    btn: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
});

export const AdminSettle = (props) => {
    const { classes, history } = props;
    //const user = useSelector(state => state.user);
    const { managerLoading, settlements, salesForSettlements } = useSelector((state) => state.manager);
    const { adminhakwons, adminLoading } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const thisMonth = dayjs().format('YYYY/MM');
    const [month, setMonth] = useState(thisMonth);
    const [searchHakwon, setSearchHakwon] = useState('');
    const [searchType, setSearchType] = useState('isSettled');
    const [open, setOpen] = useState(false);
    const [detail] = useState([]);
    const [hName, sethName] = useState('');
    const [withPrevious, setWithPrevious] = useState(true);
    const [saIds, setSaIds] = useState([]);
    const ours =
        month > dayjs('2023-11-01').format('YYYY/MM')
            ? ['죽곡원', '주은교육테스트원']
            : ['죽곡원', '헬리오시티원', '주은교육테스트원'];
    // 임시 로컬 작업

    const handleSearchSettlement = (type) => {
        setSearchType(type);
        const dates = {
            from: 0,
            to: dayjs() / 1000,
        };
        const variables = {
            from: dates.from,
            to: dates.to,
            withPrevious: withPrevious,
            month: month,
            searchHakwon: searchHakwon,
            searchType: type,
        };
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            //dispatch(getAllSalesFromTo(dates));
            dispatch(getSalesForSettlements(variables));
            dispatch(getSettlements());
        }
    };
    const handleChangeWithPrevious = () => {
        if (withPrevious) {
            setWithPrevious(false);
        } else {
            setWithPrevious(true);
        }
    };

    const extendedSettled = salesForSettlements.map((sa, index) => {
        const tem =
            settlements.length > 0
                ? settlements.filter((st) => st.yyyymm === month.replace('/', '') && st.hName === sa[0])
                : [];
        return [...sa, tem];
    });

    const results = searchType === 'notSettled' ? salesForSettlements : extendedSettled;

    // const handleListByHakwon = (h, s) => {
    //     setOpen(true);
    //     sethName(h);
    //     setDetail(s);
    // };
    const handleOpenDialogByIds = (h, ids) => {
        setOpen(true);
        sethName(h);
        setSaIds(ids);
    };

    // 정산처리 다이어로그
    const [openS, setOpenS] = useState(false);
    const [settleDate, setSettleDate] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    const [toBeAmount, setToBeAmount] = useState(0);
    const [cashAmt, setCashAmt] = useState(0);
    const [pointAmt, setPointAmt] = useState(0);
    const [incen, setIncen] = useState(0);
    const [tax, setTax] = useState(0);
    const [diff, setDiff] = useState(0);
    const [details, setDetails] = useState([]);
    const [totalAmt, setTotalAmt] = useState(0);
    const [memo, setMemo] = useState('');
    const [hInfo, setHInfo] = useState({});
    const [sId, setSId] = useState([]);

    useEffect(() => {
        if (Number(cashAmt) + Number(incen) >= 35000) {
            const temp1Tax = Math.floor(((Number(cashAmt) + Number(incen)) * 0.03) / 10) * 10;
            const tempTax = temp1Tax + Math.floor((temp1Tax * 0.1) / 10) * 10;
            //console.log(tempTax);
            setTax(numberFormat(tempTax));
        } else {
            setTax(0);
        }
    }, [cashAmt, incen]);

    const handleSettlementRequest = (hName, detail, amt, sid) => {
        setOpenS(true);

        setDetails(
            detail
                .filter((sa) => (sa.status === 'paid' && sa.amount > 0) || sa.amount - sa.cancel_amount > 0)
                .map((sa) => {
                    const applied = sa.settlement / (sa.amount / 15000);
                    return {
                        id: sa.paymentId,
                        settlement: sa.isRevoked ? ((sa.amount - sa.cancel_amount) / 15000) * applied : sa.settlement,
                    };
                })
        );
        if (sid) {
            const ds = settlements.filter((st) => st.settlementId === sid)[0];
            setToBeAmount(ds.paidInCash + ds.paidInPoint);
            setCashAmt(ds.paidInCash);
            setPointAmt(ds.paidInPoint);
            setTotalAmt(ds.paidInCash + ds.paidInPoint + ds.incentive - ds.tax);
            setIncen(ds.incentive);
            setMemo(ds.memo);
            // setTax(ds.tax);
            setSId(sid);
        } else {
            const temp1Tax = Math.floor((amt * 0.03) / 10) * 10;
            const tempTax = temp1Tax + Math.floor((temp1Tax * 0.1) / 10) * 10;
            setToBeAmount(amt);
            setCashAmt(amt);
            setTotalAmt(0);
            setIncen(0);
            setTax(amt >= 35000 ? tempTax : 0);
        }

        dispatch(getOneHakwon({ hName: hName }));
    };

    const handleCalculate = () => {
        let ta =
            Number(cashAmt ? cashAmt : 0) +
            Number(pointAmt ? pointAmt : 0) +
            Number(incen ? incen : 0) -
            Number(tax ? String(tax).replace(/,/gi, '') : 0);
        let df = ta - Number(toBeAmount) + Number(tax ? tax.replace(/,/gi, '') : 0);
        //console.log(cashAmt, pointAmt, Number(incen ? incen :0), tax, Number(tax.replace(/,/gi,'')), Number(toBeAmount), ta, df);
        // console.log(ta, df, tax);

        setTotalAmt(ta);
        setDiff(df);
        setMemo(`정산완료`);
    };

    // 학원상세정보
    useEffect(() => {
        if (adminhakwons) {
            setHInfo(adminhakwons[0]);
        }
    }, [adminhakwons]);

    // toggle
    const [sortType, setSortType] = useState('salesD');

    const toggleCreatedAt = () => {
        if (sortType === 'createdAtDesc') {
            setSortType('createdAtAsc');
        } else {
            setSortType('createdAtDesc');
        }
    };

    const toggleName = () => {
        if (sortType === 'nameDesc') {
            setSortType('nameAsc');
        } else {
            setSortType('nameDesc');
        }
    };
    const toggleNo = () => {
        if (sortType === 'noDesc') {
            setSortType('noAsc');
        } else {
            setSortType('noDesc');
        }
    };
    const toggleType = () => {
        if (sortType === 'typeDesc') {
            setSortType('typeAsc');
        } else {
            setSortType('typeDesc');
        }
    };
    const toggleCategory = () => {
        if (sortType === 'categoryDesc') {
            setSortType('categoryAsc');
        } else {
            setSortType('categoryDesc');
        }
    };
    const toggleMonthType = () => {
        if (sortType === 'monthTypeDesc') {
            setSortType('monthTypeAsc');
        } else {
            setSortType('monthTypeDesc');
        }
    };
    const toggleStatus = () => {
        if (sortType === 'statusD') {
            setSortType('statusA');
        } else {
            setSortType('statusD');
        }
    };
    const toggleAmount = () => {
        if (sortType === 'amountD') {
            setSortType('amountA');
        } else {
            setSortType('amountD');
        }
    };
    const toggleSettle = () => {
        if (sortType === 'settleD') {
            setSortType('settleA');
        } else {
            setSortType('settleD');
        }
    };
    const toggleHName = () => {
        if (sortType === 'hNameD') {
            setSortType('hNameA');
        } else {
            setSortType('hNameD');
        }
    };
    const toggleCount = () => {
        if (sortType === 'countA') {
            setSortType('countD');
        } else {
            setSortType('countA');
        }
    };
    const toggleSales = () => {
        if (sortType === 'salesA') {
            setSortType('salesD');
        } else {
            setSortType('salesA');
        }
    };
    const toggleSet = () => {
        if (sortType === 'setA') {
            setSortType('setD');
        } else {
            setSortType('setA');
        }
    };
    console.log(details);

    // 전체 학원 리스트
    const sortedResults = results.sort((a, b) =>
        sortType === 'hNameD'
            ? b[0] > a[0]
                ? 1
                : -1
            : sortType === 'hNameA'
            ? a[0] > b[0]
                ? 1
                : -1
            : sortType === 'statusD'
            ? b[1].status > a[1].status
                ? 1
                : -1
            : sortType === 'statusA'
            ? a[1].status > b[1].status
                ? 1
                : -1
            : sortType === 'countA'
            ? a[1].filter((sa) => sa.status === 'paid' && sa.amount > 0).length -
              b[1].filter((sa) => sa.status === 'paid' && sa.amount > 0).length
            : sortType === 'countD'
            ? b[1].filter((sa) => sa.status === 'paid' && sa.amount > 0).length -
              a[1].filter((sa) => sa.status === 'paid' && sa.amount > 0).length
            : sortType === 'salesA'
            ? a[1].reduce((p, n) => p + n.amount - n.cancel_amount, 0) -
              b[1].reduce((p, n) => p + n.amount - n.cancel_amount, 0)
            : sortType === 'salesD'
            ? b[1].reduce((p, n) => p + n.amount - n.cancel_amount, 0) -
              a[1].reduce((p, n) => p + n.amount - n.cancel_amount, 0)
            : sortType === 'setA'
            ? a[1].reduce(
                  (p, n) =>
                      p + (n.status === 'paid' && n.amount > 0 && ours.indexOf(n.hName) === -1 ? n.settlement : 0),
                  0
              ) -
              b[1].reduce(
                  (p, n) =>
                      p + (n.status === 'paid' && n.amount > 0 && ours.indexOf(n.hName) === -1 ? n.settlement : 0),
                  0
              )
            : sortType === 'setD'
            ? b[1].reduce(
                  (p, n) =>
                      p + (n.status === 'paid' && n.amount > 0 && ours.indexOf(n.hName) === -1 ? n.settlement : 0),
                  0
              ) -
              a[1].reduce(
                  (p, n) =>
                      p + (n.status === 'paid' && n.amount > 0 && ours.indexOf(n.hName) === -1 ? n.settlement : 0),
                  0
              )
            : null
    );
    const allHCount = sortedResults.filter((sa) => {
        return sa[1].reduce((p, n) => p + (n.amount > 0 ? n.settlement : 0), 0);
    }).length;

    const allCount = sortedResults
        .map(
            (sa) =>
                sa[1].filter(
                    (de) => de.amount > 0 //&& de.status === 'paid'
                ).length
        )
        .reduce((a, b) => a + b, 0);
    const allAmount = sortedResults
        .map((sa) => sa[1].reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0))
        .reduce((a, b) => a + b, 0);
    const allSettle = sortedResults
        .map((sa) => {
            const appliedSettleAmount = sa[1].length > 50 ? 5000 : sa[1].length > 31 ? 4000 : 3000;
            return sa[1].reduce(
                (p, n) =>
                    p +
                    (n.status === 'paid' && n.amount > 0 && ours.indexOf(n.hName) === -1
                        ? n.settlement
                        : n.isRevoked === true
                        ? (appliedSettleAmount * (n.amount - n.cancel_amount)) / 15000 - n.settlement //Math.abs(n.settlement) * -1
                        : 0),
                0
            );
        })
        .reduce((a, b) => a + b, 0);

    const allCountPlayer = sortedResults
        .map(
            (sa) =>
                sa[1].filter(
                    (de) =>
                        de.amount > 0 && //&& de.status === 'paid'
                        de.category === '플레이어'
                ).length
        )
        .reduce((a, b) => a + b, 0);
    const allCountRunner = sortedResults
        .map(
            (sa) =>
                sa[1].filter(
                    (de) =>
                        de.amount > 0 && //&& de.status === 'paid'
                        de.category === '러너'
                ).length
        )
        .reduce((a, b) => a + b, 0);
    const allCountETC = allCount - allCountPlayer - allCountRunner;

    const allAmountPlayer = sortedResults
        .map((sa) =>
            sa[1]
                .filter((sa) => sa.category === '플레이어')
                .reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0)
        )
        .reduce((a, b) => a + b, 0);
    const allAmountRunner = sortedResults
        .map((sa) =>
            sa[1]
                .filter((sa) => sa.category === '러너')
                .reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0)
        )
        .reduce((a, b) => a + b, 0);
    const allAmountETC = allAmount - allAmountPlayer - allAmountRunner;

    // 학원별 매출리스트
    const saleList = detail
        .filter((sa) => sa.amount > 0)
        .sort((a, b) =>
            sortType === 'createdAtAsc'
                ? a.createdAt > b.createdAt
                    ? 1
                    : -1
                : sortType === 'createdAtDesc'
                ? b.createdAt > a.createdAt
                    ? 1
                    : -1
                : sortType === 'createdAtDesc'
                ? b.createdAt > a.createdAt
                    ? 1
                    : -1
                : sortType === 'nameDesc'
                ? b.buyer_name > a.buyer_name
                    ? 1
                    : -1
                : sortType === 'nameAsc'
                ? a.buyer_name > b.buyer_name
                    ? 1
                    : -1
                : sortType === 'noDesc'
                ? b.paymentId > a.paymentId
                    ? 1
                    : -1
                : sortType === 'noAsc'
                ? a.paymentId > b.paymentId
                    ? 1
                    : -1
                : sortType === 'settleD'
                ? (b.status === 'paid' && b.amount > 0 ? b.settlement : 0) >
                  (a.status === 'paid' && a.amount > 0 ? a.settlement : 0)
                    ? 1
                    : -1
                : sortType === 'settleA'
                ? (b.status === 'paid' && b.amount > 0 ? b.settlement : 0) <
                  (a.status === 'paid' && a.amount > 0 ? a.settlement : 0)
                    ? 1
                    : -1
                : sortType === 'amountD'
                ? b.amount - (b.cancel_amount ? b.cancel_amount : 0) >
                  a.amount - (a.cancel_amount ? a.cancel_amount : 0)
                    ? 1
                    : -1
                : sortType === 'amountA'
                ? b.amount - (b.cancel_amount ? b.cancel_amount : 0) <
                  a.amount - (a.cancel_amount ? a.cancel_amount : 0)
                    ? 1
                    : -1
                : sortType === 'statusD'
                ? b.status > a.status
                    ? 1
                    : -1
                : sortType === 'statusA'
                ? b.status < a.status
                    ? 1
                    : -1
                : sortType === 'typeDesc'
                ? b.lessonType + b.totalRound + b.amount > a.lessonType + a.totalRound + a.amount
                    ? 1
                    : -1
                : sortType === 'typeAsc'
                ? b.lessonType + b.totalRound + b.amount < a.lessonType + a.totalRound + a.amount
                    ? 1
                    : -1
                : sortType === 'monthTypeDesc'
                ? dayjs(b.lessonDate * 1000).get('date') > 20 && b.lessonType === 'regular'
                    ? dayjs(b.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(b.lessonDate * 1000).get('month') >
                      (dayjs(a.lessonDate * 1000).get('date') > 20 && a.lessonType === 'regular')
                    ? dayjs(a.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(a.lessonDate * 1000).get('month')
                    ? 1
                    : -1
                : sortType === 'monthTypeAsc'
                ? dayjs(b.lessonDate * 1000).get('date') > 20 && b.lessonType === 'regular'
                    ? dayjs(b.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(b.lessonDate * 1000).get('month') <
                      (dayjs(a.lessonDate * 1000).get('date') > 20 && a.lessonType === 'regular')
                    ? dayjs(a.lessonDate * 1000)
                          .add(1, 'month')
                          .get('month')
                    : dayjs(a.lessonDate * 1000).get('month')
                    ? 1
                    : -1
                : sortType === 'categoryDesc'
                ? b.category - a.category > 0
                    ? 1
                    : -1
                : sortType === 'categoryAsc'
                ? a.category - b.category > 0
                    ? 1
                    : -1
                : null
        );
    const hCount = saleList.filter((sa) => sa.amount > 0 && sa.status === 'paid').length;
    const hAmount = saleList.reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const appliedSettleAmount = saleList.length > 50 ? 5000 : saleList.length > 31 ? 4000 : 3000;
    const hSettle = saleList.reduce(
        (p, n) =>
            p +
            (n.status === 'paid' && n.amount > 0
                ? n.settlement
                : n.isRevoked === true
                ? (appliedSettleAmount * (n.amount - n.cancel_amount)) / 15000 - n.settlement // Math.abs(n.settlement) * -1
                : 0),
        0
    );
    const tempC = saleList.filter(
        (sa) => sa.amount > 0 //&& sa.status === 'paid'
    );
    // 카테고리별 Count
    const hCountPlayer = tempC.filter((t) => t.category === '플레이어').length;
    const hCountRunner = tempC.filter((t) => t.category === '러너').length;
    const hCountETC = hCount - hCountPlayer - hCountRunner;
    // 카테고리별 Amount
    const hAmountPlayer = saleList
        .filter((sa) => sa.category === '플레이어')
        .reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const hAmountRunner = saleList
        .filter((sa) => sa.category === '러너')
        .reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const hAmountETC = hAmount - hAmountPlayer - hAmountRunner;

    const proceedSettlement = () => {
        let mess = ` :: ${hInfo.hName}의 ${month} 월 정산은,
                    현금정산금액 : ${cashAmt > 0 ? numberFormat(cashAmt) : 0} 원, 
                    포인트정산금액 :  ${pointAmt > 0 ? numberFormat(pointAmt) : 0} 원, 
                    인센티브 : ${incen > 0 ? numberFormat(incen) : 0} 원
                    원천세(3.3%): ${numberFormat(tax)} 원
                    ------------------------------
                    합계 : ${numberFormat(totalAmt)} 원   입니다.`;

        console.log(mess);
        if (
            totalAmt === 0 &&
            ours.indexOf(hInfo.hName) === -1 &&
            cashAmt === 0 &&
            pointAmt === 0 &&
            incen === 0 &&
            tax === 0
        ) {
            const cf = window.confirm('부분취소등으로 매출액만 남은 건입니다. 정산 진행하시겠습니까?');
            if (cf) {
                if (window.confirm(mess)) {
                    handleSaveSettlement();
                    setOpenS(false);
                }
            }
        } else if (
            totalAmt === 0 &&
            ours.indexOf(hInfo.hName) === -1 &&
            (cashAmt !== 0 || pointAmt !== 0 || incen !== 0 || tax !== 0)
        ) {
            window.alert('계산 버튼을 눌러주세요.');
        } else if (totalAmt === 0 && ours.indexOf(hInfo.hName) > -1) {
            const cf = window.confirm('직영원은 마감처리됩니다. 진행하시겠습니까?');
            if (cf) {
                if (window.confirm(mess)) {
                    handleSaveSettlement();
                    setOpenS(false);
                }
            }
        } else if (diff !== 0) {
            const cf = window.confirm('차이가 발생했습니다. 그래도 진행하시겠습니까?');
            if (cf) {
                if (window.confirm(mess)) {
                    handleSaveSettlement();
                    setOpenS(false);
                }
            }
        } else {
            if (window.confirm(mess)) {
                handleSaveSettlement();
                setOpenS(false);
            }
        }
    };
    const handleSaveSettlement = () => {
        if (searchType === 'isSettled') {
            // 수정
            const info = {
                isSettled: true,
                memo: memo,
                hakwonId: hInfo.hakwonId,
                hName: hInfo.hName,
                paidInCash: Number(cashAmt ? cashAmt : 0),
                incentive: Number(incen ? incen : 0),
                yyyymm: month.replace('/', ''),
                paidInPoint: Number(pointAmt ? pointAmt : 0),
                tax: !tax ? 0 : Number(String(tax).replace(/,/gi, '')),
            };
            //console.log(info, tax);
            dispatch(updateSettlement(sId, info, withPrevious, month, searchHakwon, searchType));
        } else {
            // 정산
            const info = {
                isSettled: true,
                memo: memo,
                hakwonId: hInfo.hakwonId,
                hName: hInfo.hName,
                paidInCash: Number(cashAmt ? cashAmt : 0),
                incentive: Number(incen ? incen : 0),
                yyyymm: month.replace('/', ''),
                paidInPoint: Number(pointAmt ? pointAmt : 0),
                tax: Number(tax ? tax.replace(/,/gi, '') : 0),
            };
            //console.log(info)
            //console.log(details)
            dispatch(postSettlement(info, hInfo.hName));
            dispatch(updateSalesBySettledIds(details));
        }
    };

    const handleMemoBeforeClose = () => {
        const cf = window.confirm('메모 내용을 계속 유지하시겠습니까?');
        if (cf) {
            setOpenS(false);
        } else {
            setMemo('');
            setOpenS(false);
        }
    };

    const handleUpdatePaymentData = (ids) => {
        const dates = {
            from: 0,
            to: dayjs() / 1000,
        };
        const variables = {
            from: dates.from,
            to: dates.to,
            withPrevious: withPrevious,
            month: month,
            searchHakwon: searchHakwon,
            searchType: 'notSettled',
        };
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(updatePaymentData(ids, variables));
        }
    };

    return (
        <Box p={1} m={1}>
            <Box height={20} />
            <Typography variant="subtitle1" gutterBottom>
                정산 현황
            </Typography>
            <Divider light />
            <Box p={1} m={1} style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                    size="small"
                    margin="dense"
                    className={classes.date}
                    style={{ backgroundColor: '#FAF3E7', width: 150 }}
                    required
                    label={'정산월(YYYY/MM)'}
                    value={month}
                    onChange={(e) => setMonth(e.currentTarget.value)}
                />
                <HakwonAutocomplete
                    searchHakwon={searchHakwon}
                    setSearchHakwon={setSearchHakwon}
                    label="가맹학원명 검색"
                />
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSearchSettlement('notSettled')}
                    className={classes.btn}
                >
                    조회
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => handleSearchSettlement('isSettled')}
                    className={classes.btn}
                >
                    결과
                </Button>
            </Box>
            <FormControlLabel
                control={<Checkbox checked={withPrevious} onChange={handleChangeWithPrevious} name="checked" />}
                label={
                    withPrevious ? (
                        <Typography variant="body1">이월된 미정산내역포함</Typography>
                    ) : (
                        <Typography variant="body1">이월 미정산 제외</Typography>
                    )
                }
            />
            <Box height={10} />
            <Typography variant="body2">
                ㆍ정산범위 : 원데이클래스는 당월기준(1일~말일), 정기수업은 수업월기준(전25~당25일)
            </Typography>
            <Typography variant="body2">
                ㆍ정산이전 취소 : 매출취소 시 정산하지 않음.{' '}
                <strong>부분 취소의 경우에는 매출 비율에 맞춰 상계 취소함(업데이트)</strong>
            </Typography>
            <Typography variant="body2">
                ㆍ정산이후 사후정산 : 누락된 데이터나, 정산 이후 취소 내역 등은 상계 처리됨. 금액이 모자라면 정산하지
                않고 순연됨.
            </Typography>

            <Box height={10} />
            <Typography variant="subtitle1" color={searchType === 'isSettled' ? 'secondary' : 'primary'}>
                {searchType === 'isSettled' ? '[정산 결과 조회]' : '[정산 예정 금액]'}
            </Typography>
            {managerLoading ? (
                <CenterLoading />
            ) : (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.padding}>No</TableCell>
                                <TableCell className={classes.padding}>정산월</TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleHName}
                                        endIcon={
                                            sortType === 'hNameD' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'hNameA' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        가맹원상세내역
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding} align="right">
                                    <Button
                                        onClick={toggleCount}
                                        endIcon={
                                            sortType === 'countD' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'countA' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        건수
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding} align="right">
                                    <Button
                                        onClick={toggleSales}
                                        endIcon={
                                            sortType === 'salesD' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'salesA' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        총매출액
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding} align="right">
                                    <Button
                                        onClick={toggleSet}
                                        endIcon={
                                            sortType === 'setD' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'setA' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        정산예정금액
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding} align="center">
                                    정산금액
                                </TableCell>
                                <TableCell className={classes.padding} align="right">
                                    인센티브
                                </TableCell>
                                <TableCell className={classes.padding} align="right">
                                    세금
                                </TableCell>
                                <TableCell className={classes.padding} align="right">
                                    실지급액
                                </TableCell>

                                <TableCell className={classes.padding} align="right">
                                    차이
                                </TableCell>
                                <TableCell className={classes.padding} align="center">
                                    <Button
                                        onClick={toggleStatus}
                                        endIcon={
                                            sortType === 'statusD' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'statusA' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        상태
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding} align="left">
                                    지불방법
                                </TableCell>
                                <TableCell className={classes.padding} align="left">
                                    비고
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedResults.map((sa, index) => {
                                const h = sa[0];
                                const s = sa[1];
                                const sIds = s.map((sa) => sa.paymentId);
                                const resul = sa.length > 2 ? sa[2] : [];
                                // 합계

                                const sum_sales = s.reduce(function (prev, next) {
                                    return prev + next.amount - (next.cancel_amount ? next.cancel_amount : 0);
                                }, 0);
                                const appliedSettleAmount = s.length > 50 ? 5000 : s.length > 31 ? 4000 : 3000;

                                const sum_settle = s.reduce(function (p, n) {
                                    let paid_settlement =
                                        n.status === 'paid' && n.amount > 0 && ours.indexOf(h) === -1
                                            ? n.settlement
                                            : n.isRevoked
                                            ? (appliedSettleAmount * (n.amount - n.cancel_amount)) / 15000 -
                                              n.settlement //Math.abs(n.settlement) * -1
                                            : 0;

                                    return p + paid_settlement;
                                }, 0);

                                const acount = s.filter(
                                    (
                                        sa // sa.status === 'paid' &&
                                    ) => sa.amount > 0
                                ).length;

                                //  정산결과
                                const results_total = resul.reduce(
                                    (p, n) => p + Number(n.paidInCash) + Number(n.paidInPoint),
                                    0
                                );
                                const results_date = resul.reduce((p, n) => Math.max(p, n.settledAt), 0);
                                const results_cash = resul.reduce((p, n) => p + n.paidInCash, 0);
                                const results_point = resul.reduce((p, n) => p + n.paidInPoint, 0);
                                const results_incen = resul.reduce((p, n) => p + n.incentive, 0);
                                const results_tax = resul.reduce((p, n) => p + Number(n.tax ? n.tax : 0), 0);
                                const memos = resul.map((st) => st.memo).join(' / ');
                                const results_sid = resul.map((st) => st.settlementId)[0];

                                // console.log('results_total', results_total, results_cash, results_point, results_incen, results_tax)

                                /*  */
                                return (
                                    <TableRow key={index}>
                                        <TableCell size="small" className={classes.padding}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {month}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {/* <Button color="primary" onClick={() => handleListByHakwon(h, s)}>
                                                {h}
                                            </Button> */}
                                            <Button color="primary" onClick={() => handleOpenDialogByIds(h, sIds)}>
                                                {h}
                                            </Button>
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="right">
                                            {acount}
                                        </TableCell>

                                        <TableCell size="small" className={classes.padding} align="right">
                                            {numberFormat(sum_sales)}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="right">
                                            {numberFormat(sum_settle)}
                                        </TableCell>

                                        <TableCell size="small" className={classes.padding} align="center">
                                            {searchType === 'isSettled' ? (
                                                <strong>{numberFormat(results_total)}</strong>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleSettlementRequest(h, s, sum_settle, results_sid)
                                                    }
                                                >
                                                    정산
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="right">
                                            {searchType === 'isSettled' ? numberFormat(results_incen) : '-'}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="right">
                                            {searchType === 'isSettled' ? numberFormat(results_tax) : '-'}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="right">
                                            {searchType === 'isSettled'
                                                ? numberFormat(results_total - results_tax)
                                                : '-'}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="right">
                                            {searchType === 'isSettled'
                                                ? numberFormat(results_total - results_cash - results_point)
                                                : '-'}
                                        </TableCell>

                                        <TableCell size="small" className={classes.padding} align="center">
                                            {searchType === 'isSettled' ? (
                                                <>
                                                    <strong>{dayjs(results_date * 1000).format('YYYY-MM-DD')}</strong>{' '}
                                                    <IconButton
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() =>
                                                            handleSettlementRequest(h, s, sum_settle, results_sid)
                                                        }
                                                    >
                                                        <EditRounded />{' '}
                                                    </IconButton>
                                                </>
                                            ) : (
                                                '미정산'
                                            )}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="left">
                                            {searchType === 'isSettled'
                                                ? results_cash > 0 && results_point > 0
                                                    ? '복합'
                                                    : results_cash > 0
                                                    ? '현금'
                                                    : results_point > 0
                                                    ? '포인트'
                                                    : '-'
                                                : '-'}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding} align="left">
                                            {searchType === 'isSettled' ? memos : '-'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {allHCount > 0 && (
                                <TableRow style={{ backgroundColor: '#fee' }}>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}>
                                        {allHCount}개원
                                    </TableCell>
                                    <TableCell size="small" className={classes.padding} align="right">
                                        {numberFormat(allCount)}건
                                    </TableCell>

                                    <TableCell size="small" className={classes.padding} align="right">
                                        {numberFormat(allAmount)}
                                    </TableCell>
                                    <TableCell size="small" className={classes.padding} align="right">
                                        {numberFormat(allSettle)}
                                    </TableCell>

                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>

                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}>
                                        <>
                                            <Typography variant="body2">
                                                ·플레이어: {numberFormat(allCountPlayer)}건,{' '}
                                                {numberFormat(allAmountPlayer)}원
                                            </Typography>
                                            <Typography variant="body2">
                                                ·러너: {numberFormat(allCountRunner)}건, {numberFormat(allAmountRunner)}
                                                원
                                            </Typography>
                                            <Typography variant="body2">
                                                ·기타: {numberFormat(allCountETC)}건, {numberFormat(allAmountETC)}원
                                            </Typography>
                                        </>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Box height={100} />
            <Box height={100} />

            {/* adminSettleDetail */}
            <AdminSettleDetail
                {...{
                    classes,
                    open,
                    setOpen,
                    hName,
                    hCount,
                    hCountPlayer,
                    hCountRunner,
                    hCountETC,
                    hAmount,
                    hSettle,
                    hAmountPlayer,
                    hAmountRunner,
                    hAmountETC,
                    sortType,
                    searchType,
                    // data
                    saIds,
                    // saleList,
                    // sortedResults,
                    // function
                    toggleNo,
                    toggleName,
                    toggleCreatedAt,
                    toggleMonthType,
                    toggleStatus,
                    toggleAmount,
                    toggleSettle,
                    toggleType,
                    toggleCategory,
                    // update
                    handleUpdatePaymentData,
                    handleSearchSettlement,
                }}
            />
            <Box>
                <Dialog open={openS} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        {/*  {adminhakwons[0].hName} ({adminhakwons[0].partnerType}) */}
                        :: {adminLoading ? <CenterLoading /> : hInfo ? hInfo.hName : ''} (
                        {hInfo ? hInfo.partnerType : ''}) 정산
                    </DialogTitle>
                    <DialogContent>
                        <Typography>ㆍ은행: {hInfo?.accountBank}</Typography>
                        <Typography>ㆍ계좌번호: {hInfo?.accountNumber}</Typography>
                        <Typography>ㆍ예금주: {hInfo?.accountOwner}</Typography>
                        <Typography>ㆍ주민번호: {hInfo?.accountNIN}</Typography>
                        <Box>
                            {hInfo && hInfo.accountImages
                                ? hInfo?.accountImages.map((v, i) => (
                                      <Button
                                          key={i}
                                          size="small"
                                          style={{ marginLeft: 10, marginBottom: 10 }}
                                          variant="contained"
                                          onClick={() => window.open(v, '자료_' + Number(i + 1), 'location=no')}
                                      >
                                          {'자료_' + Number(i + 1)}
                                      </Button>
                                  ))
                                : null}
                        </Box>
                        <Box height={10} />
                        <Typography variant="body2">
                            *입력/수정은 <MuiLink href="/admin/hakwon">{'관리자>가맹원'}</MuiLink> 메뉴에서{' '}
                        </Typography>
                        <Box height={10} />
                        <TextField label="정산대상월" value={month} size="small" margin="dense" />
                        <TextField
                            label="정산일시"
                            value={settleDate}
                            onChange={(e) => setSettleDate(e.target.value)}
                            size="small"
                            margin="dense"
                            style={{ marginLeft: 10 }}
                        />
                        <TextField
                            fullWidth
                            label="정산예정금액"
                            value={numberFormat(toBeAmount)}
                            size="small"
                            margin="dense"
                            style={{ width: '60%' }}
                        />
                        <TextField
                            fullWidth
                            label="현금(Cash)정산금액"
                            value={numberFormat(cashAmt)}
                            onChange={(e) => setCashAmt(e.target.value.replace(/,/gi, ''))}
                            size="small"
                            margin="dense"
                            style={{ width: '60%' }}
                        />
                        <TextField
                            fullWidth
                            label="포인트(Point)정산금액"
                            value={pointAmt === 0 ? 0 : numberFormat(pointAmt)}
                            onChange={(e) => setPointAmt(e.target.value.replace(/,/gi, ''))}
                            size="small"
                            margin="dense"
                            style={{ width: '60%' }}
                        />
                        <TextField
                            label="인센티브(Incentive)"
                            fullWidth
                            value={incen}
                            onChange={(e) => setIncen(e.target.value.replace(/,/gi, ''))}
                            size="small"
                            margin="dense"
                            style={{ width: '60%' }}
                        />
                        <TextField
                            label="원천세(3.3%)"
                            fullWidth
                            value={numberFormat(tax)}
                            onChange={(e) => setTax(e.target.value.replace(/,/gi, ''))}
                            size="small"
                            margin="dense"
                            style={{ width: '60%' }}
                        />
                        <Box height={10} />
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleCalculate}
                            startIcon={<FunctionsRounded />}
                        >
                            계산
                        </Button>
                        <Box height={10} />
                        <Typography variant="subtitle1" gutterBottom>
                            ㆍ실지급 합계 : {numberFormat(totalAmt)} 원
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            ㆍ차이 : {diff > 0 ? numberFormat(diff) : 0} 원
                        </Typography>

                        <Box height={10} />

                        <TextField
                            label="메모"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            size="small"
                            margin="dense"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={proceedSettlement}
                            startIcon={<PaymentRounded />}
                        >
                            {searchType === 'isSettled' ? '수정' : '정산'}
                        </Button>
                        <Button variant="outlined" onClick={handleMemoBeforeClose}>
                            닫기
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default withStyles(styles)(AdminSettle);

function numberFormat(inputNumber) {
    if (!inputNumber) return 0;
    if (typeof inputNumber === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
