import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// persist
// import {persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; 
// localstorage 이용

// 총 6개 리듀서 사용
import userReducer from './reducers/userReducer';
import dataReducer from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';
import enrollReducer from './reducers/enrollReducer';
import managerReducer from './reducers/managerReducer';
import adminReducer from './reducers/adminReducer';

const initialState = {};
const middleware = [thunk];

// persist config
/* const persistConfig ={
  key: "root",
  // localStorage에 저장합니다
  storage,
  // whitelist: ["userReducer",..]
  // blacklist: ["userReducer"],
  timeout: null
}; */

const reducers = combineReducers({
    user: userReducer,
    data: dataReducer,
    UI: uiReducer,
    enroll: enrollReducer,
    manager: managerReducer,
    admin: adminReducer,
});


// devtool 사용을 위한 설정
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

// persist
/* const enhancedReducer = persistReducer(persistConfig, reducers); */

/* const store = createStore(enhancedReducer, initialState, enhancer); */
const store = createStore(reducers, initialState, enhancer);
export default store;