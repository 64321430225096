import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
const CenterLoading = (props) => {


    return (
        <Box align='center' style={{margin: 10}}>
            <CircularProgress color="primary" size={30} />
        </Box>
    );
};
export default CenterLoading;

