import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
// components
import NavBarDark from '../components/layout/NavBarDark';
import MainTabs from '../components/layout/MainTabs';
import AdminRole from '../pages/admin/AdminRole';
import AdminHakwon from '../pages/admin/AdminHakwon';
import AdminCustomer from '../pages/admin/AdminCustomer';
import AdminProduct from '../pages/admin/AdminProduct';
import AdminLesson from '../pages/admin/AdminLesson';
import AdminSales from '../pages/admin/AdminSales';
import AdminSettle from '../pages/admin/AdminSettle';
import AdminDelivery from '../pages/admin/AdminDelivery';
import AdminEnrolled from '../pages/admin/AdminEnrolled';
import AdminKakaoLink from '../pages/admin/AdminKakaoLink';
import AdminEvent from '../pages/admin/AdminEvent';
import AdminAttendPad from '../pages/admin/AdminAttendPad';
import Footer from '../components/layout/Footer';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import MainGrid from '../components/layout/MainGrid';


const styles = (theme) => ({
    ...theme.userPage,
});


export const Admin = (props) => {
    const { history } = props;
    const { topic } = useParams();
    const { rnr } = useSelector(state => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        if (rnr) {
            if (rnr.level > 1) {
                return (<Redirect to='/' />);
            } else {
                console.log('admin 권한이 확인되었습니다.')
            }
        }
    }, [rnr, dispatch])
    const tabInfo = [
        { id: "role", label: "권한관리", link: '/admin/role' },
        { id: "hakwon", label: "가맹원", link: '/admin/hakwon' },
        { id: "customer", label: "고객", link: '/admin/customer' },
        { id: "product", label: "상품", link: '/admin/product' },
        { id: "lesson", label: "수업", link: '/admin/lesson' },
        { id: "sales", label: "매출", link: '/admin/sales' },
        { id: "settle", label: "정산", link: '/admin/settle' },
        { id: "delivery", label: "배송", link: '/admin/delivery' },
        { id: "enrolled", label: "수강생", link: '/admin/enrolled' },
        { id: "kakaolink", label: "카카오링크", link: '/admin/kakaolink' },
        { id: "event", label: "이벤트", link: '/admin/event' },
        { id: "develop", label: "임시", link: '/admin/develop' },
    ];

    return (
        <>
            <NavBarDark menu={7} history={history} />
            <div className='container'>
                <MainGrid>
                    <MainTabs tabInfo={tabInfo} />
                    {(topic === tabInfo[0].id || typeof topic === 'undefined') && <AdminRole history={history} />}
                    {topic === tabInfo[1].id && <AdminHakwon history={history} />}
                    {topic === tabInfo[2].id && <AdminCustomer history={history} />}
                    {topic === tabInfo[3].id && <AdminProduct history={history} />}
                    {topic === tabInfo[4].id && <AdminLesson history={history} />}
                    {topic === tabInfo[5].id && <AdminSales history={history} />}
                    {topic === tabInfo[6].id && <AdminSettle history={history} />}
                    {topic === tabInfo[7].id && <AdminDelivery history={history} />}
                    {topic === tabInfo[8].id && <AdminEnrolled history={history} />}
                    {topic === tabInfo[9].id && <AdminKakaoLink history={history} />}
                    {topic === tabInfo[10].id && <AdminEvent history={history} />}
                    {topic === tabInfo[11].id && <AdminAttendPad history={history} />}
                </MainGrid>
                <Footer />
            </div>
        </>
    );
};


export default withStyles(styles)(Admin);

