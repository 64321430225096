import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getSalesByIds, updateSettlementState } from '../../redux/actions/managerActions';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Paper,
    Box,
    TextField,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
// import CenterLoading from '../../components/layout/CenterLoading';

export const AdminSettleDetail = (props) => {
    const {
        classes,
        open,
        setOpen,
        hName,
        // hCount,
        // hCountPlayer,
        // hCountRunner,
        // hCountETC,
        // hAmount,
        // hAmountPlayer,
        // hAmountRunner,
        // hAmountETC,
        sortType,
        searchType,
        // data
        saIds,
        //saleList,
        //sortedResults,
        // function
        toggleNo,
        toggleName,
        toggleCreatedAt,
        toggleMonthType,
        toggleStatus,
        toggleAmount,
        toggleSettle,
        toggleType,
        toggleCategory,
        // payments update
        handleUpdatePaymentData,
        // handleSearchSettlement,
    } = props;
    // redux
    const dispatch = useDispatch();
    // const { adminLoading } = useSelector((state) => state.admin);
    const { salesByIds } = useSelector((state) => state.manager);

    /* 새로운 규칙 도입 : 30이하 3,000원, 50이하 4,000원, 50초과 5,000원 */
    // const sAmount = hCount > 50 ? 5000 : hCount > 31 ? 4000 : 3000;
    const [appliedSettleAmount, setAppliedSettleAmount] = useState(0);

    useEffect(() => {
        if (saIds) {
            dispatch(getSalesByIds(saIds));
        }
    }, [dispatch, saIds]);

    useEffect(() => {
        if (open) {
            const sAmount = salesByIds.length > 50 ? 5000 : salesByIds.length > 31 ? 4000 : 3000;
            setAppliedSettleAmount(sAmount);
        }
    }, [open, salesByIds]);

    // 가격이 바뀌면, 금액도 바뀌어야 합니다. FIXME:
    const saleListPaymentIds = salesByIds.map((s) => ({
        paymentId: s.paymentId,
        amount: s.amount,
        settlement:
            s.amount === 15000
                ? Number(appliedSettleAmount)
                : s.amount === 45000
                ? Number(appliedSettleAmount * 3)
                : s.settlement,
    }));

    const handleUpdate = async () => {
        handleUpdatePaymentData(saleListPaymentIds);
        dispatch(getSalesByIds(saIds));
    };
    const handleUpdateAndClose = () => {
        dispatch(updateSettlementState(hName, salesByIds));
        setOpen(false);
    };

   

    // const appliedSalesDataByHakwon = sortedResults.length > 0 ? sortedResults[0][1] : saleList;
    const hSettle = salesByIds.reduce(
        (p, n) =>
            p +
            (n.status === 'paid' && n.amount > 0
                ? n.settlement
                : n.isRevoked === true
                ? (appliedSettleAmount * (n.amount - n.cancel_amount)) / 15000 - n.settlement // 부분취소후 // Math.abs(n.settlement) * -1
                : 0),
        0
    );
    const hCount = salesByIds.length;
    const hCountPlayer = salesByIds.filter((sa) => sa.category === '플레이어').length;
    const hCountRunner = salesByIds.filter((sa) => sa.category === '러너').length;
    const hCountETC = hCount - hCountPlayer - hCountRunner;
    const hAmount = salesByIds.reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const hAmountPlayer = salesByIds
        .filter((sa) => sa.category === '플레이어')
        .reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const hAmountRunner = salesByIds
        .filter((sa) => sa.category === '러너')
        .reduce((p, n) => p + n.amount - (n.cancel_amount ? n.cancel_amount : 0), 0);
    const hAmountETC = hAmount - hAmountPlayer - hAmountRunner;

    return (
        <Dialog open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>{hName} 정산금액</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box>
                        <Typography variant="body2">
                            {' '}
                            - 건수 : 총 {hCount} 건 (플레이어: {hCountPlayer}, 러너 : {hCountRunner}, 기타: {hCountETC}){' '}
                        </Typography>
                        <Typography variant="body2">
                            {' '}
                            - 금액 : 총 {numberFormat(hAmount)} 원 (플레이어: {numberFormat(hAmountPlayer)}, 러너 :{' '}
                            {numberFormat(hAmountRunner)}, 기타: {numberFormat(hAmountETC)})
                        </Typography>
                    </Box>
                    {searchType === 'notSettled' && (
                        <Box mr={20}>
                            <Typography variant="body2" color="secondary">
                                적용정산금액 (한 번에 적용이 안되면 다시 눌러주세요)
                            </Typography>

                            <TextField
                                variant="outlined"
                                size="small"
                                value={appliedSettleAmount}
                                onChange={(e) => setAppliedSettleAmount(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '5px' }}
                                onClick={handleUpdate}
                            >
                                적용
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: '5px' }}
                                onClick={handleUpdateAndClose}
                            >
                                갱신하고 닫기
                            </Button>
                        </Box>
                    )}
                </Box>

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.padding}>No</TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleNo}
                                        endIcon={
                                            sortType === 'noAsc' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'noDesc' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        주문번호
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleName}
                                        endIcon={
                                            sortType === 'nameAsc' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'nameDesc' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        원생
                                    </Button>
                                </TableCell>

                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleCreatedAt}
                                        endIcon={
                                            sortType === 'createdAtAsc' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'createdAtDesc' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        결제일
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>수업일</TableCell>
                                {/* <TableCell className={classes.padding} align='center'><Button onClick={toggleLName} endIcon={sortType === 'lNameAsc' ? <ArrowDropDown color='primary' /> : sortType === 'lNameDesc' && <ArrowDropUp color='secondary' />}>수업이름</Button></TableCell> */}

                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleMonthType}
                                        endIcon={
                                            sortType === 'monthTypeAsc' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'monthTypeDesc' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        월구분
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleStatus}
                                        endIcon={
                                            sortType === 'statusA' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'statusD' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        상태
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleAmount}
                                        endIcon={
                                            sortType === 'amountA' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'amountD' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        매출
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleSettle}
                                        endIcon={
                                            sortType === 'settleA' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'settleD' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        <Typography variant="button">정산</Typography>
                                    </Button>
                                </TableCell>

                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleType}
                                        endIcon={
                                            sortType === 'typeAsc' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'typeDesc' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        유형
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>
                                    <Button
                                        onClick={toggleCategory}
                                        endIcon={
                                            sortType === 'categoryAsc' ? (
                                                <ArrowDropDown color="primary" />
                                            ) : (
                                                sortType === 'categoryDesc' && <ArrowDropUp color="secondary" />
                                            )
                                        }
                                    >
                                        구분
                                    </Button>
                                </TableCell>
                                <TableCell className={classes.padding}>정산여부</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            <TableBody>
                                {salesByIds.map((row, index) => (
                                    <TableRow key={row.paymentId}>
                                        <TableCell size="small" className={classes.padding}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell
                                            size="small"
                                            className={classes.padding}
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Typography variant="subtitle2">{row.paymentId}</Typography>
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {row.buyer_name}
                                        </TableCell>

                                        <TableCell size="small" className={classes.padding} width="100px">
                                            {dayjs(row.createdAt * 1000).format('YYYY-MM-DD')}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {row.lessonDate ? dayjs(row.lessonDate * 1000).format('MM/DD(dd)') : ''}
                                        </TableCell>
                                        {/* <TableCell size='small' className={classes.padding} width='20%'>{row.name}</TableCell> */}

                                        <TableCell size="small" className={classes.padding} align="center">
                                            {dayjs(row.lessonDate * 1000).get('date') > 20 &&
                                            row.lessonType === 'regular'
                                                ? String(
                                                      dayjs(row.lessonDate * 1000)
                                                          .add(1, 'month')
                                                          .get('month') + 1
                                                  ) + '월'
                                                : String(dayjs(row.lessonDate * 1000).get('month') + 1) + '월'}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {row.status === 'paid' ? (
                                                '결제'
                                            ) : row.status === 'cancelled' && row.cancel_amount < row.amount ? (
                                                <Typography color="error">부분취소</Typography>
                                            ) : (
                                                <Typography color="error">취소</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            <Typography
                                                variant="body2"
                                                color={
                                                    row.status === 'cancelled' && row.cancel_amount < row.amount
                                                        ? 'error'
                                                        : 'inherit'
                                                }
                                            >
                                                {numberFormat(row.amount - (row.cancel_amount ? row.cancel_amount : 0))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            <Typography variant="body2">
                                                {numberFormat(
                                                    row.status === 'paid' && row.amount > 0
                                                        ? row.settlement
                                                        : row.isRevoked === true
                                                        ? appliedSettleAmount *
                                                              ((row.amount - row.cancel_amount) / 15000) -
                                                          row.settlement //Math.abs(row.settlement) * -1
                                                        : 0
                                                )}
                                            </Typography>
                                        </TableCell>

                                        <TableCell size="small" className={classes.padding}>
                                            {row.totalRound
                                                ? row.totalRound + '개월'
                                                : row.lessonType === 'trial'
                                                ? '원데이'
                                                : row.lessonType === 'premium'
                                                ? '프리미엄'
                                                : row.imp_uid.substring(0, 5) === 'imps_'
                                                ? String(row.paymentId.split('_')[3]) + '개월'
                                                : row.lessonType === 'regular' && row.amount === 45000
                                                ? '3개월'
                                                : row.lessonType === 'regular' && row.amount === 15000
                                                ? '1개월'
                                                : '-'}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {row.category}
                                        </TableCell>
                                        <TableCell size="small" className={classes.padding}>
                                            {row.isSettled
                                                ? '완료'
                                                : row.status === 'paid' && row.amount > 0
                                                ? '정산대상'
                                                : row.isRevoked
                                                ? '상계처리'
                                                : '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {/* 합계 */}
                                <TableRow style={{ backgroundColor: '#fee' }}>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding} align="right">
                                        {hCount}건
                                    </TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}>
                                        <strong>{numberFormat(hAmount)}</strong>
                                    </TableCell>
                                    <TableCell size="small" className={classes.padding}>
                                        <strong>{numberFormat(hSettle)}</strong>
                                    </TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                    <TableCell size="small" className={classes.padding}></TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Typography variant="subtitle2">
                    대상건수 : {hCount}, 매출액: {numberFormat(hAmount)}, 정산금액 : {numberFormat(hSettle)}
                </Typography>
                <Button variant="outlined" onClick={handleUpdateAndClose}>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminSettleDetail;

function numberFormat(inputNumber) {
    if (!inputNumber) return 0;
    if (typeof inputNumber === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
