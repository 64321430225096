import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Typography, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
    ...theme.userPage,
});
const GrayTypo = withStyles({
    root: {
        color: "#585a66",
    }
})(Typography);
const Que = (props) => {
    return (
    <Box>
        <Typography variant='body1' color='secondary' display='inline' style={{ margin: 10 }}>Q : </Typography>
        <Typography variant='body1' color='secondary' display='inline'>{props.que}</Typography>
    </Box>)
}
const Ans = (props) => {
    return (
    <Box>
        <GrayTypo variant='body1' color='secondary' display='inline' style={{ margin: 10 }}>A : </GrayTypo>
        <GrayTypo variant='body1' color='secondary' display='inline'>{props.ans}</GrayTypo>
        


    </Box>
    )
}
function QnaAccordian(props) {
    const { data } = props;
    
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box>
            {
                data.map((dat, index) =>
                    <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)} key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Que que={dat.que} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Ans ans={dat.ans} />
                        </AccordionDetails>
                    </Accordion>
                )
            }

        </Box>
    );
}
export default withStyles(styles)(QnaAccordian)
