import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, updateCurriculum, postCurriculum, deleteCurriculum, deleteCurriculumImagesAll } from '../../redux/actions/dataActions';
import UploadImage from '../../components/util/UploadImage';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TextField, InputAdornment } from '@material-ui/core';
import { AddTwoTone, DeleteRounded, DescriptionRounded, FileCopyRounded } from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPages,
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth: 650,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tf80: {
        width: '80%',
        margin: theme.spacing(1)
    },
    tf40: {
        width: '39%',
        margin: theme.spacing(1)
    },
    btn: {
        marginLeft: theme.spacing(1)
    }

});




function DialogEditDataGrid(props) {
    const { classes } = props;
    //const [cvalue, setCvalue] = React.useState(props.value);

    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(true);
    //const { hakwon } = useSelector(state => state.manager);
    const { curriculum, product, dataLoading } = useSelector(state => state.data);
    const dispatch = useDispatch();

    // field
    const initialD = {
        curriculumId: '',
        curriculumName: '',
        productName: '',
        productId: '',
        yyyymm: '',
        week: '',
        status: '',
        desc: {
            목표: '',
            활동명: '',
            활동: '',
            활동자료: '',
            준비: '',
            워크북: ''
        },
        category: '',
        packages: '',
        createdAt: '',
        createdBy: '',
        startDate: '',
        endDate: '',
        images: []
    }
    const [data, setData] = useState(initialD);
    const [copied, setCopied] = useState(initialD);

    const [newUrl, setNewUrl] = useState('');
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        //setCvalue(props.value);
        setOpen(props.dialogOpen);
        if (curriculum && typeof curriculum.productId !== 'undefined') {
            dispatch(getProduct(curriculum.productId));
            setData({
                curriculumName: curriculum.curriculumName,
                productName: product.productName,
                productId: product.productId,
                curriculumId: curriculum.curriculumId,
                yyyymm: curriculum.yyyymm,
                week: curriculum.week,
                desc: curriculum.desc,
                status: curriculum.status,
                category: curriculum.category,
                packages: curriculum.packages,
                createdAt: dayjs(curriculum.createdAt * 1000).format('YYYY-MM-DD HH:mm:ss'),
                createdBy: curriculum.createdBy,
                startDate: dayjs(curriculum.startDate * 1000).format('YYYY-MM-DD'),
                endDate: dayjs(curriculum.endDate * 1000).format('YYYY-MM-DD'),
                videos: curriculum.videos,
                images: curriculum.images,
                changedAt: dayjs(curriculum.changedAt * 1000).format('YYYY-MM-DD HH:mm:ss'),
                changedBy: curriculum.changedBy
            })
            setUrls(
                curriculum.images ? curriculum.images : []
            )
        }


    }, [props.dialogOpen, dispatch, curriculum, product.productId, product.productName]);


    const onChangeData = (e) => {
        e.preventDefault();
        setData({
            ...data, [e.target.name]: e.target.value
        });
    };
    const onChangeDataDesc = (e) => {
        setData({
            ...data,
            desc: {
                ...data.desc,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleDelete = (cid, pid)=>{
        dispatch(deleteCurriculum(cid, pid));
        props.setDialogOpen(false);
        setOpen(false);
        setData(initialD);
        setUrls([]);
        setNewUrl('');
    }

    const handleClose = () => {
        setOpen(false);
        setData(initialD);
        props.setDialogOpen(false);
        setUrls([]);
        setNewUrl('');
    };
    const handleAddNewUrl = () => {
        if (newUrl) {
            setUrls([...urls, newUrl]);
            setNewUrl('');
        } else {
            alert('입력해주세요')
        }

    }
    const handleDeleteUrl = (index) => {
        let temp = [...urls];
        temp.splice(index, 1);
        setUrls(temp);
    }
    const handleDeleteAll = () => {
        dispatch(deleteCurriculumImagesAll(data.curriculumId));
    }
    const onChangeUrls = (e, index) => {
        let temp = [...urls];
        temp.splice(index, 1, e.target.value);
        setUrls(temp);
    }

    const newCurri = () => {
        setEditMode(false);
        setData({
            productName: '',
            productId: '',
            curriculumId: '',
            curriculumName: '',
            yyyymm: '',
            week: '',
            desc: {
                목표: '',
                활동명: '',
                활동: '',
                활동자료: '',
                준비: '',
                워크북: ''
            },
            category: curriculum.category,
            packages: '',
            createdAt: '',
            createdBy: '',
            status: '',
            startDate: '',
            endDate: '',
            images: [],
            changedAt: '',
            changedBy: ''
        });

    }
    const handleSave = () => {
        const newData = {
            productName: data.productName,
            productId: data.productId,
            category: data.category,
            curriculumName: data.curriculumName,
            yyyymm: data.yyyymm,
            week: data.week,
            desc: data.desc,
            status: data.status,
            packages: data.packages,
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            startDate: data.startDate,
            endDate: data.endDate,
            images: urls
        }
        const updateData = {
            ...newData,
            curriculumId: data.curriculumId,
        }
        if (editMode) {
            dispatch(updateCurriculum(updateData.curriculumId, newData));

        } else {
            // 신규등록
            dispatch(postCurriculum(newData.productId, newData));
            setEditMode(true)
        }
        props.setDialogOpen(false);
        setOpen(false);
        setData(initialD);
        setUrls([]);
        setNewUrl('');
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const copyCurri = () => {
        setCopied(data);
    }
    const pasteCurri = () => {
        setData(copied);
        setCopied(initialD);
    }



    return (
        <div>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='md'
                fullScreen={fullScreen} /* {fullscreen} */
                className={classes.dialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </DialogTitle>

                <DialogContent dividers>
                    <Typography variant='body1' gutterBottom>{props.textContent}</Typography>

                    <Box height={20} />
                    <Box>

                        <Typography variant='subtitle1' gutterBottom> 상품 </Typography>
                        {editMode &&
                            <TextField
                                label='관련 상품명'
                                color='primary'
                                variant='outlined'
                                name='productName'
                                value={data.productName}
                                onChange={onChangeData}
                                size='small'
                                className={classes.tf80}
                                readOnly
                            />
                        }
                        <TextField
                            label='관련 상품코드'
                            color='primary'
                            variant='outlined'
                            name='productId'
                            value={data.productId}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf80}
                            readOnly
                        /><br />
                        <Typography variant='subtitle1' gutterBottom> 커리큘럼 </Typography>
                        <Box align='right'>
                            {
                                editMode ?
                                    <Button variant='contained' color='primary' onClick={newCurri} size='small'>+ 신규등록</Button>
                                    :
                                    <Button variant='contained' color='secondary' onClick={() => { setEditMode(true); setOpen(false) }} size='small'>x 종료하고 닫기</Button>
                            }

                            <Button className={classes.btn} variant='outlined' color='primary' onClick={copyCurri} size='small' startIcon={<FileCopyRounded />}>복사해두기</Button>
                            <Button className={classes.btn} variant='outlined' color='primary' onClick={pasteCurri} size='small' startIcon={<DescriptionRounded />}>붙여넣기</Button>


                        </Box>
                        {editMode &&
                            <TextField
                                label='커리큘럼 아이디'
                                color='primary'
                                variant='outlined'
                                name='curriculumId'
                                value={data.curriculumId}
                                onChange={onChangeData}
                                size='small'
                                className={classes.tf80}
                                required
                            />}
                        <TextField
                            label='커리큘럼 명'
                            color='primary'
                            variant='outlined'
                            name='curriculumName'
                            value={data.curriculumName}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='연령/카테고리'
                            color='primary'
                            variant='outlined'
                            name='category'
                            value={data.category}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <Typography variant='subtitle1' gutterBottom> 일정 </Typography>
                        <TextField
                            label='대상월'
                            color='primary'
                            variant='outlined'
                            name='yyyymm'
                            value={data.yyyymm}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf40}
                            required
                            helperText='YYYYMM 형태로 입력'
                        />
                        <TextField
                            label='주차'
                            color='primary'
                            variant='outlined'
                            name='week'
                            value={data.week}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf40}
                            required
                        /><br />
                        <TextField
                            label='상태'
                            color='primary'
                            variant='outlined'
                            name='status'
                            value={data.status}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf40}
                            required
                        /><br />

                        <Typography variant='subtitle1' gutterBottom> 활동정보 </Typography>
                        <TextField
                            label='활동'
                            color='primary'
                            variant='outlined'
                            name='활동'
                            value={data.desc.활동}
                            onChange={onChangeDataDesc}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='활동명'
                            color='primary'
                            variant='outlined'
                            name='활동명'
                            value={data.desc.활동명}
                            onChange={onChangeDataDesc}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='목표'
                            color='primary'
                            variant='outlined'
                            name='목표'
                            value={data.desc.목표}
                            onChange={onChangeDataDesc}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='활동자료'
                            color='primary'
                            variant='outlined'
                            name='활동자료'
                            value={data.desc.활동자료}
                            onChange={onChangeDataDesc}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='준비'
                            color='primary'
                            variant='outlined'
                            name='준비'
                            value={data.desc.준비}
                            onChange={onChangeDataDesc}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='워크북'
                            color='primary'
                            variant='outlined'
                            name='워크북'
                            value={data.desc.워크북}
                            onChange={onChangeDataDesc}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />
                        <TextField
                            label='패키지'
                            color='primary'
                            variant='outlined'
                            name='packages'
                            value={data.packages}
                            onChange={onChangeData}
                            size='small'
                            className={classes.tf80}
                            required
                        /><br />

                    </Box>
                    <Typography variant='subtitle1' gutterBottom> 이미지/영상정보 </Typography>
                    <Typography variant='body2' gutterBottom> 이미지는 [+이미지]로 추가, 영상은 URL을 직접 입력해주세요.(예, https://vimeo.com/515602441) </Typography>

                    <Box>
                        <UploadImage />
                        <TextField
                            label='URL추가'
                            variant='outlined'
                            value={newUrl}
                            onChange={e => setNewUrl(e.target.value)}
                            margin='dense'
                            size='small'
                            fullWidth
                        />
                        <Button color='primary' startIcon={<AddTwoTone />} onClick={handleAddNewUrl}>추가</Button>
                        {
                            urls &&
                            urls.map((ima, ind) =>

                                <TextField
                                    key={ind}
                                    value={ima}
                                    onChange={e => onChangeUrls(e, ind)}
                                    label={'index_' + ind}
                                    variant='outlined'
                                    margin='dense'
                                    size='small'
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <IconButton
                                                onClick={() => handleDeleteUrl(ind)}
                                                edge="start"
                                            ><DeleteRounded />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            )
                        }
                        <Button color='primary' startIcon={<DeleteRounded />} onClick={handleDeleteAll}>전부삭제</Button>


                    </Box>
                    <Box height={20} />
                    <Typography variant='subtitle1' gutterBottom> 작성자 </Typography>
                    <TextField
                        label='최초작성일'
                        color='primary'
                        variant='outlined'
                        name='createdAt'
                        value={dayjs(data.createdAt * 1000)}
                        onChange={onChangeData}
                        size='small'
                        className={classes.tf40}
                        readOnly
                    />
                    <TextField
                        label='작성자'
                        color='primary'
                        variant='outlined'
                        name='createdBy'
                        value={data.createdBy}
                        onChange={onChangeData}
                        size='small'
                        className={classes.tf40}
                        readOnly
                    /><br />
                    {
                        typeof curriculum.changedAt !== 'undefined' &&
                        <Box>
                            <TextField
                                label='수정일'
                                color='primary'
                                variant='outlined'
                                name='changedAt'
                                value={data.changedAt}
                                onChange={onChangeData}
                                size='small'
                                className={classes.tf40}
                                readOnly
                            />
                            <TextField
                                label='작성자'
                                color='primary'
                                variant='outlined'
                                name='createdBy'
                                value={data.createdBy}
                                onChange={onChangeData}
                                size='small'
                                className={classes.tf40}
                                readOnly
                            /><br />
                        </Box>
                    }

                </DialogContent>

                <DialogActions>
                    <Button autoFocus={false} onClick={handleClose} color="primary" variant='outlined'>닫기</Button>
                    <Button onClick={()=>handleDelete(data.curriculumId, data.productId)} color='secondary' variant='contained' disbaled={dataLoading}>삭제</Button>
                    <Button onClick={handleSave} color="primary" variant='contained' disabled={dataLoading}>
                        {props.actionButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default withStyles(styles)(DialogEditDataGrid);