import React from 'react';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
    ...theme.userPages,
    subGrid: {
        padding: 15
    },
});
function SubGrid(props) {
    const { classes } = props;
    return (
        <Box className={classes.subGrid}>
            {props.children}
        </Box>
    )
}

export default withStyles(styles)(SubGrid);

