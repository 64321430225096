import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
    ...theme.userPages,
   
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


// 다이어로그
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});



function PostCode(props) {
    //const { classes } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        } 

        props.onChange(fullAddress);
        setOpen(false);

    }
    // [props] ==> title / textContent / mainContent / actionButton / actionHandler
    // 
    return (
        <div>
            {/* 호출하는 부분  */}
            <Box onClick={handleClickOpen}>
                {props.children}
            </Box>

            {/* 팝업되는 부분 */}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth='sm'
                fullScreen={fullScreen}>

                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DaumPostcode
                        onComplete={handleComplete}
                        height={500}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant='outlined'>닫기</Button>
                   {/*  <Button onClick={props.actionHandler} color="primary" variant='contained'>
                        {props.actionButton}
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default withStyles(styles)(PostCode);


