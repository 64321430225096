import React, { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyClasses } from '../../redux/actions/enrollActions';
// compo
import MyClassInfo from '../myclass/MyClassInfo';

// mui
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box component='div' style={{ padding: '3px 30px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 100,
        height: 50,
        borderBottom: '1px solid',
        borderBottomColor: '#dbdbdb',
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(2),
        fontFamily: [
        ].join(','),
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,


        },
        '&$selected': {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: '#98e6b5'
        },
        '&:focus': {
            color: theme.palette.secondary.main,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const styles = (theme) => ({
    ...theme.userPages,
    root: {
        flexGrow: 1,
        backgroundColor: 'transparent', //'#eeeeee',//theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    tabs: {
        [theme.breakpoints.up('sm')]: {
            borderRight: `1px solid #dbdbdb`,
            minWidth: 80
        },

    },

});


function TabsVertical(props) {
    const { classes } = props;
    const { myclasses } = useSelector(state => state.enroll);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        dispatch(getMyClasses());
    }, [dispatch]);

    
    // 초기결제만 호출(자동결제는 별도 관리)
    // 정렬
    const sortedClasses = myclasses
        .filter(sa=>sa.status ==='paid' || sa.status ==='cancelled')
        .filter(sa=>sa.merchant_uid.length === 15)
        .sort((a, b) => b.paidAt - a.paidAt);
    
    const monthlyPayments = myclasses
        .filter(sa=>sa.merchant_uid.length > 15);
    //console.log(sortedClasses);

    

    return (
        <div>
            {
                sortedClasses.length === 0 && <Box>
                    <Typography gutterBottom>수강 신청 정보가 없습니다.</Typography>
                    <Typography></Typography>
                    <Box height={200} />
                </Box>
            }
            <div className={classes.root}>

                <Tabs
                    orientation={matches ? 'horizontal' : 'vertical'}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs"
                    className={classes.tabs}
                >
                    {
                        sortedClasses.map((cl, index) =>
                            <StyledTab key={index} label={<Typography variant='subtitle1'>{'수업 ' + Number(index + 1)}</Typography>} {...a11yProps(index)} />)
                    }

                </Tabs>
                {
                    sortedClasses.map((cl, index) =>
                        <TabPanel key={index} value={value} index={index}>
                            <MyClassInfo myclass={cl} monthlyPayments={monthlyPayments}/>
                        </TabPanel>
                    )
                }


            </div>
        </div>
    );
}
export default withStyles(styles)(TabsVertical);