import React, { useState } from 'react';

// MUI stuff
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
    Box, Grid, Paper, Button, ButtonGroup,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell,
    FormControl, FormControlLabel, Radio, RadioGroup, Select, MenuItem,
    TextField, Typography, FormGroup, Checkbox, useMediaQuery
} from '@material-ui/core';
import dayjs from 'dayjs';
import PostCode from '../util/PostCode';
import { SearchRounded } from '@material-ui/icons';

const NewStudent = (props) => {
    const { classes, value, setValue } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [expand1, setExpand1] = useState(false);
    const [expand2, setExpand2] = useState(false);
    const onChangeValue = e => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }
    return (
        <Box>
            <LeftTitleTextField title='원생이름*'
                fullWidth
                value={value.studentNameForHakwon}
                name='studentNameForHakwon'
                onChange={onChangeValue}
            />
            <Box height={20} />
            <LeftTitle title='학부모입력*'>
                <ButtonGroup color='secondary' variant='contained'>
                    <Button onClick={() => { setExpand1(!expand1); setExpand2(false); }}>학부모 검색 후 선택</Button>
                    <Button onClick={() => { setExpand2(!expand2); setExpand1(false); }}>학부모와 함께 등록(1인만 가능)</Button>

                </ButtonGroup>
            </LeftTitle>

            {/* 검색후 선택 */}
            {
                expand1 &&
                <Box m={1} p={matches ? 1 : 2} className={classes.back}>
                    <Typography variant='subtitle2' color='secondary'>[학부모 검색 후 선택]</Typography>
                    <LeftTitleTextFieldButton
                        title='학부모이름'
                        onClick={null}
                        buttonName='검색'
                    />
                    <Box height={10} />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#eee' }}>
                                    <TableCell className={classes.padding}>관계</TableCell>
                                    <TableCell className={classes.padding}>성함</TableCell>
                                    <TableCell className={classes.padding}>연락처</TableCell>
                                    <TableCell className={classes.padding}>선택하기</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }


            {/* 학부모정보와함께 등록 */}
            {
                expand2 && <Box m={1} p={matches ? 1 : 2} className={classes.back}>
                    <Typography variant='subtitle2' color='secondary'>[학부모와 함께 등록]</Typography>
                    <LeftTitleTextField
                        title='학부모이름'
                        onClick={null}
                    />
                    <LeftTitleRadio title='관계*' value={null} onChange={null}>
                        <FormControlLabel value={'mother'} control={<Radio />} label='어머니' />
                        <FormControlLabel value={'father'} control={<Radio />} label='아버지' />
                        <FormControlLabel value={'etc'} control={<Radio />} label='기타' />
                    </LeftTitleRadio>

                    <LeftTitle title='직업'>
                        <FormControl variant="outlined" size='small' margin='dense' color='secondary' fullWidth>
                            <Select
                                value={'선택'}
                                onChange={null}
                            >
                                <MenuItem value="">
                                    <em>직업선택</em>
                                </MenuItem>
                                <MenuItem value={10}>직업ㅇ라알ㅇㄹㅇㄹ</MenuItem>
                                <MenuItem value={20}>직업2ㅇㄹㅇㄹㅇㄹㅇㄹ</MenuItem>
                                <MenuItem value={30}>직업3ㅇㄹㅇㄹㅇㄹㅇㄹㅇㄹ</MenuItem>
                            </Select>
                        </FormControl>
                    </LeftTitle>

                    <LeftTitleTextField
                        title='전화번호'
                        value={null}
                        onChange={null}
                    />
                    <LeftTitleTextFieldButton
                        title='휴대폰*'
                        value={null}
                        onChange={null}
                        required
                        buttonName='중복검사'
                    />

                    <LeftTitleTextField
                        title='메모'
                        value={null}
                        onChange={null}
                        rows={3}
                        multiline
                    />
                </Box>
            }

            <Box height={20} />
            <Box>
                {/* 학생정보 */}
                <LeftTitleTextField
                    title='원생 생년월일*'
                />
                <LeftTitleRadio title='성별' value={value.sex} onChange={onChangeValue}>
                    <FormControlLabel value='남' control={<Radio color='primary' name='sex' />} label='남' />
                    <FormControlLabel value='여' control={<Radio color='primary' name='sex' />} label='여' />
                </LeftTitleRadio>
                <LeftTitleTextField
                    title='학교 혹은 유치원'
                />
                <LeftTitle title='주소검색'>
                    <PostCode title='주소검색' textContent="주소검색" onChange={(addr) => setValue({ ...value, juso1: addr })}>
                        <Button variant='contained' color='secondary' startIcon={<SearchRounded />}>주소 검색</Button>
                    </PostCode>
                </LeftTitle>


                <LeftTitleTextField
                    title='주소*'
                    value={value.juso1}
                    name='juso1'
                    onChange={onChangeValue}
                />
                <LeftTitleTextField
                    title='상세주소'
                />
                <LeftTitleTextField
                    title='원생 핸드폰'
                />
                <LeftTitleCheckbox title='소속반*'>
                    <FormControlLabel
                        label="소리반" control={<Checkbox checked={null} onChange={null} name="checkedA" />}
                    />
                    <FormControlLabel
                        label="피카소반" control={<Checkbox checked={null} onChange={null} name="checkedA" />}
                    />
                </LeftTitleCheckbox>
                <LeftTitleRadio title='통원방법' value={null} onChange={null}>
                    <FormControlLabel
                        value={'all'}
                        control={<Radio />}
                        label='학원차량'
                    />
                    <FormControlLabel
                        value={'general'}
                        control={<Radio />}
                        label='도보'
                    />
                </LeftTitleRadio>
                <LeftTitleTextField
                    title='스마트e피아노ID'
                />
                <LeftTitleCheckbox title='교육과목*'>
                    <FormControlLabel
                        label="음악" control={<Checkbox checked={null} onChange={null} name="checkedA" />}
                    />
                    <FormControlLabel
                        label="미술" control={<Checkbox checked={null} onChange={null} name="checkedA" />}
                    />
                </LeftTitleCheckbox>
                <LeftTitleCheckbox title='수업요일'>
                    <FormControlLabel label="월" control={<Checkbox checked={null} onChange={null} name="checkedA" />} />
                    <FormControlLabel label="화" control={<Checkbox checked={null} onChange={null} name="checkedA" />} />
                    <FormControlLabel label="수" control={<Checkbox checked={null} onChange={null} name="checkedA" />} />
                    <FormControlLabel label="목" control={<Checkbox checked={null} onChange={null} name="checkedA" />} />
                    <FormControlLabel label="금" control={<Checkbox checked={null} onChange={null} name="checkedA" />} />
                    <FormControlLabel label="토" control={<Checkbox checked={null} onChange={null} name="checkedA" />} />
                </LeftTitleCheckbox>

                <LeftTitleTextField
                    title='교육비(음악)'
                />
                <LeftTitleTextField
                    title='교육비(미술)'
                />
                <LeftTitleTextField
                    title='기타비용'
                />
                <LeftTitleTextField
                    title='교육비납부일'
                />

                <LeftTitleRadio
                    title='교육비알림' value={null} onChange={null} >
                    <FormControlLabel
                        value={'all'}
                        control={<Radio />}
                        label='자동'
                    />
                    <FormControlLabel
                        value={'general'}
                        control={<Radio />}
                        label='수동'
                    />
                </LeftTitleRadio>
                <LeftTitleTextField
                    title='세부내용'
                    value={null}
                    onChange={null}
                    rows={3}
                    multiline
                />
                <LeftTitleTextField
                    title='진도상황'
                    value={null}
                    onChange={null}
                    rows={3}
                    multiline
                />
                <LeftTitleTextField
                    title='추가가족사항'
                    value={null}
                    onChange={null}
                    rows={3}
                    multiline
                />
                <LeftTitleTextFieldButton
                    title='등ㆍ하원 키'
                    buttonName={'중복검사'}
                />
                <LeftTitleTextField
                    title='메모'
                    value={null}
                    onChange={null}
                    rows={3}
                    multiline
                />
                <LeftTitleTextField
                    title='등록일자'
                    type='date'
                    defaultValue={dayjs().format('YYYY-MM-DD')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <LeftTitleRadio title='활성화' value={null} onChange={null}>
                    <FormControlLabel value={'active'} control={<Radio />} label='활성화' />
                    <FormControlLabel value={'inactive'} control={<Radio />} label='비활성화' />
                </LeftTitleRadio>
                <LeftTitleRadio title='퇴원여부' value={null} onChange={null}>
                    <FormControlLabel value={'active'} control={<Radio />} label='등원중' />
                    <FormControlLabel value={'dormant'} control={<Radio />} label='휴원' />
                    <FormControlLabel value={'out'} control={<Radio />} label='퇴원' />
                </LeftTitleRadio>

                <LeftTitleTextField
                    title='퇴원일자'
                    type='date'
                    defaultValue={dayjs().format('YYYY-MM-DD')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Box>
        </Box >
    )
}
const LeftTitle = ({ title, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
        <Grid item xs={matches ? 12 : 10}>
            {children}
        </Grid>
    </Grid>);
}
const LeftTitleTextField = ({ title, ...other }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
        <Grid item xs={matches ? 12 : 10}>
            <TextField
                {...other}
                variant='outlined'
                size='small'
                margin='dense'
                fullWidth
                color='secondary'
            />
        </Grid>
    </Grid>);
}
const LeftTitleTextFieldButton = ({ title, buttonName, onClick, ...other }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (<Grid container direction='row' alignItems='center'>
        <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
        <Grid item xs={matches ? 8 : 6}>
            <TextField
                {...other}
                variant='outlined'
                size='small'
                margin='dense'
                fullWidth
                color='secondary'
            />
        </Grid>
        <Grid item xs={4}>
            <Button style={{ marginLeft: 20 }} variant='contained' color='secondary' onClick={onClick}>{buttonName}</Button>
        </Grid>
    </Grid>);
}

const LeftTitleRadio = ({ title, value, onChange, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container direction='row' alignItems='center'>
            <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
            <Grid item xs={matches ? 12 : 10}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="category" name="category" value={value} onChange={onChange} row>
                        {children}
                    </RadioGroup>
                </FormControl>
            </Grid>

        </Grid>

    )
}
const LeftTitleCheckbox = ({ title, children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container direction='row' alignItems='center'>
            <Grid item xs={matches ? 12 : 2} justifyContent='flex-start' >{title}</Grid>
            <Grid item xs={matches ? 12 : 10}>
                <FormGroup row>
                    {children}
                </FormGroup>
            </Grid>

        </Grid>

    )
}


const styles = (theme) => ({
    ...theme.userPage,

    table: {

    },
    back: {
        border: '1px solid #ddd',
        minHeight: 200
    },

    padding: {
        paddingRight: 5,
        paddingLeft: 5,
    },

    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },

});
export default withStyles(styles)(NewStudent);
