/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
//import utc from 'dayjs/plugin/utc';
import { RRule, RRuleSet } from 'rrule';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { saveNewLesson, getAllProductGroup, postBactchLessons } from '../../redux/actions/managerActions';
import { getProducts } from '../../redux/actions/dataActions';
import { isLogged } from '../../redux/actions/userActions';
// components
import CenterLoading from '../../components/layout/CenterLoading';
import LessonTimePicker from './LessonTimePicker';
import MakeLessonList from './MakeLessonList';
import DateTimeSelector from './DateTimeSelector';
// MUI stuff
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    InputAdornment,
    FormControlLabel,
    RadioGroup,
    Radio,
    Divider,
    Paper,
    Checkbox,
} from '@material-ui/core';
import { CalendarTodayRounded, NoteAddRounded, ReportRounded } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const styles = (theme) => ({
    ...theme.userPage,
    palette: {
        type: 'dark',
        action: {
            disabled: '#666',
        },
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%',
        backgroundColor: '#eee',
    },
    halftextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#eee',
        width: '43%',
    },
    twoThirdtextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#eee',
        width: '60%',
    },
    switch: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    form: {
        width: '90%',
        margin: theme.spacing(1),
        backgroundColor: '#eee',
    },
    select: {
        backgroundColor: '#eee',
        hover: '#eee',
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toggleBtn: {
        backgroundColor: '#eee',
    },
    radioGroup: {
        disabledColor: '#eee',
    },
    radio: {
        root: {
            color: '#fff',
        },
        disabled: {
            color: '#eee',
        },
    },
});
const WhiteRadio = withStyles({
    root: { color: '#666' },
})(Radio);

export const LessonAdd = (props) => {
    const { classes, setAddMode, setLessonInfo, history } = props;
    const { credentials } = useSelector((state) => state.user);
    const { managerErrors, managerLoading, productGroupAll } = useSelector((state) => state.manager);
    const { products } = useSelector((state) => state.data);
    const { loading } = useSelector((state) => state.UI);
    const dispatch = useDispatch();

    // state
    //const [prodAge, setProdAge] = useState('');
    const [lname, setLname] = useState('');
    const [enr] = useState(0);
    const [cap, setCap] = useState(50);
    const [descForParent, setDescForParent] = useState({
        info1: '',
        info2: '',
    });

    // 반복 라디오
    const [repeatRadio, setRepeatRadio] = useState('one');
    const onChangeRepeatRadio = (e) => {
        setRepeatRadio(e.target.value);
    };
    // 상세
    const [price, setPrice] = useState(0);
    const [settle, setSettle] = useState(0);
    const [count] = useState(0);
    const [duration, setDuration] = useState('50');
    const [status] = useState(true); // boolen => DB에는 String

    const initialDatetime = localStorage.lastViewDate
        ? dayjs(localStorage.lastViewDate).set('hour', 13).set('minute', 0).set('second', 0)
        : dayjs().set('hour', 13).set('minute', 0).set('second', 0);
    const [startDate, setStartDate] = useState(initialDatetime);
    const [endDate, setEndDate] = useState(dayjs(initialDatetime).add(40, 'minute'));

    const [isCountLimit] = useState(true);
    const [exception, setException] = useState(false);
    const [exDates, setExDates] = useState('');
    const [rDates, setRDates] = useState('');

    const [withoutTimeTable, setWithoutTimeTable] = useState(true);

    const [validCheck, setValidCheck] = useState(false);
    const [errorMessage, setErrorMessage] = useState('입력된 값을 확인하세요');

    // 상품선택
    const [selectedProductGroupId, setSelectedProductGroupId] = useState('');
    const [selectedProductId, setSelectedProductId] = useState('');
    // const [, setCurris] = useState([]);
    const [categories, setCategories] = useState([]);
    // 시간대 설정
    const initialTimes = withoutTimeTable ? ['15:00'] : ['13:00', '14:00', '15:00', '16:00'];
    const [times, setTimes] = useState(initialTimes);
    // 레슨자동생성
    const [lessonLists, setLessonLists] = useState([]);
    // weekday
    const [wday, setWday] = useState([]);
    const handleWday = (e, week) => {
        setWday([week]);
    };
    // 결과생성여부
    const [isCreated, setIsCreated] = useState(false);

    // get lessons & products
    useEffect(() => {
        // dispatch(getProductsWithCurri());
        dispatch(getProducts());
        //dispatch(getCurriculums());
        dispatch(getAllProductGroup());
    }, [dispatch]);

    const makeCategoryArray = (products, pid) => {
        products
            .filter((p) => p.productId === pid)
            .map((prod) => {
                // 세팅가능한 값들
                setPrice(numberFormat(prod.price));
                setSettle(numberFormat(prod.settlement));
                setLname(prod.productName);
                prod.isRepeat === true ? setRepeatRadio('repeat') : setRepeatRadio('one');

                // 카테고리 생성
                // let cats = curriculums.filter((c) => c.productId === pid).map((c) => c.category);
                // const uniqCats = cats.filter((f, i) => cats.indexOf(f) === i);
                // console.log(uniqCats, cats, prod);

                let cats;
                switch (prod.productGroupId) {
                    case 'musicgogo':
                        cats = ['플레이어', '러너'];
                        break;
                    case 'musicgogoPremium':
                        cats = ['플레이어'];
                        break;
                    case 'musicgogoOneDayClass':
                        cats = ['4-5세', '6-7세'];
                        break;
                    case 'pianogogoOneDayClass':
                        cats = ['6-7세', '8-9세'];
                        break;
                    default:
                        cats = ['플레이어', '러너'];
                        break;
                }

                setCategories(cats);
            });
    };

    const onChangeProductGroup = (e) => {
        setCategories([]);
        setSelectedCategory('');
        if (e.target.value !== '') {
            setSelectedProductGroupId(e.target.value);
            // setCurris([]);
            const pid = productGroupAll.filter((g) => g.productGroupId === e.target.value)[0].productIds[0];
            
            makeCategoryArray(products, pid);
        }
    };
    const handleWithoutTimeTable = () => {
        withoutTimeTable ? setCap(10) : setCap(50);
        setWithoutTimeTable(!withoutTimeTable);
    };

    // }
    // 커리큘럼 선택

    const [selectedCategory, setSelectedCategory] = useState('');
    const onChangeCategory = (e) => {
        
        setSelectedCategory(e.target.value);
        const next = lname.split('[')[0] + '[' + e.target.value + ']';
        setLname(next);

        if (selectedProductGroupId === 'musicgogoOneDayClass') {
            setSelectedProductId('sOjJwaCrMxIMqst181l7');
        } else if (selectedProductGroupId === 'pianogogoOneDayClass') {
            setSelectedProductId('C3i47lsVsh90QFUBuSDL');
        }
    };

    const handleSaveLesson = () => {
        
        if (
            isEmpty(lname) ||
            isEmpty(selectedCategory) ||
            isEmpty(selectedProductId) ||
            (startDate / 1000 > endDate / 1000 && (isCountLimit === false || repeatRadio === 'one'))
        ) {
            setValidCheck(true);
            setErrorMessage('수업명, 연령/카테고리, 상품, 또는 날짜가 맞는 지 확인하세요.');
        } else if (isEmpty(startDate) || (isEmpty(endDate) && isCountLimit === false)) {
            setValidCheck(true);
            setErrorMessage('날짜를 바르게 선택하셔야 합니다.');
        } else if (repeatRadio === 'repeat' && wday === []) {
            setValidCheck(true);
            setErrorMessage('요일을 선택하셔야 합니다.');
        } else if (repeatRadio === 'one' && isEmpty(endDate)) {
            setValidCheck(true);
            setErrorMessage('종료일을 선택하셔야 합니다.');
        } else if (exDates !=='' && !checkDate(exDates)) {
            setValidCheck(true);
            setErrorMessage('날짜 형식을 바르게 입력해주세요.');
        } else if (rDates !=='' && !checkDate(rDates)) {
            setValidCheck(true);
            setErrorMessage('날짜 형식을 바르게 입력해주세요.');
        } else {
            let inheritCount = products.filter((prod) => prod.productId === selectedProductId)
                ? products.filter((prod) => prod.productId === selectedProductId)[0].count
                : null;
            let inheritLessonType = products.filter((prod) => prod.productId === selectedProductId)
                ? products.filter((prod) => prod.productId === selectedProductId)[0].lessonType
                : null;

            let newLessonInfo = {
                hakwonId: credentials.myHakwon.hakwonId, // back
                //curriculumId: selectedCurriId,   // back
                productId: selectedProductId,
                category: selectedCategory,
                lessonName: lname + viewHour(dayjs(startDate).format('HH:mm')),
                startDate: startDate / 1,
                endDate: isEmpty(endDate) && isCountLimit === true ? null : endDate / 1,
                enrolled: Number(enr),
                capacity: Number(cap),
                isPaid: Number(price) > 0 ? true : false,
                isFull: false,
                // desc: descr 삭제 -- 커리큘럼에서 받아온다.
                descForParent: descForParent,
                price: Number(price.replace(',', '')), // back
                settlement: Number(settle.replace(',', '')), // back
                isRepeat: repeatRadio === 'repeat' ? true : false,
                weekday: wday, // []
                duration: repeatRadio === 'repeat' ? mH(duration) : null,
                count: count > 0 ? Number(count) : 0,
                isCountLimit: isCountLimit,
                exDate: exDates.trim().replace(' ', '').split(','),
                rDate: rDates.trim().replace(' ', '').split(','),
                status: status ? 'open' : 'closed',
                lessonType: inheritLessonType,
                recurrentDates:
                    repeatRadio === 'repeat'
                        ? makeRrule(wday, startDate, endDate, rDates, exDates, count, isCountLimit, inheritCount)
                        : null,
            };

            const res = dispatch(isLogged(history));
            if (res === 'logged') {
                dispatch(saveNewLesson(newLessonInfo));

                setLessonInfo(null);
                setAddMode(false);
            }
        }
    };

    // 일괄 저장
    const handleSaveLessonsBatch = async () => {
        if (isCreated === false) {
            alert('수업 생성 이후에 저장할 수 있습니다. +생성버튼을 눌러주세요');
            
        } else {
            // (1) 생성버튼을 누르면 lessonLists 가 생김 (productId 배열)
            let tobeUploadedLessons = [];

            // (2) 공통정보 생성
            let newLessonCommonInfo = {
                hakwonId: credentials.myHakwon.hakwonId, // 체크필요
                category: selectedCategory,
                enrolled: Number(enr),
                capacity: Number(cap),
                isPaid: Number(price.replace(',', '')) > 0 ? true : false,
                isFull: false,
                descForParent: descForParent,
                isRepeat: repeatRadio === 'repeat' ? true : false,
                weekday: wday,
                duration: repeatRadio === 'repeat' ? mH(duration) : null,
                exDate: exDates.trim().replace(' ', '').split(','), // FIXME:
                rDate: rDates.trim().replace(' ', '').split(','),
                status: status ? 'open' : 'closed',
                isCountLimit: isCountLimit,
                isWithoutTimeTable: withoutTimeTable ? true : false,
            };

            // (3) 고유정보
            // 총 times length * lessonLists length 만큼 레슨이 생성됨

            lessonLists.map((pid) => {
                const prod = products.filter((p) => p.productId === pid)[0]; // products에서 필터링

                const checkCount = prod.count;
                times.map((time, ind) => {
                    let tobeRecurDates = makeNewRrule(
                        wday,
                        udateWithDateAndTime(startDate, time),
                        rDates,
                        exDates,
                        checkCount
                    );
                    let tobeEndate = datestringToNumber(tobeRecurDates[tobeRecurDates.length - 1]);
                    let tobeStartDate = datestringToNumber(tobeRecurDates[0]);
                    let uploadedInfo = {
                        ...newLessonCommonInfo,
                        lessonName:
                            prod.productName +
                            '[' +
                            selectedCategory +
                            ']' +
                            viewWday(wday) +
                            ' ' +
                            (withoutTimeTable ? '' : viewHour(time)) +
                            ' 클래스',
                        productId: pid,
                        price: prod.price,
                        settlement: prod.settlement,
                        lessonType: prod.lessonType,
                        recurrentDates: tobeRecurDates,
                        endDate: tobeEndate,
                        startDate: tobeStartDate,
                    };
                    tobeUploadedLessons.push(uploadedInfo);
                });
            });
            //console.log(tobeUploadedLessons);

            dispatch(postBactchLessons(tobeUploadedLessons)); // 데이터와, 학원아이디를 넘긴다
            setIsCreated(false);
            setAddMode(false);
        }
    };

    

   

    return (
        <Box>
            <Box>
                <Typography variant="subtitle2" gutterBottom>
                    수업 종류
                </Typography>
                <FormControl variant="filled" className={classes.form}>
                    <InputLabel id="productGroup">수업 종류 선택</InputLabel>
                    <Select
                        labelId="productGroup"
                        id="selectproductGroup"
                        value={selectedProductGroupId}
                        onChange={onChangeProductGroup}
                        size="small"
                        label="수업 종류 선택"
                        placeholder="수업 종류를 먼저 선택해주세요."
                        className={classes.select}
                    >
                        <MenuItem value="">
                            <em>선택</em>
                        </MenuItem>
                        {productGroupAll
                            .sort((a, b) => a['order'] - b['order'])
                            .map((group) => (
                                <MenuItem key={group.order} value={group.productGroupId}>
                                    {group.productGroupName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl variant="filled" className={classes.form}>
                    <InputLabel id="curri-label">연령/카테고리</InputLabel>
                    <Select
                        labelId="curri-label"
                        id="select-outlined"
                        value={selectedCategory}
                        onChange={onChangeCategory}
                        size="small"
                        label="연령/카테고리"
                        placeholder="연령/카테고리를 선택해주세요."
                        className={classes.select}
                    >
                        <MenuItem value="">
                            <em>선택</em>
                        </MenuItem>

                        {categories.map((cate, index) => {
                            return (
                                <MenuItem key={index} value={cate}>
                                    {cate}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            {/* 날짜선택 */}

            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                날짜선택
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup row value={repeatRadio} onChange={onChangeRepeatRadio} className={classes.radioGroup}>
                    <FormControlLabel
                        value="one"
                        control={
                            <WhiteRadio
                                disabled={
                                    selectedProductGroupId === 'musicgogo' ||
                                    selectedProductGroupId === 'musicgogoPremium'
                                }
                                className={classes.radio}
                            />
                        }
                        label="1회"
                    />
                    <FormControlLabel
                        value="repeat"
                        control={
                            <WhiteRadio
                                disabled={
                                    selectedProductGroupId === 'musicgogoOneDayClass' ||
                                    selectedProductGroupId === 'pianogogoOneDayClass'
                                }
                                className={classes.radio}
                            />
                        }
                        label="매주 반복"
                    />
                </RadioGroup>
            </FormControl>
            <br />

            {repeatRadio === 'repeat' ? (
                <Box>
                    <Typography variant="subtitle2" gutterBottom>
                        (1) 요일 선택
                    </Typography>
                    <Box>
                        <ToggleButtonGroup
                            value={wday}
                            onChange={handleWday}
                            aria-label="week day"
                            exclusive={true}
                            className={classes.toggleBtn}
                        >
                            <ToggleButton value="MO" aria-label="mo">
                                월
                            </ToggleButton>
                            <ToggleButton value="TU" aria-label="tu">
                                화
                            </ToggleButton>
                            <ToggleButton value="WE" aria-label="we">
                                수
                            </ToggleButton>
                            <ToggleButton value="TH" aria-label="th">
                                목
                            </ToggleButton>
                            <ToggleButton value="FR" aria-label="fr">
                                금
                            </ToggleButton>
                            <ToggleButton value="SA" aria-label="sa">
                                토
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Typography style={{ marginLeft: 20, marginTop: 10 }} variant="body2" color="secondary">
                            {' '}
                            {wday ? '매주 ' + viewWday(wday) : ''}
                        </Typography>
                    </Box>
                    <Box height={30} />
                    <Typography variant="subtitle2" gutterBottom>
                        (2) 시간별 클래스 생성
                    </Typography>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                <Checkbox checked={withoutTimeTable} onChange={handleWithoutTimeTable} name="checked" />
                            }
                            label={
                                withoutTimeTable ? (
                                    <Typography variant="body1">나중에 수업 시간 설정</Typography>
                                ) : (
                                    <Typography variant="body1">지금 시간별 수업 생성</Typography>
                                )
                            }
                        />
                    </FormControl>
                    <br />
                    <Typography style={{ marginLeft: 20 }} variant="subtitle2" color="primary" gutterBottom>
                        {withoutTimeTable
                            ? '* 수업시간을 일괄 설정하지 않고 사후에 개별 적용하실 수 있습니다.(권장)'
                            : '* 수업시간을 변경하실 수 있습니다.'}
                    </Typography>
                    {!withoutTimeTable && <LessonTimePicker times={times} setTimes={setTimes} />}

                    <Box height={30} />
                    <Typography variant="subtitle2" gutterBottom>
                        (3) 수업 일자
                    </Typography>
                    <KeyboardDatePicker
                        color="primary"
                        label="첫수업 시작일"
                        value={startDate}
                        onChange={(e) => {
                            setStartDate(e);
                        }}
                        inputVariant="filled"
                        format="YYYY-MM-DD"
                        margin="dense"
                        className={classes.twoThirdtextField}
                        keyboardIcon={<CalendarTodayRounded color="primary" />}
                        disablePast
                        
                    />

                    <TextField
                        label="수업시간"
                        variant="filled"
                        color="secondary"
                        size="small"
                        margin="dense"
                        required
                        className={classes.twoThirdtextField}
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">분</InputAdornment>,
                        }}
                    />
                    <br />
                    <Button
                        variant="outlined"
                        startIcon={<ReportRounded />}
                        color="secondary"
                        onClick={() => setException(!exception)}
                    >
                        <Typography variant="subtitle2">예외 일자 처리</Typography>
                    </Button>
                    {exception && (
                        <Box>
                            <TextField
                                color="primary"
                                label="(예외) 제외일자"
                                value={exDates}
                                onChange={(e) => setExDates(e.target.value)}
                                variant="filled"
                                margin="dense"
                                className={classes.textField}
                            />
                            <br />
                            <Typography variant="subtitle2" color="primary">
                                반복에서 제외되는 날짜는 2021-12-25, 2021-12-31 과 같은 날짜표기로 (컴마)로 구분하여 입력해주세요.
                            </Typography>
                            <TextField
                                color="primary"
                                label="(예외) 추가일자"
                                value={rDates}
                                onChange={(e) => setRDates(e.target.value)}
                                variant="filled"
                                margin="dense"
                                className={classes.textField}
                            />
                            <br />
                            <Typography variant="subtitle2" color="primary">
                                특정날짜를 추가하고 싶다면, 2021-12-25, 2021-12-31 과 같은 날짜표기로 (컴마)로 구분하여
                                입력해주세요.
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : (
                <DateTimeSelector
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    addMode={true}
                />
            )}

            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                결제/정산금액
            </Typography>
            <TextField
                label="결제금액 (매월)"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                readOnly
                value={price}
                InputProps={{
                    endAdornment: <InputAdornment position="end">원</InputAdornment>,
                }}
            />
            <TextField
                label="정산금액"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                readOnly
                value={settle}
                InputProps={{
                    endAdornment: <InputAdornment position="end">원</InputAdornment>,
                }}
            />

            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                공통 정보
            </Typography>
            <Typography variant="caption">* 각 수업별로 상세페이지에서 수정하실 수 있습니다.</Typography>
            <br />
            <TextField
                label="등록인원"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                type="number"
                value={enr}
                InputProps={{
                    endAdornment: <InputAdornment position="end">명</InputAdornment>,
                }}
            />
            <TextField
                label="마감 정원"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                type="number"
                value={cap}
                onChange={(e) => setCap(e.target.value)}
                required
                InputProps={{
                    endAdornment: <InputAdornment position="end">명</InputAdornment>,
                }}
            />
            <TextField
                label="학부모 공지사항"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.textField}
                value={descForParent.info1}
                onChange={(e) => setDescForParent({ ...descForParent, info1: e.target.value })}
                required
            />
            <TextField
                label="준비물 등"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.textField}
                value={descForParent.info2}
                onChange={(e) => setDescForParent({ ...descForParent, info2: e.target.value })}
                required
            />

            {/* 결제방법별 레슨 리스트 생성 */}
            <MakeLessonList
                selectedProductGroupId={selectedProductGroupId}
                productGroupAll={productGroupAll}
                setLessonLists={setLessonLists}
                wday={wday}
                selectedCategory={selectedCategory} //에러체크 포함하여 건넴
                setIsCreated={setIsCreated}
                // 추가됨
                startDate={startDate}
                endDate={endDate}
                isCountLimit={isCountLimit}
                setValidCheck={setValidCheck}
                setErrorMessage={setErrorMessage}
                exDates={exDates}
                rDates={rDates}
                lname={lname}
                selectedProductId={selectedProductId}
                repeatRadio={repeatRadio}
            />

            {/* 레슨리스트 받아온것 */}
            {lessonLists.map((l, ind) => {
                const prod = products.filter((p) => p.productId === l)[0];

                return (
                    <Box p={1} key={ind}>
                        <Paper style={{ padding: 10 }}>
                            <Box>
                                <Typography variant="subtitle2" color="secondary">
                                    {prod.productName + '[' + selectedCategory + ']'}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2">커리큘럼 : 총 {prod.count} 회 수업</Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">
                                    시작일: {dayjs(startDate).format('YYYY-MM-DD')}{' '}
                                </Typography>
                            </Box>

                            {times.map((t, i) => (
                                <Typography key={i} variant="body2">
                                    {' '}
                                    - 매주 {viewWday(wday)} {withoutTimeTable ? '' : viewHour(t)} 클래스
                                </Typography>
                            ))}
                        </Paper>
                    </Box>
                );
            })}

            <Divider light className={classes.divider} />

            <Box align="center" style={{ margin: 10 }}>
                <Typography variant="body1" color="error">
                    {managerErrors ? managerErrors.error : ''}
                </Typography>
                <Typography variant="body1" color="error">
                    {validCheck ? errorMessage : ''}
                </Typography>
            </Box>
            {loading && <CenterLoading />}

            <Box align="right" style={{ margin: 10 }}>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={
                        selectedProductGroupId === 'musicgogo' || selectedProductGroupId === 'musicgogoPremium'
                            ? handleSaveLessonsBatch
                            : handleSaveLesson
                    }
                    startIcon={<NoteAddRounded />}
                    disabled={managerLoading}
                >
                    {' '}
                    {selectedProductGroupId === 'musicgogo' || selectedProductGroupId === 'musicgogoPremium'
                        ? '생성 수업 저장'
                        : '수업 추가'}
                </Button>
            </Box>
        </Box>
    );
};

const numberFormat = (inputNumber) => {
    if (!inputNumber) return;
    if (typeof inputNumber === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// const dd = (dayString) => {
//     dayjs.extend(utc);
//     return dayjs.utc(dayString).format();
// }
const mH = (num) => {
    var myNum = Number(num);
    var hours = Math.floor(myNum / 60);
    var minutes = myNum % 60;
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
};
const isEmpty = (string) => {
    if (!string) {
        return true;
    } else if (string.toString().trim() === '') {
        return true;
    } else {
        return false;
    }
};
const udate = (string) => {
    const timeGap = 9;
    const d = new Date(string);
    const da = new Date(
        Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours() - timeGap, d.getMinutes(), d.getSeconds())
    );
    return da;
};
const udateImportSdt = (string, startDate) => {
    const timeGap = 9;
    const sdt = new Date(startDate);
    const d = new Date(string);
    const da = new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            sdt.getHours() - timeGap,
            sdt.getMinutes(),
            sdt.getSeconds()
        )
    );
    return da;
};
const makeWeekday = (arr) => {
    arr.map((ar) => {
        if (ar === 'MO') return (ar = RRule.MO);
        if (ar === 'TU') return (ar = RRule.TU);
        if (ar === 'WE') return (ar = RRule.WE);
        if (ar === 'TH') return (ar = RRule.TH);
        if (ar === 'FR') return (ar = RRule.FR);
        if (ar === 'SA') return (ar = RRule.SA);
    });

    return arr;
};
// rrule 적용하는 기능
const makeRrule = (wday, startDate, endDate, rDates, exDates, count, isCountLimit, inheritCount) => {
    let EX = exDates.trim().replace(' ', '').split(',');
    let RD = rDates.trim().replace(' ', '').split(',');
    let cnt = Number(count);
    const rruleSet = new RRuleSet();
    rruleSet.rrule(
        new RRule({
            freq: RRule.WEEKLY,
            //interval: 1,
            byweekday: makeWeekday(wday),
            dtstart: udate(startDate),
            until: isCountLimit ? null : udate(endDate),
            count: cnt === 0 ? inheritCount : cnt,
        })
    );

    if (RD[0].length > 0) {
        RD.map((r) => rruleSet.rdate(udateImportSdt(r, startDate)));
    }
    if (EX[0].length > 0) {
        EX.map((r) => rruleSet.exdate(udateImportSdt(r, startDate)));
    }
    let result = rruleSet.all().map((date) => dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss')); // 저장할때

    return result;
};

// rrule 새롭게 정의함 || 공통제외 일자가 들어가고, 시작날짜 포맷이 다름.
const makeNewRrule = (wday, startDate, rDates, exDates, inheritCount) => {
    let EX = exDates.trim().replace(' ', '').split(',');
    let RD = rDates.trim().replace(' ', '').split(',');

    const rruleSet = new RRuleSet();
    rruleSet.rrule(
        new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: makeWeekday(wday),
            dtstart: udate(startDate),
            count: inheritCount,
        })
    );
    // FIXME: 2021년 세트만 저장되어 있음..!!
    // TODO: 매년 변경할 수 있도록 조정 또는 진입 장치 마련 필요, 필요정보 :  시작,종료일, 제외할 주차 4개 (6,30,38,52)
    // console.log(udate(startDate));

    // 1안 - 주요 요일 제거
    // byweekno : 2021 [6, 30, 38, 52] , 2022 [5, 30, 36, 52]
    // 2안 -- 5주차 제거
    rruleSet.exrule(
        new RRule({
            freq: RRule.DAILY,
            dtstart: udateImportSdt('2021-01-01', startDate),
            until: udateImportSdt('2022-01-01', startDate),
            count: 28,
            interval: 1,
            wkst: RRule.SU,
            byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
            byweekno: [13, 22, 35, 48],
        })
    );
    rruleSet.exrule(
        new RRule({
            freq: RRule.DAILY,
            dtstart: udateImportSdt('2022-01-01', startDate),
            until: udateImportSdt('2023-01-01', startDate),
            count: 28,
            interval: 1,
            wkst: RRule.SU,
            byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
            byweekno: [13, 22, 35, 48],
        })
    );
    rruleSet.exrule(
        new RRule({
            freq: RRule.DAILY,
            dtstart: udateImportSdt('2023-01-01', startDate),
            until: udateImportSdt('2024-01-01', startDate),
            count: 28,
            interval: 1,
            wkst: RRule.SU,
            byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU],
            byweekno: [13, 22, 35, 48],
        })
    );

    if (RD[0].length > 0) {
        RD.map((r) => rruleSet.rdate(udateImportSdt(r, startDate)));
    }
    if (EX[0].length > 0) {
        EX.map((r) => rruleSet.exdate(udateImportSdt(r, startDate)));
    }
    const result = rruleSet.all().map((date) => dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss'));

    // exrule에 의해서 삭제되었을 경우, 추가 생성;
    let addedResult = [];
    const diff = inheritCount - rruleSet.all().length;
    if (diff > 0) {
        const addRuleSet = new RRuleSet();
        addRuleSet.rrule(
            new RRule({
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: makeWeekday(wday),
                dtstart: udate(rruleSet.all()[rruleSet.all().length - 1]),
                count: diff + 1,
            })
        );
        addedResult = addRuleSet.all().map((date) => dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss'));
        addedResult.splice(0, 1); // 첫원소 제거
    }

    return [...result, ...addedResult];
};
const viewWday = (weekString) => {
    let dud = [];
    if (!weekString) return;
    weekString.map((d) => {
        // eslint-disable-next-line no-unused-expressions
        d === 'MO'
            ? (d = '월')
            : d === 'TU'
            ? (d = '화')
            : d === 'WE'
            ? (d = '수')
            : d === 'TH'
            ? (d = '목')
            : d === 'FR'
            ? (d = '금')
            : d === 'SA'
            ? (d = '토')
            : '';
        dud.push(d + '요일');
    });

    return dud.join(', ');
};
const viewHour = (string) => {
    let hour = '';
    let minutes = '';
    if (string.length > 0) {
        const h = Number(string.split(':')[0]);
        const m = Number(string.split(':')[1]);
        h > 12
            ? (hour = '오후' + String(h - 12) + '시')
            : h === 12
            ? (hour = '오후' + String(h) + '시')
            : (hour = '오전' + String(h) + '시');
        m === 0 ? (minutes = '') : (minutes = String(m));

        return hour + minutes;
    }
};
const udateWithDateAndTime = (date, time) => {
    const timeGap = 9;

    const d = new Date(date);

    const tmp = time.split(':');
    const t = new Date(2020, 1, 1, Number(tmp[0]), Number(tmp[1]));

    const dateAndTime = new Date(
        Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), t.getHours() - timeGap, t.getMinutes(), t.getSeconds())
    );

    return dateAndTime;
};
const datestringToNumber = (string) => {
    const date = string.split(' ')[0];
    const time = string.split(' ')[1];
    const yyyy = date.split('-')[0];
    const mm = date.split('-')[1];
    const dd = date.split('-')[2];
    const hh = time.split(':')[0];
    const mi = time.split(':')[1];
    const tString = new Date(Number(yyyy), Number(mm) - 1, Number(dd), Number(hh), Number(mi), 0);
    //console.log(tString, tString/1);
    return tString / 1;
};

function checkDate(string) {
    const reg = /(|,)(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))+/g;
    return reg.test(string);
} 
    


export default withStyles(styles)(LessonAdd);
