import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// mui
import { makeStyles} from "@material-ui/styles";
import { Toolbar, Typography, Tooltip, IconButton } from "@material-ui/core";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon
} from "@material-ui/icons";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, tableTitle } = props;

  const deleteRow = () => {};
  const filteringLists = () => {};
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} 개 선택됨
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="삭제">
          <IconButton aria-label="삭제" onClick={deleteRow}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="목록 필터">
          <IconButton aria-label="목록 필터" onClick={filteringLists}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

export default EnhancedTableToolbar;
