import React from 'react';
import ReactPlayer from 'react-player';

// components
// MUI stuff

import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

// reactPlayer에서 권고하는 세팅
const styles = (theme) => ({
    ...theme.userPage,
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%'
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0
    }


});

export const VideoClip = (props) => {
    const { classes, url, category, videoName, runningTime } = props;

    return (
        <Box p={1} m={1}>
            <Box className={classes.playerWrapper}>
                <ReactPlayer
                    className={classes.reactPlayer}
                    url={url}
                    width='100%'
                    height='100%'
                    pip={true}
                    controls={true}
                    allow='fullScreen'
                //allowFullScreen
                />
            </Box>
            {
                (videoName === '' || typeof videoName === 'undefined') ? null :
                    <Box p={1} m={1}>
                        <Typography variant='body2'>{category}</Typography>
                        <Typography variant='subtitle2'>{videoName}</Typography>
                        <Typography variant='caption'>{runningTime}</Typography>
                    </Box>
            }


        </Box>

    );
};



export default withStyles(styles)(VideoClip);

