import React from 'react';
import { Link } from 'react-router-dom';
// component
import DummyDivider from '../../components/layout/DummyDivider';
import Full2 from '../../images/full2.png';
import ClassRoom from '../../images/classroom.png';
import HomePicture from '../../images/product_main.jpg';
import Ssam from '../../images/ssam.jpg';
import FirstImage from '../../images/gogoabout.png';
import VideoClip from '../../components/home/VideoClip';
// mui
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { LibraryAddCheckRounded } from '@material-ui/icons';
// changed
const styles = (theme) => ({
    ...theme.userPage,
    root: {
        margin: '0 auto 0 auto',
        maxWidth: 1300,
    },
    box: {
        margin: theme.spacing(3)
    },
    right: {
        float: 'right',
        marginTop: 50,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20
    },
    whiteBack: {
        backgroundColor: '#ffffff',
        margin: '0 auto',

    },
    fullImage: {
        backgroundImage: `url(${Full2})`,
        width: '100%',
        height: 360,
        objectFit: 'cover',
        backgroundPosition: 'center center'
    },
    fullImage2: {
        backgroundImage: `url(${Full2})`,
        width: '100%',
        height: 400,
        objectFit: 'cover',
        backgroundPosition: 'center center'
    },
    grad: {
        backgroundColor: 'rgba(0,0,0,0.6)'
    },
    noneGrad: {

    },
    classroomimage: {
        width: 500,
        [theme.breakpoints.down('xs')]: {
            width: 300
        }
    },
    imageLarge: {
        width: 600,
        [theme.breakpoints.down('xs')]: {
            width: 300
        }
    },
    peachBackground: {
        backgroundColor: 'rgba(241, 86, 36, 0.1)'
    },
    whiteBox: {
        backgroundColor: '#ffffff'
    },
    ssamtext: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2),
            textAlign: 'left'
        }
    },
    videoClip: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('xs')]:{
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        }
        
    }
});
const WhiteTypo = withStyles({
    root: { color: "#ffffff", opacity: 1 }
})(Typography);

function GogoAbout(props) {
    const { classes } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Box>
            <DummyDivider top={20} bottom={10} />
            <Box align='center'>
                <Typography color='secondary' variant='h2'>음악을 특별하게 만나자!</Typography>
            </Box>
            {matches ? <Box>
                <Box className={classes.box}>
                    <img src={FirstImage} width='100%' alt='gogo' />
                </Box>
                <Box className={classes.box}>
                    <Typography variant='h4'>놀이와 체험을 통해</Typography>
                    <Typography variant='h4'>특별하게 음악을 만나는</Typography>
                    <Typography variant='h4'>음악교육 프로그램</Typography>
                </Box>
                <Box className={classes.box}>
                    <Typography variant='body1'>
                        뮤직고고는 유아부터 초등 아이들까지 아이의
                        연령별 발달에 맞추어 다양한 음악적 경험을
                        가질 수 있는 음악교육 프로그램으로 악기연주,
                    신체활동, 음악이론, 감상표현 등 4영역으로 구성되어 있습니다.</Typography>
                    <Typography variant='body1'>
                        ‘음률체험교실’은 뮤직고고만의 특별한 공간으로,
                        음악성 뿐만 아니라 언어, 수학, 인지, 논리적사고,
                    사회성, 신체, 창의성을 자라게 하는 음악체험을 위한 통합놀이 공간입니다. </Typography>
                    <Typography variant='body1'>
                        뮤직고고 음악 수업을 진행하는 선생님들은
                        ‘뮤직고고 지도자’과정을 이수한 음악교육 최고의
                        전문 교사로 구성되어 있습니다.
                </Typography>
                </Box>
            </Box>
                :
                <Box>
                    <Box className={classes.right}><img src={FirstImage} width='550px' height='300px' alt='gogo' /></Box>
                    <Box height={60} />
                    <Typography variant='h3' gutterBottom>놀이와 체험을 통해</Typography>
                    <Typography variant='h3' gutterBottom>특별하게 음악을 만나는</Typography>
                    <Typography variant='h3' gutterBottom>음악교육 프로그램</Typography>
                    <Box height={50} />
                    <Typography variant='body1'>
                        뮤직고고는 유아부터 초등 아이들까지 아이의
                        연령별 발달에 맞추어 다양한 음악적 경험을
                        가질 수 있는 음악교육 프로그램으로 악기연주,
                    신체활동, 음악이론, 감상표현 등 4영역으로 구성되어 있습니다.</Typography>
                    <Box height={50} />

                    <Typography variant='body1'>
                        ‘음률체험교실’은 뮤직고고만의 특별한 공간으로,
                        음악성 뿐만 아니라 언어, 수학, 인지, 논리적사고,
                    사회성, 신체, 창의성을 자라게 하는 음악체험을 위한 통합놀이 공간입니다. </Typography>
                    <Typography variant='body1'>
                        뮤직고고 음악 수업을 진행하는 선생님들은
                        ‘뮤직고고 지도자’과정을 이수한 음악교육 최고의
                        전문 교사로 구성되어 있습니다.
                </Typography>
                </Box>
            }

            <Box className={classes.box}>
                <Box height={matches ? 30 : 50} />
                <Typography align='center' variant='h2'>음악을 제대로 배워보자</Typography>
                <Box height={20} />
                <Typography align='center' variant='h5'>다양한 음악 영역으로 꼼꼼하게 구성된</Typography>
                <Typography align='center' variant='h5'>스페셜 커리큘럼!</Typography>
                <Box height={20} />
                <Grid container direction='row' spacing={4}>
                    <Grid item xs={12} sm={6} >
                        <Box p={3} m={1} align='center' style={{ backgroundColor: 'rgba(84, 181, 84, 0.7)', height: 240 }}>
                            <Box height={10} />
                            <WhiteTypo variant='h3'>악기연주</WhiteTypo>
                            <Box height={20} />
                            <WhiteTypo variant='body1'>다양한 악기를
                            직접 연주하고
                            소리를 탐색하며
                            표현력과 상상력을
                                향상시켜요.</WhiteTypo>

                            <Box height={20} />
                            <WhiteTypo variant='h4'>음악성 발달
                            </WhiteTypo>
                            <WhiteTypo variant='h4'>
                                수학 및 논리적 사고능력 발달</WhiteTypo>
                            <Box height={20} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                        <Box p={3} m={1} align='center' style={{ backgroundColor: 'rgba(26, 172, 196, 0.7)', height: 240 }}>
                            <Box height={10} />
                            <WhiteTypo variant='h3'>신체활동</WhiteTypo>
                            <Box height={20} />
                            <WhiteTypo variant='body1'>몸을 움직이며
                            생활 속의 다양한
                            소품과 교구를
                            활용하여 놀이로
                            음악을
                                느끼고 경험해요.</WhiteTypo>

                            <Box height={20} />
                            <WhiteTypo variant='h4'>신체 발달</WhiteTypo>
                            <WhiteTypo variant='h4'>사회정서 발달</WhiteTypo>
                            <Box height={20} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                        <Box p={3} m={1} align='center' style={{ backgroundColor: 'rgba(223, 172, 84, 0.7)', height: 240 }}>
                            <Box height={10} />
                            <WhiteTypo variant='h3'>음악이론</WhiteTypo>
                            <Box height={20} />
                            <WhiteTypo variant='body1'>재미있는 놀이를
                            통해 음악의 요소와
                            다양한 내용을
                            이해하고,
                            생각을 키워줘요
                                </WhiteTypo>

                            <Box height={20} />
                            <WhiteTypo variant='h4'>인지 발달</WhiteTypo>
                            <WhiteTypo variant='h4'>언어능력 발달</WhiteTypo>
                            <Box height={20} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                        <Box p={3} m={1} align='center' style={{ backgroundColor: 'rgba(223, 66, 165, 0.7)', height: 240 }}>
                            <Box height={10} />
                            <WhiteTypo variant='h3'>감상&표현</WhiteTypo>
                            <Box height={20} />
                            <WhiteTypo variant='body1'>보고 듣고 몸으로
                            표현하는 과정을
                            통해 잠재된
                            음악성 발달,
                            감상을 통해
                            창의적 발상,
                            다양한 표현을
                                경험해요. </WhiteTypo>

                            <Box height={20} />
                            <WhiteTypo variant='h4'>창의성 발달</WhiteTypo>
                            <WhiteTypo variant='h4'> </WhiteTypo>
                            <Box height={20} />
                        </Box>
                    </Grid>

                </Grid>


            </Box>
            <Box className={classes.whiteBox}>
                <Box height={matches ? 50 : 80} />
                {
                    matches ? <Box><Typography align='center' variant='h2'>행복한 음악경험을</Typography><Typography align='center' variant='h2'>'음률체험교실'에서</Typography></Box>
                        : <Typography align='center' variant='h2'>행복한 음악경험을 '음률체험교실'에서</Typography>
                }

                <Box height={20} />
                <Typography align='center' variant='h5'>다양한 악기와 음악교구를 비치하여 </Typography>
                <Typography align='center' variant='h5'>음악을 즐겁게 만날 수 있는 </Typography>
                <Typography align='center' variant='h5'>어린음악대만의</Typography>
                <Typography align='center' variant='h5'>음악체험 통합놀이공간</Typography>
                <Box height={20} />
                <Box align='center'>
                    <img src={ClassRoom} alt='classroom' className={classes.classroomimage} />
                </Box>
                <Box height={50} />
            </Box>

            <Box p={6} className={classes.peachBackground}>
                <Box height={20} />
                {
                    matches ? <Box><Typography align='center' variant='h2'>행복한 음악경험을</Typography><Typography align='center' variant='h2'>가정에서</Typography></Box>
                        : <Typography align='center' variant='h2'>행복한 음악경험을 가정에서</Typography>
                }

                <Box height={20} />
                <Typography align='center' variant='h5'>음률체험교실에서 진행하는 뮤직고고, 피아노고고 원데이 클래스를</Typography>
                <Typography align='center' variant='h5'>온라인으로 가정에서도!</Typography>
                <Box height={20} />
                <Box align='center'>
                    <img src={HomePicture} alt='classroom' className={classes.classroomimage} />
                </Box>
            </Box>

            <Box className={classes.whiteBox}>
                <Box height={matches ? 50 : 80} />
                {
                    matches ? <Box><Typography align='center' variant='h2'>대한민국 1% 쌤에게</Typography><Typography align='center' variant='h2'>음악수업을 받자!</Typography></Box>
                        : <Typography align='center' variant='h2'>대한민국 1% 쌤에게 음악수업을 받자!</Typography>
                }
                <Box height={50} />
                <Box align='center'>
                    <img src={Ssam} alt='classroom' className={classes.imageLarge} />
                </Box>

                <Box height={20} />
                <Box className={classes.ssamtext}>
                    <Typography variant='h5' gutterBottom>· <b>뮤직고고 지도자 과정</b>을 이수한 음악교육 전문교사</Typography>
                    <Typography variant='body2'>※ <b>뮤직고고 지도자 과정</b>은 현장 교사들을 대상으로 </Typography>
                    <Typography variant='body2'>뮤직고고 전연령대의 커리큘럽과 수업진행을 위한 </Typography>
                    <Typography variant='body2' gutterBottom>음악교육 이론을 제시하는 과정입니다.</Typography>
                    <Typography variant='h5' gutterBottom>· 음악교육을 이수한 전문교사 </Typography>
                    <Typography variant='h5' gutterBottom>· 20여종의 악기 연주법 마스터 </Typography>
                    <Typography variant='h5' gutterBottom>· 온/오프라인으로 지속적인 재교육과 보수교육을 통한 최고의 교사진</Typography>
                    <Typography variant='body2'>※ 뮤직고고 교사는 <b>뮤직고고 지도자 과정</b>을 통해 수준 높고 체계적인 교육과정을 이수 받습니다.</Typography>

                </Box>



                <Box height={50} />
            </Box>

            <Box className={classes.whiteBack}>
                <Box align='center' className={classes.box}>
                    <Box height={70} />
                    <Typography variant={matches ? 'h3' : 'h2'}>어디서나 만날 수 있는</Typography>
                    <Typography color='primary' variant={matches ? 'h2' : 'h1'}>musicGOGO!</Typography>
                </Box>
                <Box align='center' className={classes.box}>
                    <Typography variant='h5'>엄마가 꼭 따라 다니던</Typography>
                    <Typography variant='h5'>센터수업을 온라인 컨텐츠로</Typography>
                    <Typography variant='h5'>어디서든 즐겁게!</Typography>
                </Box>
                <Box align='center' className={classes.videoClip}>

                    <Box height={20} />
                    <VideoClip url={'https://vimeo.com/518913626'}
                        category="[뮤직고고 플레이어 3월 4주 (감상&표현)]"
                        videoName='나는 표현할 수 있어요Ⅱ'
                    />

                </Box>
                <Box height={50} />
            </Box>
            <Box className={matches ? classes.fullImage : classes.fullImage2}>
                {matches ?
                    <Box>
                        <Box height={180}></Box>
                        <Box align='center' height={180} className={matches ? classes.grad : classes.noneGrad}>
                            <Box>
                                <Box height={20} />
                                <WhiteTypo variant='h3'>먼저, 아이가 재미있어야죠!</WhiteTypo>
                            </Box>
                            <Box className={classes.box} >
                                
                                <WhiteTypo variant='h5'>아이가 가장 좋아 하는 수업으로</WhiteTypo>
                                <WhiteTypo variant='h5'>짜여진 단계별 프로그램</WhiteTypo>
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box align='center'>
                        <Box height={200} />
                        <Typography variant='h1' gutterBottom>먼저, 아이가 재미있어야죠!</Typography>
                        <Typography variant='h3'>아이가 가장 좋아 하는 수업으로 짜여진 단계별 프로그램</Typography>

                    </Box>
                }
            </Box>
            {/* <Box className={classes.box}>
                <Box height={50} />
                <Typography color='secondary' align='center' variant='h2'>우리 아이는 몇 살인가요?</Typography>
                <Box height={50} />
                <Grid container direction='row' spacing={4}>
                    <Grid item style={{ backgroundColor: 'rgba(241,86,36,0.4)' }} xs={6} >
                        <Box align='center'>
                            <Box height={15} />
                            <Typography variant='h4'>플레이어</Typography>
                            <Typography variant='h5'>Player</Typography>
                            <Typography variant='h3'>6-7세</Typography>
                            <Box height={20} />
                            <Typography variant='body1'>음악에 대한</Typography>
                            <Typography variant='body1'>사랑을 심어주는</Typography>
                            <Typography variant='body1'>유아음악 놀이수업</Typography>
                        </Box>
                    </Grid>
                    <Grid item style={{ backgroundColor: 'rgba(0,128,255,0.4)' }} xs={6} >
                        <Box align='center'>
                            <Box height={15} />
                            <Typography variant='h4'>러너</Typography>
                            <Typography variant='h5'>Runner</Typography>
                            <Typography variant='h3'>8-9세</Typography>
                            <Box height={20} />
                            <Typography variant='body1'>음악과 함께</Typography>
                            <Typography variant='body1'>성장하는</Typography>
                            <Typography variant='body1'>초등음악 감성수업</Typography>
                        </Box>
                    </Grid>
                </Grid>


            </Box> */}

            <Box align='center' m={3}>
                <Button variant='contained' color='primary' size='large' startIcon={<LibraryAddCheckRounded />} component={Link} to='/lesson'>수업 신청 하기</Button>
            </Box>



        </Box>
    )
}

export default withStyles(styles)(GogoAbout);