import React from 'react';
import { useParams } from 'react-router-dom';


// components
import Qna from '../pages/help/Qna';
import InfoCancel from '../pages/help/InfoCancel';
import SearchWon from '../pages/help/SearchWon';
import Navbar from '../components/layout/Navbar'
import MainGrid from '../components/layout/MainGrid';
import MainTabs from '../components/layout/MainTabs';
import Footer from '../components/layout/Footer';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
    ...theme.userPage,
});

export const Help = (props) => {
    const { topic } = useParams();

    const tabInfo = [
        { id: "qna", label: "자주하는 질문", link: '/help/qna' },
        { id: "infocancel", label: "수업변경취소", link: '/help/infocancel' },
        { id: "search", label: "주변 학원 찾기", link: '/help/search' }

    ];
    return (
        <>
            <Navbar menu={2} />
            <div className='container'>
                <MainGrid>
                    <MainTabs tabInfo={tabInfo} />
                    {(topic === tabInfo[0].id || typeof topic === 'undefined') && <Qna />}
                    {topic === tabInfo[1].id && <InfoCancel />}
                    {topic === tabInfo[2].id && <SearchWon />}

                </MainGrid >
                <Footer />
            </div>
        </>
    );
};


export default withStyles(styles)(Help);

