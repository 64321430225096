import {
    SET_PRODUCTS,
    LIKE_PRODUCT,
    UNLIKE_PRODUCT,
    DELETE_PRODUCT,
    POST_PRODUCT,
    LOADING_DATA,
    STOP_LOADING_DATA,
    SET_ERRORS,
    CLEAR_ERRORS,
    SET_PRODUCT,
    SUBMIT_COMMENT,
    SET_CURRICULUM,
    SET_CURRICULUMS,
    SET_DATA_ERRORS,
    SET_VIDEOS, SET_FREE_VIDEOS,
    CLEAR_DATA_ERRORS,
    SET_EVENTS,
    SET_REFUNDS
} from '../types';
import axios from 'axios';


// GET all products
export const getProducts = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get('/getproducts')
        .then(res => {
            dispatch({
                type: SET_PRODUCTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_PRODUCTS,
                payload: []
            })
        })
}
// get one product
export const getProduct = (productId) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(`/product/${productId}`)
        .then(res => {
            dispatch({
                type: SET_PRODUCT,
                payload: res.data
            });
            dispatch({ type: STOP_LOADING_DATA })
        })
        .catch(err => console.error(err))
}
// like a product
export const likeProduct = (productId) => (dispatch) => {
    axios
        .get(`/product/${productId}/like`)
        .then(res => {
            dispatch({
                type: LIKE_PRODUCT,
                payload: res.data
            })
        })
        .catch(err => console.error(err));
}
// unlike a product
export const unlikeProduct = (productId) => (dispatch) => {
    axios
        .get(`/product/${productId}/unlike`)
        .then(res => {
            dispatch({
                type: UNLIKE_PRODUCT,
                payload: res.data
            })
        })
        .catch(err => console.error(err));
}
// Delete a product 
export const deleteProduct = (productId) => (dispatch) => {
    axios
        .delete(`/product/${productId}`)
        .then(res => {
            dispatch({
                type: DELETE_PRODUCT,
                payload: productId
            })
        })
        .catch(err => console.error(err))
}
// Post a product
export const postProduct = (newProduct) => (dispatch) => {
    dispatch({ type: LOADING_DATA })
    axios
        .post(`/postproduct`, newProduct)
        .then(res => {
            dispatch({
                type: POST_PRODUCT,
                payload: res.data
            });
            dispatch({
                type: CLEAR_ERRORS
            })
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}
// clear errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}

// submit a comment
export const submitComment = (productId, commentData) => (dispatch) => {
    dispatch({ type: LOADING_DATA })
    axios
        .post(`/product/${productId}/comment`, commentData)
        .then(res => {
            dispatch({
                type: SUBMIT_COMMENT,
                payload: res.data
            });
            dispatch(clearErrors());
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}
// get any user data
export const getUserData = (name) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(`/user/${name}`)
        .then(res => {
            dispatch({
                type: SET_PRODUCTS,
                payload: res.data.products
            })
        })
        .catch(() => {
            dispatch({
                type: SET_PRODUCTS,
                payload: null
            })
        })
}

// [커리큘럼] get ALL Curriculums 
export const getCurriculums = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    //const productId = 'DzbNwY2Bz5uPXP5r2sX6';
    axios
        .get(`/curriculums`)
        .then(res => {
            dispatch({
                type: SET_CURRICULUMS,
                payload: res.data
            })
            dispatch({ type: STOP_LOADING_DATA })
        })
        .catch((err) => {
            dispatch({
                type: SET_DATA_ERRORS,
                payload: err.response.data
            })
            dispatch({ type: STOP_LOADING_DATA })
        })
}
// 상품별 커리쿨럼
export const getCurriculumsByProduct = (pid) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(`/curriculums/${pid}`)
        .then(res => {
            dispatch({
                type: SET_CURRICULUMS,
                payload: res.data
            })
            dispatch({ type: STOP_LOADING_DATA })
        })
        .catch((err) => {
            dispatch({
                type: SET_DATA_ERRORS,
                payload: err.response.data
            })
            dispatch({ type: STOP_LOADING_DATA })
        })
}
// 커리등록
export const postCurriculum = (pid, data) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .post(`/curriculum/${pid}`, data)
        .then(res => {
            dispatch({
                type: SET_CURRICULUM,
                payload: res.data
            })
            dispatch(getCurriculumsByProduct(pid));
        })
        .catch(err => {
            dispatch({
                type: SET_DATA_ERRORS,
                payload: err.response.data
            })
            dispatch({ type: STOP_LOADING_DATA })
        })
}
// 커리 하나
export const getCurriculum = (cid) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(`/getcurriculum/${cid}`)
        .then(res => {
            dispatch({ type: SET_CURRICULUM, payload: res.data })
            dispatch({ type: STOP_LOADING_DATA })
        })
        .catch(err => {
            dispatch({ type: SET_DATA_ERRORS, payload: err.response.data })
            dispatch({ type: STOP_LOADING_DATA })
        })
}
// 커리 삭제
export const deleteCurriculum = (cid, pid)=>(dispatch)=>{
    dispatch({type: LOADING_DATA});
    axios
        .delete(`/curriculum/${cid}`)
        .then(res=>{
            alert(res.data.message);
            dispatch(getCurriculumsByProduct(pid));
            
            
        })
        .catch(err=>{
            alert(err.response.error);
            dispatch(getCurriculumsByProduct(pid));
           
            
        })
}
// 업데이트
export const updateCurriculum = (cid, data) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .post(`/updatecurriculum/${cid}`, data)
        .then(res => {
            dispatch(getCurriculumsByProduct('DzbNwY2Bz5uPXP5r2sX6'));
            dispatch({ type: STOP_LOADING_DATA })
        })
        .catch(err => {
            dispatch({
                type: SET_DATA_ERRORS,
                payload: err.response.data
            })
            dispatch({ type: STOP_LOADING_DATA })
        })
}
// 이미지
export const uploadCurriculumImage = (cid, formData) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .post(`/curriculum/image/${cid}`, formData)
        .then(() => {
            dispatch(getCurriculum(cid));
            
            dispatch({ type: STOP_LOADING_DATA })
        })
        .catch(err => {
            dispatch({ type: SET_DATA_ERRORS, payload: err.response.data })
            dispatch({ type: STOP_LOADING_DATA })
        })
}
// 이미지 버켓에서 삭제
export const deleteCurriculumImage = (fileName) => (dispatch) => {
    axios
        .post(`/curriculum/deleteimage`, fileName)
        .then(res => {
            dispatch({ type: STOP_LOADING_DATA });
        })
        .catch(err => {
            console.error(err);
            dispatch({ type: STOP_LOADING_DATA });
        })
}
// 이미지 주소 DB에서 삭제
export const deleteCurriculumImageInDB = (cid, newImages) => (dispatch) => {
    axios
        .post(`/updatecurriculum/${cid}`, { images: newImages })
        .then(res => {
            dispatch(getCurriculum(cid));
        })
        .catch(err => {
            console.error(err);
        })
}
export const deleteCurriculumImagesAll = (cid) => (dispatch)=>{
    axios
        .post(`/deletecurriculumimges/${cid}`)
        .then(res=>{
            dispatch(getCurriculum(cid));
        })
        .catch(err=>{
            console.log(err);
        })
}

// video가져오기
export const getPaidVideos = () => (dispatch) => {
    dispatch({type: LOADING_DATA});
    dispatch({type: CLEAR_DATA_ERRORS});
    axios
        .get(`/videos`)
        .then(res=>{
            dispatch({
                type: SET_VIDEOS,
                payload: res.data
            })
            dispatch({
                type: STOP_LOADING_DATA
            })
        })
        .catch(err=>{
            dispatch({type: STOP_LOADING_DATA});
            dispatch({type: SET_DATA_ERRORS});
        })
}
export const getFreeVideos = () => (dispatch) => {
    dispatch({type: LOADING_DATA});
    dispatch({type: CLEAR_DATA_ERRORS});
    axios
        .get(`/videosFree`)
        .then(res=>{
            dispatch({
                type: SET_FREE_VIDEOS,
                payload: res.data
            })
            dispatch({
                type: STOP_LOADING_DATA
            })
        })
        .catch(err=>{
            dispatch({type: STOP_LOADING_DATA});
            dispatch({type: SET_DATA_ERRORS});
        })
}
// video정보업데이트
export const updateVideo = (videoId, data)=>(dispatch)=>{
    dispatch({type: LOADING_DATA});
    dispatch({type: CLEAR_DATA_ERRORS});
    axios
        .post(`/video/${videoId}`, data)
        .then(res=>{
            dispatch(getFreeVideos());
            dispatch(getPaidVideos());
            dispatch({
                type: STOP_LOADING_DATA
            })
        })
        .catch(err=>{
            dispatch({type: STOP_LOADING_DATA});
            dispatch({type: SET_DATA_ERRORS});
        })
}
export const deleteVideo = (videoId)=>(dispatch)=>{
    dispatch({type: LOADING_DATA});
    dispatch({type: CLEAR_DATA_ERRORS});
    axios
        .delete(`/video/${videoId}`)
        .then(res=>{
            dispatch(getFreeVideos());
            dispatch(getPaidVideos());
            dispatch({
                type: STOP_LOADING_DATA
            })
        })
        .catch(err=>{
            dispatch({type: STOP_LOADING_DATA});
            dispatch({type: SET_DATA_ERRORS});
        })
}
export const postVideo = (data)=>(dispatch)=>{
    dispatch({type: LOADING_DATA});
    dispatch({type: CLEAR_DATA_ERRORS});
    axios
        .post(`/video`, data)
        .then(res=>{
            dispatch(getFreeVideos());
            dispatch(getPaidVideos());
            dispatch({
                type: STOP_LOADING_DATA
            })
        })
        .catch(err=>{
            dispatch({type: STOP_LOADING_DATA});
            dispatch({type: SET_DATA_ERRORS});
        })
}
// Event 관리 - 이벤트 모두 가져오기
export const getEvents = ()=>(dispatch)=>{
    axios
        .get('/events')
        .then(res=>{
            dispatch({
                type: SET_EVENTS,
                payload: res.data
            })

        })
}
export const postEvent = (info)=>(dispatch)=>{
    axios
        .post(`/event`, info)
        .then(res=>{
            dispatch(getEvents());
        })
        .catch(err=>{
            console.log(err);
        })

}
export const deleteEvent = (eventId)=>(dispatch)=>{
    axios
        .delete(`/event/${eventId}`)
        .then(res=>{
            dispatch(getEvents());
        })
        .catch(err=>{
            console.log(err);
        })
}
export const updateEvent = (eventId, info)=>(dispatch)=>{
    axios
        .post(`/event/${eventId}`, info)
        .then(res=>{
            dispatch(getEvents());
        })
        .catch(err=>{
            console.log(err);
        })
}

// Refund 관리
export const getRefunds = ()=>(dispatch)=>{
    axios
        .get('/refunds')
        .then(res=>{
            dispatch({
                type: SET_REFUNDS,
                payload: res.data
            })

        })
}
export const postRefund = (info)=>(dispatch)=>{
    axios
        .post(`/refund`, info)
        .then(res=>{
            dispatch(getRefunds());
        })
        .catch(err=>{
            console.log(err);
        })

}
export const deleteRefund = (refundId)=>(dispatch)=>{
    axios
        .delete(`/refund/${refundId}`)
        .then(res=>{
            dispatch(getRefunds());
        })
        .catch(err=>{
            console.log(err);
        })
}
export const updateRefund = (refundId, info)=>(dispatch)=>{
    axios
        .post(`/refund/${refundId}`, info)
        .then(res=>{
            dispatch(getRefunds());
        })
        .catch(err=>{
            console.log(err);
        })
}


