import React from 'react';

// components
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Typography, Box } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    margin: {
        margin: theme.spacing(2)
    },
    gutterBottom: {
        marginBottom: theme.spacing(2)
    }

});
const info = [
    { step: 1, name: 'Step.1 수업 선택', desc: '수업을 선택해주세요' },
    { step: 2, name: 'Step.2 주변 학원 선택', desc: '근처에 있는 어린음악대 학원을 선택해 주세요.' },
    { step: 3, name: 'Step.3 클래스 선택', desc: '클래스를 선택해 주세요.' },
    { step: 4, name: 'Step.4 결제하기', desc: '결제 정보를 꼼꼼하게 잘 살펴 보세요.' },
];
export const EnrollStepTitle = (props) => {
    const { classes, currentStep } = props;
    return (
        <div>
            {
                info.map((i, index) =>
                    (currentStep === i.step) && <Box key={index}>
                        <Typography align='center' color='primary' variant='h3' className={classes.margin}>{info[index].name}</Typography>
                        <Typography align='center' color='inherit' variant='subtitle1' className={classes.gutterBottom}>{info[index].desc}</Typography>
                    </Box>)
            }

        </div>
    );
};



export default withStyles(styles)(EnrollStepTitle);



