import React, { useEffect, useState } from 'react';

// Redux stuff
import { useDispatch } from 'react-redux';
import { getProducts } from '../redux/actions/dataActions';
// components
import Navbar from '../components/layout/Navbar'
import MainGrid from '../components/layout/MainGrid';
import Footer from '../components/layout/Footer';
import EnrollStepper from '../components/enroll/EnrollStepper';
import ProductGroup from '../components/enroll/ProductGroup';
import EnrollStepTitle from '../components/enroll/EnrollStepTitle';
import Event from '../pages/Event';

// MUI stuff
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';


const styles = (theme) => ({
    ...theme.userPage,
    button: {
        marginRight: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    more: {
        marginLeft: 'auto'
    },
});

export const Lesson = (props) => {
    //const { classes, history } = props;
    //const { products } = useSelector(state => state.data);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProducts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [currentStep, setCurrentStep] = useState(1);

    return (
        <>
            <Navbar menu={1} /> 
            <div className='container'>
                <MainGrid>
                    <EnrollStepTitle currentStep={currentStep} />

                    {/* 스텝 표시 */}
                    <EnrollStepper step1='' />
                    <Box height={45} />
                    
                    {/* 이벤트 배너 */}
                    <Event />

                    {/* 메인 상품 안내 페이지 */}

                    {
                        currentStep === 1 && <ProductGroup setStep1='' setCurrentStep={setCurrentStep} />
                    }

                </MainGrid>
                <Footer />
            </div>
        </>

    );
};




export default withStyles(styles)(Lesson);

