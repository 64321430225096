import React from 'react';

// component
import LittleBandLogo from '../../images/littleband_logo.png';
import Image from '../../images/main_small_4.png';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Box, Button, useMediaQuery, Typography, Grid } from '@material-ui/core';


const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        marginTop: theme.spacing(5),
    },
    btn: {
        padding: '0 25px',
        height: 45,
    },
    image:{
        width: '100%'
    }

});
const YellowButton = withStyles({
    root: {
        background: '#f2da00',
        padding: '0 25px',
        height: 45,
        '&:hover': {
            background: '#ffeb33',
        },
        logo: {
            width: '100%'
        }
    }
})(Button);
const SearchWon = props => {
    const { classes } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const naverLink = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = "https://map.naver.com/v5/search/어린음악대";
    }
    const kakaoMapLink = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = 'https://map.kakao.com/link/search/어린음악대'
    }
    const markup = <Box align='center'>
        <Box p={4} >
            <Typography variant='subtitle1'>주변에 어떤 '어린음악대' 학원이 있는지</Typography>
            <Typography variant='subtitle1'>지도앱으로 찾아보세요!</Typography>
        </Box>

        <Box className={classes.margin} align='center'>
            <Button variant='contained' color='secondary' className={classes.btn} onClick={naverLink}>네이버 지도에서 어린음악대 학원 검색</Button>
        </Box>
        <Box className={classes.margin} align='center'>
            <YellowButton variant='contained' className={classes.btn} onClick={kakaoMapLink}>카카오 지도에서 어린음악대 학원 검색</YellowButton>
        </Box>
        <Box height={60} />
        <Box align='center'>
            <img src={LittleBandLogo} alt='logo' className={classes.logo} />
        </Box>
        <Box height={60} />
    </Box>
    return (
        <Box>
            {
                matches ?
                    <Box>{markup}</Box>
                    :
                    <Grid container>
                        <Grid item xs={6}>
                            <img src={Image} alt='' className={classes.image}/>
                        </Grid>
                        <Grid item xs={6}>
                            {markup}
                        </Grid>
                    </Grid>
            }

        </Box>
    );
};

export default withStyles(styles)(SearchWon);