import React from 'react';
import { Link } from 'react-router-dom';
// Redux stuff
//import { useSelector, useDispatch } from 'react-redux';
// components
import Navbar from '../components/layout/Navbar'
import Footer from '../components/layout/Footer';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Grid, Typography, Button, Box } from '@material-ui/core';
//import { InboxOutlined, InboxRounded, Notifications } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
});
export const Joined = (props) => {
    //const { classes } = props;
    //const user = useSelector(state => state.user);
    //const dispatch = useDispatch()
    return (
        <div><Navbar />
            <div className='container'>
                <Grid container>
                    <Grid item sm={2} />
                    <Grid item xs={12} sm={8} style={{ textAlign: 'center' }}>
                        <Box height={20} />
                        <Typography variant='h4'>회원가입 완료</Typography>
                        <Box height={50} />
                        <Typography variant='subtitle2'>가입해주셔서 감사합니다. 다양한 뮤직고고의 서비스를 이용해보시기 바랍니다.</Typography>
                        <Box height={20} />
                        <Button color='primary' component={Link} to='/'>메인화면으로 이동</Button>
                    </Grid>
                    <Grid item sm={2} />
                </Grid>
                <Footer />
            </div>
        </div>

    );
};



export default withStyles(styles)(Joined);

