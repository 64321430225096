import React, { useState, useEffect, useRef } from 'react';
import CKEditor from 'ckeditor4-react';
// redux
// component
import VideoClip from '../../components/home/VideoClip'; // 필요
import '../../App.css';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid, IconButton, TextField, Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Box } from '@material-ui/core';
import { CloseRounded, EditRounded, DeleteRounded, FileCopyRounded } from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPage,
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogRead = props => {
    const { classes, managerLoading, vimeos, bId, type, readOpen, setReadOpen, name, description, title, createdAt, rnr, setEditMode, editMode,
        handleUploadImage, buttonTitle, buttonUrl, url, handleDeleteBoard, handleEditBoardContent, } = props;

    const [editTitle, setEditTitle] = useState('');
    const [editName, setEditName] = useState('');
    const [data, setData] = useState('');



    useEffect(() => {
        setEditTitle(title);
        setEditName(name);
        setData(description);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onEditorChange = (event) => {
        //setDescription(e.target.value)
        setData(event.editor.getData());
    }
    const hanldeDelete = (bId, type) => {
        //console.log(bId, type);
        if (window.confirm("삭제하시겠습니까?")) {
            handleDeleteBoard(bId, type);
            setReadOpen(false);
            setEditMode(false);
        }
    }
    const handleEdit = () => {
        let editedData = {
            title: editTitle,
            boardId: bId,
            type: type,
            description: data,
            name: editName,
            vimeos: vimeos,
            buttonUrl: buttonUrl,
            buttonTitle: buttonTitle
        }
        //console.log(editedData);
        handleEditBoardContent(editedData);
        setReadOpen(false);
        setEditMode(false);
    }


    const handleAddPicture = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    }
    const handleImageChange = (e) => {
        const image = e.target.files[0];

        // send to the server
        const formData = new FormData();    // FormData()를 이용하여 전송
        formData.append('image', image, image.name);

        handleUploadImage(formData);
    }
    const urlRef = useRef();
    let markup = description?.replace(/<p>/g, '<Typography variant="body1">')
        .replace(/<\/p>/g, '</Typography><br/>')
        .replace(/<pre>/g, '<Typography variant="subtitle1">')
        .replace(/<\/pre>/g, '</Typography><br/>')
        .replace(/<img/gi, '<img style={{display:"block", margin:"auto"}}');

    function popup() {
        let string = urlRef.current.value;
        prompt('ctr+C로 복사하세요', string)


    }
    //console.log(data);
    return (
        <div>

            {
                editMode ?
                    <Dialog open={readOpen} fullScreen disableEnforceFocus>
                        <DialogContent>
                            <TextField
                                margin="dense"
                                label="제목"
                                type="text"
                                value={editTitle}
                                onChange={e => setEditTitle(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                margin="dense"
                                label="작성자"
                                type="text"
                                value={editName}
                                onChange={e => setEditName(e.target.value)}
                                fullWidth
                            />
                            <CKEditor
                                data={data}
                                onChange={onEditorChange} />
                            <Box height={30} />
                            {
                                type !== '질문답변' && <Box>
                                    <Box>
                                        <Button variant='contained' onClick={handleAddPicture}>URL 등록</Button>
                                        <input
                                            type='file'
                                            id='imageInput'
                                            hidden='hidden'
                                            onChange={handleImageChange} />
                                    </Box>
                                    <Box>
                                        <Typography>{url?.message}</Typography>
                                        <img src={url?.message} alt='NoImage' />
                                    </Box>
                                    <Box height={20} />



                                </Box>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { setReadOpen(false); setEditMode(false); }} color="primary" variant='contained'>닫기</Button>
                            <Button onClick={() => hanldeDelete(bId, type)} disabled={managerLoading} variant='contained' startIcon={<DeleteRounded />}>삭제</Button>
                            <Button onClick={handleEdit} disabled={managerLoading} variant='contained' startIcon={<EditRounded />}>변경사항저장</Button>
                        </DialogActions>
                    </Dialog>
                    :
                    <Dialog open={readOpen} aria-labelledby="form-dialog-title" fullScreen>

                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setReadOpen(false)}>
                            <CloseRounded />
                        </IconButton>
                        <DialogTitle id="form-dialog-title" align='center'>
                            <Typography variant='h4'>{title}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='row' spacing={2}>
                                <Grid item  sm={3} />
                                <Grid item xs={12} sm={6}>
                                    <DialogContentText>
                                        <Typography variant='body2' gutterBottom>작성자: {name}</Typography>
                                        <Typography variant='body2' gutterBottom>작성일: {createdAt}</Typography>
                                        <input ref={urlRef} style={{ display: 'none' }} value={`https://musicgogo.co.kr/view/${bId}`} />

                                    </DialogContentText>
                                    <Typography variant='h5' color='primary' gutterBottom></Typography>

                                    <Box>
                                        <div dangerouslySetInnerHTML={{ __html: markup }}></div>
                                    </Box>
                                    <Box align='center'>
                                        {vimeos && vimeos.replace(/ /g, '').split(',').map((vimeo, index) =>
                                            <VideoClip
                                                url={`https://vimeo.com/${vimeo}`}
                                                key={index}
                                            />)
                                        }
                                    </Box>
                                </Grid>
                                <Grid item sm={3}/>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setReadOpen(false)} color="primary" variant='contained'>닫기</Button>
                            {
                                rnr.level < 2 &&
                                <Box>
                                    <Button onClick={() => setEditMode(true)} startIcon={<EditRounded />} variant='contained'>수정(관리자)</Button>
                                    <Button onClick={popup} startIcon={<FileCopyRounded />} variant='contained'>url복사</Button>
                                </Box>
                            }
                        </DialogActions>
                    </Dialog>
            }

        </div>
    );
}






export default withStyles(styles)(DialogRead);