import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { deleteBoard, editBoard, getAllBoards, getHakwonInfo, postBoard, uploadBoardImage } from '../../redux/actions/managerActions';
import { getKakaoLinks } from '../../redux/actions/adminActions';
import { getUserData } from '../../redux/actions/userActions';
// components
import LevelCheck from './LevelCheck';
import DummyDivider from '../../components/layout/DummyDivider';
import NotAllowed from './NotAllowed';
import DialogWrite from './DialogWrite';
import DialogRead from './DialogRead';
import CenterLoading from '../../components/layout/CenterLoading';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Typography, Divider, Button, Box } from '@material-ui/core';
import { EditRounded, NotificationImportant } from '@material-ui/icons';
import KakaoLink from '../admin/KakaoLink';
import Pagination from '../../components/util/Pagination';


const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
});
const WhiteNewIcon = withStyles({
    root: {color: '#fff', paddingBottom: 0}
})(NotificationImportant);
const SkyTypo = withStyles({
    root: { color: "rgb(54, 175, 220)" }
})(Typography);

export const HakwonMain = (props) => {
    // const { classes } = props;
    const { rnr, credentials } = useSelector(state => state.user);
    const { hakwon, boards, managerLoading, url } = useSelector(state => state.manager);
    const { adminKakaolinks } = useSelector(state => state.admin);
    const dispatch = useDispatch();
    const [allowed, setAllowed] = useState(false);

    // dialog
    const [open, setOpen] = useState(false);
    const [readOpen, setReadOpen] = useState(false);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    //const [description, setDescription] = useState('');
    const [name, setName] = useState();
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState({});


    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
        dispatch(getAllBoards());
        dispatch(getKakaoLinks());
    }, [dispatch])

    const handleWriteNotification = (type) => {
        setType(type);
        setOpen(true);
    }
    const handlePostData = (data) => {
        dispatch(postBoard(data));
    }
    const handleDeleteBoard = (bId, type) => {
        dispatch(deleteBoard(bId, type));
    }
    const callDialogRead = (data) => {
        setEditData(data);
        setReadOpen(true);
    }
    const handleUploadImage = (formData) => {
        dispatch(uploadBoardImage(formData));
    }
    const handleEditBoardContent = (data) => {
        dispatch(editBoard(data));
    }

    //console.log('allowed', allowed)
    return (
        <div>
            <LevelCheck
                checkingTeacherLevel={3}
                setAllowed={setAllowed}
                allowed={allowed}
                hakwon={hakwon}
                rnr={rnr}
                credentials={credentials}
            />
            {
                allowed ?
                    <Box m={1} p={1}>
                        <Box height={20} />
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Box m={1} p={1}>
                                    {
                                        rnr.level < 2 &&
                                        <Button
                                            size='small'
                                            style={{ float: 'right', marginRight: 10 }}
                                            variant='contained'
                                            endIcon={<EditRounded />}
                                            onClick={() => handleWriteNotification('공지')}
                                        >공지쓰기</Button> /*  */
                                    }
                                    <Typography variant='subtitle1' gutterBottom >본사 공지 사항</Typography>


                                    <Divider light style={{ backgroundColor: '#666' }} />
                                    <Box p={1}>
                                        {
                                            managerLoading ? <CenterLoading />
                                                :
                                                <Pagination
                                                    itemList={
                                                        boards
                                                            .filter(board => board.type === '공지')
                                                            .sort((a, b) => b?.createdAt - a?.createdAt)
                                                            .map(bo =>
                                                                <SkyTypo
                                                                    key={bo.boardId}
                                                                    variant='body2'
                                                                    gutterBottom
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => callDialogRead(bo)}>
                                                                    {bo.title} {dayjs(bo.createdAt * 1000) > dayjs().add(-7, 'day') && <WhiteNewIcon fontSize='small' />}
                                                                </SkyTypo>
                                                            )}
                                                    perPage={5}
                                                />
                                        }
                                    </Box>

                                    <Box height={20} />
                                    {
                                        rnr.level < 2 &&
                                        <Button
                                            size='small'
                                            style={{ float: 'right', marginRight: 10 }}
                                            variant='contained'
                                            endIcon={<EditRounded />}
                                            onClick={() => handleWriteNotification('매뉴얼')}
                                        >쓰기</Button>
                                    }
                                    <Typography variant='subtitle1' gutterBottom>이용방법안내 및 매뉴얼</Typography>

                                    <Divider light style={{ backgroundColor: '#666' }} />
                                    <Box p={1}>
                                        {
                                            managerLoading ? <CenterLoading />
                                                :
                                                <Pagination
                                                    itemList={
                                                        boards.filter(board => board.type === '매뉴얼').sort((a, b) => b?.createdAt - a?.createdAt).map(bo =>
                                                            <SkyTypo key={bo.boardId} variant='body2' gutterBottom style={{ cursor: 'pointer' }} onClick={() => callDialogRead(bo)}>
                                                            {bo.title} {dayjs(bo.createdAt * 1000) > dayjs().add(-7, 'day') && <WhiteNewIcon fontSize='small' />}
                                                            </SkyTypo>
                                                        )}
                                                    perPage={5}
                                                />
                                        }
                                    </Box>

                                    <Box height={20} />
                                    {
                                        rnr.level < 4 &&
                                        <Button
                                            size='small'
                                            color='secondary'
                                            style={{ float: 'right', marginRight: 10 }}
                                            variant='contained'
                                            endIcon={<EditRounded />}
                                            onClick={() => handleWriteNotification('질문답변')}
                                        >쓰기</Button>
                                    }
                                    <Typography variant='subtitle1' gutterBottom>질문과 답변</Typography>

                                    <Divider light style={{ backgroundColor: '#666' }} />
                                    <Box p={1}>
                                        {
                                            managerLoading ? <CenterLoading />
                                                :
                                                <Pagination
                                                    itemList={
                                                        boards.filter(board => board.type === '질문답변').sort((a, b) => b?.createdAt - a?.createdAt).map(bo =>
                                                            <SkyTypo key={bo.boardId} variant='body2' gutterBottom style={{ cursor: 'pointer' }} onClick={() => callDialogRead(bo)}>
                                                                {bo.title} {dayjs(bo.createdAt * 1000) > dayjs().add(-7, 'day') && <WhiteNewIcon fontSize='small' />}
                                                            </SkyTypo>
                                                        )}
                                                    perPage={5}
                                                />
                                        }
                                    </Box>
                                    <Box height={30} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box m={1} p={1}>
                                    <Typography variant='subtitle1' gutterBottom>고객 공유 자료(카카오톡)</Typography>
                                    <Divider light style={{ backgroundColor: '#666' }} />
                                    <SkyTypo variant='caption'>*내용을 수정해서 발송하실 수 있습니다.</SkyTypo><br />
                                    <SkyTypo variant='caption'>*상세내용은 2줄이상 작성불가능합니다.</SkyTypo><br />
                                    <SkyTypo variant='caption'>*미리 '나에게 보내기'하여 확인한 다음 발송하시면 좋습니다.</SkyTypo>
                                    <DummyDivider top={20} />

                                    {adminKakaolinks?.filter(k => k.where === 'both').sort((a, b) => b.createdAt - a.createdAt)
                                        .map((kakao, index) => <Box key={index}>
                                            <KakaoLink
                                                data={kakao}
                                                hName={hakwon.hName}
                                                rnr={rnr}
                                            />
                                        </Box>)}



                                    <Box></Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                    :
                    <NotAllowed allowed={allowed} rnr={rnr} />
            }
            <DialogWrite
                open={open}
                setOpen={setOpen}
                title={title}
                setTitle={setTitle}
                name={name}
                setName={setName}
                postData={handlePostData}
                handleUploadImage={handleUploadImage}
                url={url}
                type={type}
                hName={hakwon.hName}
            />
            <DialogRead
                key={editData.boardId}
                readOpen={readOpen}
                setReadOpen={setReadOpen}
                title={editData.title}
                setTitle={setTitle}
                description={editData.description}
                createdAt={dayjs(editData.createdAt * 1000).format('YYYY-MM-DD(dd) A hh:mm:ss')}
                name={editData.name}
                setName={setName}
                rnr={rnr}
                editMode={editMode}
                setEditMode={setEditMode}
                managerLoading={managerLoading}
                handleDeleteBoard={handleDeleteBoard}
                handleUploadImage={handleUploadImage}
                handleEditBoardContent={handleEditBoardContent}
                setEditData={setEditData}
                bId={editData.boardId}
                type={editData.type}
                vimeos={editData.vimeos}
                buttonUrl={editData.buttonUrl}
                buttonTitle={editData.buttonTitle}
                url={url}
            />
        </div >


    );
};



export default withStyles(styles)(HakwonMain);

