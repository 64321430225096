import { Typography } from '@material-ui/core';
import React from 'react';
import MainGrid from '../../components/layout/MainGrid';
import SubGrid from '../../components/layout/SubGrid';
import Navbar from '../../components/layout/Navbar';

function Marketing() {
    return (
        <>
            <Navbar />
            <MainGrid>
                <SubGrid style={{ padding: 5 }}>

                    <Typography variant='h4'>마케팅 수신 동의 (선택)</Typography>
                    <Typography variant='body2'>
                        <br />
                        <br />
                        <br />뮤직고고가 제공하는 이용자 맞춤형 서비스 및 상품 추천, 각종 경품 행서, 이벤트 등의 광고성 정보를 전자우편이나 서신우편,문자 (SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.
                    <br />
                        <br />-마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다.
                    <br />동의를 거부하시더라도 뮤직고고에서 제공하는 서비스의 이용에 제한이 되지 않습니다.
                    <br />단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
                    <br />

                        <br />2. 미동의 시 불이익 사항
                    <br />
                        <br />개인정보보호법 제 22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다.
                    <br />단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
                    <br />

                        <br />[시행일자 : 2020.11.01 ]
<br />
                    </Typography>


                </SubGrid>
            </MainGrid>
        </>

    );
}

export default Marketing;