/* eslint-disable array-callback-return */
import React from 'react';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { giveAdminRoles, giveManagerRoles } from '../../redux/actions/adminActions';
import { sendATA3 } from '../../redux/actions/enrollActions';
// components

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Box, Grid, Typography, Divider, Button, TextField, Link as MuiLink } from '@material-ui/core';
import CenterLoading from '../../components/layout/CenterLoading';
import { isLogged } from '../../redux/actions/userActions';


const styles = (theme) => ({
    ...theme.userPage,
    half: {
        backgroundColor: '#fff'
    }
});
export const AdminRole = (props) => {
    const { classes, history } = props;
    const { adminLoading, adminErrors, admins, managers } = useSelector(state => state.admin);
    const { loading, errors } = useSelector(state => state.UI);
    const dispatch = useDispatch();

    const [inputText, setInputText] = React.useState('');
    const [messages, setMessages] = React.useState([]);

    const batchAdminRole = () => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(giveAdminRoles());
        }

    }
    const batchManagerRole = () => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            dispatch(giveManagerRoles());
        }

    }

    const checkingFormat = () => {
        if (inputText === '') {
            alert('발송할 메세지를 붙여넣으세요.')
        } else {
            let text = inputText.split('||');
            let vars = [];
            // eslint-disable-next-line array-callback-return
            text.map(mes => {
                let spl = mes.split('%%');
                if (spl.length > 1) {
                    let phone = spl[0].trim();
                    let message = spl[1].replace(/(\r\n\t|\n|\r\t)/gm, "");
                    let intro; let outro; let name_nums = '';
                    message.split('@@').map((an, i, arr) => {
                        // eslint-disable-next-line array-callback-return
                        if (i === 0) {
                            intro = an;
                        } else if (i === arr.length - 1) {
                            outro = an;
                        } else {
                            let num_book = 0;
                            let name = '';
                            an.split('@').map((b, ind) => {
                                if (ind === 0) {
                                    name = b;
                                } else {
                                    num_book += Number(b.slice(-4).replace(/[권\\)\\(]/gi, ''));
                                }
                            })

                            name_nums += name + '\n 총 ' + num_book + '권 \n'
                        }


                    });
                    const another = `${intro}\n\n${name_nums}\n글자수 제한으로 축약해서 발송되었습니다.\n\n${outro}`;
                    console.log(another);

                    message = message.replace(/@/g, '\n');

                    vars.push({ phone: phone, message: message, anotherMessage: another });


                } else {
                    alert('형식이 잘못되었습니다.')
                }

            });
            setMessages(vars);
        }
    }

    const handleSendMessage = () => {
        const res = dispatch(isLogged(history));
        const tobeSent = messages.map(mess=>{
            return {
                phone: mess.phone,
                message: mess.message.length > 900 ? mess.anotherMessage : mess.message
            }

        });
        console.log(tobeSent);
        if (res === 'logged') {
            var result = window.confirm("발송하시겠습니까?");
            if (result) {
                dispatch(sendATA3(tobeSent));
                setMessages([]);
                setInputText('');
            }
            alert('발송 요청 되었습니다.');
        }

    }

    return (
        <Box p={1} m={1}>
            <Typography variant='h5' gutterBottom>관리자(admin) 권한 설정</Typography>
            <Divider light variant='middle' style={{ marginBottom: 10, marginTop: 10 }} />
            <Typography variant='body2' color='textSecondary'> - 회원가입 후, 버튼을 누르면 관리자 권한(level1)이 부여됩니다. 관리자여부는 등록된 직원 '휴대전화번호'로 관리됩니다.</Typography>
            <Typography variant='body2' color='textSecondary'> - 관리자는 마이페이지 '내학원'설정을 변경하면, 학원장과 동일한 권한으로 어느 학원이나 들어갈 수 있습니다. </Typography>
            <Box p={2} m={2}>
                <Button variant='contained' disabled={adminLoading} onClick={batchAdminRole}>관리자 권한 부여 Batch</Button>
            </Box>
            <Box p={1} m={1}>
                <Typography variant='caption' color='secondary'>현재 부여된 관리자 권한 : 총 {admins.length} 명</Typography>
                {adminLoading && <CenterLoading />}
                {adminErrors ? adminErrors.error : null}
                {admins.map((adm, index) => (
                    <Box key={index}>
                        <Typography variant='caption'>[{adm.phone}] {adm.name} </Typography>
                    </Box>
                ))}
            </Box>
            <Box height={20} />

            <Typography variant='h5' gutterBottom>학원장(manager) 권한 설정</Typography>
            <Divider light variant='middle' style={{ marginBottom: 10, marginTop: 10 }} />
            <Typography variant='body2' color='textSecondary'> - 회원가입 후, <strong>관리자가 아래 버튼을 눌러야 학원장 권한(level2)이 부여</strong>됩니다. 학원장 여부는 미리 등록된 학원정보에서 원장 '개인휴대전화번호'로 관리됩니다. </Typography>
            <Typography variant='body2' color='textSecondary'> - 마이페이지에서 '내학원' 설정을 본인 학원으로 하여야 학원장 메뉴를 사용할 수 있으며, 다른 학원에는 접속하면 권한이 없다고 나옵니다.</Typography>
            <Typography variant='body2' color='textSecondary'> - 복수 개의 학원을 운영하시는 분들도, 마이페이지에서 내학원을 변경하시면, 변경된 학원에 메뉴 접속이 가능합니다.</Typography>
            <Typography variant='body2' color='primary'> - 관리자-가맹원 메뉴에서 가맹원을 검색후, 가맹원을 누르면 뜨는 팝업에서 '등급부여 전화번호' 변경을 통해 번호를 변경하실 수 있습니다.</Typography>
            <Box p={2} m={2}>
                <Button variant='contained' disabled={adminLoading} onClick={batchManagerRole}>원장 권한 부여Batch</Button>
            </Box>
            <Box p={1} m={1}>
                <Typography variant='caption' color='secondary'>현재 부여된 학원장 권한 : 총 {managers?.length} 명</Typography>
                {loading && <CenterLoading />}
                {errors ? errors.error : null}
                {managers.map((man, ind) => (
                    <Box key={ind}>
                        <Typography variant='caption'>[{man.phone}] [{man.hName}]  {man.name}</Typography>
                    </Box>
                ))}
            </Box>

            <Box height={20} />

            <Typography variant='h5' gutterBottom>학부모 교재 구매 알리미</Typography>
            <Divider light variant='middle' style={{ marginBottom: 10, marginTop: 10 }} />
            <Typography variant='body2' color='textSecondary'> - 발송할 내역을 아래 창에 입력후 발송버튼을 눌러주세요. </Typography>
            <Typography display='inline' variant='body2' color='textSecondary'> - 엑셀파일을 다운로드하여, 발송할 내역을 정리하세요. </Typography>
            <MuiLink href='https://drive.google.com/u/0/uc?id=12H7GEhWA3lnkucI3Zmae5763W34GgD-V&export=download' rel="noopener noreferrer" target='_blank'>(다운링크)</MuiLink>
            <Box p={1} m={1}>
                <TextField
                    value={inputText}
                    onChange={e => setInputText(e.target.value)}
                    multiline
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    size='small'
                />
            </Box>
            <Box p={2} m={2}>
                <Button variant='contained' color='primary' disabled={adminLoading} onClick={checkingFormat}>check</Button>
            </Box>
            <Box p={2} m={2}>
                {
                    messages.length > 0 && <Typography variant='subtitle1'>{messages.length}개 메시지 발송 가능</Typography>
                }

                <Grid container direction='row'>
                    {
                        messages.map((mes, ind) =>
                            <Grid item xs={6} key={ind}>
                                <Box p={1} m={1}>
                                    <TextField
                                        variant='outlined'
                                        label='전화번호'
                                        value={mes.phone}
                                        fullWidth
                                        className={classes.half}
                                        margin='dense'
                                        size='small'
                                    />
                                    <TextField
                                        variant='outlined'
                                        label='메세지'
                                        value={mes.message.length > 900 ? mes.anotherMessage : mes.message}
                                        className={classes.half}
                                        fullWidth
                                        multiline
                                        margin='dense'
                                        size='small'
                                    />
                                    <Typography variant='body2' color={mes.message.length > 1200 ? 'error' : 'inherit'}>글자수: {mes.message.length > 900 ? `${mes.message.length}=>${mes.anotherMessage.length} 축약` : mes.message.length}</Typography>
                                </Box>
                            </Grid>)
                    }
                </Grid>
            </Box>
            <Box p={2} m={2}>
                {
                    messages.length > 0 && <Button variant='contained' color='primary' disabled={adminLoading} onClick={handleSendMessage}>일괄 발송</Button>
                }
            </Box>
            <Box p={2} m={2}>
                {
                    adminErrors && <Typography>{adminErrors.error}</Typography>
                }
            </Box>
        </Box >


    );
};



export default withStyles(styles)(AdminRole);

