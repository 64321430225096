
import React, {useState, useEffect} from 'react';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { uploadBoardImage } from '../../redux/actions/managerActions';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CenterLoading from '../layout/CenterLoading';

function UploadImage(props) {
    const { url, managerLoading } = useSelector(state => state.manager);
    const dispatch = useDispatch();

    const [tempUrl, setTempUrl] = useState('');
    const [display, setDisplay] = useState(true);

    useEffect(() => {
        if (url) {
            setTempUrl(url.message);
        }
    }, [url]);

    // image 업로드
    const handleAddPicture = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    }
    const handleImageChange = (e) => {
        const image = e.target.files[0];
        // send to the server
        const formData = new FormData();    // FormData()를 이용하여 전송
        formData.append('image', image, image.name);
        handleUploadImage(formData);
    }

    const handleUploadImage = (formData) => {
        if (url) {
            dispatch(uploadBoardImage(formData));
            
        }
    }
    const handleCloseImage = ()=>{
        if (display===true){
            setDisplay(false);
        } else {
            setDisplay(true);
        }
        
    }

    return (
        <Box>
            <Box>
                <Button size='small' color='primary' variant='contained' onClick={handleAddPicture}>+이미지</Button>
                <Button style={{marginLeft: 30}} size='small' variant='contained' onClick={handleCloseImage}>닫기/다시열기</Button>
                <input
                    type='file'
                    id='imageInput'
                    hidden='hidden'
                    onChange={handleImageChange} />
            </Box>
            <Box display={ display ? 'block' : 'none'}>
                <Typography variant='caption' id='url'>{tempUrl}</Typography>
                {
                    managerLoading ? <CenterLoading /> : tempUrl && <img src={tempUrl} alt='URL' />
                }
                
            </Box>





        </Box>
    )
}
export default UploadImage;
