
import {
    SET_ERRORS_ENROLL, CLEAR_ERRORS_ENROLL,
    LOADING_ENROLL, STOP_LOADING_ENROLL,
    SET_LESSON, SET_LESSONS,
    SET_STUDENT,
    CHECK_STUDENTS, INIT_STUDENTS,
    SET_HAKWONS, SET_MAINHAKWON,
    SET_PRODUCTGROUP_ENROLL,
    SET_PRODUCTGROUPALL_ENROLL,
    SET_MYCLASSES,
    SET_MOBILE_PAY_CHECK_RESULT
} from '../types';

// 주문결제(enroll), Lesson, Hakwon, Student, ProductGroup 관련
const initialState = {
    enrollLoading: false,
    enrollErrors: null,
    lessons: [],
    lesson: {},
    hakwons: [],
    hakwon: {},
    students: [],
    child: {},
    children: [],
    productGroup: {},
    productGroupAll: [],
    myclasses: [],
    mobilePayCheckResult: {}

};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ERRORS_ENROLL:
            return {
                ...state,
                enrollLoading: false,
                enrollErrors: action.payload
            };
        case CLEAR_ERRORS_ENROLL:
            return {
                ...state,
                enrollLoading: false,
                enrollErrors: null
            };
        case LOADING_ENROLL:
            return {
                ...state,
                enrollLoading: true
            }
        case STOP_LOADING_ENROLL:
            return {
                ...state,
                enrollLoading: false
            }
        case SET_LESSONS:
            return {
                ...state,
                lessons: action.payload
            }
        case SET_LESSON:
            return {
                ...state,
                lesson: action.payload
            }
        // 학생검색
        case CHECK_STUDENTS:
            return {
                ...state,
                students: action.payload
            }
        case INIT_STUDENTS:
            return {
                ...state,
                students: []
            }
        // 학생등록... 리덕스에만 저장합니다.
        case SET_STUDENT:
            let newChildren;
            if (state.children.length === 0) {
                newChildren = [action.payload];
            } else {
                let index = state.children.findIndex(ch => ch.studentId === action.payload.studentId);
                if (index > -1) {
                    newChildren = [...state.children];
                } else {
                    newChildren = [...state.children, action.payload]
                }
            }
            return {
                ...state,
                child: action.payload,
                children: newChildren
            }
        case SET_HAKWONS:
            return {
                ...state,
                hakwons: action.payload
            }
        case SET_MAINHAKWON:
            return {
                ...state,
                hakwon: action.payload
            }
        case SET_PRODUCTGROUP_ENROLL: {
            return {
                ...state,
                productGroup: action.payload
            }
        }
        case SET_PRODUCTGROUPALL_ENROLL: {
            return {
                ...state,
                productGroupAll: action.payload
            }
        }
        case SET_MYCLASSES: {
            return {
                ...state,
                myclasses: action.payload
            }
        }
        case SET_MOBILE_PAY_CHECK_RESULT: {
            return {
                ...state,
                mobilePayCheckResult: action.payload
            }
        }
        default:
            return state;
    }
}
