import React from 'react';
import { Link } from 'react-router-dom';
// Redux stuff
// components
import main1 from '../../images/main_large_1.png';
import main2 from '../../images/main_large_2.png';
import main3 from '../../images/main_large_3.png';
import main4 from '../../images/main_large_4.png';
import main5 from '../../images/main_large_5.png';
import small1 from '../../images/main_small_1.png';
import small2 from '../../images/main_small_2.png';
import small3 from '../../images/main_small_3.png';
import small4 from '../../images/main_small_4.png';
import small5 from '../../images/main_small_5.png';

// MUI stuff
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button } from '@material-ui/core';
function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return String(Math.floor(Math.random() * (max - min + 1)) + min);
}
const rand = getRandomIntInclusive(0, 4);
const resLarge = [main1, main2, main3, main4, main5][rand];
const resSmall = [small1, small2, small3, small4, small5][rand];

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        /* postiton: 'absolute',
        left:0,
        right:0, */
        margin: '0 auto'
    },
    subroot: {
        /* position: 'absolute',
        left: 0,
        right: 0, */
    },
    banner1: {
        height: 350,
        backgroundImage: `url(${resLarge})`,
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${resSmall})`,
            backgroundPosition: 'bottom',
            height: 400

        }
    },
    header: {
        position: 'absolute',
        top: 91,
        bottom: 0,
        left: 120,
        margin: 'auto',

        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            top: 55,
            left: 0,
            right: 0
        }
    },
    sub: {
        fontFamily: 'Nanum Pen Script',
        margin: 'auto',
        fontSize: 75,
        position: 'absolute',
        fontWeight: 'normal',

        [theme.breakpoints.up('sm')]: {
            top: 143,
            bottom: 0,
            left: 212,
            fontSize: 75,
        },
        [theme.breakpoints.down('xs')]: {
            top: 80,
            textAlign: 'center',
            left: 0,
            right: 0,
            fontSize: 50


        }
    },
    button: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            marginTop: 251,
            left: 301,
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginTop: 162,
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3)
        }
    }
});
const GrayTypo = withStyles({
    root: { color: '#787878' }
})(Typography);
export const Banner1 = (props) => {
    const { classes } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.subroot}>
                <Box align='center' className={classes.banner1}>
                    <Box><GrayTypo variant={matches ? 'h5' : 'h2'} className={classes.header}>우리 아이 음악성을 어떻게 키워주지? </GrayTypo>
                        <Typography id='answer' variant='h1' className={classes.sub}>정답은 뮤직고고! </Typography>
                    </Box>
                    <Button variant='contained' color='primary' className={classes.button} component={Link} to='/gogo/about'>자세히 보기</Button>
                </Box>
            </Grid>
        </Grid>

    );
};



export default withStyles(styles)(Banner1);

