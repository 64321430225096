import React from 'react';
import {Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Chip, Grid, Box, Typography } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
    button: {
        marginRight: theme.spacing(1),
    },
    card: {

    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    more: {
        marginLeft: 'auto'
    },

    margin: {
        margin: theme.spacing(2)
    },
    gutterBottom: {
        marginBottom: theme.spacing(2)
    },
    textBlack:{
        color: '#212121'
    }

});

const ConChip = withStyles({
    root: {
        backgroundColor: '#98e6b5',
        color: '#0EB54C',
        borderRadius: 16,
        fontWeight: 'bold',
        cursor: 'pointer'

    }
})(Chip);
const UnConChip = withStyles({
    root: {
        backgroundColor: '#dedede',
        color: '#787878',
        borderRadius: 16,
        fontWeight: 'bold',
    }
})(Chip);
// step1, step2, step3, step4

const EnrollStepper = (props) => {
    const { classes } = props;

    return (

        <Grid container direction='row' justifyContent='center'>

            {
                props.step1 ?
                    <Grid item xs={3} lg={2} component={Link} to='/lesson'>
                        <Box align='center'><ConChip color='secondary' variant='outlined' label='Step 1' /></Box>
                        <Box align='center' className={classes.textBlack}><Typography align='center'  variant='caption'><strong>{props.step1}</strong></Typography></Box>
                    </Grid>
                    :
                    <Grid item xs={3} lg={2}>
                        <Box align='center'><ConChip color='secondary' variant='outlined' label='Step 1' /></Box>
                        <Box align='center' className={classes.textBlack}><Typography align='center'  variant='caption'><strong>{'수업 선택'}</strong></Typography></Box>
                    </Grid>
            }

            {
                props.step2 ?
                    <Grid item xs={3} lg={2} component={Link} to='/enrollstep2'>
                        <Box align='center'><ConChip variant='outlined' label='Step 2' /></Box>
                        <Box align='center' className={classes.textBlack}><Typography align='center'  variant='caption'><strong>{props.step2}</strong></Typography></Box>
                    </Grid>
                    :
                    <Grid item xs={3} lg={2}>
                        <Box align='center'><UnConChip variant='outlined' label='Step 2' /></Box>
                        <Box align='center'><Typography align='center'  variant='caption'>--</Typography></Box>
                    </Grid>
            }
            {
                props.step3 ?
                    <Grid item xs={3} lg={2} component={Link} to='/enrollstep3'>
                        <Box align='center'><ConChip variant='outlined' label='Step 3' /></Box>
                        <Box align='center' className={classes.textBlack}><Typography align='center'  variant='caption'><strong>{props.step3}</strong></Typography></Box>
                    </Grid>
                    :
                    <Grid item xs={3} lg={2}>
                        <Box align='center'><UnConChip variant='outlined' label='Step 3' /></Box>
                        <Box align='center'><Typography align='center'  variant='caption'>--</Typography></Box>
                    </Grid>
            }
            {
                props.step4 ?
                    <Grid item xs={3} lg={2} component={Link} to='/enrollstep4'>
                        <Box align='center'><ConChip variant='outlined' label='Step 4' /></Box>
                        <Box align='center' className={classes.textBlack}><Typography align='center'  variant='caption'><strong>{props.step4}</strong></Typography></Box>
                    </Grid>
                    :
                    <Grid item xs={3} lg={2}>
                        <Box align='center'><UnConChip variant='outlined' label='Step 4' /></Box>
                        <Box align='center'><Typography align='center'  variant='caption'>--</Typography></Box>
                    </Grid>
            }
        </Grid>

    )
}
export default withStyles(styles)(EnrollStepper);