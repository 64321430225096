import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';
import gogo from '../logo.svg';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { withAppLogin, signupUser } from '../redux/actions/userActions';
// component
import Navbar from '../components/layout/Navbar'
import Footer from '../components/layout/Footer';
//MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import { Box, Paper, FormControlLabel, Checkbox, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { CalendarToday, ArrowDropDownSharp, SearchRounded } from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        margin: theme.spacing(3),
        textAlign: 'left'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    btn: {
        float: 'right'
    }
});

function Signup(props) {
    const { classes } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [, setAccountId] = useState('');
    const [joinPath, setJoinPath] = useState('kakao');
    const [profileImage, setProfileImage] = useState('');
    const [errors, setErrors] = useState({});
    const [display, setDisplay] = useState('none');

    
    // 다른 방법으로 가입하기 추가 (기존 경로로 /other 추가하면 접속가능)
    useEffect(()=>{
        if(window.location.pathname ==='/signup/other') {
            setDisplay('block');
        } else {
            setDisplay('none');
        }
    },[])
    

    const UI = useSelector(state => state.UI);
    //const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        setErrors(UI.errors);
    }, [UI.errors]);

    const handleSubmit = e => {
        e.preventDefault();
        const newUserData = {
            email: email,
            password: password, //confirmPassword: confirmPassword,
            name: name,
            phone: phone,
            accountId: password, // FIXME: 수정필요
            joinPath: joinPath,
            profileImage: profileImage,
        };
        dispatch(signupUser(newUserData, props.history));

    };
    const changeEmail = e => { return setEmail(e.currentTarget.value); }
    const changePassword = e => { return setPassword(e.currentTarget.value); }
    const changeName = e => { return setName(e.currentTarget.value); }
    const changeAccountId = e => { return setAccountId(e.currentTarget.value); }
    const changePhone = e => { return setPhone(e.currentTarget.value); }
    const changeJoinPath = e => { return setJoinPath(e.currentTarget.value); }
    const changeProfileImage = e => { return setProfileImage(e.currentTarget.value); }
    //
    const changePasswordConfirm = e => setPasswordConfirm(e.currentTarget.value);




    // 카카오 로그인
    const ORIGIN_PATH = window.location.origin;
    const REDIRECT_URI = `${ORIGIN_PATH}/kakao`;
    const ref = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=1f452428169c375c44607a1a307b8091&redirect_uri=${REDIRECT_URI}`
    const YellowButton = withStyles({
        root: {
            background: '#f2da00',
            width: 250,
            height: 38,
            '&:hover': {
                background: '#ffeb33',
            },
        }
    })(Button);

    const withApp = () => {
        dispatch(withAppLogin());
    }


    return (
        <>
            <Navbar />
            <div className='container'>
                <Grid container className={classes.form}>
                    <Grid item sm={2} />
                    <Grid item xs={11} sm={8}>
                        <Box height={50} />
                        <img src={gogo} width='150px' alt='logo' />
                        <Typography variant='h4' className={classes.pageTitle}>회원가입</Typography>
                        <Box height={20} />

                        <Box height={50} />

                        <a href={ref}><YellowButton> 카카오로 5초만에 회원 가입</YellowButton></a>
                        <Hidden smUp>
                            <Box height={50} />
                            <div style={{ textAlign: 'center' }}>
                                <YellowButton onClick={withApp}> 카카오톡으로 5초만에 회원 가입 </YellowButton>
                            </div>
                            <Box height={50} />
                        </Hidden>

                        <Box height={100} />
                        <Box display={display}>

                            <Typography variant='subtitle2' align='left'>다른 방법으로 회원가입</Typography>
                            <Typography variant='subtitle1' color='primary' className={classes.margin}>이용 약관 등 동의</Typography>

                            <Paper className={classes.paper}>
                                <FormControlLabel control={<Checkbox />} label={<Typography variant='body2'>[필수] 본 서비스 이용 약관 동의</Typography>} />
                                <Button className={classes.btn} variant='outlined' color='primary' component={Link} to='/terms'>약관 보기</Button>
                            </Paper>
                            <Paper className={classes.paper}>
                                <FormControlLabel control={<Checkbox />} label={<Typography variant='body2'>[필수] 개인정보 수집 및 이용 동의</Typography>} />
                                <Button className={classes.btn} variant='outlined' color='primary' component={Link} to='/privacy'>방침 보기</Button>
                            </Paper>
                            <Paper className={classes.paper}>
                                <FormControlLabel control={<Checkbox />} label={<Typography variant='body2'>[선택] 마케팅 활용 동의</Typography>} />
                                <Button className={classes.btn} variant='outlined' color='primary' component={Link} to='/marketing'>내용 보기</Button>
                            </Paper>
                            <FormControlLabel className={classes.btn} control={<Checkbox />} label={<Typography variant='body2'>모두 동의합니다</Typography>} />
                            <Box className={classes.margin} component='div'>&nbsp;</Box>
                            <Typography variant='subtitle1' color='primary' className={classes.margin}>회원 정보 입력</Typography>
                            <form noValidate onSubmit={handleSubmit}>
                                <TextField
                                    id='email'
                                    name='email'
                                    type='email'
                                    label='이메일'
                                    className={classes.textField}
                                    value={email}
                                    onChange={changeEmail}
                                    helperText={errors ? errors.email : null}
                                    error={errors ? (errors.email ? true : false) : false}
                                    required
                                    autoComplete='userName'
                                    fullWidth />

                                <TextField
                                    id='password'
                                    name='password'
                                    type='password'
                                    label='패스워드'
                                    className={classes.textField}
                                    value={password}
                                    onChange={changePassword}
                                    helperText={errors ? errors.password : null}
                                    error={errors ? (errors.password ? true : false) : false}
                                    required
                                    autoComplete='new-password'
                                    fullWidth />
                                <TextField
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    type='password'
                                    label='패스워드 확인'
                                    className={classes.textField}
                                    value={passwordConfirm}
                                    onChange={changePasswordConfirm}
                                    helperText={errors ? errors.confirmPassword : null}
                                    error={errors ? (errors.confirmPassword ? true : false) : false}
                                    autoComplete='new-password'
                                    fullWidth />
                                <TextField
                                    id='name'
                                    name='name'
                                    type='text'
                                    label='이름'
                                    required
                                    className={classes.textField}
                                    value={name}
                                    onChange={changeName}
                                    helperText={errors ? errors.name : null}
                                    error={errors ? (errors.name ? true : false) : false}
                                    fullWidth />
                                <TextField
                                    id='accountId'
                                    name='accountId'
                                    type='text'
                                    label='accountId(숫자)'
                                    className={classes.textField}
                                    value={password}
                                    onChange={changeAccountId}
                                    helperText={errors ? errors.error : null}
                                    error={errors ? (errors.error ? true : false) : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalendarToday />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth />
                                <TextField
                                    id='phone'
                                    name='phone'
                                    type='text'
                                    label='phone'
                                    required
                                    className={classes.textField}
                                    value={phone}
                                    onChange={changePhone}
                                    helperText={errors ? errors.error : null}
                                    error={errors ? (errors.error ? true : false) : false}
                                    fullWidth />
                                <TextField
                                    id='joinPath'
                                    name='joinPath'
                                    type='text'
                                    label='가입경로'
                                    className={classes.textField}
                                    value={joinPath}
                                    onChange={changeJoinPath}
                                    helperText={errors ? errors.error : null}
                                    error={errors ? (errors.error ? true : false) : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ArrowDropDownSharp />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth />
                                <TextField
                                    id='profileImage'
                                    name='profileImage'
                                    type='text'
                                    label='프로필이미지'
                                    className={classes.textField}
                                    value={profileImage}
                                    onChange={changeProfileImage}

                                    error={errors ? (errors.error ? true : false) : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchRounded />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth />
                                {errors ? (errors.general && <Typography variant="body2" className={classes.customError}>{errors.general}</Typography>) : null}
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    className={classes.button}
                                    disabled={UI.loading}
                                >회원가입
                        {UI.loading && <CircularProgress size={25} className={classes.progress} />}
                                </Button>
                                <div>
                                    <small>이미 계정이 있으신가요? 로그인은 <Link to="/login">여기로</Link></small>
                                </div>
                            </form>
                        </Box>
                        
                    </Grid>
                    <Grid item xs={1} sm={2} />
                </Grid>
                <Footer />
            </div>
        </>
    );

}

export default withStyles(styles)(Signup);
