import React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { deleteKakaoLink, updateKakaoLink } from '../../redux/actions/adminActions';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Box, Typography, TextField, Button, Divider, FormControlLabel, Switch } from '@material-ui/core';
import { SendRounded } from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPage,
    tf: {
        backgroundColor: '#fff',

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 300,
        },
    },
    box: {
        width: 264,
        borderRadius: 10,
        backgroundColor: '#fff'
    }
});
const KakaoLink = (props) => {
    const { classes, data, hName, rnr } = props;

    const [btitle, setBtitle] = React.useState('');
    const [bdesc, setBdesc] = React.useState('');
    const [bimage, setBimage] = React.useState('');
    const [blink, setBlink] = React.useState('')
    const [bbtn, setBbtn] = React.useState('');
    const [isBoth, setIsBoth] = React.useState(false);
    //
    const [blink2, setBlink2] = React.useState('');
    const [bbtn2, setBbtn2] = React.useState('');

    const dispatch = useDispatch();

    React.useEffect(() => {
        setBtitle(data.title.replace(/\$\{name\}/g, hName));
        setBdesc(data.description.replace(/\$\{name\}/g, hName));
        setBimage(data.image);
        setBlink(data.link);
        setBbtn(data.button);
        setIsBoth(data.where === 'both' ? true : false)
        //
        setBlink2(data.link2 ? data.link2 : '');
        setBbtn2(data.button2 ? data.button2 : '');

    }, [hName, data]);

    const sendBoard = () => {
        const Kakao = window.Kakao;
        if (!Kakao.isInitialized()) {
            Kakao.init('be5ea3edf2d75970788c5adabba77a38');
            console.log(Kakao);
        };
        if (data.type === 'feed') {
            Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: btitle,
                    description: bdesc,
                    imageUrl: bimage,
                    link: {
                        mobileWebUrl: blink,
                        webUrl: blink,
                    },
                },
                buttons: [{
                    title: bbtn,
                    link: {
                        mobileWebUrl: blink,
                        webUrl: blink,
                    }
                }, {
                    title: bbtn2,
                    link: {
                        mobileWebUrl: blink2,
                        webUrl: blink2,
                    }
                }]
            });
        } else {
            Kakao.Link.sendCustom({
                templateId: Number(data.template),
                templateArgs: {
                    titel: btitle,
                    description: bdesc,
                    THU: bimage,
                    link: blink,
                    BUT: bbtn
                },
                serverCallbackArgs: {
                    description: bdesc
                }
            });
        }

    }

    const handleDelete = () => {
        let kId = data.kakaolinkId;
        dispatch(deleteKakaoLink(kId));
    }
    const handleUpdate = () => {
        let kId = data.kakaolinkId;
        let newData = {
            title: btitle,
            description: bdesc,
            image: bimage,
            link: blink,
            button: bbtn,
            where: isBoth ? 'both' : 'db',
            //
            link2: blink2,
            button2: bbtn2

        };
        dispatch(updateKakaoLink(kId, newData));
    }

    return (
        <Box>
            <Divider light style={{ margin: 10 }} />
            <Typography variant='subtitle1'>{btitle}</Typography>
            <Box align='center' className={classes.box}>
                <img src={bimage} alt="" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
                {
                    data.type !== '지정템플릿' &&
                    <Box>
                        <Box align='left' style={{ color: '#000', marginLeft: 10 }}>
                            <Typography variant='subtitle1' gutterBottom>{btitle}</Typography>
                            <Typography variant='body2' gutterBottom>{`${bdesc.substring(0,46)}${bdesc.length > 46 ? '...' : ''}`}</Typography>
                        </Box>
                        {
                            data.type === 'feed' ?
                                <Box>
                                    <Button variant='contained' size='small' style={{ width: '80%', margin: 10 }} onClick={() => window.open(`${blink}`, "a", "width=400, height=600, left=100, top=50")}>{bbtn}</Button>
                                    <Button variant='contained' size='small' style={{ width: '80%', marginBottom:10 }} onClick={() => window.open(`${blink2}`, "a", "width=400, height=600, left=100, top=50")}>{bbtn2}</Button>
                                </Box>
                                :
                                <Button variant='contained' size='small' style={{ width: '80%', margin: 10 }} onClick={() => window.open(`https://musicgogo.co.kr/view/${blink}`, "a", "width=400, height=600, left=100, top=50")}>{bbtn}</Button>
                        }


                    </Box>
                }
            </Box>
            <TextField
                variant='filled'
                label='타이틀'
                value={btitle}
                onChange={e => setBtitle(e.target.value)}
                size="small"
                margin='dense'
                fullWidth
                className={classes.tf}
            /><br />
            <TextField
                variant='filled'
                label='내용'
                value={bdesc}
                onChange={e => setBdesc(e.target.value)}
                size="small"
                margin='dense'
                fullWidth
                multiline
                className={classes.tf}
            />
            {
                data.type === 'feed' &&
                <Box>

                    <TextField
                        variant='filled'
                        label='버튼이름'
                        value={bbtn}
                        size='small'
                        margin='dense'
                        onChange={e => setBbtn(e.target.value)}
                        fullWidth
                        className={classes.tf}
                    />
                    <TextField
                        variant='filled'
                        label='링크1'
                        value={blink}
                    size='small'
                    margin='dense'
                    onChange={e => setBlink(e.target.value)}
                    fullWidth
                    className={classes.tf}
                    />
                    <TextField
                        variant='filled'
                        label='버튼이름2'
                        value={bbtn2}
                        size='small'
                        margin='dense'
                        onChange={e => setBbtn2(e.target.value)}
                        fullWidth
                        className={classes.tf}
                    />
                    
                    <TextField
                        variant='filled'
                        label='링크2'
                        value={blink2}
                        size='small'
                        margin='dense'
                        onChange={e => setBlink2(e.target.value)}
                        fullWidth
                        className={classes.tf}
                    />
                </Box>
            }

            {
                data.type !== '지정템플릿' && data.type !== 'feed' &&
                <TextField
                    variant='filled'
                    label='버튼이름'
                    value={bbtn}
                    size="small"
                    margin='dense'
                    onChange={e => setBbtn(e.target.value)}
                    fullWidth
                    className={classes.tf}
                />
            }

            {
                rnr.level < 2 &&
                <Box>
                    <TextField
                        variant='filled'
                        label='이미지 (admin Only)'
                        value={bimage}
                        size="small"
                        margin='dense'
                        onChange={e => setBimage(e.target.value)}
                        fullWidth
                        className={classes.tf}
                    /><br />
                    {
                        data.type !== '지정템플릿' && data.type !== 'feed' &&
                        <TextField
                            variant='filled'
                            label='링크_게시판번호 (admin Only)'
                            value={blink}
                            size="small"
                            margin='dense'
                            onChange={e => setBlink(e.target.value)}
                            fullWidth
                            className={classes.tf}
                        />
                    }

                    <Box height={10} />
                    <Box align='left'>
                        <FormControlLabel
                            control={<Switch checked={isBoth} onChange={e => setIsBoth(e.target.checked)} />}
                            label={isBoth ? "학원메인게시" : "비공개"}
                        />
                    </Box>

                </Box>
            }


            <Box height={5} />
            <Box>
                <Button id="send-btn" onClick={sendBoard} variant='contained' color='secondary' startIcon={<SendRounded />}>
                    {rnr.level < 2 ? '보내기' : '학부모에게 카카오톡 보내기'}
                </Button>
                {rnr.level < 2 && <Button onClick={handleUpdate} variant='contained' style={{ marginLeft: 5 }}>저장</Button>}
                {rnr.level < 2 && <Button onClick={handleDelete} variant='contained' style={{ marginLeft: 5 }}>삭제</Button>}
            </Box>
        </Box>
    );
}
export default withStyles(styles)(KakaoLink);