import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import '../components/enroll/calendar-dark.css';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { deleteLessons, getLessonsByHakwon } from '../redux/actions/managerActions';
//import { getProducts, getCurriculums } from '../redux/actions/dataActions';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Box,
    Button,
    FormGroup,
    Grid,
    Typography,
    Divider,
    FormControl,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    IconButton,
} from '@material-ui/core';
import { AddRounded, CloseRounded, DeleteRounded, FiberNewRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
// components
import MainGridDark from '../components/layout/MainGridDark';
import DummyDivider from '../components/layout/DummyDivider';
import LessonAdd from '../components/manager/LessonAdd';
import LessonInfo from '../components/manager/LessonInfo';
import Footer from '../components/layout/Footer';
import LevelCheck from '../pages/manager/LevelCheck';
import NavBarDark from '../components/layout/NavBarDark';
import { NotAllowed } from './manager/NotAllowed';

const styles = (theme) => ({
    ...theme.userPage,
    palette: {
        type: 'dark',
        color: '#eee',
    },
    marginRL: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    calendar: {
        width: 300,
        height: 400,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%',
        backgroundColor: '#eeeeee',
    },
    halftextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#eeeeee',
        width: '43%',
    },
    switch: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    form: {
        width: '90%',
        margin: theme.spacing(1),
    },
    select: {
        backgroundColor: '#eeeeee',
    },
});
const WhiteCheckbox = withStyles({
    root: { color: '#666' },
})(Checkbox);
const WhiteRadio = withStyles({
    root: { color: '#666' },
})(Radio);
export const Lessonmanager = (props) => {
    const { classes, history } = props;
    // redux
    const { rnr, credentials } = useSelector((state) => state.user);
    const { hakwon, lessons } = useSelector((state) => state.manager);
    //const { products, curriculums } = useSelector(state => state.data);
    const { loading } = useSelector((state) => state.UI);
    const dispatch = useDispatch();
    // countdown loading
    const [second, setSecond] = useState(4);
    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(second) > 1) {
                setSecond(parseInt(second) - 1);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [second]);

    // 권한
    const [allowed, setAllowed] = useState(false);

    // view check state
    const [check, setCheck] = useState({
        trial: true,
        regular: true,
        premium: true,
        passed: false,
    });

    const [searchDate, setSearchDate] = useState(null);
    const [addMode, setAddMode] = useState(false);
    // 레슨
    const [radio, setRadio] = useState(0);
    const [lessonInfo, setLessonInfo] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('all');

    // 캘린더 월 고정
    const cRef = React.useRef();
    const [lastViewDate, setLastViewDate] = useState(
        localStorage.lastViewDate ? localStorage.lastViewDate : dayjs().format('YYYY-MM-DD')
    );

    const onRadioSelect = (e) => {
        // select & save lesson
        e.preventDefault();
        setRadio(e.target.value);        
    };

    const handleChange = (e) => {
        setCheck({ ...check, [e.target.name]: e.target.checked });
    };
    // get lessons & products
    useEffect(() => {
        dispatch(getLessonsByHakwon());
        //dispatch(getProductsWithCurri());
        //
        //dispatch(getProducts());
        //dispatch(getCurriculums());
    }, [dispatch]);

    // 캘린더
    // FIXME: lastMonthDeadline => thisMonthDeadline
    const thisMonthDeadLine = dayjs().add(0, 'month').set('date', 26).format('YYYYMMDD');
    // console.log(thisMonthDeadLine);
    const filteredLessons = lessons.filter(
        (l) =>
            (l.lessonType === 'regular' &&
                check.regular &&
                (check.passed
                    ? true
                    : dayjs(l.startDate * 1000).format('YYYYMMDD') >= thisMonthDeadLine)) ||
            (l.lessonType === 'premium' &&
                check.premium &&
                (check.passed
                    ? true
                    : dayjs(l.startDate * 1000).format('YYYYMMDD') >= thisMonthDeadLine)) ||
            (l.lessonType === 'trial' &&
                check.trial &&
                (check.passed
                    ? true
                    : dayjs(l.startDate * 1000).format('YYYYMMDD') > dayjs().format('YYYYMMDD')))
    );

    // 1회성 레슨
    const datesOne = filteredLessons
        .filter((l) => l.isRepeat === false)
        .map((lesson) => ({
            date: dayjs(lesson.startDate * 1000).format('YYYY-MM-DD'),
            display: 'background',
        }));

    // 반복 레슨
    const recurDates = filteredLessons
        .filter((lesson) => lesson.isRepeat === true)
        .map((l) =>
            l.recurrentDates.map((r, i, le) => ({
                date: dayjs(r).format('YYYY-MM-DD'),
                display: 'background',
                duration: le.duration,
            }))
        );
    const flated = recurDates.reduce((a, v) => a.concat(v), []);
    const dates = [...datesOne, ...flated];
    
    

    // 캘린더 리턴
    const handleDateClick = (date) => {
        setSearchDate(date.dateStr);
        setLessonInfo(null); //초기화
        setRadio(0);
        setLastViewDate(date.dateStr);
        localStorage.setItem('lastViewDate', date.dateStr);
    };

    // 레슨 상세 - 라디오 선택시,
    const showLessonInfo = (index) => {
        setAddMode(false);
        setLessonInfo(refilterByCategory[index]);
    };
    // 레슨 삭제
    const handleDeleteAllLesson = (ls) => {
        const isEnrolled = ls.filter((le) => le.enrolled > 0).length;

        const lists = ls.filter((le) => le.enrolled === 0).map((le) => le.lessonId);
        const cf = window.confirm(
            isEnrolled > 0
                ? '이미 수강신청된 수업이 있습니다.(수강신청된 수업은 삭제하실 수 없습니다.) 수강신청된 수업만 제외하고 모두 삭제하시겠습니까?'
                : '삭제하시겠습니까?'
        );
        if (cf) {
            dispatch(deleteLessons({ lessons: lists }));
        }
    };

    // 재필터
    const reFilteredLessons = filteredLessons
        .filter((le) =>
            le.isRepeat
                ? le.recurrentDates.some((d) => dayjs(d).format('YYYY-MM-DD') === searchDate)
                : searchDate === dayjs(le.startDate * 1000).format('YYYY-MM-DD')
        )
        .sort((a, b) => (b.lessonName < a.lessonName ? 1 : b.lessonName > a.lessonName ? -1 : 0));

    
    
    // category
    const categories = reFilteredLessons.map((le) => le.category);
    const uniqCates = categories.filter((el, ind) => categories.indexOf(el) === ind).sort((a,b)=>b-a);

    const onChangeCategory = (e) => {
        setSelectedCategory(e.target.value);
        setRadio(0);
        setLessonInfo(null);
    };

    const refilterByCategory = reFilteredLessons.filter((le) =>
        selectedCategory === 'all' ? true : le.category === selectedCategory
    );

    const markup = refilterByCategory.map((lesson, index) => {
        isClosed = lesson.status !== 'open' ? true : lesson.enrolled >= lesson.capacity ? true : false;
        isClosed
            ? (label = (
                  <div>
                      {' '}
                      <Typography variant="subtitle2" display="inline">
                          {lesson.lessonName}
                      </Typography>
                      <Typography variant="subtitle2" display="inline" color="error">
                          [{lesson.enrolled >= lesson.capacity ? '마감' : '닫힘'}]
                      </Typography>
                  </div>
              ))
            : (label = <Typography variant="subtitle2">{lesson.lessonName}</Typography>);

        return (
            <FormControlLabel
                key={index}
                value={lesson.lessonId}
                control={<WhiteRadio />}
                label={label}
                onClick={() => showLessonInfo(index)}
            />
        );
    });

    //console.log(lessons.filter(l=>dayjs(l.createdAt*1000).format('YYYYMMDD')>'20220101' && l.category==='플레이어'));

    let label;
    let isClosed;
    return (
        <>
            <NavBarDark menu={5} />
            <div className="container">
                <MainGridDark>
                    <LevelCheck
                        checkingTeacherLevel={2}
                        setAllowed={setAllowed}
                        allowed={allowed}
                        hakwon={hakwon}
                        rnr={rnr}
                        credentials={credentials}
                    />
                    {allowed ? (
                        <Box m={1} p={1}>
                            <DummyDivider top={20} />
                            <Typography align="center" variant="h3">
                                수업 일정 관리
                            </Typography>
                            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
                            <Box align="right">
                                <Typography variant="caption" className={classes.marginRL}>
                                    {credentials.myHakwon?.hName}
                                </Typography>
                            </Box>
                            <Grid container>
                                <Grid item container direction="row">
                                    <Grid item xs={12} sm={6}>
                                        <div className={classes.calendar}>
                                            <Typography variant="h5" gutterBottom>
                                                {' '}
                                                수업 일정
                                            </Typography>
                                            {loading ? (
                                                <Box align="center">
                                                    <Typography variant="caption" color="secondary">
                                                        로딩 중... {second}초
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <FormGroup row>
                                                    <FormControlLabel
                                                        label="원데이"
                                                        control={
                                                            <Checkbox
                                                                checked={check.trial}
                                                                onChange={handleChange}
                                                                name="trial"
                                                                size="small"
                                                                color="secondary"
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        label="뮤직고고"
                                                        control={
                                                            <Checkbox
                                                                checked={check.regular}
                                                                onChange={handleChange}
                                                                name="regular"
                                                                size="small"
                                                                color="secondary"
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        label="프리미엄"
                                                        control={
                                                            <Checkbox
                                                                checked={check.premium}
                                                                onChange={handleChange}
                                                                name="premium"
                                                                size="small"
                                                                color="secondary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                            {loading ? (
                                                <Box>
                                                    <Skeleton
                                                        variant="rect"
                                                        width={300}
                                                        height={30}
                                                        style={{ margin: 5, backgroundColor: '#666' }}
                                                    />
                                                    <Skeleton
                                                        variant="rect"
                                                        width={300}
                                                        height={250}
                                                        style={{ margin: 5, backgroundColor: '#666' }}
                                                    ></Skeleton>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <FullCalendar
                                                        ref={cRef}
                                                        defaultView="dayGridMonth"
                                                        plugins={[dayGridPlugin, interactionPlugin]}
                                                        headerToolbar={{
                                                            start: 'prev',
                                                            center: 'title',
                                                            end: 'today next',
                                                        }}
                                                        buttonText={{ today: '오늘' }}
                                                        locale="ko-KR"
                                                        initialDate={lastViewDate}
                                                        fixedWeekCount={false}
                                                        events={dates}
                                                        eventColor="transparent"
                                                        eventTextColor="#f15624"
                                                        eventBackgroundColor="#f15624"
                                                        eventBorderColr="#f15624"
                                                        dateClick={handleDateClick}
                                                    />
                                                    <FormControlLabel
                                                        label={check.passed ? '지난수업 포함' : '지난수업 제외'}
                                                        control={
                                                            <WhiteCheckbox
                                                                checked={check.passed}
                                                                onChange={handleChange}
                                                                name="passed"
                                                                color="secondary"
                                                            />
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography display="inline" variant="h5" gutterBottom>
                                            {' '}
                                            등록된 수업{' '}
                                        </Typography>
                                        <Typography display="inline" variant="body2" color="error" gutterBottom>
                                            {' '}
                                            {searchDate
                                                ? dayjs(searchDate).format('YYYY-MM-DD(dd)')
                                                : ' 날짜를 선택해주세요'}
                                        </Typography>

                                        <div>
                                            <Box height={10} />
                                            <FormControl component="fieldset">
                                                {reFilteredLessons.length === 0 ? (
                                                    <div>
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="error"
                                                            display="inline"
                                                            gutterBottom
                                                        >
                                                            {' '}
                                                            등록된 수업이 없습니다.
                                                        </Typography>
                                                        <Box height={100} />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Box height={10} />
                                                        <Typography variant="caption" display="inline" gutterBottom>
                                                            연령/카테고리
                                                        </Typography>
                                                        <RadioGroup
                                                            row
                                                            aria-label="categoryName"
                                                            name="categoryName"
                                                            value={selectedCategory}
                                                            onChange={onChangeCategory}
                                                        >
                                                            {uniqCates.map((cat, ind) => (
                                                                <FormControlLabel
                                                                    key={ind}
                                                                    value={cat}
                                                                    control={<WhiteRadio color="primary" />}
                                                                    label={cat}
                                                                />
                                                            ))}
                                                            {uniqCates.length > 1 && (
                                                                <FormControlLabel
                                                                    value={'all'}
                                                                    control={<WhiteRadio color="primary" />}
                                                                    label={'전체'}
                                                                />
                                                            )}
                                                        </RadioGroup>
                                                        <Divider
                                                            style={{ backgroundColor: '#666', marginBottom: 10 }}
                                                        />
                                                        <RadioGroup
                                                            aria-label="lessonName"
                                                            name="lessonName"
                                                            value={radio}
                                                            onChange={onRadioSelect}
                                                        >
                                                            {markup}
                                                        </RadioGroup>
                                                    </div>
                                                )}
                                            </FormControl>
                                        </div>
                                        <Box align="right">
                                            {refilterByCategory.length > 0 && (
                                                <Button
                                                    size="small"
                                                    style={{ marginRight: 10 }}
                                                    variant="outlined"
                                                    color="secondary"
                                                    startIcon={<DeleteRounded />}
                                                    onClick={() => handleDeleteAllLesson(refilterByCategory)}
                                                >
                                                    {refilterByCategory.length}개 수업 전부 삭제
                                                </Button>
                                            )}
                                        </Box>
                                        <Divider light style={{ marginTop: 5, marginBottom: 10 }} />
                                        {addMode ? (
                                            <Box>
                                                <Typography variant="h5" color="primary">
                                                    신규 수업 등록 <FiberNewRounded />
                                                </Typography>
                                                <Box align="right">
                                                    <IconButton
                                                        className={classes.button}
                                                        color="primary"
                                                        onClick={() => setAddMode(false)}
                                                    >
                                                        <CloseRounded />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Typography variant="h5" display="inline" gutterBottom>
                                                    {' '}
                                                    수업 상세 정보
                                                </Typography>
                                                <Box align="right">
                                                    <Button
                                                        className={classes.button}
                                                        color="primary"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => setAddMode(true)}
                                                        startIcon={<AddRounded />}
                                                    >
                                                        신규수업등록
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}

                                        <div>
                                            {/* 조회 모드 */}
                                            {lessonInfo && !addMode && (
                                                <LessonInfo
                                                    lessonInfo={lessonInfo}
                                                    setRadio={setRadio}
                                                    setLessonInfo={setLessonInfo}
                                                    history={history}
                                                />
                                            )}
                                            {/* 생성모드 */}
                                            {addMode && (
                                                <Box>
                                                    <LessonAdd
                                                        setAddMode={setAddMode}
                                                        setLessonInfo={setLessonInfo}
                                                        history={history}
                                                    />
                                                </Box>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <NotAllowed allowed={allowed} rnr={rnr} />
                    )}
                </MainGridDark>
                <Footer />
            </div>
        </>
    );
};

export default withStyles(styles)(Lessonmanager);
