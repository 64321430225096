import React from 'react';

// Redux stuff

// components
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Box, Typography, Link as MuiLink, Paper } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
});
export const NotAllowed = (props) => {
    const { allowed, rnr } = props;

    return (
        <Box>
            {
                !allowed &&
                <Box align='center'>
                    <Paper style={{margin:20, padding:20}}>
                        <InfoRounded color='primary' />
                        <Typography variant='subtitle2' color='primary' gutterBottom>페이지 접근 권한이 없습니다.</Typography>
                        {
                            rnr.level === 3 && <Typography variant='subtitle2' color='primary' gutterBottom>원장 님에게 요청하시기 바랍니다.</Typography>
                        }
                        <Box height={10} />
                        <Typography variant='subtitle2' color='primary'>만일 학원 등록이 안되셨다면, </Typography>
                        <Typography variant='subtitle2' color='primary'>'마이 페이지'에서 내학원 등록이 필요합니다.</Typography>
                        <Box height={10} />
                        <MuiLink color='secondary' href='/mypage'>'마이 페이지'로 이동</MuiLink>
                    </Paper>
                    <Box height={500} />
                </Box>
            }

        </Box>


    );
};



export default withStyles(styles)(NotAllowed);

