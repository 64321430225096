import React, { useEffect } from 'react';

// redux stuff

const LevelCheck = props => {
    const {
        checkingTeacherLevel,
        setAllowed,
        hakwon,
        rnr,
        credentials } = props;
    //const [checked, setChecked] = useState(false);
    // 교사정보불러오기
   // console.log('allowed:', allowed, 'rnr.level:', rnr.level, 'rnr.teacherLevel:', rnr.teacherLevel, hakwon.ownerPhone, credentials.phone); 
    useEffect(() => {
        if (rnr.level < 2 && hakwon) {
            setAllowed(true);
            //setChecked(true);
        } else if (hakwon && rnr.level === 2 && (hakwon.ownerPhone?.indexOf(credentials.phone) > -1)) {
            setAllowed(true);
            //setChecked(true);
        } else if (hakwon && rnr.level === 3 && rnr.hakwonId === credentials.myHakwon?.hakwonId && rnr.teacherLevel <= checkingTeacherLevel) {
            setAllowed(true);
            //setChecked(true);
        } else {
            setAllowed(false)
            //setChecked(false);
        }
    }, [setAllowed, rnr, hakwon, credentials, checkingTeacherLevel]);

    //console.log(rnr.teacherLevel, typeof rnr.teacherLevel);
    //console.log('allowed:', allowed, 'checked', checked, 'rnr.level:', rnr.level, hakwon.hakwonId, credentials.myHakwon?.hakwonId, 'rnr.teacherLevel:', rnr.teacherLevel, hakwon.ownerPhone, credentials.phone);

    //console.log('rnr.level', rnr.level)

    return (
        <div>{props.children}</div>
    );
}
export default LevelCheck;