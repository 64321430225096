import React from 'react';
import { useParams } from 'react-router-dom';
// Redux stuff
import { connect } from 'react-redux';
// components
import MainGrid from '../../components/layout/MainGrid';
import SubGrid from '../../components/layout/SubGrid';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Divider, Typography } from '@material-ui/core';
import DummyDivider from '../../components/layout/DummyDivider';
import Navbar from '../../components/layout/Navbar';

const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        margin: theme.spacing(2)
    },

});

const SuccessPayment = props => {
    //const { classes } = props;
    const { payid } = useParams();

    return (
        <>
            <Navbar menu={1} />
            <MainGrid>
                <SubGrid>
                    <Typography variant='h5' gutterBottom>결제 완료</Typography>
                    <Divider light />
                    <DummyDivider top={15} />
                    <Typography variant='body1' gutterBottom>결제가 성공적으로 이루어졌습니다.</Typography>
                    <Typography variant='body1' gutterBottom>승인번호 : {payid}</Typography>

                </SubGrid>
            </MainGrid>
        </>
    );
};
const mapStateToProps = state => ({
    data: state.data
});
const mapActionsToProps = {

};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(SuccessPayment));