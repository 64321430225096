import React, { useState } from 'react';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';

const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        color: '#fff'
    },
    button: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(1)
        }
    },
    minHeight: {
        minHeight: 130
    }
});
const Pagination = (props) => {
    const { classes, itemList, perPage } = props;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;

    const paginated = itemList.slice(indexOfFirst, indexOfLast);
    const lastPage = Math.ceil(itemList.length / perPage);

    const handlePrev = () => {
        setCurrentPage(pre => pre === 1 ? 1 : pre - 1);
    }
    const handleNext = () => {
        setCurrentPage(pre => pre === lastPage ? lastPage : pre + 1)
    }

    return (
        <Box>
            <Box className={classes.minHeight}>
            {
                paginated.map((item, index) => <Box key={index}>
                    <Typography>{item}</Typography>
                </Box>)
            }
            </Box>
            <Box align='right'>
                <Typography color='secondary' display='inline' variant='body2'>{currentPage} / {lastPage}</Typography>
                <IconButton className={classes.button} color='secondary' size='small' onClick={handlePrev}><ArrowBackIosRounded /></IconButton>
                <IconButton color='secondary' size='small' onClick={handleNext}><ArrowForwardIosRounded /></IconButton>

            </Box>
        </Box>
    )
}
export default withStyles(styles)(Pagination);