import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
    getLesson,
    requestPayment,
    requestBilling,
    getHakwon,
    getEnrollProductGroup,
} from '../../redux/actions/enrollActions';
import { getOneSales } from '../../redux/actions/managerActions';
import { getProduct } from '../../redux/actions/dataActions';

// components
import PostCode from '../../components/util/PostCode';
// MUI stuff
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Box,
    Checkbox,
    Link as MuiLink,
    Button,
    TextField,
    Divider,
    Typography,
    FormControl,
    InputAdornment,
    IconButton,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import { InfoRounded, NavigateBeforeOutlined, NavigateNextRounded, SearchRounded } from '@material-ui/icons';
import DummyDivider from '../layout/DummyDivider';

/* const prods = [
    {groductGroupId : 'musicgogo', productIds: ['DzbNwY2Bz5uPXP5r2sX6', 'JnzBWJx8oTwH9KLcWG0B', 'LWftI74DJAzr3h9ZGERH', 'LY2bzzk1kL1jOyssZXDI']},
    {groductGroupId : 'musicgogoPremium', productIds: ['hYkzmnFPqshqRNgr4gCN', 'CwuUfd5sL2srGqpSxoHZ', 'KiflCPN7CCrr7pTAeJQz', 'WkHIN2b2KZ2316kcjIFs']},
    {groductGroupId : 'musicgogoOneDayClass', productIds: ['sOjJwaCrMxIMqst181l7', '1wp5k5zQFu9zvZFAEzlA']},
    {groductGroupId : 'pianogogoOneDayClass', productIds: ['C3i47lsVsh90QFUBuSDL', 'LaZSibia6d7u2i9THhJk']},

]; */

const styles = (theme) => ({
    ...theme.userPages,
    margin: {
        margin: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonRight: {
        margin: theme.spacing(1),
        paddingLeft: 'auto',
    },
    textField: {
        backgroundColor: '#ffffff',
        width: '100%',
    },
});
// 재결제 일자
// const paidDate = '2021-03-15';

const TempPay = (props) => {
    const { classes } = props;
    const { round, paymentId, lessonId } = useParams();
    localStorage.setItem('redir', window.location.search);
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // redux
    const { credentials } = useSelector((state) => state.user);
    const { lesson, hakwon, productGroup } = useSelector((state) => state.enroll);
    const { sales } = useSelector((state) => state.manager);
    const { product } = useSelector((state) => state.data);
    const dispatch = useDispatch();

    console.log(lesson, hakwon, productGroup);

    // radio
    const [placeRadio, setPlaceRadio] = useState('hakwon');

    // state
    const [receiverName, setReceiverName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [juso1, setJuso1] = useState();
    const [juso3, setJuso3] = useState();
    const [requestInfo, setRequestInfo] = useState('');
    const [isRegularPayment, setIsRegularPayment] = useState(paymentId.substring(0, 2) === 'b_' ? true : false);
    const [regularTerm, setRegularTerm] = useState('');
    const [isConfirmChecked, setIsConfirmChecked] = useState(false);
    const [checked, setChecked] = useState(false);
    //const [age, setAge] = useState('');

    useEffect(() => {
        dispatch(getOneSales(paymentId));
        dispatch(getLesson(lessonId));
    }, [dispatch, paymentId, lessonId]);

    useEffect(() => {
        if (sales) {
            setReceiverName(sales.buyer_name);
            dispatch(getHakwon(sales.hakwonId));
            setPhoneNumber(sales.buyer_tel);
        }
    }, [dispatch, sales]);

    useEffect(() => {
        dispatch(getProduct(lesson.productId));
    }, [dispatch, lesson.productId]);

    useEffect(() => {
        if (hakwon) {
            setJuso1(hakwon.address?.juso1 + hakwon.address?.juso2);
            setJuso3(hakwon.address?.juso3);
        }
    }, [hakwon]);

    useEffect(() => {
        if (product) {
            dispatch(getEnrollProductGroup(product.productGroupId));
        }
    }, [dispatch, product]);

    const handleSetJuso1 = (fullAddress) => {
        setJuso1(fullAddress);
    };
    const handleConfirmCheckbox = () => {
        setIsConfirmChecked(!isConfirmChecked);
        if (!checked) {
            if (juso1 === '' || juso1 === ' ') alert('주소를 입력해주세요');
            if (receiverName === '') alert('받으신분 이름이 빠졌습니다.');
        }
    };

    useEffect(() => {
        if (!!juso1 && !!receiverName && !!phoneNumber && isConfirmChecked) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [juso1, receiverName, phoneNumber, isConfirmChecked]);

    useEffect(() => {
        if (productGroup.products) {
            const info = productGroup.products.filter((prd) => prd.productId === lesson.productId)[0];
            if (info) {
                setRegularTerm(info.range);
                if (info.range === '6개월' || info.range === '12개월') {
                    setIsRegularPayment(true);
                } else if (info.range === '1개월' || info.range === '3개월') {
                    setIsRegularPayment(false);
                } else {
                    return;
                }
            }
        }
    }, [productGroup, lesson.productId]);

    // change radio
    const onChangePlaceRadio = (e) => {
        setPlaceRadio(e.target.value);
        if (e.target.value === 'hakwon') {
            setJuso1(hakwon.address?.juso1 + '(' + hakwon.address?.juso2 + ')');
            setJuso3(hakwon.address?.juso3);
        } else {
            setJuso1(credentials.address?.juso1 + '(' + credentials.address?.juso2 + ')');
            setJuso3(credentials.address?.juso3);
        }
    };

    const CallRequestPayment = () => {
        // 1) 먼저 주문내역을 DB에 저장하고, merchant_uid를 생성한다.
        if (typeof credentials.email === 'undefined' || typeof lesson.price === 'undefined') {
            alert('로그인 상태를 확인하세요.');
        } else if (phoneNumber === '' || phoneNumber === ' ') {
            alert('전화번호를 확인하세요');
        } else if (receiverName === '' || receiverName === ' ') {
            alert('받으실분 이름을 확인하세요');
        } else if (juso1 === '' || juso1 === ' ') {
            alert('주소를 입력해주세요');
        } else {
            let paymentData = {
                // 결제포맷
                name: lesson.lessonName,
                custom_data: lessonId,
                payment_method: 'card',
                amount: lesson.price, // FIXME:
                tax_free: lesson.price,
                buyer_email: credentials.email,
                buyer_tel: phoneNumber,
                buyer_name: receiverName,
                buyer_addr: juso1 + ' ' + juso3,
                pg: 'kcp',
                merchant_uid: 'm_' + new Date().getTime(),
                m_redirect_url: `${window.location.origin}/payment/result`,
            };
            let anotherData = {
                // 생년월일, 학원코드, 학원이름, 주문자, 자녀이름, enrolled처리.
                age: sales.age,
                hName: sales.hName,
                hakwonId: sales.hakwonId,
                hakwonPhone: sales.hakwonPhone, // 하나만 선택
                hakArea: sales.hakArea ? sales.hakArea : null,
                which: placeRadio === 'hakwon' ? sales.hName : `집으로 배송(${juso1} ${juso3})`,
                parentName: credentials.name,
                productId: lesson.productId,
                settlement: lesson.settlement,
                category: lesson.category,
                lessonType: lesson.lessonType,
                lessonDate: lesson.startDate,
                lessonId: lessonId,
                price: lesson.price,
                productGroupId: productGroup.productGroupId,
            };
            // console.log(paymentData, anotherData)
            dispatch(requestPayment(paymentData, anotherData));
        }
    };

    const CallMonthlyPayment = () => {
        // 1) 먼저 주문내역을 DB에 저장하고, merchant_uid를 생성한다.
        if (typeof credentials.email === 'undefined' || typeof lesson.price === 'undefined') {
            alert('로그인 상태를 확인하세요.');
        } else if (phoneNumber === '' || phoneNumber === ' ') {
            alert('전화번호를 확인하세요');
        } else if (receiverName === '' || receiverName === ' ') {
            alert('받으실분 이름을 확인하세요');
        } else if (juso1 === '' || juso1 === ' ') {
            alert('주소를 입력해주세요');
        } else {
            const totalRound =
                lesson.productId === 'LWftI74DJAzr3h9ZGERH' || lesson.productId === 'KiflCPN7CCrr7pTAeJQz' ? 6 : 12;
            const rMinute = getRandomInt(0, 30);
            const rSecond = getRandomInt(0, 60);
            const dateString = new Date().getTime();

            const billingDates = Array.from({ length: totalRound + 1 - Number(round) }, (v, i) => {
                if (i === 0) {
                    // 첫 결제는, 1시간 뒤 자동 결제 예약
                    return dayjs().add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
                } else if (i > 0) {
                    // 두번째 이후는 결제,
                    // 수업시작일이 전월 25 ~ 당월사이 결제월의 26일 오후2시에 결제한다
                    if (dayjs(lesson.startDate * 1000).get('date') > 25) {
                        return dayjs(lesson.startDate * 1000)
                            .add(i, 'month')
                            .set('date', 26)
                            .hour(14)
                            .minute(rMinute)
                            .second(rSecond)
                            .format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        return dayjs(lesson.startDate * 1000)
                            .add(i - 1, 'month')
                            .set('date', 26)
                            .hour(14)
                            .minute(rMinute)
                            .second(rSecond)
                            .format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                /* 날짜값 오류로 제거 하였음 */
                // else {
                //     // 제외 예정
                //     return dayjs()
                //         .year(2000)
                //         .month(i)
                //         .date(1)
                //         .hour(14)
                //         .minute(rMinute)
                //         .second(rSecond)
                //         .format('YYYY-MM-DD HH:mm:ss');
                // }
            });
            const billingUids = Array.from(
                { length: totalRound + 1 - Number(round) },
                (v, i) => `b_${dateString}_${i + Number(round)}_${String(totalRound)}`
            );

            let billingData = {
                // 결제포맷
                pg: 'kcp_billing',
                merchant_uid: 'b_' + dateString,
                payment_method: 'card',
                customer_uid: credentials.uid, // 고객 고유 빌링 키
                name: `${lesson.lessonName}(자동정기결제등록)`,
                amount: 0, // 0 으로 설정하여 빌링키 발급만 진행합니다. 형식적 표현
                buyer_email: credentials.email,
                buyer_name: receiverName,
                buyer_tel: phoneNumber,
                buyer_addr: juso1 + ' ' + juso3,
                buyer_postcode: '',
                custom_data: lesson.lessonId,
                m_redirect_url: `${window.location.origin}/payment/result`,
            };

            let anotherData = {
                // 생년월일, 학원코드, 학원이름, 주문자, 자녀이름, enrolled처리.
                age: sales.age,
                hName: sales.hName,
                hakwonId: sales.hakwonId,
                hakwonPhone: sales.hakwonPhone, // 하나만 선택
                hakArea: sales.hakArea ? sales.hakArea : null,
                which: placeRadio === 'hakwon' ? sales.hName : `집으로 배송(${juso1} ${juso3})`,
                parentName: credentials.name,
                productId: lesson.productId,
                settlement: lesson.settlement,
                price: lesson.price,
                category: lesson.category,
                lessonType: lesson.lessonType,
                lessonDate: lesson.startDate,
                lessonId: lesson.lessonId,
                productGroupId: productGroup.productGroupId,
                round: 1,
                totalRound: totalRound,
                billingDates: billingDates,
                billingUids: billingUids,
            };
            // console.log(billingData, anotherData);

            dispatch(requestBilling(billingData, anotherData));
        }
    };

    // console.log(datestringToNumber("2024-02-14 15:00:00"));

    return (
        <Container>
            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
            <Typography variant="h5" gutterBottom>
                선택하신 수업 정보
            </Typography>
            <Container>
                <Typography variant="h4" color="secondary" gutterBottom>
                    {lesson.lessonName}
                </Typography>
            </Container>
            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
            <Typography variant="h5" gutterBottom>
                수강생 정보
            </Typography>
            <DummyDivider top={10} />

            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} sm={2}>
                    <Typography color="textSecondary">이름</Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                    <Typography color="inherit">
                        <b>
                            {' '}
                            {sales.buyer_name} ({sales.age})
                        </b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} sm={2}>
                    <Typography color="textSecondary">휴대전화</Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                    <Typography color="inherit">
                        <b> {sales.buyer_tel}</b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} sm={2}>
                    <Typography color="textSecondary">수업장소</Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                    <Typography color="inherit">
                        <b>{sales.hName}</b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} sm={2}>
                    <Typography color="textSecondary">수업시작일</Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                    <Typography color="inherit">
                        <b>{dayjs(lesson.startDate * 1000).format('YYYY-MM-DD A hh:mm')}</b>
                    </Typography>
                </Grid>
            </Grid>
            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />

            <Typography variant="h5" gutterBottom>
                수령 정보
            </Typography>
            <FormControl component="fieldset">
                {/* 학원배송지 사전 입력 FIXME: */}
                <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="hakwon"
                    value={placeRadio}
                    onChange={onChangePlaceRadio}
                >
                    <FormControlLabel
                        value="hakwon"
                        control={<Radio color="secondary" />}
                        label="학원"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="home"
                        control={<Radio color="secondary" />}
                        label="가정"
                        disabled={true} // 가정 배송 일괄 중지 (홈스쿨 적용시에만 오픈 예정)
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
            <Typography color="primary" variant="body2" gutterBottom>
                * 기본 배송지는 학원입니다. 뮤직고고, 뮤직고고프리미엄 수업의 워크북, 1인교구패키지는 매월 26일 수업이
                진행되는 학원으로 일괄 배송됩니다.{' '}
            </Typography>
            <Box height={10} />

            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Typography color="textSecondary">받으시는 분(자녀명)</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        className={classes.textField}
                        placeholder="이름을 입력하세요."
                        value={receiverName}
                        onChange={(e) => setReceiverName(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Typography color="textSecondary">휴대전화 번호</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        variant="outlined"
                        type="tel"
                        fullWidth
                        className={classes.textField}
                        placeholder="휴대전화번호"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Typography color="textSecondary">주소</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        variant="outlined"
                        className={classes.textField}
                        placeholder="검색 아이콘을 누르세요"
                        value={juso1}
                        onChange={(e) => setJuso1(e.target.value)}
                        disabled={placeRadio === 'hakwon'}
                        InputProps={
                            placeRadio !== 'hakwon' && {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PostCode title="주소검색" textContent="주소검색" onChange={handleSetJuso1}>
                                            <IconButton color="secondary">
                                                <SearchRounded />
                                            </IconButton>
                                        </PostCode>
                                    </InputAdornment>
                                ),
                            }
                        }
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item sm={2}>
                    <Typography color="textSecondary"> </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        className={classes.textField}
                        disabled={placeRadio === 'hakwon'}
                        placeholder="상세 주소를 입력하세요"
                        value={juso3}
                        onChange={(e) => setJuso3(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Typography color="textSecondary">배송시요청사항</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        className={classes.textField}
                        placeholder="배송시 요청 사항을 입력하세요"
                        value={requestInfo}
                        onChange={(e) => setRequestInfo(e.target.value)}
                    />
                </Grid>
            </Grid>

            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
            <Typography variant="h5" gutterBottom>
                선택한 결제 방법
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="regulr">
                    <FormControlLabel
                        value="general"
                        control={<Radio color="primary" onClick={() => setIsRegularPayment(false)} />}
                        label="일시 결제"
                        labelPlacement="end"
                        checked={!isRegularPayment}
                        disabled={isRegularPayment}
                    />
                    <FormControlLabel
                        value="regular"
                        control={<Radio color="primary" onClick={() => setIsRegularPayment(true)} />}
                        label="정기 결제"
                        labelPlacement="end"
                        checked={isRegularPayment}
                        disabled={!isRegularPayment}
                    />
                </RadioGroup>

                {isRegularPayment && (
                    <Box>
                        <Typography color="secondary" variant="body2" gutterBottom>
                            * 정기결제는 매달 지정된 날짜에 자동으로 결제되는 상품입니다.
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            * 정기 결제 최초 등록 시에는 0원으로 표기되고, 약 1시간 이후 첫회 결제가 진행되며,
                            2번째월부터는 정해진 날짜에 결제됩니다. 자세한 결제 예정일은 마이클래스에서 확인하실 수
                            있습니다.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            정기 결제일
                        </Typography>

                        <TextField
                            id="regularPayDay"
                            name="regularPayDay"
                            type="text"
                            variant="outlined"
                            label="정기 결제일 (매월)"
                            className={classes.textField}
                            value="전월 26일"
                            readOnly
                        />
                        <FormControl component="fieldset">
                            {/* 정기결제 선택 FIXME: */}
                            <RadioGroup row aria-label="position" name="position" defaultValue="hakwon">
                                <FormControlLabel
                                    value="6개월"
                                    control={<Radio color="primary" onClick={() => setRegularTerm('6개월')} />}
                                    label="6개월 정기결제"
                                    labelPlacement="end"
                                    disabled={regularTerm !== '6개월'}
                                    checked={regularTerm === '6개월'}
                                />
                                <FormControlLabel
                                    value="12개월"
                                    control={<Radio color="primary" onClick={() => setRegularTerm('12개월')} />}
                                    label="12개월 정기결제"
                                    labelPlacement="end"
                                    disabled={regularTerm !== '12개월'}
                                    checked={regularTerm === '12개월'}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )}
            </FormControl>

            <Divider light style={{ marginTop: 20, marginBottom: 20 }} />
            <Typography variant="h5" gutterBottom>
                결제 정보
            </Typography>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} sm={2}>
                    <Typography color="textSecondary">결제금액</Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                    <Typography color="inherit">
                        <b> {numberFormat(sales.amount)} 원</b>
                    </Typography>
                </Grid>
            </Grid>
            <DummyDivider top={20} />
            <Divider light />
            <DummyDivider top={20} />

            <Typography variant="subtitle1" gutterBottom>
                개인정보 판매자 제공동의
            </Typography>
            <Box lineHeight={0.5} m={1}>
                <Typography variant="caption" color="textPrimary">
                    고객님께서는 아래 내용에 대하여 동의를 거부하실수 있으며, 거부시 상품 배송, CS가 제한됩니다.
                </Typography>
                <Typography variant="caption" color="textPrimary">
                    <ul style={{ listStyle: 'none' }}>
                        {' '}
                        <li>· 개인정보를 제공받는 자 : [주은교육(주)]</li>
                        <li>· 개인정보를 제공받는 자의 개인정보 </li>
                        <li>· 이용 목적 : 주문상품의 배송, 고객상담 및 불만처리</li>
                        <li>
                            · 제공하는 개인정보의 항목 : 성명, 주소, 연락처(안심번호 적용 시 연락처는 제외),
                            개인통관고유부호(선택시), 공동현관 출입번호(입력시)
                        </li>
                        <li>· 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 구매확정 후 3개월까지</li>
                    </ul>
                </Typography>
            </Box>
            <DummyDivider top={5} />
            <Divider light />
            <DummyDivider top={5} />
            <Typography variant="subtitle1" gutterBottom>
                개인정보 수집 및 이용동의
            </Typography>

            <Box lineHeight={0.5} m={1}>
                <Typography variant="caption" color="textPrimary">
                    고객님께서는 아래 내용에 대하여 동의를 거부하실 수 있으며, 거부 시 상품배송, 구매 및 결제, 일부
                    포인트 적립이 제한됨. 이용계약(이용약관)이 존속중인 탈퇴하지 않은 회원의 경우 보유기간은
                    보존의무기간 이상 보관할 수 있으며, 이 기간이 경과된 기록에 대해서 파기요청이 있는 경우 파기함.
                    결제수단에 따른 개인정보 수집 이용 항목이 상이할 수 있음.
                </Typography>
            </Box>
            <DummyDivider top={10} />
            <Divider light />
            <DummyDivider top={10} />
            <Typography variant="subtitle1" gutterBottom>
                주문 상품 정보 동의
            </Typography>
            <DummyDivider top={5} />
            <Typography variant="caption" color="textPrimary">
                주문 상품의 상품명, 가격, 배송정보에 동의합니다.
            </Typography>
            <DummyDivider top={30} />
            <Box align="center">
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={isConfirmChecked}
                            onChange={handleConfirmCheckbox}
                            name="isConfirmChecked"
                        />
                    }
                    label={<Typography variant="subtitle2">[필수] 약관을 확인하였으며 결제에 동의합니다</Typography>}
                />
            </Box>

            <DummyDivider top={30} />
            <InfoRounded color="error" />
            <br />
            <Box lineHeight={0.5} m={1}>
                <Typography variant="caption">
                    수강 시작으로 부터 7일 이내 환불하셔야 위약금없이 환불이 가능합니다.{' '}
                </Typography>
                <br />
                <Typography variant="caption">
                    온라인 원데이 클래스는 배송 받으신 교구패키지를 개봉 하였을 경우 환불이 불가합니다.
                </Typography>
                <br />
                <Typography variant="caption">
                    <MuiLink component={Link} to="/terms">
                        전체 환불 정책 보기
                    </MuiLink>
                </Typography>
            </Box>
            {isRegularPayment && !matches && (
                <Box lineHeight={0.5} m={1}>
                    <Typography variant="caption">
                        정기결제의 경우,{' '}
                        <strong>PC환경에서는 보안강화를 위햇 공인인증서보안프로그램 설치가 필요합니다.</strong> 설치를
                        원하지 않으시면, <strong>모바일 웹 환경에서 진행</strong>하시기를 바랍니다.{' '}
                    </Typography>
                    <br />
                </Box>
            )}

            <Grid container justifyContent="center">
                <Button
                    component={Link}
                    to={`/enrollstep3`}
                    className={classes.button}
                    variant="outlined"
                    startIcon={<NavigateBeforeOutlined />}
                >
                    <Typography variant="button">수업재선택</Typography>
                </Button>
                <Button
                    disabled={!checked}
                    className={classes.buttonRight}
                    color="primary"
                    variant="contained"
                    endIcon={<NavigateNextRounded />}
                    onClick={isRegularPayment ? CallMonthlyPayment : CallRequestPayment} // FIXME: 수행할 함수의 조건을 분기
                >
                    <Typography variant="button">결제 요청</Typography>
                </Button>
            </Grid>
        </Container>
    );
};
function numberFormat(inputNumber) {
    return String(inputNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// function phoneFormat(string) {
//     if (string.startsWith("+")) {
//         string = "0" + string.substring(3);
//     } else if (string.startsWith("82")) {
//         string = "0" + string.substring(2);
//     }
//     const str = string.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
//     return String(str);
// }

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
}

export default withStyles(styles)(TempPay);
