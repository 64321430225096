import React, {  useEffect } from 'react';
// Redux stuff

// components

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Typography,  RadioGroup, Radio, Switch, FormControlLabel, Box,  FormControl, TextField } from '@material-ui/core';



const styles = (theme) => ({
    ...theme.userPage,
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    btn: {
        margin: theme.spacing(1)
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        backgroundColor: '#ffffff'
    },
    text: {
        marginLeft: theme.spacing(4)
    }
});


const TeacherInfo = ({ teacherInfo, managerLoading, setM1, setM2, setTname, setLev, setIsWorking, m1, m2, tname, lev, isWorking }) => {



    const onChangeLevel = (e) => {
        setLev(e.target.value);

    }
    const onChangeWorking = (e) => {
        setIsWorking(e.target.checked ? true: false);

    }

    useEffect(() => {
        if(typeof teacherInfo.teacher !=='undefined') {
            setM1( teacherInfo.teacher?.memo1);
            setM2(teacherInfo.teacher?.memo2);
            setTname(teacherInfo.teacher?.teacherName);
            setLev(String(teacherInfo.teacher?.teacherLevel));
            setIsWorking(teacherInfo.teacher?.isWorking);
        }
    }, [teacherInfo.teacher, setLev, setIsWorking, setM1, setM2, setTname]);

    return (
        <Box>
            {
                managerLoading ?
                    <Box>...로딩중</Box>
                    :
                    <Box>
                        <Typography>전화번호 : {teacherInfo.user?.phone}</Typography>
                        <Typography>이메일 : {teacherInfo.user?.email}</Typography>
                        <Typography>카카오닉네임 : {teacherInfo.user?.name}</Typography>
                        <TextField
                            margin="dense"
                            id="tname"
                            label="교사 이름"
                            type="text"
                            value={tname}
                            onChange={e => {
                                setTname(e.target.value);

                            }}
                            fullWidth
                        />
                        <TextField

                            margin="dense"
                            id="memo1"
                            label="호칭/역할"
                            type="text"
                            value={m1}
                            onChange={e => {
                                setM1(e.target.value);

                            }}
                            fullWidth
                        />
                        <TextField

                            margin="dense"
                            id="memo2"
                            label="메모"
                            type="text"
                            value={m2}
                            onChange={e => {
                                setM2(e.target.value);

                            }}
                            fullWidth
                        />
                        <FormControlLabel
                            control={<Switch 
                                     checked={isWorking}  
                                     value={isWorking} 
                                     onChange={onChangeWorking} 
                                     name="isW" />}
                            label={isWorking ? '근무 중' : '근무 하지 않음'}
                        />
                        <Typography>권한 레벨 설정</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup row value={lev ? lev : '3'} onChange={onChangeLevel}>
                                <FormControlLabel value='3' label='레벨3' control={<Radio />} />
                                <FormControlLabel value='2' label='레벨2' control={<Radio />} />
                                <FormControlLabel value='1' label='레벨1' control={<Radio />} />
                                <FormControlLabel value='0' label='레벨0' control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
            }
            <Box height={20} />
            <Typography color='primary' variant='subtitle1' gutterBottom>*권한 설정 안내</Typography>
            <Typography variant='subtitle2' gutterBottom>권한의 종류</Typography>
            <Typography variant='body2' gutterBottom>레벨3: 원생관리 기능부여</Typography>
            <Typography variant='body2' gutterBottom>레벨2: + 수업관리 기능까지</Typography>
            <Typography variant='body2' gutterBottom>레벨1: + 교사정보관리, 학원정보 수정까지</Typography>
            <Typography variant='body2' gutterBottom>레벨0: + 매출정산 정보까지 '학원장'과 동등한 권한 부여</Typography>

        </Box>

    );
}
export default withStyles(styles)(TeacherInfo);