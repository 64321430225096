import React from 'react';
// Redux stuff
// import { useSelector, useDispatch } from 'react-redux';
// components
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { TextField, IconButton } from '@material-ui/core';
import { DeleteRounded, AddRounded } from '@material-ui/icons';
const styles = (theme) => ({
    ...theme.userPage,
    twoThirdtextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#ffffff',
        width: '60%'
    },
});
export const LessonTimePicker = (props) => {
    const { classes, times, setTimes } = props;
    // const user = useSelector(state => state.user);
    // const dispatch = useDispatch();


    const deleteLineHandler = (index) => {
        if (times.length === 1) {
            alert('입력하려면 하나 이상은 필요합니다');
        } else {
            times.splice(index, 1);
            setTimes([...times]);
        }

    }
    const addLineHandler = () => {
        const last = times[times.length - 1];
        const next = String(Number(last.split(':')[0]) + 1) + ':00';

        setTimes([...times, next])
    }
    const onChangeTime = (e, index)=>{
        times[index] =  e.target.value;
        setTimes([...times]);
    }
    return (
        <div>
            {times.map((time, index) =>
                <div key={index}> 
                    <TextField
                        id= {`id-${index}`}
                        type="time"
                        variant='outlined'
                        value={time}
                        onChange={(e)=>onChangeTime(e, index)}
                        className={classes.twoThirdtextField}
                        InputLabelProps={{
                            shrink: false,
                        }}
                        margin='dense'
                        inputProps={{
                            step: 600, // 10 min
                        }}

                    />
                    <IconButton onClick={() => deleteLineHandler(index)} ><DeleteRounded color='secondary' /></IconButton>
                    {
                        index === times.length - 1 && <IconButton onClick={addLineHandler} ><AddRounded color='secondary' /></IconButton>
                    }
                </div>
            )}

        </div>


    );
};



export default withStyles(styles)(LessonTimePicker);

