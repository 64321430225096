import React from 'react';

// Redux stuff

// components
import Navbar from '../components/layout/Navbar'
import MainGrid from '../components/layout/MainGrid';
import MainTabs from '../components/layout/MainTabs';
import DummyDivider from '../components/layout/DummyDivider';
import TabsVertical from '../components/layout/TabsVertical';
import Footer from '../components/layout/Footer';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Typography } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.userPage,
});
const tabInfo = [
    { id: 'mypage', label: '마이 페이지', link: '/mypage' },
    { id: 'myclass', label: '마이 클래스', link: '/myclass' },
];
export const Myclass = (props) => {
    return (
        <>
            <Navbar menu={3}/>
            <div className='container'>
                <MainGrid>
                    <MainTabs tabInfo={tabInfo} initValue={1} />
                    <DummyDivider top={20} bottom={20} />
                    <Typography align='center' variant='h3' color='textSecondary' gutterBottom>마이 클래스</Typography>
                    <DummyDivider top={30} bottom={30} />
                    <TabsVertical />
                </MainGrid>
                <Footer />
            </div>
        </>
    );
};

export default withStyles(styles)(Myclass);

