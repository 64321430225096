import {
    SET_ERRORS_ENROLL,
    CLEAR_ERRORS_ENROLL,
    LOADING_ENROLL,
    STOP_LOADING_ENROLL,
    SET_LESSONS,
    SET_LESSON,
    CHECK_STUDENTS,
    INIT_STUDENTS,
    SET_STUDENT,
    SET_HAKWONS,
    SET_MAINHAKWON,
    // 학생검색만
    LOADING_UI,
    STOP_LOADING_UI,
    SET_PRODUCTGROUPALL_ENROLL,
    SET_PRODUCTGROUP_ENROLL,
    SET_MYCLASSES,
    SET_ADMIN_ERRORS,
    SET_MOBILE_PAY_CHECK_RESULT,
} from '../types';
import axios from 'axios';
import { getUserData } from './userActions';
import dayjs from 'dayjs';

// GET all lessons by hakwon
export const getLessonsByHakwon = (hakwonId) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });
    axios
        .get(`/getlessons/${hakwonId}`)
        .then((res) => {
            dispatch({
                type: SET_LESSONS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        })
        .catch((err) => {
            dispatch({
                type: SET_LESSONS,
                payload: [],
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        });
};
// GET all lessons by lessonId
export const getLesson = (lessonId) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });
    axios
        .get(`/lesson/${lessonId}`)
        .then((res) => {
            dispatch({
                type: SET_LESSON,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        })
        .catch((err) => {
            dispatch({
                type: SET_LESSON,
                payload: {},
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        });
};
// 등록한 학원정보
export const getHakwon = (hakwonId) => (dispatch) => {
    axios
        .get(`/hakwon/${hakwonId}`)
        .then((res) => {
            dispatch({
                type: SET_MAINHAKWON,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.error(err);
        });
};
/// added
// checkStudentsByName : 로딩이 중복이어서, 이것만 UI 로딩 땡겨씀
export const checkStudentByName = (hakwonId, studentName) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post(`/checkstudent/${hakwonId}`, studentName)
        .then((res) => {
            dispatch({
                type: CHECK_STUDENTS,
                payload: res.data,
            });
            dispatch({ type: CLEAR_ERRORS_ENROLL });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: SET_ERRORS_ENROLL,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_UI });
            dispatch({ type: INIT_STUDENTS });
        });
};
// get hakwons' contact info
export const getHakwonsContactsByArea = (area) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });
    axios
        .get(`/gethakwonscontacts/${area}`)
        .then((res) => {
            dispatch({
                type: SET_HAKWONS,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        })
        .catch((err) => {
            dispatch({
                type: SET_HAKWONS,
                payload: [],
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        });
};
// set student
export const setMyStudent = (studentId) => (dispatch) => {
    axios
        .get(`/student/${studentId}`)
        .then((res) => {
            dispatch({
                type: SET_STUDENT,
                payload: res.data,
            });
        })
        .catch((err) => console.error(err));
};
// save student
export const saveMyStudent = (studentData) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });
    axios
        .post('/student', studentData)
        .then((res) => {
            dispatch({
                type: SET_STUDENT,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
            dispatch({ type: CLEAR_ERRORS_ENROLL });

            dispatch(getUserData());
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS_ENROLL,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        });
};
// save to User db
export const saveMyStudentIntoUser = (studentId) => (dispatch) => {
    axios
        .post(`/student2user/${studentId}`)
        .then((res) => {
            dispatch({ type: CLEAR_ERRORS_ENROLL });
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS_ENROLL,
                payload: err.response,
            });
        });
};
// set Main Hakwon
export const setMainHakwon = (hakwonId) => (dispatch) => {
    axios
        .post(`/setuser/${hakwonId}`)
        .then((res) => {
            dispatch({
                type: SET_MAINHAKWON,
                payload: res.data,
            });
        })
        .catch((err) => console.error(err));
};
// update StudentInfo
export const updateStudent = (newData) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });

    axios
        .post(`/updatestudent/${newData.studentId}`, newData)
        .then((res) => {
            dispatch({
                type: SET_STUDENT,
                payload: res.data,
            });
            dispatch(getUserData());
            dispatch({ type: STOP_LOADING_ENROLL });
            dispatch({ type: CLEAR_ERRORS_ENROLL });
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: SET_ERRORS_ENROLL,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        });
};
// 리퀘스트 보내고, 스튜던트 저장하기 (동시에)
export const saveStudentBeforRequest = (data) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });
    axios
        .post('/student', data)
        .then((res) => {
            dispatch({
                type: SET_STUDENT,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
            dispatch(getUserData());
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS_ENROLL,
                payload: err.response,
            });
            dispatch({ type: STOP_LOADING_ENROLL });
        });
};
export const saveRequestToHakwon = (hakwonId, data) => (dispatch) => {
    axios
        .post(`/requeststudent/${hakwonId}`, data)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            console.log(err.response.data);
        });
};

// Product Group 정보세팅
export const getAllEnrollProductGroupAll = () => (dispatch) => {
    axios
        .get(`/productgroup`)
        .then((res) => {
            dispatch({
                type: SET_PRODUCTGROUPALL_ENROLL,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.error(err);
        });
};
export const getEnrollProductGroup = (productGroupId) => (dispatch) => {
    axios
        .get(`/productgroup/${productGroupId}`)
        .then((res) => {
            dispatch({
                type: SET_PRODUCTGROUP_ENROLL,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.error(err);
        });
};

// 1) 결제요청전에 먼저 DB에 주문번호 저장
export const savePaymentData = (paymentData, anotherData) => (dispatch) => {
    let allData = {
        ...paymentData,
        ...anotherData,
    };
    // console.log(allData);
    axios
        .post(`/payments/order`, allData)
        .then((res) => {
            console.log(res.data);
            return { code: 1 };
        })
        .catch((err) => {
            console.log(err);
            return { code: -1 };
        });
};

// 2) requst payment (IAMPORT || KCP)
export const requestPayment = (paymentData, anotherData) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });

    let allData = {
        ...paymentData,
        ...anotherData,
    };
    // 1. 데이터를 DB에 먼저 저장한다.
    axios
        .post(`/payments/order`, allData)
        .then((res) => {
            // 2. 저장후, imp 호출
            const IMP = window.IMP;
            IMP.init('imp34301476');
            IMP.request_pay(paymentData, function (rsp) {
                if (rsp.success) {
                    axios
                        .post(`/payments/complete`, {
                            imp_uid: rsp.imp_uid,
                            merchant_uid: rsp.merchant_uid,
                        })
                        .then((data) => {
                            // 3. 서버 결제 API성공시 로직

                            switch (data.data.status) {
                                case 'vbankIssued':
                                    // 가상계좌 발급시 로직// 계좌발급을 사용하지 않음(카드결제만)
                                    console.log('가상계좌 발급이 성공적으로 진행되었습니다.');
                                    break;
                                case 'success':
                                    // 결제성공시 로직
                                    console.log('결제가 성공적으로 진행되었습니다....');

                                    // 4. 알림톡(ATA) 발송(1,2)
                                    if (paymentData.buyer_tel) {
                                        dispatch(
                                            sendATA1({
                                                uName: anotherData.parentName, //주문학부모명
                                                telNum: paymentData.buyer_tel.replace(/-/gi, ''),
                                                lessonName: paymentData.name,
                                                lessonDate: dayjs(anotherData.lessonDate * 1000).format(
                                                    'YYYY-MM-DD(dd) A hh:mm'
                                                ),
                                                hName: anotherData.hName,
                                            })
                                        );
                                    }
                                    if (anotherData.hakwonPhone) {
                                        dispatch(
                                            sendATA2({
                                                uName: paymentData.buyer_name, //학생명
                                                age: anotherData.age,
                                                parentName: anotherData.parentName,
                                                telNum: anotherData.hakwonPhone.replace(/-/gi, ''),
                                                parentNum: paymentData.buyer_tel,
                                                lessonName: paymentData.name,
                                                lessonDate: dayjs(anotherData.lessonDate * 1000).format(
                                                    'YYYY-MM-DD(dd) A hh:mm'
                                                ),
                                                hName: anotherData.hName,
                                                which: anotherData.which,
                                            })
                                        );
                                    }

                                    // 5. 레슨 enrolled +1 처리
                                    dispatch(updateLessonEnrolled({ lessonId: anotherData.lessonId, number: 1 }));
                                    break;
                                default:
                                    break;
                            }
                            dispatch({ type: STOP_LOADING_ENROLL });

                            // 6. 결제성공페이지로 넘기기

                            window.location.replace(
                                `/payment/resultpc?imp_uid=${JSON.parse(data.config.data).imp_uid}&merchant_uid=${
                                    JSON.parse(data.config.data).merchant_uid
                                }&imp_success=${true}`
                            );
                        });
                } else {
                    dispatch({ type: STOP_LOADING_ENROLL });
                    alert(`결제에 실패하였습니다. 에러: ${rsp.error_msg}`);
                }
            });
        })
        .catch((err) => {
            // 7. 저장에 오류가 생길 경우
            dispatch({ type: STOP_LOADING_ENROLL });
            console.log('저장에러발생', allData);
            alert(
                `문제가 발생했습니다. 잠시 후 다시 시도해주세요. 문제가 계속되면 고객센터로 문의해주시기 바랍니다.(${err.error})`
            );
        });
};

// mobile callback <--- 클라이언트 /payment/result 에서 <-- 일반 / 정기결제 모두
export const checkPaymentResultMobile = (val) => async (dispatch) => {
    dispatch({ type: LOADING_ENROLL });
    const { merchant_uid, imp_uid, checked } = val;
    // 앞서 저장한 DB에서 불러온다.
    const getPaymentData = await axios.get(`/getonesales/${merchant_uid}`);
    const paymentData = getPaymentData.data;
    console.log(paymentData);

    const data1 = {
        uName: paymentData.parentName, //주문학부모명
        telNum: paymentData.buyer_tel.replace(/-/gi, ''),
        lessonName: paymentData.name,
        lessonDate: dayjs(paymentData.lessonDate * 1000).format('YYYY-MM-DD(dd) A hh:mm'),
        hName:
            paymentData.merchant_uid.substring(0, 2) === 'b_'
                ? paymentData.hName
                : paymentData.hName + `\n * 정기 결제일은 전월 26일입니다.`,
    };
    const data2 = {
        uName: paymentData.buyer_name, //학생명
        age: paymentData.age,
        parentName: paymentData.parentName,
        telNum: paymentData.hakwonPhone ? paymentData.hakwonPhone.replace(/-/gi, '') : '01000000000',
        parentNum: paymentData.buyer_tel,
        lessonName: paymentData.name,
        lessonDate: dayjs(paymentData.lessonDate * 1000).format('YYYY-MM-DD(dd) A hh:mm'),
        hName: paymentData.hName,
        which:
            paymentData.merchant_uid.substring(0, 2) === 'b_'
                ? paymentData.which
                : paymentData.which + `\n * 정기 결제일은 전월 26일입니다.`,
    };

    // 1) 정기결제의 경우에는, 유효성 검증을 하지 않고 발송한다.
    if (merchant_uid.substring(0, 2) === 'b_') {
        // 여기서 스케쥴을 등록해줘야 한다.
        const checkRes = await axios.post(`/iamport-callback/schedule`, {
            imp_uid: imp_uid,
            merchant_uid: merchant_uid,
        });
        const { code, message } = checkRes.data;
        if (code === 0) {
            // 문자보내기 등의 call-back
            // 이미 발송된 경우는 캔슬
            if (!checked) {
                dispatch(sendATA1(data1)); // FIXME: 반드시 변경
                dispatch(sendATA2(data2));
                dispatch(updateLessonEnrolled({ lessonId: paymentData.lessonId, number: 1 }));
            }

            dispatch({
                type: SET_MOBILE_PAY_CHECK_RESULT,
                payload: {
                    status: 'success',
                    message: '정기결제가 성공적으로 등록되었습니다. \n실제 결제일은 전월 26일 입니다',
                },
            });
        } else {
            dispatch({
                type: SET_MOBILE_PAY_CHECK_RESULT,
                payload: {
                    status: 'failed',
                    message: `정기결제 등록에 오류가 발생했습니다. 고객센터로 문의해주시기 바랍니다. (에러메세지: -${message})`,
                },
            });
        }
    } else {
        // 2) 일반결제의 경우 모바일 유효성을 검증한다.
        const checks = await axios.post(`/payments/completemobile`, { imp_uid: imp_uid, merchant_uid: merchant_uid });

        if (checks?.data) {
            const { status, message } = checks.data;
            console.log(status, message);

            if (status === 'success') {
                //FIXME: check here!!
                if (!checked) {
                    dispatch(sendATA1(data1)); // FIXME: 반드시 변경
                    dispatch(sendATA2(data2));
                    dispatch(updateLessonEnrolled({ lessonId: paymentData.lessonId, number: 1 }));
                }
                dispatch({
                    type: SET_MOBILE_PAY_CHECK_RESULT,
                    payload: { status, message },
                });
            }
        }
    }
};
// lesson enrolled 업데이트하기
export const updateLessonEnrolled =
    ({ lessonId, number }) =>
    (dispatch) => {
        axios
            .post(`/updatelessonenrolled`, { lessonId, number })
            .then((res) => {
                console.log(res.data);

                return res.data;
            })
            .catch((err) => {
                console.error(err);
            });
    };

// 정기결제(Billing) 신청하기
export const requestBilling = (billingData, anotherData) => (dispatch) => {
    dispatch({ type: LOADING_ENROLL });

    let allData = {
        ...billingData,
        ...anotherData,
    };
    // 1. 데이터를 DB에 먼저 저장한다.
    axios
        .post(`/payments/order`, allData)
        .then((res) => {
            // 2. 저장 후, imp호출
            const IMP = window.IMP;
            IMP.init('imp34301476');
            IMP.request_pay(billingData, (rsp) => {
                // 1) 빌링키 발급 요청
                // console.log(rsp);

                // 2) 빌링키 발급 성공
                if (rsp.success) {
                    // 3) 스케쥴 등록 / 결제없이 스케쥴만 등록하기. (PC 버전 콜백)
                    axios
                        .post('/iamport-callback/schedule', {
                            imp_uid: rsp.imp_uid,
                            merchant_uid: rsp.merchant_uid,
                        })
                        .then((data) => {
                            // console.log('스케쥴 등록 성공')
                            // console.log(data.statusText);
                            // 성공시에 (성공메시지 확인요망)

                            if (data.statusText === 'OK') {
                                //
                                // 문자보내기 등의 call-back

                                if (billingData.buyer_tel) {
                                    dispatch(
                                        sendATA1({
                                            uName: anotherData.parentName, //주문학부모명
                                            telNum: billingData.buyer_tel.replace(/-/gi, ''),
                                            lessonName: billingData.name,
                                            lessonDate: dayjs(anotherData.lessonDate * 1000).format(
                                                'YYYY-MM-DD(dd) A hh:mm'
                                            ),
                                            hName: anotherData.hName,
                                        })
                                    );
                                }
                                if (anotherData.hakwonPhone) {
                                    dispatch(
                                        sendATA2({
                                            uName: billingData.buyer_name, //학생명
                                            age: anotherData.age,
                                            parentName: anotherData.parentName,
                                            telNum: anotherData.hakwonPhone.replace(/-/gi, ''),
                                            parentNum: billingData.buyer_tel,
                                            lessonName: billingData.name,
                                            lessonDate: dayjs(anotherData.lessonDate * 1000).format(
                                                'YYYY-MM-DD(dd) A hh:mm'
                                            ),
                                            hName: anotherData.hName,
                                            which: anotherData.which,
                                        })
                                    );
                                }
                            }
                            dispatch({ type: STOP_LOADING_ENROLL });
                            // success
                            window.location.replace(
                                `/payment/resultpc?imp_uid=${JSON.parse(data.config.data).imp_uid}&merchant_uid=${
                                    JSON.parse(data.config.data).merchant_uid
                                }&imp_success=${true}`
                            );
                        })
                        .catch((err) => {
                            //console.log(err);
                            //console.log('스케쥴 실패')
                            dispatch({ type: STOP_LOADING_ENROLL });
                            window.location.replace(
                                `/payment/resultpc?imp_uid=${rsp.imp_uid}&merchant_uid=${
                                    rsp.merchant_uid
                                }&imp_success=${true}`
                            );
                        });
                } else {
                    dispatch({ type: STOP_LOADING_ENROLL });
                    // 빌링키 발급 실패
                    alert(
                        '정기결제 등록에 실패했습니다. 다시 진행하여 주시기 바랍니다. 문제가 계속되면 고객센터로 연락주시기 바랍니다.'
                    );
                }
            });
        })
        .catch((err) => {
            dispatch({ type: STOP_LOADING_ENROLL });
            // 저장오류 발생시,
            alert(
                `문제가 발생했습니다. 잠시 후 다시 시도해주세요. 문제가 계속되면 고객센터로 문의해 주시기 바랍니다.(${err.error})`
            );
        });
};

// 마이클래스 결제정보가져오기.
export const getMyClasses = () => (dispatch) => {
    axios
        .get('/payments')
        .then((res) => {
            dispatch({
                type: SET_MYCLASSES,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.error(err);
        });
};

// 알림톡 발송
// 1) 구매 학부모 수업결제 알림 - 일반결제
export const sendATA1 = (data) => async (dispatch) => {
    console.log(data);
    await axios
        .post('/sendata1', data)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            console.error(err);
        });
};
// 2) 구매내역 대상 학원장 알림 - 일반결제
export const sendATA2 = (data) => async (dispatch) => {
    console.log(data);
    await axios
        .post('/sendata2', data)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            console.error(err);
        });
};
// 3) 아이뮤즈몰 학부모구재 학원장 알림(그룹 발송) - 별도
export const sendATA3 = (data) => (dispatch) => {
    axios
        .post('/sendata3', data)
        .then((res) => {
            dispatch({ type: SET_ADMIN_ERRORS });
        })
        .catch((err) => {
            dispatch({
                type: SET_ADMIN_ERRORS,
            });
        });
};

// 4) 학부모가 학원장에게 원생 등록 확인 요청 날림
export const sendATA4 = (data) => (dispatch) => {
    axios
        .post('/sendata4', data)
        .then((res) => {
            console.log(data);
            console.log(res.data);
        })
        .catch((err) => {
            dispatch({
                type: SET_ADMIN_ERRORS,
            });
            console.log(err);
        });
};

// 5) 학원장이 확인후, 학부모에게 알림 발송(구매유도)
export const sendATA5 = (data) => (dispatch) => {
    axios
        .post('/sendata5', data)
        .then((res) => {
            console.log(data);
            console.log(res.data);
        })
        .catch((err) => {
            dispatch({
                type: SET_ADMIN_ERRORS,
            });
            console.log(err);
        });
};

// 6) 수강연장 요청 (학부모) 일괄 그룹 전송
export const sendATA6 = (data) => (dispatch) => {
    axios
        .post('/sendata6', data)
        .then((res) => {
            console.log(data);
            console.log(res.data);
        })
        .catch((err) => {
            dispatch({
                type: SET_ADMIN_ERRORS,
            });
            console.log(err);
        });
};

// 6) 수강연장 요청 (가맹원장) 일괄 그룹 전송
export const sendATA7 = (data) => (dispatch) => {
    axios
        .post('/sendata7', data)
        .then((res) => {
            console.log(data);
            console.log(res.data);
        })
        .catch((err) => {
            dispatch({
                type: SET_ADMIN_ERRORS,
            });
            console.log(err);
        });
};

// 7) 수강취소 요청 처리 관리자 완료 알림톡
export const sendATA8 = (data) => (dispatch) => {
    axios
        .post('/sendata8', data)
        .then((res) => {
            console.log(data);
            console.log(res.data);
        })
        .catch((err) => {
            dispatch({
                type: SET_ADMIN_ERRORS,
            });
            console.log(err);
        });
};
