/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { RRule, RRuleSet } from 'rrule';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { deleteLesson, updateLesson } from '../../redux/actions/managerActions'; // getProductsWithCurri
import { getProducts, getCurriculums } from '../../redux/actions/dataActions';
import { isLogged } from '../../redux/actions/userActions';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Box,
    Button,
    FormControl,
    Switch,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    InputAdornment,
    FormControlLabel,
    RadioGroup,
    Radio,
    Divider,
} from '@material-ui/core';
import { CalendarTodayRounded, DeleteRounded, ReportRounded, SaveRounded } from '@material-ui/icons';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
// components
import CenterLoading from '../../components/layout/CenterLoading';
import DateTimeSelector from './DateTimeSelector';
import LessonAccordian from './LessonAccordian';

const styles = (theme) => ({
    ...theme.userPage,
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%',
        backgroundColor: '#ffffff',
    },
    halftextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#ffffff',
        width: '43%',
    },
    twoThirdtextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#ffffff',
        width: '60%',
    },
    switch: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    form: {
        width: '90%',
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
    },
    select: {
        backgroundColor: '#ffffff',
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    category: {
        width: '90%',
        margin: theme.spacing(1),
        backgroundColor: '#ffffff',
    },
});
const WhiteRadio = withStyles({
    root: { color: '#666' },
})(Radio);
export const LessonInfo = (props) => {
    const { classes, lessonInfo, setLessonInfo, setRadio, history } = props; // lessonInfo를 받아옴
    const { credentials } = useSelector((state) => state.user);
    const { managerErrors } = useSelector((state) => state.manager);
    const { products, curriculums } = useSelector((state) => state.data);
    const { loading } = useSelector((state) => state.UI);
    const dispatch = useDispatch();

    // state
    const [lname, setLname] = useState('');
    const [enr, setEnr] = useState(0);
    const [cap, setCap] = useState(0);

    const [, setDescr] = useState({
        목표: '',
        활동: '',
        준비: '',
        활동명: '',
        활동자료: '',
        워크북: '',
    });
    const [descForParent, setDescForParent] = useState({
        info1: '',
        info2: '',
    });
    const [price, setPrice] = useState(0);
    const [settle, setSettle] = useState(0);
    const [count, setCount] = useState(0);
    const [duration, setDuration] = useState('');
    const [status, setStatus] = useState(false); // boolen => DB에는 String
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isCountLimit, setIsCountLimit] = useState(true);
    const [exception, setException] = useState(false);
    const [exDates, setExDates] = useState('');
    const [rDates, setRDates] = useState('');
    const [withoutTimeTable, setWitoutTimeTable] = useState(false);

    const [validCheck, setValidCheck] = useState(false);
    const [errorMessage, setErrorMessage] = useState('입력된 값을 확인하세요');

    // 상품선택
    const [selectedProductId, setSelectedProductId] = useState('');
    // const [curris, setCurris] = useState([]);
    const [, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    // lessonManager에서 라디오버튼으로 lessonInfo 변경될때,
    useEffect(() => {
        setSelectedProductId(lessonInfo.productId);
        setLname(lessonInfo.lessonName);
        setEnr(lessonInfo.enrolled ? lessonInfo.enrolled : 0);
        setCap(lessonInfo.capacity);
        setDescForParent({
            info1: lessonInfo.descForParent.info1,
            info2: lessonInfo.descForParent.info2,
        });
        setPrice(lessonInfo.price);
        setSettle(lessonInfo.settlement);
        setCount(lessonInfo.recurrentDates?.length);
        setRepeatRadio(lessonInfo.isRepeat ? 'repeat' : 'one');
        setDuration(lessonInfo.duration ? reversemH(lessonInfo.duration) : 0);
        setStatus(lessonInfo.status === 'open' ? true : false);
        setStartDate(dayjs(lessonInfo.startDate * 1000).format('YYYY-MM-DD HH:mm:ss'));
        setEndDate(
            lessonInfo.endDate
                ? dayjs(lessonInfo.endDate * 1000).format(
                      lessonInfo.isRepeat ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss'
                  )
                : null
        );
        setIsCountLimit(lessonInfo.isCountLimit);
        setWday(lessonInfo.weekday);
        setException(false);
        setExDates(typeof lessonInfo.exDate !== 'undefined' ? lessonInfo.exDate.join() : '');
        setRDates(typeof lessonInfo.rDate !== 'undefined' ? lessonInfo.rDate.join() : '');
        setSelectedCategory(lessonInfo.category);
        setDescr({
            목표: '',
            활동: '',
            준비: '',
            활동명: '',
            활동자료: '',
            워크북: '',
        });
        // 타임테이블없는 수업(13:00)
        setWitoutTimeTable(lessonInfo.isWithoutTimeTable ? lessonInfo.isWithoutTimeTable : false);
    }, [lessonInfo]);

    // 불러올때, 카테고리, 커리큘럼 생성
    useEffect(() => {
        products.map((prod) => {
            if (prod.productId === selectedProductId) {
                setPrice(numberFormat(prod.price));
                setSettle(numberFormat(prod.settlement));
            }
        });
    }, [selectedProductId, lessonInfo, products]);

    /*  const onChangeCategory = e => {
         setSelectedCategory(e.target.value);
     } */

    // 상품변경시, 카테고리, 커리큘럼 자동생성
    const onChangeProduct = (e) => {
        setSelectedProductId(e.target.value);
        // setCurris([]);
        setCategories([]);
        // eslint-disable-next-line array-callback-return
        products.map((prod) => {
            if (prod.productId === e.target.value) {
                setPrice(numberFormat(prod.price));
                setSettle(numberFormat(prod.settlement));
                setLname(prod.productName);
            }
        });
    };
    // 반복 라디오
    const [repeatRadio, setRepeatRadio] = useState(lessonInfo.isRepeat ? 'repeat' : 'one');
    const onChangeRepeatRadio = (e) => {
        setRepeatRadio(e.target.value);
    };
    // weekday
    const [wday, setWday] = useState(lessonInfo.weekday);
    // const handleWday = (e, week) => {
    //     setWday(week);
    // }

    // get lessons & products
    useEffect(() => {
        // dispatch(getProductsWithCurri());
        dispatch(getProducts());
        dispatch(getCurriculums());
    }, [dispatch]);

    const handleUpdateLesson = () => {
        setValidCheck(false);
        let inheritCount = products.filter((prod) => prod.productId === selectedProductId)
            ? products.filter((prod) => prod.productId === selectedProductId)[0].count
            : null;
        let inheritLessonType = products.filter((prod) => prod.productId === selectedProductId)
            ? products.filter((prod) => prod.productId === selectedProductId)[0].lessonType
            : null;

        if (
            isEmpty(lname) ||
            isEmpty(selectedCategory) ||
            isEmpty(selectedProductId) ||
            (startDate / 1000 > endDate / 1000 && (isCountLimit === false || repeatRadio === 'one'))
        ) {
            setValidCheck(true);
            setErrorMessage('수업명, 연령/카테고리, 상품, 또는 날짜가 맞는 지 확인하세요.');
        } else if (isEmpty(startDate) || (isEmpty(endDate) && isCountLimit === false)) {
            setValidCheck(true);
            setErrorMessage('날짜를 바르게 선택하셔야 합니다.');
        } else if (repeatRadio === 'repeat' && wday === []) {
            setValidCheck(true);
            setErrorMessage('요일을 선택하셔야 합니다.');
        } else if (repeatRadio === 'one' && isEmpty(endDate)) {
            setValidCheck(true);
            setErrorMessage('종료일을 선택하셔야 합니다.');
        } else {
            let newLessonInfo = {
                hakwonId: credentials.myHakwon.hakwonId, // back
                //curriculumId: selectedCurriId,   // back
                productId: selectedProductId,
                lessonName: lname,
                startDate: startDate / 1,
                endDate: isEmpty(endDate) && isCountLimit === true ? null : endDate / 1,
                enrolled: Number(enr),
                capacity: Number(cap),
                isPaid: Number(price) > 0 ? true : false,
                isFull: false,
                // desc: descr 삭제 -- 커리큘럼에서 받아온다.
                descForParent: descForParent,
                price: Number(price.replace(',', '')), // back
                settlement: Number(settle.replace(',', '')), // back
                isRepeat: repeatRadio === 'repeat' ? true : false,
                weekday: wday, // []
                duration: repeatRadio === 'repeat' ? mH(duration) : null,
                count: count > 0 ? Number(count) : 0,
                isCountLimit: isCountLimit,
                exDate: exDates.trim().replace(' ', '').split(','),
                rDate: rDates.trim().replace(' ', '').split(','),
                status: status ? 'open' : 'closed',
                lessonType: inheritLessonType,
                recurrentDates:
                    repeatRadio === 'repeat'
                        ? makeRrule(wday, startDate, endDate, rDates, exDates, count, isCountLimit, inheritCount)
                        : null,
            };

            const res = dispatch(isLogged(history));
            if (res === 'logged') {
                dispatch(updateLesson(lessonInfo.lessonId, newLessonInfo));
                setLessonInfo(null);
                setRadio(0);
            }
        }
    };
    const handleDeleteLesson = () => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            setValidCheck(false);

            if (Number(lessonInfo.enrolled) > 0) {
                setValidCheck(true);
                setErrorMessage(
                    '수강생이 있는 수업은 삭제하실 수 없습니다. 보이지 않기를 원하시면 수업 상태를 닫힘으로 하세요.'
                );
            } else {
                if (window.confirm('삭제하시겠습니까?')) {
                    dispatch(deleteLesson(lessonInfo.lessonId));
                    setLessonInfo(null);
                }
            }
        }
    };

    const filteredCurris = curriculums
        .filter((c) => c.category === selectedCategory && c.status === 'open' && c.productId === 'DzbNwY2Bz5uPXP5r2sX6')
        .filter((c) => {
            const isSameYear = dayjs(lessonInfo.startDate * 1000).year() === dayjs(lessonInfo.endDate * 1000).year();
            if (isSameYear) {
                return (
                    c.yyyymm.substr(4, 2) >= dayjs(lessonInfo.startDate * 1000).format('MM') &&
                    c.yyyymm.substr(4, 2) <= dayjs(lessonInfo.endDate * 1000).format('MM')
                );
            } else {
                return (
                    c.yyyymm.substr(4, 2) >= dayjs(lessonInfo.startDate * 1000).format('MM') ||
                    c.yyyymm.substr(4, 2) <= dayjs(lessonInfo.endDate * 1000).format('MM')
                );
            }
        })
        .map((c) => {
            const isSameYear = dayjs(lessonInfo.startDate * 1000).year() === dayjs(lessonInfo.endDate * 1000).year();
            const ord = isSameYear
                ? '1'
                : c.yyyymm.substr(4, 2) >= dayjs(lessonInfo.startDate * 1000).format('MM')
                ? '1'
                : '2';
            return {
                ...c,
                order: ord + c.yyyymm.substr(4, 2) + c.week,
            };
        })
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    
    return (
        <Box>
            <Box>
                <Typography variant="subtitle2" gutterBottom>
                    수업 종류
                </Typography>
                <FormControl variant="filled" className={classes.form}>
                    <InputLabel id="demo-simple-select-outlined-label">수업 종류 선택</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedProductId}
                        onChange={onChangeProduct}
                        size="small"
                        label="수업 종류"
                        className={classes.select}
                        readOnly
                    >
                        <MenuItem value="">
                            <em>선택</em>
                        </MenuItem>
                        {products
                            .filter((prod) => prod.targetRange !== 'online')
                            .sort((a, b) => a['order'] - b['order'])
                            .map((prod) => (
                                <MenuItem key={prod.order} value={prod.productId}>
                                    {prod.productName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <TextField
                    variant="filled"
                    value={selectedCategory}
                    label="연령/카테고리"
                    readOnly
                    fullWidth
                    className={classes.category}
                />

                {/* <FormControl variant="outlined" className={classes.form}>
                    <InputLabel id="curri-label">연령/카테고리</InputLabel>
                    
                    <Select
                        labelId="curri-label"
                        id="select-outlined"
                        value={selectedCategory}
                        onChange={onChangeCategory}
                        size='small'
                        label="연령/카테고리"
                        placeholder='연령/카테고리를 선택해주세요.'
                        className={classes.select}
                    >
                        <MenuItem value=""><em>선택</em></MenuItem>

                        {
                            categories.map((cate, index) =>
                                <MenuItem key={index} value={cate} >{cate}</MenuItem>)
                        }


                    </Select>
                </FormControl> */}
            </Box>
            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                수업명
            </Typography>
            <TextField
                label="수업명 입력"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                onChange={(e) => setLname(e.target.value)}
                className={classes.textField}
                value={lname}
                required
            />

            <TextField
                label="결제금액"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                readOnly
                value={price}
                InputProps={{
                    endAdornment: <InputAdornment position="end">원</InputAdornment>,
                }}
            />
            <TextField
                label="정산금액"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                readOnly
                value={settle}
                InputProps={{
                    endAdornment: <InputAdornment position="end">원</InputAdornment>,
                }}
            />

            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                정원관리
            </Typography>
            <TextField
                label="등록인원"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                type="number"
                value={enr}
                InputProps={{
                    endAdornment: <InputAdornment position="end">명</InputAdornment>,
                }}
            />
            <TextField
                label="정원"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.halftextField}
                type="number"
                value={cap}
                onChange={(e) => setCap(e.target.value)}
                required
                InputProps={{
                    endAdornment: <InputAdornment position="end">명</InputAdornment>,
                }}
            />

            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                날짜
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup row value={repeatRadio} onChange={onChangeRepeatRadio}>
                    <FormControlLabel value="one" control={<WhiteRadio disabled={lessonInfo.isRepeat} />} label="1회" />
                    <FormControlLabel
                        value="repeat"
                        control={<WhiteRadio disabled={!lessonInfo.isRepeat} />}
                        label="매주 반복"
                    />
                </RadioGroup>
            </FormControl>
            <br />
            {repeatRadio === 'repeat' ? (
                <Box>
                    {/* <Box align='center'>
                            <ToggleButtonGroup
                                value={wday}
                                onChange={handleWday}
                                aria-label="week day"
                            >
                                <ToggleButton value="MO" aria-label="mo">월</ToggleButton>
                                <ToggleButton value="TU" aria-label="tu">화</ToggleButton>
                                <ToggleButton value="WE" aria-label="we">수</ToggleButton>
                                <ToggleButton value="TH" aria-label="th">목</ToggleButton>
                                <ToggleButton value="FR" aria-label="fr">금</ToggleButton>
                                <ToggleButton value="SA" aria-label="sa">토</ToggleButton>

                            </ToggleButtonGroup>
                        </Box> */}

                    {lessonInfo.isWithoutTimeTable ? (
                        <KeyboardDatePicker
                            color="primary"
                            label="시작일"
                            value={startDate}
                            onChange={(e) => setStartDate(e)}
                            inputVariant="filled"
                            format={'YYYY-MM-DD'}
                            margin="dense"
                            className={classes.textField}
                            keyboardIcon={<CalendarTodayRounded color="primary" />}
                        />
                    ) : (
                        <KeyboardDateTimePicker
                            color="primary"
                            label={'시작일시'}
                            value={startDate}
                            onChange={(e) => setStartDate(e)}
                            inputVariant="filled"
                            format={'YYYY-MM-DD A hh:mm'}
                            margin="dense"
                            className={classes.textField}
                            keyboardIcon={<CalendarTodayRounded color="primary" />}
                        />
                    )}
                    {lessonInfo.isWithoutTimeTable ? (
                        <KeyboardDatePicker
                            color="primary"
                            label={'종료일'}
                            value={endDate}
                            onChange={(e) => setEndDate(e)}
                            inputVariant="filled"
                            format={'YYYY-MM-DD'}
                            margin="dense"
                            className={classes.textField}
                            keyboardIcon={<CalendarTodayRounded color="primary" />}
                        />
                    ) : (
                        <KeyboardDateTimePicker
                            color="primary"
                            label={'종료일자'}
                            value={endDate}
                            onChange={(e) => setEndDate(e)}
                            inputVariant="filled"
                            format={'YYYY-MM-DD A hh:mm'}
                            margin="dense"
                            className={classes.textField}
                            keyboardIcon={<CalendarTodayRounded color="primary" />}
                        />
                    )}

                    <br />

                    <TextField
                        label="총 횟수"
                        variant="filled"
                        color="secondary"
                        size="small"
                        margin="dense"
                        className={classes.halftextField}
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">회</InputAdornment>,
                        }}
                    />

                    <TextField
                        label="수업시간"
                        variant="filled"
                        color="secondary"
                        size="small"
                        margin="dense"
                        required
                        className={classes.halftextField}
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">분</InputAdornment>,
                        }}
                    />
                    <Box p={1} m={1}>
                        <Button
                            variant="outlined"
                            startIcon={<ReportRounded />}
                            color="secondary"
                            size="small"
                            onClick={() => setException(!exception)}
                        >
                            예외 일자 처리
                        </Button>
                    </Box>

                    {exception && (
                        <Box>
                            <TextField
                                color="primary"
                                label="(예외) 제외일자"
                                value={exDates}
                                onChange={(e) => setExDates(e.target.value)}
                                variant="filled"
                                margin="dense"
                                className={classes.textField}
                            />
                            <br />
                            <Typography variant="body2" color="primary">
                                반복에서 제외되는 날짜는 2021-12-25, 2021-12-31 과 같이 ,(컴마)로 구분하여 입력해주세요.
                            </Typography>
                            <TextField
                                color="primary"
                                label="(예외) 추가일자"
                                value={rDates}
                                onChange={(e) => setRDates(e.target.value)}
                                variant="filled"
                                margin="dense"
                                className={classes.textField}
                            />
                            <br />
                            <Typography variant="body2" color="primary">
                                특정날짜를 추가하고 싶다면, 2021-12-25, 2021-12-31 과 같이 ,(컴마)로 구분하여
                                입력해주세요.
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : (
                <DateTimeSelector
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    addMode={false}
                />
            )}

            {/* 커리큘럼 상세 */}
            {lessonInfo.isRepeat && (
                <Box>
                    <Divider light className={classes.divider} />
                    <Typography variant="subtitle2" gutterBottom>
                        커리큘럼 상세정보 보기
                    </Typography>
                    <Typography variant="body2" color="primary" gutterBottom>
                        * 선택한 기간에 이용가능한 커리큘럼만 확인하실 수 있습니다.
                    </Typography>
                    <Box height={10} />
                    <LessonAccordian
                        curriculums={filteredCurris}
                        recurrentDates={lessonInfo.recurrentDates}
                        withoutTimeTable={withoutTimeTable}
                    />
                </Box>
            )}

            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                학원용 알림사항
            </Typography>
            <Typography variant="caption" color="error" gutterBottom>
                * 각 학원에서 해당 수업에 공지하는 내용을 입력해주세요.
            </Typography>
            <TextField
                label="공지"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.textField}
                value={descForParent.info1}
                onChange={(e) => setDescForParent({ ...descForParent, info1: e.target.value })}
            />
            <TextField
                label="준비물 등"
                variant="filled"
                color="secondary"
                size="small"
                margin="dense"
                className={classes.textField}
                value={descForParent.info2}
                onChange={(e) => setDescForParent({ ...descForParent, info2: e.target.value })}
            />
            <Divider light className={classes.divider} />
            <Typography variant="subtitle2" gutterBottom>
                수업 오픈 여부
            </Typography>
            <FormControlLabel
                control={
                    <Switch
                        checked={status ? true : false}
                        value={status ? true : false}
                        onChange={(e) => setStatus(e.target.checked)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label={status ? '수업오픈' : '수업닫힘'}
                className={classes.switch}
            />
            <br />
            <Typography variant="caption" color="error">
                * 꺼두시면, 학부모에게 노출되지 않습니다.
            </Typography>
            <Box align="center" style={{ margin: 10 }}>
                <Typography variant="body1" color="error">
                    {managerErrors ? managerErrors.error : ''}
                </Typography>
                <Typography variant="body1" color="error">
                    {validCheck ? errorMessage : ''}
                </Typography>
            </Box>
            {loading && <CenterLoading />}

            <Box align="right" style={{ margin: 10 }}>
                <Button
                    color="primary"
                    className={classes.button}
                    variant="contained"
                    onClick={handleUpdateLesson}
                    startIcon={<SaveRounded />}
                    disabled={loading}
                >
                    {' '}
                    저장
                </Button>
                <Button
                    color="secondary"
                    className={classes.button}
                    variant="outlined"
                    onClick={handleDeleteLesson}
                    startIcon={<DeleteRounded />}
                    disabled={loading}
                >
                    {' '}
                    삭제
                </Button>
            </Box>
        </Box>
    );
};
const numberFormat = (inputNumber) => {
    if (!inputNumber) return;
    if (typeof inputNumber === 'number') {
        inputNumber = inputNumber.toString();
    }
    return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* const dd = (dayString) => {
    dayjs.extend(utc);
    return dayjs.utc(dayString).format();
} */
const mH = (num) => {
    var myNum = Number(num);
    var hours = Math.floor(myNum / 60);
    var minutes = myNum % 60;
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
};
const reversemH = (num) => {
    var a = Number(num.split(':')[0]);
    var b = Number(num.split(':')[1]);
    return a * 60 + b;
};
const isEmpty = (string) => {
    if (!string) {
        return true;
    } else if (string.toString().trim() === '') {
        return true;
    } else {
        return false;
    }
};

const udate = (string) => {
    const timeGap = 9;
    const d = new Date(dayjs(string).format('YYYY-MM-DDTHH:mm:ss')); // 정리됨.
    const da = new Date(
        Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours() - timeGap, d.getMinutes(), d.getSeconds())
    );
    return da;
};
const udateImportSdt = (string, startDate) => {
    const timeGap = 9;
    const sdt = new Date(dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'));
    const d = new Date(dayjs(string).format('YYYY-MM-DDTHH:mm:ss'));
    const da = new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            sdt.getHours() - timeGap,
            sdt.getMinutes(),
            sdt.getSeconds()
        )
    );

    return da;
};
const makeWeekday = (arr) => {
    //eslint-diable-next-line
    arr.map((ar) => {
        if (ar === 'MO') ar = RRule.MO;
        if (ar === 'TU') ar = RRule.TU;
        if (ar === 'WE') ar = RRule.WE;
        if (ar === 'TH') ar = RRule.TH;
        if (ar === 'FR') ar = RRule.FR;
        if (ar === 'SA') ar = RRule.SA;
    });

    return arr;
};
// rrule 적용하는 기능
const makeRrule = (wday, startDate, endDate, rDates, exDates, count, isCountLimit, inheritCount) => {
    let EX = exDates.trim().replace(' ', '').split(',');
    let RD = rDates.trim().replace(' ', '').split(',');
    let cnt = count ? Number(count) : 0;
    const rruleSet = new RRuleSet();
    rruleSet.rrule(
        new RRule({
            freq: RRule.WEEKLY,
            byweekday: makeWeekday(wday),
            dtstart: udate(startDate),
            until: isCountLimit ? null : udate(endDate),
            count: cnt === 0 ? inheritCount : cnt,
        })
    );

    if (RD[0].length > 0) {
        RD.map((r) => rruleSet.rdate(udateImportSdt(r, startDate)));
    }
    if (EX[0].length > 0) {
        EX.map((r) => rruleSet.exdate(udateImportSdt(r, startDate)));
    }
    let result = rruleSet.all().map((date) => dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss'));

    return result;
};

export default withStyles(styles)(LessonInfo);
