import React, { useState } from 'react';
import dayjs from 'dayjs';
// Redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { getVisitLog } from '../../redux/actions/adminActions';

// components

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';


import { Box, Typography, TextField, Button, Divider, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@material-ui/core';
import { isLogged } from '../../redux/actions/userActions';

const styles = (theme) => ({
    ...theme.userPage,
    btn: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
});
export const AdminCustomer = (props) => {
    const { classes, history } = props;
    const dispatch = useDispatch();
    const { adminVisitLog } = useSelector(state => state.admin);

    const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYYMMDD'));
    const [toDate, setToDate] = useState(dayjs().format('YYYYMMDD'));

    const handleLogs = () => {
        const res = dispatch(isLogged(history));

        if (res === 'logged') {
            dispatch(getVisitLog({
                from: fromDate,
                to: toDate
            }));
        }
    };

    // test
    console.log(dayjs().isAfter('2021-01-01'));



    // test
    return (
        <Box p={1} m={1}>

            <Typography variant='subtitle1' gutterBottom>방문 현황 조회</Typography>
            <Divider light />
            <Box m={1} p={1}>
                <TextField
                    size='small'
                    margin='dense'
                    style={{ backgroundColor: '#FAF3E7', width: 90 }}
                    required
                    value={fromDate}
                    onChange={e => setFromDate(e.target.value)}
                />
                <Typography display='inline' className={classes.text}> ~ </Typography>
                <TextField
                    size='small'
                    margin='dense'
                    style={{ backgroundColor: '#FAF3E7', width: 90 }}
                    required
                    value={toDate}
                    onChange={e => setToDate(e.target.value)}
                />
                <Button color='primary' size='small' variant='contained' onClick={handleLogs} className={classes.btn} >조회</Button>
            </Box>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>날짜</TableCell>
                            <TableCell>시간</TableCell>
                            <TableCell>학원</TableCell>
                            <TableCell><Tooltip title="레벨 0:시스템관리자, 레벨 1:관리자, 레벨 2: 학원장, 레벨 3:교사, 레벨 9:학부모/일반"><Button>레벨</Button></Tooltip></TableCell>
                            <TableCell>이름</TableCell>
                            <TableCell>전화</TableCell>
                            <TableCell>매체</TableCell>
                            <TableCell>디렉터리</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adminVisitLog.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="left" component="th" scope="row">
                                    <Typography variant='body2'>{row.date}</Typography>
                                </TableCell>
                                <TableCell>{dayjs(row.createdAt * 1000).format('HH:mm')}</TableCell>
                                <TableCell>{row.hName}</TableCell>
                                <TableCell>{row.level}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.phone}</TableCell>
                                <TableCell>{row.media}</TableCell>
                                <TableCell>{row.dir ? row.dir : '/'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>


    );
};



export default withStyles(styles)(AdminCustomer);

