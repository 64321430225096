import React from 'react';
import { Link } from 'react-router-dom';

// MUI stuff
import { Grid, Button, Typography, Paper } from '@material-ui/core';


export const NoHakwon = (props) => {
   
    return (
        /* 가맹원이 없을 때 */
        <Paper style={{ maxWidth: 600, margin: `20px auto`, padding: 25 }}>
            <Grid container spacing={2}>
                <Grid item style={{ width: 100 }} >
                    <Typography component='div' align='center' variant='h5' style={{ marginTop: 20 }}>안내</Typography>
                </Grid>
                <Grid item xs>
                    <Typography color='error' variant='body2' gutterBottom>해당 지역에는 아직 '어린음악대' 학원이 없습니다. </Typography>
                    <Typography variant='body1' gutterBottom>집에서 만나는 '온라인' 클래스와 다양한 서비스를 이용해 보세요. </Typography>
                   <Button variant='contained' color='secondary' style={{marginRight: 25, marginTop: 25}} fullWidth component={Link} to='/lesson'>온라인 클래스</Button>
                </Grid>
            </Grid>
        </Paper>


    );
};

export default NoHakwon;

