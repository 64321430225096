import {
    SET_USER, SET_AUTHENTICATED, SET_UNAUTHENTICATED, 
    LOADING_USER, STOP_LOADING_USER,
    LIKE_PRODUCT, UNLIKE_PRODUCT,
    MARK_NOTIFICATIONS_READ,
    SET_KAKAO, SET_KAKAOINFO, SET_BADGE, 
} from '../types';

// 고객 정보 관련
const initialState = {
    authenticated: false,
    userLoading: false,
    credentials: {},
    likes: [],
    notifications: [],
    kakao: {},
    kakaoInfo: {},
    rnr: {},
    badge: 0

    
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER:
            
            return {
                ...state,
                authenticated: true,
                userLoading: false,
                credentials: action.payload.credentials, // credentials.. likes... noti... 등등 DB에서 가져오기
                notifications: action.payload.notifications,
                likes: action.payload.likes,
                rnr: action.payload.rnr
            }
        case LOADING_USER:
            return {
                ...state,
                userLoading: true
            }
        case STOP_LOADING_USER:
            return {
                ...state,
                userLoading: false
            }
        case LIKE_PRODUCT:
            return {
                ...state,
                likes: [
                    ...state.likes,
                    {
                        name: state.credentials.name,
                        productId: action.payload.productId
                    }
                ]
            }
        case UNLIKE_PRODUCT:
            return {
                ...state,
                likes: state.likes.filter(like => like.productId !== action.payload.productId)
            }
        case MARK_NOTIFICATIONS_READ:
            state.notifications.forEach(
                not => not.read = true
            );
            return {
                ...state
            }
        case SET_BADGE:
            return {
                ...state,
                badge: action.payload
            }

        case SET_KAKAO:
            return {
                ...state,
                kakao: action.payload
            }
        case SET_KAKAOINFO:
            return {
                ...state,
                kakaoInfo: action.payload
            }
        default:
            return state;
    }
}