export default {
    palette: {
        type: 'light',
        background: {
            default: "#eeeeee"
        },
        primary: {
            main: '#f15624',
            light: '#f79845',
            dark: '#852508',
            contrastText: '#ffffff',
            backgroundColor: '#ffffff',
        },
        secondary: {
            main: '#0eb54c',//'#ffee58',
            light: '#fff9c4',
            dark: '#03c75a', // #fdd835
            contrastText: '#ffffff',
            backgroundColor: '#ffffff',
        }
    },
    typography:{
        useNextVariant: true,
        h1 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 40,
            fontWeight: 700
        },
        h2 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 30,
            fontWeight: 700
        },
        h3 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 24,
            fontWeight: 700
        },
        h4 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 20,
            fontWeight: 700
        },
        h5 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 18,
            fontWeight: 500
        },
        h6 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 14,
            fontWeight: 500
        },
        subtitle1 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 16,
            fontWeight: 500
        },
        subtitle2 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 14,
            fontWeight: 500
        },
        body1 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 16,
            fontWeight: 300
        },
        body2 :{
            fontFamily: 'Noto Sans KR',
            fontSize: 14,
            fontWeight: 300
        },
        button :{
            fontFamily: 'Noto Sans KR',
            fontSize: 14,
            fontWeight: 500
        },
        caption :{
            fontFamily: 'Noto Sans KR',
            fontWeight: 200
        },
        overline:{
            fontFamily: 'Noto Sans KR',
            fontWeight: 300
        } 
    },
    userPages: {
        form: {
            textAlign: 'center'
        },
        image: {
            margin: '20px auto 20px auto'
        },
        pageTitle: {
            margin: '10px auto'
        },
        textField: {
            margin: '10px 15px'
        },
        button: {
            margin: '20px auto',
            position: 'relative'
        },
        customError: {
            color: 'red',
            fontSize: '0.8rem',
            marginTop: 10
        },
        progress: {
            position: 'absolute',
        },
        invisibleSeparator: {
            border: 'none',
            margin: 4
        },
        visibleSeparator: {
            width: '100%',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            marginBottom: 20
        },
        paper: {
            padding: 20,
            margin: 5 // spacing이 viewport가 깨지더라
        },
        
       
        // profile
        profile: {
            '& .image-wrapper': {
                textAlign: 'center',
                position: 'relative',
                '& button': {
                    position: 'absolute',
                    top: '80%',
                    left: '70%'
                }
            },
            '& .profile-image': {
                width: 200,
                height: 200,
                objectFit: 'cover',
                maxWidth: '100%',
                borderRadius: '50%'
            },
            '& .profile-details': {
                textAlign: 'center',
                '& span, svg': {
                    verticalAlign: 'middle'
                },
                '& a': {
                    color: '#ea4f0c'
                }
            },
            '& hr': {
                border: 'none',
                margin: '0 0 10px 0'
            },
            '& svg.button': {
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
        buttons: {
            textAlign: 'center',
            '& a': {
                margin: '20px 10px'
            }
        },
        card: {
            position: 'relative',
            display: 'flex',
            marginBottom: 20
        },
        productImage: {
            minWidth: 200,
        },
        content: {
            padding: 25,
            objectFit: 'cover'
        },
        deleteButton: {
            position: 'absolute',
            left: '90%',
            top: '10%'
        },
        submitButton:{
            position: 'relative',
            float: 'right',
            marginTop: 10
        },
        progressSpinner: {
            position: 'absolute'
        },
        closeButton:{
            position: 'absolute',
            left: '91%',
            top: '6%'
        },
        commentImage: {
            maxWidth: '100%',
            height: 100,
            objectFit: 'cover',
            borderRadius: '50%'
        },
        commentData: {
            marginLeft: 20,
        }
        
        
    }  

}
