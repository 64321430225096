import React from 'react';
// components
import Navbar from '../../components/layout/Navbar';
import EnrollStepTitle from '../../components/enroll/EnrollStepTitle';
import MainGrid from '../../components/layout/MainGrid';
import TempPay from '../../components/enroll/TempPay';

import Footer from '../../components/layout/Footer';
// redux stuff

// mui stuff
import withStyles from '@material-ui/core/styles/withStyles';




const styles = theme => ({
    ...theme.userPages,
    button: {
        margin: theme.spacing(3),
    },
    buttonRight: {
        margin: theme.spacing(3),
        paddingLeft: 'auto'
    },
    leftMargin: {
        marginLeft: theme.spacing(2),
    }
});


const TempPayment = props => {

    //const { classes } = props;
    // redux
   
    return (
        <>
            <Navbar menu={1} />
            <MainGrid>
                <EnrollStepTitle currentStep={4} />
                
                <TempPay />

                <Footer />
            </MainGrid>
        </>
    )

}

export default withStyles(styles)(TempPayment);
